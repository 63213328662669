<template>
  <!-- 表单组件-普通单行文本输入 -->
	<div class="formModule">
		<div class="formModule-header">
			<div class="title">
				<span class="order-num">{{index}}.</span>
				<span class="txt">{{formDetail.title}}</span>
			</div>
			<div class="extend">
				<div class="isMust" v-if="formDetail.isNeed=='1'">必填</div>
			</div>
		</div>
		<div class="formModule-desc">填写 {{formDetail.rule.placeholder?'| '+formDetail.rule.placeholder:''}}</div>
		<div class="formModule-content">
			<div class="mod-input">
				<!-- <input class="input" type="text" placeholder="请输入"
					v-model="formDetail.value" /> -->
          <el-input placeholder="请输入" v-model="formDetail.value" clearable></el-input>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    formItem: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      formDetail: this.formItem,
    };
  },
  methods: {
    //清除输入框的值
    clearValue() {
      this.formDetail.value = null;
    },
  },
  watch: {
    "formDetail.value": {
      handler() {
        this.$bus.$emit("formDetail", this.formDetail);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner{
  background-color: #f7f7f7 !important;
  border: none;
}
::v-deep .el-input__suffix {
    .el-input__suffix-inner {
      border-color: none;
      .el-icon-circle-close:before {
        font-size: 16px;
      }
    }
  }

input::-webkit-input-placeholder { 
/* WebKit browsers */ 
color: #ccc; 
} 
input:-moz-placeholder { 
/* Mozilla Firefox 4 to 18 */ 
color: #ccc; 
} 
input::-moz-placeholder { 
/* Mozilla Firefox 19+ */ 
color: #ccc; 
} 
input:-ms-input-placeholder { 
/* Internet Explorer 10+ */ 
color: #ccc; 
}

.formModule {
		font-size: 14px;
		padding: 15px;
		height: auto;
		background-color: #ffffff;

		.formModule-header {
			display: flex;
			margin-bottom: 10px;

			.title {
				font-size: 15px;
				font-weight: 600;

				.txt::after {
					content: "：";
				}
			}

			.extend {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-left: 10px;
			}
		}

		.isMust {
			color: #ff0000;
			font-size: 14px;
		}

		.formModule-desc {
			margin-bottom: 12px;
			font-size: 12px;
			color: #9b9999;
			line-height: 1.35;
		}

		.formModule-content {}

		.op-inner {
			display: flex;
			align-items: stretch;
			justify-content: flex-end;
			line-height: 1;

			.btn-icon,
			.btn-more {
				font-size: 16px;
				display: flex;
				align-items: center;
				padding: 8px 10px;
				color: #9b9999;
			}
		}

		.mod-input {
			background-color: #f7f7f7;
			display: flex;
			align-items: stretch;
			border-radius: 6px;
			overflow: hidden;
			margin: 6px 0;

			.input {
				flex: 1;
				background-color: transparent;
				border: none;
				padding: 0 10px;
				font-size: 14px;
				height: 44px;
				line-height: 1.35;
			}
		}
	}
</style>