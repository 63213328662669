<template>
  <section class="datail-content w">
    <div class="datail-card" v-loading.fullscreen.lock="isLoading">
      <div class="main-content">
        <div class="c-left">
            <!-- <PolicySubForm :dataForm="dataForm"/> -->
        </div>
      </div>

      <div class="c-right">
        <div>
          <re-station></re-station>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ReStation from "@/components/station/reStation.vue";
import { getStationForm } from "@/api/station/station.js";

export default {
  components: {
    ReStation,
  },
  data() {
    return {
      dataForm:{},
      isLoading: true,


    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getStationForm().then((res) => {
        if (!res) return;
        console.log("res.data.data.formList",res.data.formList);
        // this.dataForm.policyName = this.policyData.policyName;
        this.isLoading = false;
      })
    },
    
  },
};
</script>

<style lang="scss" scoped>
.datail-content {
  //   height: 100%;
  min-height: 800px;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  .datail-card {
    display: flex;
    padding: 40px 50px 0;
    .main-content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .c-left {
      padding: 30px;
      // border: 1px solid rgba(229, 229, 229, 1);
      box-shadow: -4px 3px 15px rgba(173, 213, 255, 0.5);
      -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
      background-color: #fff;
    }
    .c-right {
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      .banner {
        width: 369px;
        height: 154px;
        margin-bottom: 30px;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>
