import http from "@/utils/http.js"

// 获取订阅状态
export function getSubscribe(data) {
    return http({
        url: "/api/web/subscribe/getSubscribe",
        data: data,
        method: 'GET'
    })
}

// 获取订阅状态
export function updateSubscribe(data) {
    return http({
        url: "/api/web/subscribe/updateSubscribe",
        data: data,
        method: 'PUT'
    })
}