<template>
  <div class="location-plate">
    <!-- 1.搜索框 -->
    <div class="search">
      <el-input v-model="inputValue" @input="inputChange" placeholder="请输入地市名称" clearable>
         <el-button slot="append" icon="el-icon-search" class="el-button" @click="searchLocation">搜索</el-button>  
      </el-input>
    </div>
    <div class="search-result" v-if="inputValue">
      <div class="result-content">共搜索到<span>{{selectLocation?selectLocation.length:0}}</span>个结果</div>
      <div class="result-city" v-if="selectLocation&&selectLocation.length">
        <div class="result-city-item hover-effect" @click="saveLocation(item)" v-for="(item,index) in selectLocation" :key="index" :title="item.locationName">{{item.locationName}}</div>
      </div>
      <div class="empty" v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
    <!-- 2. 最近访问 -->
    <div v-else>
      <div class="history" >
        <p>最近访问</p>
        <div class="history-city">
          <el-button @click="saveLocation(item)" class="item" v-for="(item,index) in historyCityList" :key="index" :title="item.locationName">{{item.locationName}}</el-button>
        </div>
      </div>
      <!-- 3. tab栏 -->
      <div class="tab">
        <div class="hover-effect" :class="{'currentColor':currentTabCode == item.title}" 
        @click="clickTab(item.title)" v-for="(item,index) in tab" :key="index">
          {{item.title}}
          <p :style="{'width':item.pWidth}" v-if="currentTabCode == item.title"></p>
        </div>
      </div>
      <!-- 4. 地区 -->
      <div class="area1" v-if="currentTabCode == '推荐城市'">
        <div class="history-city">
          <el-button @click="saveLocation(item)" class="item" v-for="(item,index) in hotCityList" :key="index">{{item.locationName}}</el-button>
        </div>
      </div>
      <div class="area" v-else>
        <div class="area-item" v-for="(item,index) in allLocationList" :key="index">
          <div class="initial">{{item.initial}}</div>
          <div class="city" :class="{'city-padding':areaIndex!=0}" v-for="(arr,areaIndex) in item.useArr" :key="areaIndex">
            <div @click="saveLocation(obj)" class="hover-effect" v-for="(obj,code) in arr" :key="code" :title="obj.locationName">{{obj.locationName}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllLocation,saveCurrentLocation,getSearchLocation } from "@/api/common/common.js";
export default {
  props: {
    visible:{
      type:Boolean,
      default:false
    },
    loginStatus:Boolean
  },
  data() {
    return {
      inputValue:"",
      currentTabCode:"ABCDE",
      currentTabCodeList:["A","B","C","D","E"],
      allCitiesList:[],  //全部城市
      historyCityList:[], //历史城市
      hotCityList:[], // 热门城市
      selectLocation:[],//搜索信息
      allLocationList:[],
      tab:[{
        title:"推荐城市",
        pWidth:"50px"
      },
      {
        title:"ABCDE",
        pWidth:"50px"
      },
      {
        title:"FGHIJ",
        pWidth:"40px"
      },{
        title:"KLMNO",
        pWidth:"50px"
      },
      {
        title:"PQRST",
        pWidth:"50px"
      },
      {
        title:"UVWXYZ",
        pWidth:"60px"
      }
      
      ],
      fontSize:"38px",
    };
  },
  methods: {
    //输入框事件
    inputChange(){
      if(this.inputValue){
        this.searchLocation(this.inputValue);
      }
    },
    //点击搜索事件
    clickSearch(){
      if(this.inputValue){
        this.searchLocation(this.inputValue);
      }
    },
    //搜索定位信息
    searchLocation(key){
      getSearchLocation(key).then(res =>{
          this.selectLocation = res.data;
      })
    },
    clickTab(code){
      this.currentTabCode = code;
      this.currentTabCodeList = code.split("");
      this.allLocationList = this.allCitiesList.filter(e =>{
        return this.currentTabCodeList.indexOf(e.initial) > -1;
      })
    },
    // use
    getLocation(){
      getAllLocation().then(res =>{
        if(res.data.allCitiesList){
          this.allCitiesList = this.sliceAllLocationArr(res.data.allCitiesList);
        }
        if(res.data.historyCityList){
          this.historyCityList = res.data.historyCityList;
        }
        if(res.data.hotCityList){
          this.hotCityList = res.data.hotCityList;
        }

        this.allLocationList = this.allCitiesList.filter(e =>{
          return this.currentTabCodeList.indexOf(e.initial) > -1;
        })
      })
    },
    //分割全部地区 数组为二维
    sliceAllLocationArr(arr){
      for(let index = 0; index < arr.length; index++) {
        var result = [];
        for (var i = 0; i < arr[index].cityList.length; i += 6) {
          let splic = arr[index].cityList.slice(i, i + 6);
          result.push(splic);
        }
        arr[index].useArr = result;
      }
      return arr;
    },
    //保存定位
    saveLocation(item){
      let params = {
          areaCode:item.areaCode,
          city:item.locationName
      }
      if(!this.loginStatus){
        this.$emit('closeLocation',params);
        return;
      }
      saveCurrentLocation(params).then(res =>{
        console.log(res);
        this.$emit('closeLocation',params);
      })
    }
  },
  created(){
    this.getLocation();
  },
  watch:{
    visible(visible){
      if(visible){
        this.getLocation();
      }else{
        this.inputValue = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//输入框按钮
::v-deep .el-input__inner {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-right: none;
}
//搜索按钮
::v-deep .el-input-group__append{
  background-color: #597af8;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}

.currentTab{
  color: #597AF8 !important;
  p{
    border: 2px solid black;
  }
}
.location-plate{
  min-height: 650px;
  height: auto;
  .search{
    width: 80%;
    margin-left: 20px;
    .el-button{
      color:#fff;
      background-color:#409EFF;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }
  .search-result{
    margin-left: 20px;
    margin-top: 20px;
    .result-content{
      font-size: 18px;
      color: #000;
      font-weight: bold;
      span{
        padding: 0px 4px;
        color:#2A82E4;
      }
    }
    .result-city{
      display: flex;
      flex-wrap: wrap;
      .result-city-item{
        width: 16%;
        padding:20px 10px 0px 10px;
        font-size: 18px;
        display: -webkit-box;
        word-break: break-all;/*换行规则*/
        text-overflow: ellipsis;/*最后使用省略号显示*/
        overflow: hidden;/*超出部分隐藏*/
        -webkit-box-orient: vertical;
        -webkit-line-clamp:1;/*能够显示几行*/
      }
    }
    .empty {
      min-height: 600px;
      height: auto;
      display: flex;
      justify-items: center;
      justify-content: center;
    }
  }
  .history{
    margin-left: 20px;
    margin-top: 25px;
    p{
      font-weight: bold;
      font-size: 20px;
      color: #000;
    }

    .history-city{
      margin-top: 12px;
      .item{
        width: 80px;
        height: 40px;
        border-radius: 10px;
        margin-left: 10px;
        margin-top: 12px;
        word-break: break-all;/*换行规则*/
        text-overflow: ellipsis;/*最后使用省略号显示*/
        overflow: hidden;/*超出部分隐藏*/
        -webkit-box-orient: vertical;
        -webkit-line-clamp:1;/*能够显示几行*/
      }
    }
  }
  .tab{
    margin-top: 25px;
    background-color: #DAE8F7;
    height: 40px;
    line-height: 35px;
    color: #000;
    font-size: 14px;
  }

  .tab{
    padding-left: 40px;
    display: flex;
    align-items: center;    
    div{
      text-align: left;
      width: 16%;
      padding-left: 10px;
      
    }
    p{
      height: 3px;
      background-color: #2A82E4;
    }
    .currentColor{
      color:#2A82E4;
    }
  }
  .area1{
    .history-city{
      margin-left: 20px;
      margin-top: 12px;
      .item{
        margin-top: 12px;
        margin-left: 10px;
        width: 80px;
        height: 40px;
        border-radius: 10px;
      }
    }
  }
  .area{
    margin-top: 20px;
    
    .area-item{
      .initial{
        width: 1%;
        color: #4994E7;
        float: left;
        font-size: 18px;
        padding-top: 6px;
      }
      .city{
        padding-left: 30px;
        text-align: left;
        display: flex;
        div{
          width: 16%;
          padding-left: 10px;
          padding-top: 6px;
          display: -webkit-box;
          word-break: break-all;/*换行规则*/
          text-overflow: ellipsis;/*最后使用省略号显示*/
          overflow: hidden;/*超出部分隐藏*/
          -webkit-box-orient: vertical;
          -webkit-line-clamp:1;/*能够显示几行*/
          font-size: 18px;
        }
       
      }
      .city-padding{
        padding-left: 40px;
      }
    }
    
    .area-initial{
      color: #4994E7;
    }
  }

}


</style>
