<template>
  <div class="home-page page">
    <div class="page-wrap" v-if="displayPage">
      <!-- 顶部 -->
      <page-top :isShowHome="false"></page-top>
      <!-- 头部 -->
      <Header :list="headerList" currentIndex="1"></Header>
      <!-- 主体 -->
      <main class="main">
        <!-- 轮播图 -->
        <section class="commonBlock w">
          <div class="banner-block">
            <banner :list="bannerList"></banner>
          </div>
          <div class="extend-block">
            <div class="entry-block">
              <div class="btn toactiv" @click="handleMatchClick(1)"></div>
              <div class="btn tomatch" @click="handleMatchClick(2)"></div>
            </div>
            <!-- 最新动态 -->
            <div class="dynamic">
              <div class="header">
                <div class="title">
                  <img
                    class="img"
                    :src="$globalHeader.imgUrl + '/static/icon/v2/dynamic.png'"
                    alt="news"
                  />
                </div>
                <div
                  class="more hover-effect"
                  @click="handleEnter('matchDynamic')"
                >
                  更多<span class="icon iconfont icon-more"></span>
                </div>
              </div>
              <div class="list">
                <div
                  class="item hover-effect"
                  v-for="(item, index) in dynamicTitle"
                  @click="dynamicDetailClick(item.id)"
                  :key="index"
                >
                  <div class="title">
                    <div class="spot"></div>
                    <div class="txt">{{ item.title }}</div>
                  </div>
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- <section class="kind-wrap w">
          <div class="k-top">
            <div class="title">
              2023年海峡两岸女大学生创新创业大赛 - 配套活动事项报名
            </div>
          </div>
          <div class="k-bot">
            <div class="k-item" @click="toFormKind(item)" v-for="(item, index) in matchFormKindList" :key="index">
              <div class="i-top-item-left">
                <span class="icon iconfont icon-join-record"></span>
              </div>
              <div class="i-top-item-right">
                <div class="subtitle">{{ item.headline || "" }}</div>
                <div class="title">{{ item.kindName || "" }}</div>
              </div>
            </div>
          </div>
        </section> -->

        <!-- 大赛推荐 -->
        <!-- <section class="match-wrap w">
          <div class="m-top">
            <div class="m-t-left">
              <div class="img-wrap">
                <img
                  class="img"
                 :src="$globalHeader.imgUrl +'/static/icon/mod_match.png'"
                  alt="图标"
                />
              </div>
              <span class="title">大赛推荐</span>
            </div>
            <div class="m-t-right">
              <div class="item-wrap">
                <div class="item" @click="handleMatchTab(0)">
                  <span :class="currentMatchIndex == 0 ? 'active' : ''"
                    >全部</span
                  >
                </div>
                <div class="item" @click="handleMatchTab(1)">
                  <span :class="currentMatchIndex == 1 ? 'active' : ''"
                    >进行中</span
                  >
                </div>
                <div class="item" @click="handleMatchTab(2)">
                  <span :class="currentMatchIndex == 2 ? 'active' : ''"
                    >已结束</span
                  >
                </div>
              </div>
              <div class="item-wrap" @click="handleMyMatch()">
                <div class="item">
                  <span>我的报名</span>
                </div>
              </div>
            </div>
          </div>
          <div class="m-content" v-loading="loading">
            <match-list
              :matchList="matchList"
              :matchDynamicList="matchDynamicList"
            ></match-list>
          </div>
          <div class="m-bot">
            <el-pagination
              background
              layout="prev, pager, next"
              prev-text="上一页"
              next-text="下一页"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              :total="pageCount"
              @current-change="changePageClick"
            >
            </el-pagination>
          </div>
        </section> -->
        <section class="matchBlock w">
          <div class="header">
            <div class="title">
              <span class="txt">大赛推荐</span>
              <span class="side"></span>
            </div>
            <div
              class="more hover-effect"
              @click="handleEnter('match/list?competitionType=1')"
            >
              更多<span class="icon iconfont icon-more"></span>
            </div>
          </div>
          <div class="match-content" v-loading="loading">
            <match-list :matchList="matchList"></match-list>
          </div>
          <div
            class="btn-more"
            @click="handleEnter('match/list?competitionType=1')"
          >
            <span>查看更多赛事活动</span>
            <span class="icon iconfont icon-more-txt"></span>
          </div>
        </section>

        <!-- 右侧悬浮 -->
        <div class="float-box">
          <div class="float-wrap">
            <div class="btn-warp">
              <div class="item share" @click="toPopup('share')">
                <div class="icon iconfont icon-shareTo"></div>
                <div class="text">转发分享</div>
              </div>
              <div class="item share" @click="toPopup('contact')">
                <div class="icon iconfont icon-service"></div>
                <div class="text">联系客服</div>
              </div>
              <div class="item scan" @click="toPopup('code')">
                <div class="code">
                  <img
                    class="img"
                    :src="
                      $globalHeader.imgUrl + '/static/other/mini_concat.png'
                    "
                    alt="二维码"
                  />
                </div>
                <div class="text">扫码访问</div>
              </div>
            </div>
            <div
              class="item back-top"
              @click="backToTop"
              :style="{ visibility: floatStyle }"
            >
              <div class="icon iconfont icon-totop"></div>
              <div class="text">返回顶部</div>
            </div>
          </div>
        </div>
        <!-- 分享弹窗 -->
        <div class="popup-wrap share-popup" v-if="showSharePopup">
          <el-dialog
            :visible.sync="showSharePopup"
            :close-on-click-modal="true"
            title="转发分享"
          >
            <div class="img-wrap">
              <img class="img" :src="qrcode" alt="二维码" />
            </div>
            <div class="tips">微信扫一扫或发送图片</div>
            <div class="address">
              {{ currentRouter }}
            </div>
            <div class="btn-wrap">
              <div class="btn-item" @click="copyRouter">复制链接</div>
              <div class="btn-item" @click="saveQRCode">保存图片</div>
            </div>
          </el-dialog>
        </div>

        <!-- 客服弹窗 -->
        <div class="popup-wrap share-popup" v-if="showContactPopup">
          <el-dialog
            :visible.sync="showContactPopup"
            :close-on-click-modal="true"
            title="联系客服"
          >
            <div class="img-wrap">
              <img
                class="img"
                :src="$globalHeader.imgUrl + '/static/other/corp_concat.jpg'"
                alt="二维码"
              />
            </div>
            <div class="tips">请使用微信“扫一扫”，添加客服</div>
          </el-dialog>
        </div>

        <!-- 手机站点弹窗 -->
        <div class="popup-wrap share-popup" v-if="showCodePopup">
          <el-dialog
            :visible.sync="showCodePopup"
            :close-on-click-modal="true"
            title="扫码访问"
          >
            <div class="img-wrap">
              <img
                class="img"
                :src="$globalHeader.imgUrl + '/static/other/mini_concat.png'"
                alt="二维码"
              />
            </div>
            <div class="tips">请使用微信“扫一扫”，进入小程序操作</div>
          </el-dialog>
        </div>
      </main>
      <!-- 底部 -->
      <Footer></Footer>
    </div>

    <router-view></router-view>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Banner from "@/components/common/banner.vue";
import Footer from "@/components/common/footer.vue";
import MatchList from "@/views/match/components/matchList.vue";

import { getHomeBannerList, getDynamicData } from "@/api/home/home.js";
import { getMatchList, getMatchFormKind } from "@/api/match/match.js";
import { getDynamicPage } from "@/api/match/matchDynamic.js";
import { local } from "@/utils/storage.js";
import { shareQRCode } from "@/api/common/common.js";

export default {
  components: { pageTop, Header, Banner, Footer, MatchList },
  data() {
    return {
      isLogin: false,
      displayPage: true,
      loading: false,
      headerList: [], //header的tab列表
      bannerImgHeight: "", //轮播图图片高度
      bannerList: [], //轮播图列表

      currentMatchIndex: 0, //比赛当前tab
      matchList: [], //比赛列表数据
      matchDynamicList: [], //赛事动态列表
      matchFormKindList: [], //赛事配套列表

      pageSize: 8, //分页数量
      pageCount: 0, //赛事列表总数

      dynamicTitle: [],

      showSharePopup: false, //显示分享弹窗
      floatUp: false,
      qrcode: "", //分享二维码
      qrcodeName: "", //二维码下载文件名
      currentRouter: "",
      showContactPopup: false, //显示客服弹窗
      showCodePopup: false, //显示站点弹窗
      floatStyle: "hidden", //控制右侧漂浮按钮显示
      scrollTop: 0, //滚动top
    };
  },
  created() {
    this.isLogin = local.Get("isLogin");
    this.fetchData();
  },
  methods: {
    //统一获取数据
    fetchData() {
      this.getMatchData();
      this.getMatchFormKind();
      this.getHomeBannerList();
      this.getMatchDynamicList();
      this.getDynamicData();
      this.getQRCode();
      setTimeout(() => {
        this.getBannerImgHeight();
        if (this.$el.querySelector(".page-wrap")) {
          this.pageHeight = this.$el.querySelector(".page-wrap").offsetHeight;
          console.log("this.pageHeight---", this.pageHeight);
        }
        window.addEventListener("scroll", this.handleScroll, true);
      }, 500);
    },
    //获取首页banner
    getHomeBannerList() {
      getHomeBannerList().then((res) => {
        if (!res.data) return;
        this.bannerList = res.data.bannerList;
      });
    },
    //获取赛事动态
    getMatchDynamicList() {
      getDynamicPage({ page: 1, limit: 5 }).then((res) => {
        if (!res || !res.data) return;
        this.matchDynamicList = res.data.list;
        this.matchDynamicList.forEach((e) => {
          if (e.dynamicContent.length > 50) {
            e.dynamicContent = e.dynamicContent.substring(0, 50) + "...";
          }
        });
      });
    },
    //获取赛事配套报名
    getMatchFormKind() {
      getMatchFormKind({ id: "1628991138096312321" }).then((res) => {
        this.matchFormKindList = res.data;
      });
    },
    //根据分类获取赛事数据
    getMatchData(page = 1) {
      getMatchList({
        filtrateValue: this.currentMatchIndex,
        page: page,
        limit: 12,
      }).then((res) => {
        if (!res.data) return;
        this.matchList = res.data.list;
        this.pageCount = res.data.count;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    getDynamicData() {
      const params = {
        isExclude: false,
        limit: 5,
      };
      getDynamicData(params).then((res) => {
        if (res.data) {
          this.dynamicTitle = res.data;
        }
      });
    },
    //配套报名
    toFormKind(item) {
      if (item.applyId) {
        //报名结果页面
        this.$router.push({
          path: "/match/matchDetail/" + this.matchId + "/matchSubResult",
          query: {
            matchId: item.competitionId,
            kindId: item.id,
            applyId: item.applyId,
          },
        });
      } else {
        //报名页面
        this.$router.push({
          path:
            "/match/matchDetail/" +
            this.matchId +
            "/matchKindSub/" +
            item.competitionId +
            "/" +
            item.id,
        });
      }
    },
    //获取分享二维码
    getQRCode() {
      shareQRCode({
        appId: "wx49ed84a58c6d9fc1",
        page: "pages/home",
        scene: "matchDetails:" + this.matchId,
        envVersion: "trial",
      }).then((res) => {
        this.qrcode = res.data;
        this.currentRouter = location.origin + this.$route.path;
      });
    },
    //快速跳转
    handleEnter(url) {
      if (url == "home") {
        this.$router.push("/");
      } else {
        if (url && typeof url == "string") {
          //  this.$router.replace("/" + url);
          window.location.href = "/" + url;
        }
      }
    },
    //分享
    toPopup(e) {
      if (e == "share") {
        this.showSharePopup = true;
      } else if (e == "contact") {
        this.showContactPopup = true;
      } else if (e == "code") {
        this.showCodePopup = true;
      }
    },
    //获取轮播图图片高度
    getBannerImgHeight() {
      if (this.$el.querySelector(".banner-block")) {
        this.bannerImgHeight =
          this.$el.querySelector(".banner-block").offsetHeight;
      }
      // if (!window.document.querySelector("#bannerImg1")) return;
      // this.bannerImgHeight =
      // window.document.querySelector("#bannerImg1").height;
      // console.log("bannerImgHeight----",this.bannerImgHeight);
    },
    // 获取页面滚动距离
    handleScroll() {
      setTimeout(() => {
        this.floatStyle = window.scrollY >= 100 ? "visible" : "hidden";
      }, 300);
    },
    handleMatchClick(competitionType) {
      this.$router.push({
        path: "/match/list?competitionType=" + competitionType,
      });
    },
    //跳转动态详情
    dynamicDetailClick(id) {
      this.$router.push({
        path: "/matchDynamic/dynamicDetail/" + id,
      });
    },
    //复制链接
    copyRouter() {
      let interlinkage = "";
      interlinkage = this.matchDetailData.title + "\n" + this.currentRouter;
      var textarea = document.createElement("textarea");
      textarea.value = interlinkage;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message({
        message: "链接已复制",
        type: "success",
      });
    },
    // 保存图片
    saveQRCode() {
      this.qrcodeName = this.matchDetailData.title + ".png";
      //创建a标签进行下载
      var doc = document.createElement("a");
      doc.href = this.qrcode;
      doc.download = this.qrcodeName;
      document.body.appendChild(doc);
      doc.click();
      document.body.removeChild(doc); // 删除临时实例
    },
    //返回顶部
    backToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    //赛事切换tab
    handleMatchTab(type) {
      console.log(type);
      this.loading = true;
      this.currentMatchIndex = type;
      this.getMatchData();
    },
    //我的报名
    handleMyMatch() {
      this.$router.push("/personCenter");
    },
    //切换页面
    changePageClick(page) {
      this.loading = true;
      this.getMatchData(page);
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.path == "/" || this.$route.path == "/match") {
          this.displayPage = true;
        } else {
          this.displayPage = false;
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$bgImageUrl: "https://gx.scjfu.com";
.home-page {
  display: flex;
  flex-direction: column;

  .main {
    flex: 1;
    padding-bottom: 100px;
    background-color: #efefef;
    .commonBlock {
      display: flex;
      flex-direction: column;
      padding: 20px;
      background-color: #fff;
    }
    .banner-block {
      flex: 1;
      position: relative;
      max-width: 1161px;
      height: 424px;
      overflow: hidden;
    }
    .extend-block {
      display: flex;
      .entry-block {
        display: flex;
        flex-direction: column;
        width: 50%;
        .btn {
          width: 561px;
          height: 116px;
          background: linear-gradient(0deg, #51e3f1 0%, #8cfce5 100%);
          border-radius: 10px;
          margin-top: 15px;
          margin-right: 20px;
        }
        .tomatch {
          background-image: url(#{$bgImageUrl + '/static/pc/competition/detail/web-activity-banner.jpg'});
          cursor: pointer;
        }
        .toactiv {
          background-image: url(#{$bgImageUrl + '/static/pc/competition/detail/web-competition-banner.jpg'});
          cursor: pointer;
        }
      }
    }
    .dynamic {
      display: flex;
      flex-direction: column;
      width: 50%;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0;

        .img {
          height: 100%;
          width: 100%;
        }

        .title {
          display: flex;
          align-items: center;
          height: 26px;
          margin-right: 10px;
        }

        .more {
          line-height: 1;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #888;
          .icon {
            font-size: 14px;
          }
        }
      }

      .list {
        cursor: pointer;
        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          margin-bottom: 12px;
          padding-bottom: 10px;
          border-bottom: 1px solid #f2f2f2;
          // padding: 8px 0;
          .title {
            flex: 1;
            display: flex;
            align-items: center;
          }

          .spot {
            min-height: 6px;
            min-width: 6px;
            border-radius: 50%;
            background-color: #f7932e;
          }

          .txt {
            margin-left: 0.5em;
            display: -webkit-box;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .time {
            font-size: 12px;
            color: #888;
            margin-left: 40px;
          }
        }

        // .item::before {
        //     content: "•";
        //     margin-right: 0.35em;
        //     color: #F7932E;
        // }
        .item:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border: none;
        }
      }
    }

    .kind-wrap {
      margin-bottom: 18px;
      padding: 24px 20px;
      border-radius: 6px;
      background-color: #fff;
      box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

      .k-top {
        padding-bottom: 24px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #000;
      }

      .k-bot {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .k-item {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          margin: 0 8px;
          border-radius: 5px;
          box-sizing: border-box;
          box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
          cursor: pointer;

          .i-top-item-left {
            flex-shrink: 0;
            margin-right: 10px;

            .icon {
              font-size: 28px;
              color: #fff;
            }
          }

          .i-top-item-right {
            flex: 1;
            color: #fff;

            .subtitle {
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .title {
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .k-item:hover {
          opacity: 0.9;
        }

        .k-item:nth-child(1) {
          background: linear-gradient(to right bottom, #f24451, #ee1e27);
        }

        .k-item:nth-child(2) {
          background: linear-gradient(to right bottom, #f2b43f, #f0a41c);
        }

        .k-item:nth-child(3) {
          background: linear-gradient(to right bottom, #609bd1, #3982c3);
        }

        .k-item:nth-child(4) {
          background: linear-gradient(to right bottom, #b4b467, #a6a653);
        }

        .k-item:nth-child(5) {
          background: linear-gradient(to right bottom, #f24451, #ee1e27);
        }
      }
    }
    .float-box {
      position: fixed;
      left: calc(50% + 650px);
      // bottom: 240px;
      top: 360px;
      .float-wrap {
        display: flex;
        flex-direction: column;
        .btn-warp {
          display: flex;
          flex-direction: column;
          background: #fafafa;
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 3px 16px 8px 16px;
          min-width: 72px;
          border-bottom: 1px solid #eaeaea;
          background-color: #fafafa;
          border-radius: 3px;
          color: #666666;
          transition: all 0.3s;
          cursor: pointer;
          position: relative;

          .icon {
            margin: 5px 0;
            font-size: 28px;
            color: #bbbbbb;
          }

          .text {
            font-size: 12px;
          }
          .count {
            position: absolute;
            top: 5px;
            right: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ff0000;
            color: #fff;
            min-width: 18px;
            height: 18px;
            line-height: 1;
            border-radius: 9px;
            font-size: 10px;
            padding: 0 5px;
            overflow: hidden;
          }
        }

        .item:hover {
          background-color: #fff;
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
        }

        .share {
          .icon {
            color: #5776f7;
          }
        }
        .scan {
          border-bottom: none;
          .code {
            width: 40px;
            height: 40px;
            margin: 5px 0;
            .img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .back-top {
          visibility: hidden;
          margin-top: 10px;
          border: 1px solid #eaeaea;
        }
      }
    }
    .popup-wrap {
      .mask {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1001;
        background: rgba(0, 0, 0, 0.5);
      }

      .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32%;
        min-width: 480px;
        min-height: 554px;
        height: auto;
        max-height: 70%;
        overflow: auto;
        background-color: #fff;
        border-radius: 12px;
        z-index: 1002;

        .close-btn {
          position: absolute;
          top: 8px;
          right: 12px;
          padding: 12px;
          color: #333;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .file-list {
        margin-bottom: 20px;
        font-size: 18px;
        a {
          color: #0068c6;
        }
      }
    }

    .share-popup {
      /deep/.el-dialog__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px;
      }

      /deep/.el-dialog {
        min-width: 567px;
        width: 30%;
        border-radius: 24px;
      }

      /deep/ .el-dialog__header {
        .el-dialog__title {
          font-size: 20px;
          color: #000;
        }
      }

      /deep/.el-dialog__headerbtn {
        .el-dialog__close {
          font-size: 28px;
        }
      }

      .img-wrap {
        width: 300px;
        height: 300px;
        overflow: hidden;
        margin-bottom: 26px;
      }

      .tips {
        margin-bottom: 20px;
        font-size: 16px;
        color: #999;
      }

      .address {
        margin-bottom: 20px;
        padding: 0 12px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        border-radius: 6px;
        background-color: #f2f2f2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .btn-wrap {
        display: flex;
        justify-content: center;
        padding-bottom: 24px;

        .btn-item {
          display: inline-block;
          margin: 0 20px;
          padding: 8px 16px;
          font-size: 16px;
          color: #333;
          border-radius: 2px;
          background-color: #f2f2f2;
          cursor: pointer;
        }
      }
    }
    .matchBlock {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0;
        border-bottom: 2px solid #85b4f8;
        .title {
          position: relative;
          width: 115px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(0deg, #84b1f8 0%, #8be1f5 100%);
          border-radius: 10px 10px 0px 0px;
          .ico {
            width: 36px;
            height: 36px;

            .img {
              height: 100%;
            }
          }

          .txt {
            font-size: 20px;
            color: #fff;
          }
        }
        .title::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: -24px;
          border-radius: 0px 10px 0px 0px;
          width: 24px;
          transform-origin: 0 100%;
          background: linear-gradient(0deg, #84b1f8 0%, #8be1f5 100%);
          transform: skewX(33deg);
          z-index: 1;
        }
        .more {
          font-size: 16px;
          color: #999;
          .icon {
            font-size: 16px;
          }
        }
      }

      .match-content {
        display: flex;
      }

      .btn-more {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 40px auto;
        width: 30%;
        min-width: 300px;
        color: #fff;
        background-color: #6985f8;
        height: 44px;
        border-radius: 36px;
        line-height: 1;
        font-size: 19px;
        padding: 0 20px;
        cursor: pointer;
        .icon {
          font-size: 19px;

          margin-left: 0.3em;
        }
      }
      .btn-more:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
    .match-wrap {
      .m-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;

        .m-t-left {
          display: flex;
          align-items: center;

          .img-wrap {
            width: 36px;
            height: 36px;
          }

          .title {
            margin-left: 8px;
            font-size: 30px;
            font-weight: bold;
            color: #000;
          }
        }

        .m-t-right {
          display: flex;
          align-items: center;
          height: 48px;

          .item-wrap {
            height: 48px;
            line-height: 48px;
            margin-left: 12px;
            padding: 0 24px;
            background-color: #f0f0f0;
            border-radius: 36px;

            .item {
              display: inline-block;
              padding: 0 12px;
              font-size: 18px;
              cursor: pointer;

              span {
                padding: 3px 0;
              }

              .active {
                color: #f0a31a;
                border-bottom: 2px solid #f0a31a;
              }
            }

            .item:hover {
              color: #f0a31a;
            }
          }
        }
      }

      .m-content {
        display: flex;
      }

      .m-bot {
        margin-top: 24px;

        .el-pagination {
          display: flex;
          justify-content: center;

          /deep/ .btn-prev {
            width: 100px;
            height: 40px;
            border: 1px solid #e4e4e4;
            background-color: #fff;
          }
          /deep/ .btn-prev:hover:enabled {
            color: #409eff;
          }

          /deep/ .number {
            width: 40px;
            height: 40px;
            line-height: 40px;
            border: 1px solid #e4e4e4;
            background-color: #fff;
          }

          /deep/ .more {
            width: 40px;
            height: 40px;
            line-height: 40px;
            border: 1px solid #e4e4e4;
            background-color: #fff;
          }

          /deep/ .btn-next {
            width: 100px;
            height: 40px;
            border: 1px solid #e4e4e4;
            background-color: #fff;
          }

          /deep/ .active {
            color: #fff;
            background-color: #409eff;
          }
          /deep/ .btn-next:hover:enabled {
            color: #409eff;
          }
        }
      }
    }
  }
}
</style>
