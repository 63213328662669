<template>
  <section class="datail-content w">
    <div class="datail-card">
      <div class="main-content">
        <div class="c-left info-block">
          <div class="info-content">
            <div class="info-title">
              <div class="logo" v-if="school.schoolLogo">
                <img class="img" :src="school.schoolLogo" alt="" />
              </div>
              <div>{{ statioDetail.stationName }}</div>
            </div>
            <div class="info-desc">
              <div class="desc-content">
                <div class="txt">所在地址：</div>
                <div>{{ school.address }}</div>
              </div>
              <div class="desc-content">
                <div class="txt">所属学校：</div>
                <div>{{ school.schoolName }}</div>
              </div>
              <div class="desc-content">
                <div class="txt">授牌单位：</div>
                <div>
                  {{ statioDetail.awardUnit ? statioDetail.awardUnit : "--" }}
                </div>
              </div>
            </div>
          </div>
          <div class="info-cover" v-if="statioDetail.stationIcon">
            <img class="img" :src="statioDetail.stationIcon" alt="" />
          </div>
        </div>
        <div class="c-left base-block">
          <div class="tab-block">
            <div
              class="tab-item hover-item"
              v-for="(item, index) in tabList"
              :key="index"
              @click="changeTab(item.id)"
              :class="{ 'tab-active': item.id == current }"
            >
              {{ item.title }}
            </div>
          </div>
          <div
            class="desc-block"
            v-html="statioDetail.stationDesc"
            v-if="current == 1"
          ></div>
          <div class="desc-block" v-if="current == 2">
            <el-empty description="暂无联络站"></el-empty>
          </div>
          <policy-list v-if="current == 3" :areaCode="areaCode"></policy-list>
          <match-list v-if="current == 4" :stationId="stationId"></match-list>
        </div>
      </div>

      <div class="c-right">
        <div class="banner" @click="toApply">
          <img src="https://static.scjfu.com/xwjDCZpBC4ufbXn1.jpg" alt="" />
        </div>
        <div>
          <re-station></re-station>
        </div>
      </div>
      <el-dialog
        title=""
        :visible.sync="centerDialogVisible"
        width="30%"
        center
      >
        <div style="text-align: center">
          <img :src="$globalHeader.imgUrl + '/static/miniprogram/home.png'" />
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { getStaionDetail } from "@/api/station/station.js";
import ReStation from "@/components/station/reStation.vue";
import PolicyList from "@/components/station/policyList.vue";
import MatchList from "@/components/station/matchList.vue";

export default {
  components: {
    ReStation,
    PolicyList,
    MatchList,
  },
  data() {
    return {
      stationId: "",
      statioDetail: {},
      school: {},
      current: 1,
      tabList: [
        {
          id: 1,
          title: "综合介绍",
        },
        {
          id: 2,
          title: "联络站",
        },
        {
          id: 3,
          title: "政策服务",
        },
        {
          id: 4,
          title: "赛事活动",
        },
      ],
      areaCode: null,
      centerDialogVisible: false,
    };
  },
  created() {
    this.stationId = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      getStaionDetail({
        id: this.stationId,
      }).then((res) => {
        if (!res.data) return;
        this.statioDetail = res.data;
        this.school = this.statioDetail.school;
        if (this.statioDetail) {
          this.areaCode = this.statioDetail.district;
        }
      });
    },
    changeTab(id) {
      this.current = id;
    },
    toApply() {
      this.centerDialogVisible = true;
      //  window.location.href = "/station/applySub";
    },
  },
  watch: {
    $route: {
      handler() {
        let stationId = this.$route.params.id;
        this.stationId = stationId;

        this.fetchData();
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.datail-content {
  //   height: 100%;
  min-height: 800px;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  .datail-card {
    display: flex;
    padding: 40px 50px 0;
    .main-content {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .c-left {
      padding: 30px;
      // border: 1px solid rgba(229, 229, 229, 1);
      box-shadow: -4px 3px 15px rgba(173, 213, 255, 0.5);
      -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
      background-color: #fff;
    }
    .info-block {
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      .info-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 40px;
        font-size: 14px;
        .info-title {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 600;
          .logo {
            height: 40px;
            width: 40px;
            margin-right: 20px;
            .img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .info-desc {
          flex-direction: column;
          .desc-content {
            display: flex;
            color: #383838;
            line-height: 1.8em;
            .txt {
              min-width: 5em;
              color: #848fa5;
            }
          }
        }
      }
      .info-cover {
        width: 154px;
        height: 117px;
        .img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
        }
      }
    }
    .base-block {
      padding: 0;
      margin-bottom: 40px;
      .tab-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        border-bottom: 1px solid #eaeaea;
        .tab-item {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25%;
          padding: 16px 10px;
        }

        .tab-item:not(:first-child)::before {
          content: "";
          position: absolute;
          top: 20%;
          left: 0;
          height: 60%;
          width: 1px;
          background-color: #eaeaea;
        }
        // .tab-item:hover {
        //   color: #007aff;
        // }

        // .tab-item:hover::after {
        //   background-color: #007aff;
        // }
        .tab-active {
          position: relative;
          color: #007aff;
        }
        .tab-active::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 4em;
          height: 2px;
          transform: translateX(-50%);
          background-color: #007aff;
          transition: background-color 0.3s;
        }
      }
      .desc-block {
        padding: 30px;
        font-size: 17px !important;
        line-height: 2 !important;
        min-height: 440px;
      }
    }
    .c-right {
      margin-left: 40px;
      display: flex;
      flex-direction: column;
      .banner {
        width: 369px;
        height: 154px;
        margin-bottom: 30px;
        img {
          height: 100%;
          width: 100%;
          border-radius: 8px;
        }
      }
    }
  }
}
</style>
