<template>
  <div>
    <div v-for="(item, index) in formList" :key="index">
      <!-- 输入框 -->
      <scInput
        v-if="item.type == 'input'"
        :formItem="item"
        :index="index + 1"
      />
      <!-- 附件上传 -->
      <scFile v-if="item.type == 'file'" :formItem="item" :index="index + 1" />
      <!-- 选择框 -->
      <scSelect
        v-if="item.type == 'select'"
        :formItem="item"
        :index="index + 1"
      />
      <!-- 邀请 -->
      <scInvite
        v-else-if="item.type=='project_member'"
        :formItem="item"
        :index="index + 1"
        :matchId="matchId"
				:cateId="cateId"
        :studyId="studyId"
      />
    </div>
  </div>
</template>

<script>
import scInput from "@/components/form/category/input.vue";
import scInvite from "@/components/form/category/invite.vue";

import scSelect from "@/components/form/category/select.vue";
import scFile from "@/components/form/category/file.vue";

export default {
  components: {
    scInput,
    scSelect,
    scFile,
    scInvite
  },
  props: {
    formData: Array,
    matchId: {
      type: String,
    },
    categoryId: {
      type: String,
    },
    studyId: {
      type: String,
    },
  },
  data() {
    return {
      formList: this.formData,
      cateId: this.categoryId,
    };
  },
  created() {},
  watch: {
    categoryId: {
      handler(val) {
        this.cateId = val;
      },
      deep: true,
      immediate: true,
    },
    formData: {
      deep: true,
      handler() {
        this.formList = this.formData;
      },
    },
  },
};
</script>

<style>
</style>
