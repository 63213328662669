<template>
  <div class="info-block">
    <div class="base-info-block">
      <div class="header">
        <div class="title">填写个人信息</div>
        <!-- 姓名 -->
        <div class="formModule">
          <div class="formModule-header">
            <div class="formModule-title">
              <span class="order-num">1.</span>
              <span class="txt">真实姓名</span>
            </div>
            <div class="extend">
              <div class="isMust">必填</div>
            </div>
          </div>
            <div class="formModule-desc">填写真实姓名</div>
            <div class="formModule-content">
            <div class="mod-input">
            <!-- <input class="input" type="text" placeholder="请输入"
            v-model="formDetail.value" /> -->
            <el-input placeholder="请输入" v-model="form.realName" clearable></el-input>
            </div>
          </div>
        </div>

        <!-- 身份证号 -->
        <div class="formModule">
          <div class="formModule-header">
            <div class="formModule-title">
              <span class="order-num">2.</span>
              <span class="txt">身份证号码</span>
            </div>
            <div class="extend">
              <div class="isMust">必填</div>
            </div>
          </div>
            <div class="formModule-desc">填写身份证号码</div>
            <div class="formModule-content">
            <div class="mod-input">
            <!-- <input class="input" type="text" placeholder="请输入"
            v-model="formDetail.value" /> -->
            <el-input placeholder="请输入" v-model="form.idCode" clearable></el-input>
            </div>
          </div>
        </div>

        <!-- 联系电话 -->
        <div class="formModule">
          <div class="formModule-header">
            <div class="formModule-title">
              <span class="order-num">3.</span>
              <span class="txt">联系电话</span>
            </div>
            <div class="extend">
              <div class="isMust">必填</div>
            </div>
          </div>
            <div class="formModule-desc">填写联系电话</div>
            <div class="formModule-content">
            <div class="mod-input">
            <!-- <input class="input" type="text" placeholder="请输入"
            v-model="formDetail.value" /> -->
            <el-input placeholder="请输入" v-model="form.phone" clearable></el-input>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="base-info-block">
      <div class="header">
        <div class="title">申请材料</div>
        <!-- 选择框 -->
        <div class="formModule">
          <div class="formModule-header">
            <div class="formModule-title">
              <span class="order-num">4.</span>
              <span class="txt">对象类型</span>
            </div>
            <div class="extend">
              <div class="isMust">必填</div>
            </div>
          </div>
          <div class="formModule-desc">请选择对象类型</div>
          <div class="formModule-content">
            <div class="mod-select">
              <div class="select">
                <div class="placeholder">
                  <el-select @focus="focusType" v-model="form.applicationType" clearable placeholder="请选择" style="width:100%">
                    <el-option
                      :label="type"
                      :value="type">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 表单组件-附件上传 -->
        <div class="formModule">
          <div class="formModule-header">
            <div class="formModule-title">
              <span class="order-num">5.</span
              ><span class="txt">上传材料</span>
            </div>
            <div class="extend">
              <div class="isMust" v-if="0">必填</div>
            </div>
          </div>
          <div class="formModule-desc">
            上传材料
          </div>
          <div class="formModule-content">
            <div
              class="mod-fileUpload"
              v-for="(item, index) in form.fileList"
              :key="index"
            >
              <div class="file-item">
                <div class="tit">{{ item.originalName || "" }}</div>
              </div>
            </div>
            <div class="op-outer">
              <div @click="changeFileIndex()">
                <el-upload
                  :action="fileUploadUrl"
                  :data="fileUploadParam"
                  :before-upload="beforeFileUpload"
                  :on-preview="handleFilePreview"
                  :on-remove="handleFileRemove"
                  :on-success="handleFileUploadSuccess"
                  :on-error="handleFileUploadError"
                  :before-remove="beforeFileRemove"
                  multiple
                  :limit="1"
                  :on-exceed="handleFileExceed"
                  :file-list="fileList"
                  :disabled="false"
                >
                  <el-button class="upload-btn">点击上传</el-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <div class="btn btn-submit" @click="saveSub">
      确认预审申请
    </div>
  </div>
</template>

<script>
import { getqiniuToken } from "@/api/common/common";
import { saveHandle,getHandleValidateApplication } from "@/api/policy/policy.js";
const FORM = {
  realName:"",
  idCode:"",
  phone:"",
  applicationType:"",
  fileList: [],
  handleDetailId:null,
}
export default {
  
  components: {
  },
  props: {
    dataForm:Object,
  },
  data() {
    return {
      fileUploadUrl: "https://up-z2.qiniup.com", //上传文件地址
      fileUploadParam: {},
      disabled:false,
      form:{...FORM},
      interpretateId:null,
      type:null,
      fileList:[],
    };
  },
  methods: {
    focusType(){
      this.type = this.dataForm.policyName;
    },
    saveSub(){
      if(!this.form.realName || !this.form.idCode || !this.form.phone || !this.form.applicationType){
        this.$message.warning("有未填写的信息，请补全后提交");
        return;
      }
      this.form.interpretateId = this.interpretateId;
      let fileIds = [];
      console.log(this.form.fileList);
      this.form.fileList.forEach(e => {
        fileIds.push(e.id);
      });
      this.form.fileIdList = fileIds;
      saveHandle(this.form).then(res =>{
        if(res.code == 200){
          this.$message.success(res.message);
          this.$router.push({
            path:"/policy/policyDetail/"+this.interpretateId+"/policySubResult",
            query:{
              interpretateId:this.interpretateId
            }
          });
        }else{
          this.$message.error(res.message);
        }
      }).catch(mess =>{
        this.$message.error(mess);
      })


    },
    changeFileIndex() {
      getqiniuToken().then((res) => {
        this.fileUploadParam = { key: "", token: res.data };
      });
    },
    beforeFileUpload(file) {
      this.fileUploadParam.key =
        String(new Date().getTime()) +
        Math.floor(Math.random() * 50 + 50) +
        "." +
        file.name;
    },
    handleFilePreview(file) {
      console.log(file);
    },
    handleFileExceed() {
      this.$message.warning(`抱歉，最多只能上传 1个文件`);
    },
    beforeFileRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleFileUploadSuccess(file) {
      if(!this.form.fileList){
        this.form.fileList = [];
      }
      this.form.fileList.push({
        id: file.data.id,
        originalName: file.data.fname,
        url: this.$globalHeader.fileUrl + "/" + file.data.key,
      });
      this.$message.success(`上传成功`);
      this.disabled = true;
    },
    handleFileUploadError() {
      this.$message.warning(`上传失败`);
    },
    handleFileRemove() {
      console.log("------移除--------");
      this.form.fileList = [];
      this.fileList = [];
    },
  },
  created(){
    this.fileList = [];
    this.interpretateId  = this.dataForm.interpretateId;
    getHandleValidateApplication({interpretateId:this.interpretateId}).then(res =>{
      if(res.data){
        Object.assign(this.form,res.data);
      }
      if(this.form.fileList && this.form.fileList.length){
        this.disabled = true;
        this.form.fileList.forEach(item =>{
          this.fileList.push({
            name: item.originalName,
            url: item.url,
          })
        })
      }
    })
  },
  watch:{
    
  }
};
</script>

<style lang="scss" scoped>
.info-block {
  border-radius: 8px;
  border: 1px solid #e6eff4;
  box-shadow: -2px 2px 20px 0 #dbefffad;
  margin: 20px;
  padding: 20px;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #fff;
  .base-info-block {
    padding: 32px 0;
    padding-bottom: 20px;
    .header {
      .title {
        position: relative;
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
      }
      .title:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -6px;
        transform: translate(0, -50%);
        width: 4px;
        height: 70%;
        background-color: orange;
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    width: 30%;
    min-width: 200px;
    color: #fff;
    background-color: #0099CC;
    height: 44px;
    border-radius: 36px;
    line-height: 1;
    font-size: 16px;
    padding: 0 20px;
    cursor: pointer;
  }
  .btn-submit {
    background: linear-gradient(90deg, rgba(255, 205, 52, 1) 0%, rgba(255, 146, 0, 0.91) 100%);
  }
}

::v-deep .el-input__inner{
  background-color: #f7f7f7 !important;
  border: none;
}
::v-deep .el-input__suffix {
    .el-input__suffix-inner {
      border-color: none;
      .el-icon-circle-close:before {
        font-size: 16px;
      }
    }
  }

input::-webkit-input-placeholder { 
/* WebKit browsers */ 
color: #ccc; 
} 
input:-moz-placeholder { 
/* Mozilla Firefox 4 to 18 */ 
color: #ccc; 
} 
input::-moz-placeholder { 
/* Mozilla Firefox 19+ */ 
color: #ccc; 
} 
input:-ms-input-placeholder { 
/* Internet Explorer 10+ */ 
color: #ccc; 
}

.formModule {
		font-size: 14px;
		padding: 15px;
		height: auto;
		background-color: #ffffff;

		.formModule-header {
			display: flex;
			margin-bottom: 10px;

			.formModule-title {
				font-size: 15px;
				font-weight: 600;

				.txt::after {
					content: "：";
				}
			}

			.extend {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-left: 10px;
			}
		}

		.isMust {
			color: #ff0000;
			font-size: 14px;
		}

		.formModule-desc {
			margin-bottom: 12px;
			font-size: 12px;
			color: #9b9999;
			line-height: 1.35;
		}

		.formModule-content {
      .mod-fileUpload {
      background-color: #f7f7f7;
      display: flex;
      align-items: stretch;
      border-radius: 12rpx;
      overflow: hidden;
      margin: 6px 0;

      .file-item {
        flex: 1;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 8px;
        font-size: 14px;
        min-height: 28px;
        line-height: 1.35;

        .tit {
          flex: 1;
        }
      }
    }

    .op-outer {
      margin: 6px 0;

      .upload-btn {
        color: #ffffff;
        background-color: #199ed8;
        border-radius: 6px;
        font-size: 14px;
        width: 140px;
      }
    }
    }

		.op-inner {
			display: flex;
			align-items: stretch;
			justify-content: flex-end;
			line-height: 1;

			.btn-icon,
			.btn-more {
				font-size: 16px;
				display: flex;
				align-items: center;
				padding: 8px 10px;
				color: #9b9999;
			}
		}

		.mod-input {
			background-color: #f7f7f7;
			display: flex;
			align-items: stretch;
			border-radius: 6px;
			overflow: hidden;
			margin: 6px 0;

			.input {
				flex: 1;
				background-color: transparent;
				border: none;
				padding: 0 10px;
				font-size: 14px;
				height: 44px;
				line-height: 1.35;
			}
		}
	}




  
</style>