<template>
  <div class="policy-block">
    <div class="policy-list" v-if="rePolicyData.length > 0">
      <div
        class="policy-item hover-item"
        v-for="(item, index) in rePolicyData"
        :key="index"
        @click="toDetail(item.interpretateId)"
      >
        <div class="p-header">
          <div class="title">{{ item.policyName }}</div>
        </div>
        <div class="label-list">
          <span class="is-light">{{ item.policyType }}</span>
          <span v-for="(subitem, index) in item.subTitleResults" :key="index">{{
            subitem.subTitle
          }}</span>
          <span class="is-light" v-if="item.handleType == 1">线上办理</span>
          <span class="is-light" v-if="item.handleType == 2">线下办理</span>
          <span class="is-light" v-if="item.handleType == 3"
            >线上办理、线下办理</span
          >
        </div>
        <div class="extend">
          <div class="text-date">
            <span class="icon iconfont icon-riqi"></span>
            <span class="label">时间：</span>
            <span class="text">{{ item.effectiveTimeRange }}</span>
          </div>
          <div class="text_person">
            <span class="icon iconfont icon-renshu"></span>
            <span class="num">{{ item.handleQuantity }}</span
            >人想办
          </div>
        </div>
      </div>
      <div class="m-pager">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-size="limit"
          :total="pageCount"
          @current-change="changePageClick"
        >
        </el-pagination>
      </div>
    </div>
    <div class="empty" v-else>
      <el-empty description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import { getLiaisonAreaPolicy } from "@/api/station/station.js";

export default {
  props: {
    areaCode: String,
  },
  data() {
    return {
      rePolicyData: [],
      pageCount:'',
      limit:10,
    };
  },
  methods: {
    getAreaPolicyList(page = 1) {
      let params = {
        policyArea: this.areaCode,
        page: page,
        limit: this.limit,
      };
      getLiaisonAreaPolicy(params)
        .then((res) => {
          this.rePolicyData = res.data.policyList;
          this.pageCount = res.data.total
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    //更多政策
    toMore() {
      this.$router.push("/policy");
    },
    //切换页面
    changePageClick(page) {
      this.getAreaPolicyList(page);
    },
    //详情
    toDetail(id) {
      this.$router.push({ path: "/policy/policyDetail/" + id });
    },
  },
  created() {
    this.getAreaPolicyList();
  },
  //   watch: {
  //     areaCode(newV) {
  //       console.log("newV--areaCode--", newV);
  //     },
  //   },
};
</script>

<style lang="scss" scoped>
.policy-block {
  flex: 1;
  padding: 0 20px;
  margin: 20px 0;
  min-height: 440px;
  .policy-list {
    flex: 1;
    line-height: 1.5;
    .policy-item {
      padding: 10px 20px;
      background-color: #fff;
      border-bottom: 1px solid #f2f2f2;
      //   box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
      .p-header {
        .title {
          margin-bottom: 12px;
          font-size: 16px;
          font-weight: bold;
          color: #000;
          line-height: 1.35;
        }
      }
      .label-list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        min-height: 30px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 10px;
          border-radius: 22px;
          font-size: 12px;
          color: #666;

          background-color: #eaeaea;
        }
        .is-light {
          color: #f7932e;
          background-color: #fee3b0;
        }
      }
      .extend {
        margin-top: 0.75em;
        font-size: 14px;
        color: #999;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-bottom: 2rpx solid #E4E4E4;
        .text-date {
          display: flex;
          align-items: baseline;
          .icon {
            color: #000000;
          }
          .text {
            flex: 1;
          }
        }
        .text_person {
          display: flex;
          align-items: baseline;
          color: #409eff;
          .num {
            font-weight: bold;
          }
        }
        .icon {
          margin-right: 0.35em;
        }
      }
    }
    .policy-item:nth-child(3) {
      border: none;
    }
  }
  .empty {
    min-height: 440px;
    display: flex;
    justify-items: center;
    justify-content: center;
  }
  .m-pager {
    margin: 24px 0;

    .el-pagination {
      display: flex;
      justify-content: center;

      /deep/ .btn-prev {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-prev:hover:enabled {
        color: #409eff;
      }

      /deep/ .number {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .more {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-next {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .active {
        color: #fff;
        background-color: #409eff;
      }

      /deep/ .btn-next:hover:enabled {
        color: #409eff;
      }
    }
  }
}
</style>