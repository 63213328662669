import http from "@/utils/http.js"

//获取二维码
export function getQECode() {
    return http({
        url: "/api/web/login/getQRCode",
        method: "GET"
    })
}

//二维码轮询
export function checkQECodeStatus(data) {
    return http({
        url: "/api/web/login/getQRCodeStatus/" + data,
        method: "GET"
    })
}
