<template>
  <div class="station-page page" v-loading="loading">
    <div class="page-wrap">
      <!-- 顶部 -->
      <page-top></page-top>
      <!-- 头部 -->
      <Header></Header>
      <!-- 主体 -->
      <main class="main">
        <section class="breadcrumb-plate w">
          <div class="breadcrumb-content">
            <div class="label">我的位置：</div>
            <breadcrumb />
          </div>
        </section>
        <div v-if="displayPage">
          <div class="enter-plate w">
            <div class="enter-wrap">
              <div
                class="enter-item"
                v-for="(item, index) in enterList"
                :key="index"
                @click="handleEnter(item)"
              >
                <div class="enter-item-cover">
                  <img class="img" :src="item.icon" />
                </div>
                <div class="enter-item-content">
                  <div class="title">{{ item.title }}</div>
                  <div class="desc">{{ item.desc }}</div>
                </div>
              </div>
            </div>
            <div class="banner-wrap" @click="toApply">
              <img
                class="img"
                src="https://static.scjfu.com/xwjDCZpBC4ufbXn1.jpg"
              />
            </div>
          </div>
          <section class="station-block w">
            <div class="header">
              <div class="title">
                <span class="txt">{{ tabTitle }}</span>
                <span class="side"></span>
              </div>
            </div>
            <div class="station-warp">
              <station-list v-if="currentTab == 1"></station-list>
              <div v-if="currentTab == 2" id="map"></div>
              <area-service t v-if="currentTab == 3"></area-service>
              <school-list v-if="currentTab == 4"></school-list>
              <!-- <station-map  v-if="currentTab == 2" :currentTab='currentTab'></station-map> -->
            </div>
          </section>
        </div>
        <el-dialog title="" :visible.sync="centerDialogVisible" width="30%" center>
          <div style="text-align: center">
            <img
              :src="$globalHeader.imgUrl + '/static/miniprogram/home.png'"
            />
          </div>
        </el-dialog>
        <router-view></router-view>
      </main>
      <!-- 底部 -->
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import { getStationList } from "@/api/station/station.js";

import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import stationList from "@/components/station/allStationList.vue";
import schoolList from "@/components/station/schoolList.vue";
import AreaService from "@/components/station/areaService.vue";

// import StationMap from "@/components/station/stationMap.vue";

import { local } from "@/utils/storage.js";

export default {
  components: { pageTop, Header, Footer, Breadcrumb, stationList, schoolList,AreaService },

  data() {
    return {
      loading: false,
      displayPage: true,
      enterList: [
        {
          title: "服务站",
          desc: "服务站/联络站",
          icon: this.$globalHeader.imgUrl + "/static/icon/10.png",
          url: "station",
        },
        {
          title: "站点地图",
          desc: "地图找服务站",
          icon: this.$globalHeader.imgUrl + "/static/icon/8.png",
          url: "map",
        },
        {
          title: "相关地区",
          desc: "驻各高校站点",
          icon: this.$globalHeader.imgUrl + "/static/icon/9.png",
          url: "area",
        },
        {
          title: "相关高校",
          desc: "服务站在高校",
          icon: this.$globalHeader.imgUrl + "/static/icon/7.png",
          url: "school",
        },
      ],
      markerArr: [
        {
          id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
          styleId: "myStyle", //指定样式id
          position: new window.TMap.LatLng(24.638627, 118.073705), //点标记坐标位置
          properties: {
            //自定义属性
            title: "基地",
          },
        },
      ],
      isLogin: false,
      currentTab: 1,
      tabTitle: "人才服务站",
      currentStation: null,
      centerDialogVisible:false,
    };
  },
  created() {
    this.isLogin = local.Get("isLogin");
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.getAllStationList();
    },
    //获取所有站点信息
    getAllStationList() {
      getStationList().then((res) => {
        this.stationList = res.data;
        if (this.stationList) {
          this.markerArr = [];
          this.stationList.map((item) => {
            let station = {
              id: item.station.id,
              styleId: "myStyle", //指定样式id
              position: new window.TMap.LatLng(
                item.station.latitude,
                item.station.longitude
              ),
              properties: {
                title: item.station.stationName,
                city: item.station.city,
                contactManList: item.station.contactManList,
              },
            };
            this.markerArr.push(station);
          });
        }
      });
    },

    initMap() {
      //设置一个默认的中心点
      var center = new window.TMap.LatLng(24.638627, 118.073705);
      //初始化地图
      var map = new window.TMap.Map(document.getElementById("map"), {
        center: center, //设置地图中心点坐标
        zoom: 10, //设置地图缩放级别
        // pitch: 43.5,  //设置俯仰角
        // rotation: 45    //设置地图旋转角度
      });
      //设定marker点及其样式
      var markerLayer = new window.TMap.MultiMarker({
        map: map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new window.TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": './images/R-C.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            // "anchor": { x: 16, y: 32 }  //marker点图片跟据焦点位置的偏移量
          }),
        },
        //点标记数据数组
        geometries: this.markerArr,
        // 点标记数组格式
        //   geometries: [
        //     {
        //     "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
        //     "styleId": 'myStyle',  //指定样式id
        //     "position": new window.TMap.LatLng(24.638627, 118.073705),  //点标记坐标位置
        //     "properties": {//自定义属性
        //       "title": "荆州万达广场",
        //     }
        //   }
        // ]
      });

      //设置信息窗口
      var infoWindow = new window.TMap.InfoWindow({
        map: map,
        position: new window.TMap.LatLng(24.628959, 118.064711), //信息窗口默认位置
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });

      //信息窗口设置完后默认是打开的 可以先关闭初始信息窗口
      infoWindow.close();
      //监听marker点击事件
      markerLayer.on("click", function (evt) {
        infoWindow.open(); // 打开信息窗
        infoWindow.setPosition(evt.geometry.position); // 设置信息窗位置
        infoWindow.setContent(
          `<div id="infoPopup" stationId="${
            evt.geometry.id
          }" style="cursor: pointer;min-width: 300px;display:flex;justify-content: space-between;align-items: center;"><div style="display:flex;flex-direction: column;align-items: flex-start;padding: 14px;font-size: 16px;line-height: 2em;">
          <div>${
            evt.geometry.properties.title
          }</div><div style="color: #666;">${
            evt.geometry.properties.city + " | "
          }
          <text>站长：${
            evt.geometry.properties.contactManList || "未设置"
          }</text></div></div>
          <div style="font-size: 16px;margin: 0 10px;"><text class="icon iconfont icon-more"></text></div>
          </div>`
        ); // 设置信息窗内容
        document
          .querySelector("#infoPopup")
          .addEventListener("click", function () {
            var id = this.getAttribute("stationId");
            window.location.href = "/station/stationDetail/" + id;
          });
      });
      // 监听地图点击事件
      // map.on('click',function(evt){
      // console.log("evt",evt);
      // })
    },
    handleEnter(item) {
      switch (item.url) {
        case "station":
          this.currentTab = 1;
          this.tabTitle = "人才服务站";
          break;
        case "map":
          if (this.currentTab == 2) return;
          this.currentTab = 2;
          this.tabTitle = "站点地图";
          setTimeout(() => {
            this.initMap();
          }, 50);
          break;
        case "area":
          this.currentTab = 3;
          this.tabTitle = "相关地区";
          break;
        case "school":
          this.currentTab = 4;
          this.tabTitle = "相关高校";
          break;
      }
    },
    toApply() {//点击更多
      this.centerDialogVisible = true;

      //  window.location.href = "/station/applySub";
    },
  },
  watch: {
    $route: {
      handler() {
        let routerList = this.$route.path
          .slice(1, this.$route.path.length)
          .split("/");
        if (routerList && routerList.length == 1) {
          this.displayPage = true;
        } else {
          this.displayPage = false;
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.station-page {
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      flex-direction: column;
      .breadcrumb-content {
        display: flex;
        align-items: center;
        padding: 16px 0;
        .label {
          line-height: 1;
          font-size: 14px;
        }
      }
    }

    .enter-plate {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      .enter-wrap {
        width: 40%;
        margin-right: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 20px;

        .enter-item {
          display: flex;
          align-items: center;
          height: 95px;
          border-radius: 10px;
          padding: 15px;
          width: calc(50% - 10px);
          box-shadow: -4px 3px 11px rgba(173, 213, 255, 0.5);
          .enter-item-content {
            flex: 1;
            margin-left: 15px;

            .title {
              font-size: 15px;
              font-weight: 600;
              line-height: 2em;
            }

            .desc {
              font-size: 12px;
              color: #666666;
            }
          }

          .enter-item-cover {
            width: 55px;
            height: 55px;

            // margin: 0 20px;
            .img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .enter-item:hover {
          cursor: pointer;
          -webkit-transform: scale(1.01);
          transform: scale(1.01);
        }

        .enter-item:nth-child(1) {
          background-color: #d0dffc;
          margin-bottom: 20px;
        }

        .enter-item:nth-child(2) {
          background-color: #fcebcd;
          margin-bottom: 20px;
        }
        .enter-item:nth-child(3) {
          background-color: #fcebcd;
        }
        .enter-item:nth-child(4) {
          background-color: #d0dffc;
        }
      }
      .banner-wrap {
        flex: 1;
        margin-right: 30px;
        height: 210px;
        .img {
          height: 100%;
          width: 100%;
          border-radius: 10px;
        }
      }
    }

    .station-block {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px;

        border-bottom: 2px solid #85b4f8;
        .title {
          position: relative;
          width: 136px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(0deg, #84b1f8 0%, #8be1f5 100%);
          border-radius: 10px 10px 0px 0px;
          .ico {
            width: 36px;
            height: 36px;

            .img {
              height: 100%;
            }
          }

          .txt {
            font-size: 20px;
            color: #fff;
          }
        }
        .title::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: -24px;
          border-radius: 0px 10px 0px 0px;
          width: 24px;
          transform-origin: 0 100%;
          background: linear-gradient(0deg, #84b1f8 0%, #8be1f5 100%);
          transform: skewX(33deg);
          z-index: 1;
        }
        .more {
          font-size: 16px;
          color: #999;
          .icon {
            font-size: 16px;
          }
        }
      }
      .station-warp {
        background-color: #fff;
        min-height: 600px;
        .map-block {
          height: 800px;
          padding: 40px;
          .img {
            width: 150px;
          }
        }
      }
    }
  }
}
</style>
