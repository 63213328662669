<template>
    <div class="page">
        <div class="content">
            <div class="left">
                <img class="img" :src="$globalHeader.imgUrl + '/static/icon/404.png'" alt="图片">
            </div>
            <div class="right">
                <div class="item1">404</div>
                <div class="item2">UH OH!页面丢失</div>
                <div class="item3">您访问的地址页面不存在或已调整</div>
                <div class="item4">{{ second }}秒钟倒计时，自动跳转到首页</div>
                <div class="item5" @click="backHome">返回首页</div>
            </div>
        </div>

        <easy-footer></easy-footer>
    </div>
</template>

<script>
import easyFooter from "@/components/common/easyFooter.vue";

export default {
    components: { easyFooter },
    data() {
        return {
            second: 8
        }
    },
    created() {
        this.countDown()
    },
    methods: {
        //倒计时
        countDown() {
            let time = setInterval(() => {
                this.second--
                if (this.second <= 0) {
                    clearInterval(time)
                    this.$router.replace('/')
                }
            }, 1000);

        },
        backHome() {
            this.$router.replace('/')
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5;

    .left {
        .img {
            width: 375px;
            height: 375px;
        }
    }

    .right {
        margin-left: 32px;
        margin-top: -50px;
        width: 420px;

        .item1 {
            font-size: 180px;
            font-weight: bold;
            color: #000;
        }

        .item2 {
            margin-bottom: 6px;
            font-size: 28px;
            font-weight: bold;
            color: #000;
        }

        .item3 {
            margin-bottom: 10px;
            font-size: 16px;
            color: #000;
        }

        .item4 {
            margin-bottom: 16px;
            font-size: 14px;
            color: #000;
        }

        .item5 {
            width: 180px;
            height: 44px;
            text-align: center;
            line-height: 44px;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            background-color: #3A9DD8;
            cursor: pointer;
        }
    }
}
</style>