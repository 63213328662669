// 绿色：#009933    //已晋级
// 蓝色：#3892F8    //未评审
// 红色：#FF2C2C    //未通过
// 灰色：#bbbbbb    //已取消
const color = {
    'red':'#FF2C2C',
    'blue':'#3892F8',
    'green':'#009933',
    'gray':'#bbbbbb'
}

//赛事状态
const competitionStatus = {
    '1':color['blue'],//未评审
    '2':color['red'],//未晋级
    '3':color['green'],//已晋级
    '4':color['gray'] //已作废
}

//课程状态
const courseStatus = {
    '1':color['blue'],//未评审
    '2':color['green'],//已评审
    '3':color['gray'],//已取消
    '4':color['red'] //未通过
}

//政策状态
const policyStatus = {
    '0':color['blue'],//待办理
    '1':color['blue'],//待审核
    '2':color['green'],//初审通过
    '3':color['green'],//已完成
    '4':color['red'],//未通过
    '5':color['red'],//未通过
    '6':color['gray'],//已取消
}

//订单状态
const orderStatus = {
    '1':color['blue'],//待确认
    '2':color['green'],//已确认
    '3':color['red'] //已驳回
}

//订单状态颜色
export function getOrderStatus(status) {
    return orderStatus[status];
}

//课程状态颜色
export function getCourseStatus(status) {
    return courseStatus[status];
}

//赛事状态颜色
export function getCompetitionStatus(status) {
    return competitionStatus[status];
}

//政策状态颜色
export function getPolicyStatus(status) {
    return policyStatus[status];
}

