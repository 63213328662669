<template>
  <div class="plate-wrap">
    <div class="status-plate" :class="reviewStatus.classBgc">
      <div class="title">
        <span>{{ reviewStatus.title }}</span>
      </div>
      <div class="subtitle">
        <span>{{ reviewStatus.subtitle }}</span>
      </div>
    </div>

    <div class="info-plate plate-common">
      <div class="title"> 基本信息 </div>
      <div class="content">
        <div class="item">
          <span class="item-left">记录编号：</span>
          <span class="item-right">{{ data.applyNo || "" }}</span>
        </div>
        <div class="item">
          <span class="item-left">提交时间：</span>
          <span class="item-right">{{ data.submitTime || "" }}</span>
        </div>
        <div class="item" v-if="reviewStatus.time">
          <span class="item-left">{{ reviewStatus.time }}：</span>
          <span class="item-right">{{ data.operateTime || "" }}</span>
        </div>
        <div class="item">
          <span class="item-left">项目评分：</span>
          <span class="item-right" v-if="data.auditStatus == 2">{{ data.score }}分</span>
          
          <span class="item-right" v-if="data.auditStatus == 1">--</span>
          <span class="item-right" v-if="data.auditStatus == 4">未评分</span>
        </div>
        <div
          class="item tips"
          v-if="data.comment"
          :class="reviewStatus.classColor"
        >
          <span class="item-left">点评评语：</span>
          <span class="item-right">{{ data.comment || "" }}</span>
        </div>
      </div>
    </div>

    <div class="detail-plate">
      <div class="policy-info plate-common">
        <div class="title">
          <div class="title_1">项目信息</div>
          <div class="detail-btn hover-text" @click="toDetail">
            <span class="text">查看详情</span>
            <span class="icon iconfont icon-more"></span>
          </div>
        </div>
        <div class="content">
          <div class="item">
            <div class="item-left">
              <div class="item-cover">
                <img class="img" :src="data.image" alt="" />
              </div>
            </div>
            <div class="item-right">
              <span class="text">{{ data.title || "" }}</span>
              <span class="text">截至{{ data.signupEndTime || "" }}</span>
              <span class="text">{{ data.school || "" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="conduct-info plate-common">
        <div class="title"> 提交信息 </div>
        <div class="content">
          <div class="item">
            <div class="item-left">
              <span class="text">项目主题：</span>
            </div>
            <span class="item-right">
              {{ data.name || "" }}
            </span>
          </div>
          <div class="item">
            <div class="item-left">
              <span class="text">项目计划书：</span>
            </div>
            <div class="item-right">
              <div class="item-file">
                <div class="material-name">
                  <span v-if="data.file">{{ data.file.originalName || "" }}</span>
                </div>
                <div class="look" @click="lookFile">
                  <span>查看</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="item-left">
              <span class="text">所属行业：</span>
            </div>
            <span class="item-right">
              {{ data.field || "" }}
            </span>
          </div>
          <div class="item" v-if="data.projectMember">
            <div class="item-left">
              <span class="text">项目成员：</span>
            </div>
            <span class="item-right">
              {{ data.projectMember || "" }}
            </span>
          </div>
        </div>
      </div>
      <div class="item-control" v-if="data.isEdit">
        <div class="btn cancle-btn" v-if="data.auditStatus == 1" @click.stop="cancelConduct()">取消</div>
        <div class="btn delete-btn" v-if="data.auditStatus == 3" @click.stop="deleteConduct()">删除</div>
        <div class="btn delete-btn hover-btn" v-if="data.auditStatus == 4" @click.stop="modify()">修改</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCourseDetail,deleteCourse} from "@/api/personal";
import * as util from '@/utils/util';
const statusMap = {
		1: {
			title: "待评审",
			subtitle: "待评审人员对项目进行评审打分",
			classBgc: "blue-bgc",
			classColor: "blue-color",
			time: ""
		},
		2: {
			title: "已完成",
			subtitle: "本次提交的项目，已完成评审评分",
			classBgc: "green-bgc",
			classColor: "green-color",
			time: "评审时间"
		},
		3: {
			title: "已取消",
			subtitle: "本次提交的项目，已取消",
			classBgc: "gray-bgc",
			classColor: "gray-color",
			time: "取消时间"
		},
		4: {
			title: "评审未通过",
			subtitle: "您本次提交的项目评审未通过，请及时进行调整并重新提交",
			classBgc: "orange-bgc",
			classColor: "orange-color",
			time: "评审时间"
		},
	}
export default {
  data() {
    return {
        recordId: "",
        reviewStatus: {},
        data: {}
    };
  },
  created() {

    let id = this.$route.params.id;

    this.recordId = this.$route.params.id
    this.fetchData(id)
  },
  methods: {
      fetchData(id) {
          getCourseDetail(id).then((res) => {
            if (!res.data) return;
            this.data = res.data;
            this.reviewStatus = statusMap[res.data.auditStatus]
            console.log("this.reviewStatus--",this.reviewStatus);

      });
      },
      cancelConduct() {
          const that = this;
        this.$confirm("是否确定取消提交", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then((res) => {
            if (!res) return;
            let params = {
              courseApplyId: that.data.id,
              flag: false,
              wait: 1000,
            };
            deleteCourse(params).then((res) => {
              if (res.data) {
                setTimeout(() => {
                  that.fetchData();
                  that.$message({
                    message: res.message,
                    type: "success",
                  });
                }, 1000);
              }
            });
          })
          .catch(() => {
            that.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
      deleteConduct() {
         const that = this;
      this.$confirm("是否确定删除提交", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then((res) => {
          if (!res) return;
          let params = {
            courseApplyId: that.data.id,
            flag: false,
            wait: 1000,
          };
          deleteCourse(params).then((res) => {
            if (res.data) {
              setTimeout(() => {
                that.fetchData();
                that.$message({
                  message: res.message,
                  type: "success",
                });
              }, 1000);
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消",
          });
        });
      },
      modify() {

      },
      toDetail() {
         window.location.href = "/course";
    },
      lookFile() {
        console.log("this.data.file.path",this.data.file.path);
        util.previewFile(this.data.file.path)
      }
  },
};
</script>

<style lang="scss" scoped>
.plate-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;

  .status-plate {
    padding: 20px;
    color: #fff;
    // border-radius: 16px;
    background-color: #c9c9c9;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .subtitle {
      margin-top: 0.5em;
      font-size: 16px;
    }
  }

  .blue-bgc {
    background-color: #2f72ae !important;
  }

  .orange-bgc {
    background-color: #ff6600 !important;
  }

  .green-bgc {
    background-color: #339933 !important;
  }

  .gray-bgc {
    background-color: #c9c9c9 !important;
  }

  .blue-color {
    color: #2f72ae !important;
  }

  .orange-color {
    color: #ff6600 !important;
  }

  .green-color {
    color: #339933 !important;
  }

  .gray-color {
    color: #c9c9c9 !important;
  }

  .plate-common {
    // padding: 20px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      font-size: 18px;
      font-weight: bold;
      color: #1d1e2c;

      .detail-btn {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #999;
        .text {
          font-size: 16px;
        }
        .icon {
          font-size: 14px;
        }
      }
    }

    .content {
      .item {
        display: flex;
        margin-top: 16px;
        font-size: 16px;
        color: #666;

        .item-left {
          display: flex;
          flex-shrink: 0;
          .item-cover {
                height: 94px;
                margin-right: 20px;

                img {
                  width: 116px;
                  height: 94px;
                  border-radius: 6px;

                }
              }
    

          .text {
            flex: 1;
          }
        }

        .item-right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .text {
            // margin-bottom: 6px;
            line-height: 1.35;
          }

          .material-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            padding: 24px;
            background-color: #f7f7f7;
            border-radius: 16px;

            .look {
              color: #2f72ae;
            }
          }
        }

        .item-file {
          display: flex;
          justify-content: space-between;
          color: #3a81c3;

          .material-name {
            display: -webkit-box;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .look {
            cursor: pointer;
            flex-shrink: 0;
          }
        }
      }

      .tips {
        display: flex;

        .item-left {
          flex-shrink: 0;
        }

        .item-right {
          flex: 1;
        }
      }
    }
  }

  .info-plate {
    padding: 20px;
    margin-top: 16px;
    // border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  }

  .detail-plate {
    padding: 20px;
    position: relative;
    margin-top: 20px;
    // border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);

    .conduct-info {
      padding-top: 0;
      .conduct-material {
        .item-left {
          padding-top: 20px;
        }
      }
    }
    .item-control {
      // position: absolute;
      bottom: 20px;
      right: 20px;
      display: flex;
      flex-direction: row-reverse;
      .btn {
        cursor: pointer;
        padding: 8px 24px;
        border-radius: 20px;
        text-align: center;
        margin-left: 16px;
        border: solid 1px transparent;

      }
      .hover-btn:hover {
        color: #4faaf7;
        border-color: #4faaf7 !important;
      }
      .cancle-btn {
        color: #ff6600;
        border: #ff6600 solid 1px;
      }
      .reapply-btn {
        color: #3892f8;
        border-color: #3892f8;
      }
      .delete-btn {
        color: #989898;
        border: #989898 solid 1px;
      }
      .detail-btn {
        border: #888 solid 1px;
      }
    }
  }

  .no-more {
    margin-top: 80px;
    margin-bottom: 160px;
    font-size: 16px;
    color: #999;
    text-align: center;
  }
}
</style>