import http from "@/utils/http.js"

//获取赛事动态分页
export function getDynamicPage(params) {
    return http({
        url: "/api/web/dynamic/getDynamicPage",
        method: "GET",
        data: params
    })
}

//获取赛事动态分页
export function getDynamicDetail(params) {
    return http({
        url: "/api/web/dynamic/getDynamicDetail/" + params,
        method: "GET",

    })
}
//获取赛事动态列表
export function getDynamicList(params) {
    return http({
        url: "/api/web/dynamic/getDynamicList",
        method: "GET",
        data: params

    })
}
//获取赛事资讯
export function getArticlePage(params) {
    return http({
        url: "/api/web/article/getArticlePage",
        method: "GET",
        data: params

    })
}