import http from "@/utils/http.js"

//搜索
export function search(data) {
    return http({
        url: "/api/web/home/search",
        method: "GET",
		data:data,
    })
}

//置顶类型搜索
export function specificationSearch(data) {
    return http({
        url: "/api/web/home/specificationSearch",
        method: "GET",
		data:data,
    })
}

//过滤搜索
export function searchFiler(data) {
    return http({
        url: "/api/web/home/searchFiler",
        method: "GET",
		data:data,
    })
}