<template>
  <!-- 提示语 -->
  <div class="main-body">
    <div class="prompt-message">
      <p class="p">
        重要提示:您在本系统提交的用于身份认证的资料，属于个人资料信息，系统禁用作身份认证证明用途，信息不对外开放，如认证后需要修改信息，可重新提交认证。
      </p>
    </div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.native.prevent
      v-loading.fullscreen.lock="loading"
    >
      <div class="info">
        <div class="title">
          <div class="title-circle"></div>
          <div class="title-text">基本信息</div>
        </div>
        <div class="info-field">
          <p>
            <el-form-item prop="phone">
              <label>联系电话</label>
              <span>
                <el-input
                  class="input"
                  v-model="form.phone"
                  placeholder="请输入联系电话"
                />
              </span>
            </el-form-item>
          </p>
          <p>
            <el-form-item prop="name">
              <label>真实姓名</label>
              <span>
                <el-input
                  class="input"
                  v-model="form.name"
                  placeholder="请输入真实姓名"
                />
              </span>
            </el-form-item>
          </p>
          <p>
            <el-form-item prop="sex">
              <label>性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别</label>
              <span>
                <el-radio-group v-model="form.sex">
                  <el-radio label="男">男</el-radio>
                  <el-radio label="女">女</el-radio>
                </el-radio-group>
              </span>
            </el-form-item>
          </p>
          <p>
            <el-form-item prop="province" class="form-item">
              <label>籍&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;贯</label>
              <span>
                <el-select
                  v-model="provinceCode"
                  @change="provinceChange"
                  placeholder="请选择省"
                >
                  <el-option
                    v-for="item in provinceOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </span>
            </el-form-item>
            <el-form-item prop="city" class="form-item">
              <span>
                <el-select
                  v-model="cityCode"
                  @change="cityChange"
                  placeholder="请选择市"
                >
                  <el-option
                    v-for="item in cityOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </span>
            </el-form-item>
            <el-form-item prop="district" class="form-item">
              <span>
                <el-select
                  v-model="districtCode"
                  @change="districtChange"
                  placeholder="请选择区"
                >
                  <el-option
                    v-for="item in districtOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </span>
            </el-form-item>
          </p>
        </div>
        <br />
        <div class="title">
          <div class="title-circle"></div>
          <div class="title-text">学生身份信息</div>
        </div>
        <div class="info-field">
          <el-form-item prop="school">
            <label>所属高校</label>
            <span>
              <el-autocomplete
                class="autocomplete"
                @select="handleSelect"
                :fetch-suggestions="selectScoolList"
                placeholder="请输入高校名称"
                :trigger-on-focus="false"
                v-model="form.school"
              />
            </span>
          </el-form-item>

          <el-form-item prop="academy">
            <label>所属院系</label>
            <span>
              <el-select
                class="select"
                v-model="form.academyId"
                @change="academyChange"
                placeholder="请选择"
                v-if="academyShow"
              >
                <el-option
                  v-for="item in academyOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-input
                class="input"
                placeholder="请输入所属院系"
                v-model="form.academy"
                v-else
              />
            </span>
          </el-form-item>

          <p>
            <el-form-item prop="major">
              <label>所属专业</label>
              <span>
                <el-select
                  class="select"
                  v-model="form.majorId"
                  @change="majorChange"
                  placeholder="请选择"
                  v-if="majorShow"
                >
                  <el-option
                    v-for="item in majorOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-input
                  class="input"
                  placeholder="请输入所属专业"
                  v-model="form.major"
                  v-else
                />
              </span>
            </el-form-item>
          </p>
          <p>
            <el-form-item prop="education">
              <label>最高学历</label>
              <span>
                <el-select
                  class="select"
                  v-model="form.education"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in educationOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </span>
            </el-form-item>
          </p>
          <p>
            <el-form-item prop="graduateTime">
              <label>毕业时间</label>
              <span>
                <el-date-picker
                  style="width: 80%"
                  v-model="form.graduateTime"
                  type="date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </span>
            </el-form-item>
          </p>
          <p>
            <el-form-item prop="studentNo">
              <label>学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号</label
              ><span
                ><el-input
                  class="input"
                  v-model="form.studentNo"
                  placeholder="请输入学号"
              /></span>
            </el-form-item>
          </p>
        </div>
        <div class="btn">
          <el-button
            type="primary"
            round
            size="mini"
            class="el-btn"
            @click="submit"
            style="background-color: #0099cc"
            >确认提交</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
const DEFAULT_FORM = {
  customId: null,
  phone: null,
  name: null,
  sex: null,
  nativePlace: null,
  schoolId: null,
  school: null,
  education: null,
  academyId: null,
  academy: null,
  majorId: null,
  major: null,
  studentNo: null,
  graduateTime: null,
  provinceCode: null,
  cityCode: null,
  districtCode: null,
  province: null,
  city: null,
  district: null,
};
import { saveWebCustomDetail, getCustomDetail } from "@/api/personal";
import { getScoolList, getAcademyList, getMajorList } from "@/api/school";
import { getProvinceList, getConfigAreaList } from "@/api/area";
import { local } from "@/utils/storage.js";
export default {
  components: {},
  data() {
    return {
      // 表单数据
      form: { ...DEFAULT_FORM },
      // 表单验证规则
      rules: {
        phone: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入真实姓名",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change",
          },
        ],
        province: [
          {
            required: true,
            message: "请选择省",
            trigger: "change",
          },
        ],
        city: [
          {
            required: true,
            message: "请选择市",
            trigger: "change",
          },
        ],
        district: [
          {
            required: true,
            message: "请选择区",
            trigger: "change",
          },
        ],
        school: [
          {
            required: true,
            message: "请选择学校",
            trigger: "blur",
          },
        ],
        education: [
          {
            required: true,
            message: "请选择最高学历",
            trigger: "change",
          },
        ],
        academy: [
          {
            required: true,
            message: "请选择院系",
            trigger: ["change", "blur"],
          },
        ],
        major: [
          {
            required: true,
            message: "请选择专业",
            trigger: ["change", "blur"],
          },
        ],
        studentNo: [
          {
            required: true,
            message: "请输入学号",
            trigger: "blur",
          },
        ],
        graduateTime: [
          {
            required: true,
            message: "请选择毕业时间",
            trigger: "blur",
          },
        ],
      },
      scCustom: {},
      //学校
      schoolList: [],
      academyShow: true,
      academyOptions: [],
      majorShow: true,
      majorOptions: [],
      educationOptions: ["本科以上", "大专", "中专"],
      //省
      provinceOptions: [],
      //市
      cityOptions: [],
      //区
      districtOptions: [],
      province: null,
      city: null,
      district: null,
      provinceCode: null,
      cityCode: null,
      districtCode: null,
      loading: false,
    };
  },
  methods: {
    /** 提交认证 */
    submit() {
      console.log(this.districtCode);
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        saveWebCustomDetail(this.form).then((res) => {
          console.log(res);
          this.loading = false;
          this.$message({
            message: "提交成功",
            type: "success",
          });
          //更新本地信息
          let scCustom = local.Get("scCustom");
          scCustom.auditStatus = 2;
          local.Set("scCustom", scCustom);
          console.log(window.history);
          console.log("roter",this.$router);
          let that = this;
          setTimeout(function () {
            if(that.$route.query.isBack){
              window.history.back();
            }else{
                that.handleAuthResult();
            }
          }, 1500);
        });
      });
    },
    //处理认证的结果
    handleAuthResult() {
      let redirect = this.$route.query?.redirect;
      if (redirect) {
        this.$router.push(redirect);
      } else {
        window.location.href = "/personCenter";
      }
    },

    // 远程查询学校列表
    selectScoolList(queryString, cb) {
      if (queryString != "") {
        this.schoolList = [];
        getScoolList({ name: queryString }).then((res) => {
          res.data.list.forEach((element) => {
            this.schoolList.push({
              value: element.name,
              id: element.id,
            });
          });
          cb(this.schoolList);
        });
      }
    },
    //学校 触发事件
    handleSelect(item) {
      this.form.schoolId = item.id;
      this.form.school = item.value;

      if (item.id) {
        this.academyShow = true;
        this.majorShow = true;
        this.form.academyId = null;
        this.form.majorId = null;
        this.form.academy = null;
        this.form.major = null;
        getAcademyList(item.id).then((res) => {
          this.academyOptions = res.data;
        });
      } else {
        this.form.academyId = null;
        this.form.majorId = null;
        this.form.academy = null;
        this.form.major = null;
        this.academyShow = false;
        this.majorShow = false;
      }
    },
    //院系 选择事件
    academyChange(item) {
      if (item) {
        this.form.majorId = "";
        this.form.major = "";
      }
      this.academyOptions.forEach((e) => {
        if (e.id == item) {
          this.form.academyId = e.id;
          this.form.academy = e.name;
        }
      });
      getMajorList(this.form.academyId).then((res) => {
        this.majorOptions = res.data;
        this.majorShow = res.data != null;
      });
    },
    //专业 选择事件
    majorChange(item) {
      if (item) {
        this.majorOptions.forEach((e) => {
          if (e.id == item) {
            this.form.majorId = e.id;
            this.form.major = e.name;
            // this.$set(this.form, this.form.major, );
            // this.$set(this.form, this.form.majorId, e.id);
          }
        });
      }
    },
    //省 选择事件
    provinceChange(item) {
      if (item) {
        this.cityCode = "";
        this.districtCode = "";
        this.form.city = "";
        this.form.district = "";
      }
      this.provinceOptions.forEach((e) => {
        if (e.value == item || e.label == this.province) {
          this.province = e.label;
          this.provinceCode = e.value;
          this.form.province = e.label;
          this.form.provinceCode = e.value;
        }
      });
      if (this.provinceCode) {
        getConfigAreaList({ areaCode: this.provinceCode }).then((res) => {
          this.cityOptions = res.data;
          if (this.city) {
            this.cityChange();
          }
        });
      }
    },
    //市 选择事件
    cityChange(item) {
      if (item) {
        this.district = "";
        this.form.district = "";
        this.form.districtCode = "";
      }
      this.cityOptions.forEach((e) => {
        if (e.value == item || e.label == this.city) {
          this.city = e.label;
          this.cityCode = e.value;
          this.form.city = e.label;
          this.form.cityCode = e.value;
        }
      });
      if (this.cityCode) {
        getConfigAreaList({ areaCode: this.cityCode }).then((res) => {
          this.districtOptions = res.data;
          if (this.district) {
            this.districtChange();
          }
        });
      }
    },
    //区 选择事件
    districtChange(item) {
      this.districtOptions.forEach((e) => {
        if (e.value == item || e.label == this.district) {
          this.district = e.label;
          this.districtCode = e.value;
          this.form.district = e.label;
          this.form.districtCode = e.value;
        }
      });
      this.form.nativePlace =
        this.province + "、" + this.city + "、" + this.district;
    },
    //获取认证地区
    getArea() {
      if (this.form.nativePlace) {
        const split = this.form.nativePlace.split("、");
        this.province = split[0];
        this.city = split[1];
        this.district = split[2];
        this.provinceOptions.forEach((e) => {
          if (e.label == this.province) {
            this.provinceCode = e.value;
            this.provinceChange();
          }
        });
      }
    },
  },
  created() {
    getCustomDetail().then((res) => {
      this.form = {
        ...this.form,
        ...res.data.authInfo,
        ...res.data.baseInfo,
        ...res.data.accountInfo,
      };
      getProvinceList().then((res) => {
        this.provinceOptions = res.data;
        this.getArea();
      });
      if (this.form.schoolId) {
        getAcademyList(this.form.schoolId).then((res) => {
          this.academyOptions = res.data;
          this.academyChange();
        });
      }
    });
  },

  watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-radio__inner {
  width: 23px;
  height: 23px;
}

::v-deep .el-radio__label {
  font-size: 15px;
}

::v-deep .el-form-item__error {
  padding-left: 106px;
}

::v-deep .form-item {
  display: inline-block;
}
::v-deep .el-form-item {
  margin-bottom: 26px;
}

.main-body {
  height: 1200px;

  .prompt-message {
    background-color: #0099cc;
    height: 80px;

    .p {
      padding: 10px 30px 10px 30px;
      font-size: 20px;
      color: #ffffff;
    }
  }

  .info {
    margin-top: 10px;
    background-color: #ffffff;
    height: 830px;

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .title-circle {
        width: 8px;
        height: 20px;
        background: #ffb618;
      }

      .title-text {
        margin-left: 10px;
        font-size: 22px;
        color: #000000;
        font-weight: bold;
      }
    }

    .info-field {
      align-items: center;
      margin-left: 20px;
      font-size: 20px;

      p {
        height: 60px;
        line-height: 60px;
        margin-top: 10px;
        align-items: center;
      }

      span {
        margin-left: 25px;

        .select {
          width: 80%;
        }
      }

      .el-autocomplete {
        width: 80%;
        height: 54px;
        padding: 12px 0px 0px 0px;
      }

      .input {
        border: none;
        outline: none;
        width: 80%;
        height: 42px;
        padding: 2px 0px;
      }

      label {
        font-size: 20px;
      }
    }

    .btn {
      text-align: center;

      .el-btn {
        width: 400px;
        height: 50px;
        font-size: 20px;
        margin-top: 70px;
      }
    }
  }
}
</style>