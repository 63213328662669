<template>
  <div class="personal-page page" v-loading="loading">
    <div class="page-wrap">
      <!-- 顶部 -->
      <page-top></page-top>
      <!-- 头部 -->
      <Header></Header>
      <!-- 主体 -->
      <main class="main">
        <section class="breadcrumb-plate w">
          <div class="label">我的位置：</div>
          <breadcrumb />
        </section>
        <section class="content w">
          <div class="c-left">
            <div class="user-wrap" @click="backToPerson">
              <div class="avatar-wrap">
                <img :src="scCustom.avatar" />
              </div>
              <div class="nick-wrap">
                {{ scCustom.nickname }}
              </div>
              <div class="name-wrap">
                {{ scCustom.name }}
              </div>
            </div>
            <!-- 认证信息 -->
            <div class="auth-wrap">
              <div class="sex-male" v-if="scCustom.sex == '男'">
                <span class="icon iconfont icon-sex-male"></span>
              </div>
              <div class="sex-female" v-if="scCustom.sex == '女'">
                <span class="icon iconfont icon-sex-female"></span>
              </div>
              <div class="auth" v-if="scCustom.auditStatus == 2">
                <span class="icon iconfont icon-prove"> 已认证</span>
              </div>
              <div class="student" v-if="scCustom.identityType == '1'">
                <span class="icon iconfont icon-school"> 学生</span>
              </div>
            </div>
            <!-- 菜单信息 -->
            <div class="menu-wrap">
              <div v-for="(item, index) in menuList" :key="index" class="menu" @click="handleMenuClick(item)"
                :class="{ 'active-menu': item.isActive }">
                <div class="menu-item">
                  <span :class="item.icon">&nbsp;&nbsp;&nbsp;{{ item.name }} </span>
                  <span class="icon iconfont icon-pager-right" v-if="!item.showChildren"> </span>
                  <span class="icon iconfont icon-toggle-down" v-else> </span>
                </div>
                <div class="menu-children-list" v-if="item.children && item.children.length && item.showChildren">
                  <div class="menu-children-item" v-for="(subitem, subindex) in item.children" :key="subindex"
                    :class="{ 'active-menu': subitem.isActive }" @click.stop="handleMenuChildClick(subitem)">
                    <span class="subitem-name">{{ subitem.name }} </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="c-right" :class="{'courseBg':courseRouter}">
            <div class="c-right-wrap" v-if="displayPage">
              <div class="un-auth-alert" v-if="scCustom.auditStatus != 2">
                <div>完成身份认证，享受更多权益</div>
                <div><button @click="handleAuthClick">去认证</button></div>
              </div>

              <!-- 我的参赛记录 -->
              <div class="match-record">
                <div class="title-wrap">
                  <div class="title-left">
                    <img :src="$globalHeader.imgUrl + '/static/icon/mod_match.png'" />
                    <span>我的参赛记录 </span>
                  </div>
                  <div class="title-right hover-text" @click="handleMoreClick('match')">
                    <span>更多</span>
                    <span class="icon iconfont icon-more-txt"> </span>
                  </div>
                </div>
                <div class="content-wrap" v-for="(item, index) in competitionList" :key="index">
                  <div class="content-left">
                    <img :src="item.image" style="width: 114px; height: 94px; border-radius: 6px" />
                  </div>
                  <div class="content-right">
                    <div>{{ item.projectName }}</div>
                    <div>
                      提交于：
                      {{ item.signupTime | formatDate }}
                    </div>

                    <div>
                      <span :style="{color:competitionStatusColor(item.status)}">{{ item.leftContent + " " }}</span>
                      <span :style="{backgroundColor:competitionStatusColor(item.status)}" class="status-radius" v-if="item.rightContent">{{item.rightContent}}</span>
                    </div>
                      
                  </div>
                </div>
              </div>

              <!-- 政策办理记录 -->
              <div class="policy-record">
                <div class="title-wrap">
                  <div class="title-left">
                    <img :src="$globalHeader.imgUrl + '/static/icon/mod_policy.png'" />
                    <span>政策办理记录 </span>
                  </div>
                  <div class="title-right hover-text" @click="handleMoreClick('policy')">
                    <span>更多</span>
                    <span class="icon iconfont icon-more-txt"> </span>
                  </div>
                </div>
                <div class="content-wrap" v-for="(item, index) in policyInfoList" :key="index">
                  <div class="content-left">
                    <img :src="$globalHeader.imgUrl + '/static/other/default_policy.png'"
                      style="width: 114px; height: 94px; border-radius: 6px" />
                  </div>
                  <div class="content-right">
                    <div>{{ item.policyName }}</div>
                    <div>
                      提交于：
                      {{ item.createTime | formatDate }}
                    </div>
                    <div>
                      <span :style="{backgroundColor:policyStatusColor(item.status)}" class="status-radius">{{item.handleStatus}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 双创项目提交记录 -->
              <div class="course-record">
                <div class="title-wrap">
                  <div class="title-left">
                    <img :src="$globalHeader.imgUrl + '/static/icon/mod_project.png'" />
                    <span>双创项目提交记录 </span>
                  </div>
                  <div class="title-right hover-text" @click="handleMoreClick('course')">
                    <span>更多</span>
                    <span class="icon iconfont icon-more-txt"> </span>
                  </div>
                </div>
                <div class="content-wrap" v-for="(item, index) in taskList" :key="index">
                  <div class="content-left">
                    <img :src="item.image" style="width: 114px; height: 94px; border-radius: 6px" />
                  </div>
                  <div class="content-right">
                    <div>{{ item.itemName }}</div>
                    <div>
                      提交于：
                      {{ item.applyTime | formatDate }}
                    </div>
                    <div>
                      <span :style="{backgroundColor:courseStatusColor(item.auditStatus)}" class="status-radius">{{item.auditStatus | auditStatusFilter}}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 我的订单记录 -->
              <div class="match-record">
                <div class="title-wrap">
                  <div class="title-left">
                    <img :src="$globalHeader.imgUrl + '/static/icon/mod_match.png'" />
                    <span>我的订单记录 </span>
                  </div>
                  <div class="title-right hover-text" @click="handleMoreClick('order')">
                    <span>更多</span>
                    <span class="icon iconfont icon-more-txt"> </span>
                  </div>
                </div>
                <div class="content-wrap" v-for="(item, index) in orderList" :key="index">
                  <div class="content-left">
                    <img :src="item.productImage" style="width: 114px; height: 94px; border-radius: 6px" />
                  </div>
                  <div class="content-right">
                    <div>{{ item.productName }}</div>
                    <div>下单时间：{{ item.orderTime }}</div>
                    <div>
                      <span :style="{color:orderStatusColor(item.auditStatus)}">总金额： {{ item.orderAmt }}</span>
                      <span :style="{backgroundColor:orderStatusColor(item.auditStatus)}" class="status-radius">{{item.auditStatus|orderStatusFiler}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <router-view></router-view>
          </div>
        </section>
      </main>
      <!-- 底部 -->
      <Footer></Footer>
    </div>

    <el-dialog title="" :visible.sync="centerDialogVisible" width="30%" center>
      <div style="text-align: center">
        <img :src="$globalHeader.imgUrl + '/static/miniprogram/personCenter.png'" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import { personDetail,getOrderPage } from "@/api/personal/index";
import { formatDate } from "@/utils/date";
import { local } from "@/utils/storage.js";
import { getOrderStatus,getCourseStatus,getCompetitionStatus,getPolicyStatus } from "@/components/common/status.js";


const auditStatusMap = [
  { code: "1", name: "未评审" },
  { code: "2", name: "已评审" },
  { code: "3", name: "已取消" },
  { code: "4", name: "未通过" },
];
// import AuthDetail from "./pages/authDetail.vue";
export default {
  components: { pageTop, Header, Footer, Breadcrumb },
  data() {
    return {
      displayPage: true,
      scCustom: {},
      competitionList: [], //赛事
      policyInfoList: [], //政策
      taskList: [], //课程
      show: false,
      loading: false,
      centerDialogVisible: false,
      menuList: [
        { icon: "icon iconfont icon-kabao", code: "coupon", name: "我的券包", isActive: false, showChildren: false, children: [] },
        { icon: "icon iconfont icon-collection", code: "collect", name: "我的收藏", isActive: false, showChildren: false, children: [] },
        { icon: "icon iconfont icon-files", code: "project", name: "项目资料", isActive: false, showChildren: false, children: [] },
        { icon: "icon iconfont icon-set", code: "setting", name: "系统设置", isActive: false, showChildren: false, children: [{ icon: "icon iconfont icon-kabao", code: "messageRemind", name: "消息提醒设置", isActive: false }, { icon: "icon iconfont icon-kabao", code: "aboutUs", name: "关于我们", isActive: false }] },
        { icon: "icon iconfont icon-editor", code: "auth", name: "实名认证", isActive: false, showChildren: false, children: [] },
      ],
      orderList:[],
      courseRouter:false,
    };
  },
  methods: {
    fetchData() {
      this.initPersonDetail();
    },
    //点击菜单
    handleMenuClick(item) {
      console.log(item);
      if (item.children && item.children.length) {
        item.showChildren = !item.showChildren
      } else {
        if (item.code == 'auth') {
          this.clearMenuActive(this.menuList)
          item.isActive = true
          this.$router.push('/personCenter/' + item.code)
          return
        } else {
          this.centerDialogVisible = true;
        }
      }
    },
    //点击子菜单
    handleMenuChildClick(item) {
      this.clearMenuActive(this.menuList)
      item.isActive = true
      this.$router.push('/personCenter/' + item.code)
    },
    //清空菜单active
    clearMenuActive(list) {
      if (list && list.length) {
        list.forEach(item => {
          item.isActive = false
          if (item.children && item.children.length) {
            this.clearMenuActive(item.children)
          }
        })
      }
    },
    //根据路由显示active
    menuActiveByRoute(string, list) {
      let result = false
      list.forEach(item => {
        if (result) return
        if (item.code == string) {
          item.isActive = true
          result = true
        } else if (item.children && item.children.length) {
          this.menuActiveByRoute(string, item.children)
        }
      })
    },
    //返回个人中心
    backToPerson() {
      this.clearMenuActive(this.menuList)
      this.$router.push('/personCenter')
      
    },
    //点击更多
    handleMoreClick(e) {
      if(e=='course') {
        this.$router.push('/personCenter/courseList')
        //  window.location.href = "/courseList" ;
        return
      }else if(e =='match') {
        this.$router.push('/personCenter/matchRecordList')
        return
      }else if(e== 'policy') {
        this.$router.push('/personCenter/policyList')
        return
      }else if(e== 'order') {
        this.$router.push('/personCenter/orderList')
        return
      }
      this.centerDialogVisible = true;
    },
    //认证按钮
    handleAuthClick() {
      this.$router.push('/personCenter/auth')
    },
    //初始化用户信息
    initPersonDetail() {
      this.loading = true;
      personDetail().then((res) => {
        if (!res.data) return;
        this.scCustom = res.data.baseInfo;
        local.Set("scCustom", this.scCustom);
        if(res.data.competitionList){
          this.competitionList =
          res.data.competitionList.length > 2
            ? res.data.competitionList.splice(0, 2)
            : res.data.competitionList;
        }
        if( res.data.policyInfoList){
          this.policyInfoList =
          res.data.policyInfoList.length > 2
            ? res.data.policyInfoList.splice(0, 2)
            : res.data.policyInfoList;
        }
        if(res.data.taskList){
          this.taskList =
          res.data.taskList.length > 2
            ? res.data.taskList.splice(0, 2)
            : res.data.taskList;
        }
          this.loading = false;
      }).finally(() => {
          this.loading = false;
      })
      // 订单
     getOrderPage({page:1,limit:2}).then(res =>{
        this.orderList = res.data.list;
     })
    },

    orderStatusColor(status) {
      return getOrderStatus(status);
    },
    courseStatusColor(status) {
      return getCourseStatus(status);
    },
    competitionStatusColor(status) {
      return getCompetitionStatus(status);
    },
    policyStatusColor(status) {
      return getPolicyStatus(status);
    },

    
  },
  created() {
    let code = this.$route.query?.code;
    if (code) {
      this.code = code;
    }
    this.fetchData();
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy.MM.dd hh:mm");
    },
    auditStatusFilter(code) {
      let statusName = "";
      auditStatusMap.filter((item) => {
        if (code == item.code) {
          statusName = item.name;
        }
      });
      return statusName;
    },
    // 0/已取消,1/待确认，2/已确认
    orderStatusFiler(status) {
      const statusMap = {
        "0": "已取消",
        "1": "待确认",
        "2": "已确认"
      }
      return statusMap[status];
    },
  },
  watch: {
    $route: {
      handler() {
        let routerList = this.$route.path.slice(1, this.$route.path.length).split('/')
        if (routerList && routerList.length == 1) {
          this.courseRouter = false
          this.displayPage = true;
            console.log("routerList[1]=====0",routerList[1])

        } else {
          this.courseRouter = false
          this.displayPage = false;
          if(routerList[1]== 'courseList' || routerList[1]== 'matchRecordList' || routerList[1]== 'policyList' || routerList[1]== 'orderList') {
            this.courseRouter = true
            console.log("routerList======2",routerList)

          }
          this.menuActiveByRoute(routerList[1], this.menuList)
            
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.red {
  color: red;
}

.green {
  color: #14a112;
}

.bule {
  color: #00c4f4;
}
.status-radius{
    font-size: 16px;
    color: #fff;
    width: 80px;
    text-align: center;
    border-radius: 10px;
    height: 30px;
    line-height: 30px;
    float: right;
    // right: 200px;
    margin-right: 200px;
}

.content-right {
      padding-top: 0px;
      margin-left: 16px;
      width: 100%;
      border-bottom: #f5f5f5 solid 1px;
      font-family: "Arial Normal", "Arial";
      font-weight: 400;
      font-style: normal;

      div:nth-child(1) {
        margin-top: 4px;
        height: 26px;
        font-size: 20px;
        color: #000000;
      }

      div:nth-child(2) {
        height: 26px;
        font-size: 16px;
        color: #999999;
        margin-top: 10px;
      }

      div:nth-child(3) {
        margin-top: 6px;
        font-size: 18px;
        color: #ffffff;
      }
    }

.personal-page {
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }

      .el-breadcrumb {
        cursor: pointer;

        /deep/ .el-breadcrumb__inner.is-link {
          font-weight: normal;
        }
      }
    }
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-items: stretch;

    .c-left {
      width: 320px;
      margin-right: 5px;
      background-color: #fff;
      box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
      padding: 60px 0;

      .user-wrap {
        text-align: center;
        padding: 0 30px;
        cursor: pointer;

        .avatar-wrap {
          text-align: center;
          border: #dcdcdc solid 1px;
          width: 170px;
          height: 170px;
          border-radius: 50%;
          margin-left: 40px;

          img {
            width: 160px;
            height: 160px;
            border-radius: 50%;
            margin-top: 4px;
          }
        }

        .nick-wrap {
          margin-top: 10px;
          font-size: 20px;
        }

        .name-wrap {
          margin-top: 5px;
          font-size: 16px;
        }
      }

      .auth-wrap {
        display: flex;
        text-align: center;
        flex-direction: row;
        justify-content: flex-start;
        margin: 10px 40px;
        padding: 0 30px;

        .sex-male {
          background-color: #6cc5ff;
          width: 26px;
          height: 26px;

          span {
            line-height: 24px;
            color: #fff;
          }
        }

        .sex-female {
          background-color: #ff9999;
          border-radius: 3px;
          width: 26px;
          height: 26px;

          span {
            line-height: 28px;
            color: #fff;
          }
        }

        .auth {
          background-color: #ffcc33;
          border-radius: 3px;
          width: 80px;
          height: 26px;
          margin-left: 5px;

          span {
            line-height: 26px;
            color: #fff;
            font-size: 14px;
          }
        }

        .student {
          background-color: #00b000;
          border-radius: 3px;
          width: 60px;
          height: 26px;
          margin-left: 5px;

          span {
            line-height: 26px;
            color: #fff;
            font-size: 14px;
          }
        }
      }

      .menu-wrap {
        .menu {
          display: flex;
          flex-direction: column;
          border-bottom: #f5f5f5 solid 1px;
          cursor: pointer;
          user-select: none;

          .menu-item {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 30px;
          }

          .menu-item:hover {
            background-color: #f7f7f7;
          }

          .menu-children-list {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;

            .menu-children-item {
              flex: 1;
              display: flex;
              justify-content: space-between;
              padding: 0 30px 0 60px;

              .subitem-name {
                font-size: 15px;
                color: #333;
                margin: 12px 0 12px;
              }
            }

            .menu-children-item:hover {
              background-color: #f7f7f7;
            }
          }

          span:nth-child(1) {
            font-family: "Arial Normal", "Arial";
            font-weight: 400;
            font-style: normal;
            font-size: 16px;
            color: #000000;
          }

          span:nth-child(2) {
            font-size: 20px;
            color: #c0c0c0;
          }
        }

        .active-menu {
          background-color: #f7f7f7;
        }
      }
    }

    .c-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
      padding: 20px;
    }
    .courseBg {
      background-color: #efefef !important;
      box-shadow: none;
      padding: 0;
      margin-left: 15px;
    }
    .c-right-wrap {
      flex: 1;

      .un-auth-alert {
        background: linear-gradient(to left, #1d9cff, #00c4f4);
        height: 40px;
        color: #fff;
        line-height: 40px;
        padding-left: 30px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;

        div:nth-child(2) {
          padding-right: 30px;

          button {
            width: 80px;
            line-height: 24px;
            font-size: 14px;
            color: #086092;
            border-radius: 12px;
            border: #68d4ff solid 1px;
            background: linear-gradient(to bottom, #8aebf5, #68d4ff);
          }
        }
      }

      .course-record {
        margin-top: 20px;

        .title-wrap {
          height: 30px;
          line-height: 30px;
          display: flex;
          justify-content: space-between;

          .title-left {
            img {
              height: 20px;
              width: 20px;
            }

            span {
              margin-left: 10px;
              font-weight: 700;
              font-style: normal;
              font-size: 18px;
              color: #000000;
            }
          }

          .title-right {
            font-weight: 400;
            font-size: 16px;
            color: #a1a1a1;
            cursor: pointer;

            span:nth-child(1) {
              margin-right: 8px;
            }
          }
        }

        .content-wrap {
          margin-top: 2px;
          height: 120px;
          display: flex;
          flex-direction: row;
          padding-top: 10px;

          .content-left {
            line-height: 95px;
          }

        }
      }

      .policy-record {
        margin-top: 20px;

        .title-wrap {
          height: 30px;
          line-height: 30px;
          display: flex;
          justify-content: space-between;

          .title-left {
            img {
              height: 20px;
              width: 20px;
            }

            span {
              margin-left: 10px;
              font-weight: 700;
              font-style: normal;
              font-size: 18px;
              color: #000000;
            }
          }

          .title-right {
            font-weight: 400;
            font-size: 16px;
            color: #a1a1a1;
            cursor: pointer;

            span:nth-child(1) {
              margin-right: 8px;
            }
          }
        }

        .content-wrap {
          margin-top: 2px;
          height: 120px;
          display: flex;
          flex-direction: row;
          padding-top: 10px;

          .content-left {
            line-height: 95px;
          }
        }
      }

      .match-record {
        margin-top: 20px;

        .title-wrap {
          height: 30px;
          line-height: 30px;
          display: flex;
          justify-content: space-between;

          .title-left {
            img {
              height: 20px;
              width: 20px;
            }

            span {
              margin-left: 10px;
              font-weight: 700;
              font-style: normal;
              font-size: 18px;
              color: #000000;
            }
          }

          .title-right {
            font-weight: 400;
            font-size: 16px;
            color: #a1a1a1;
            cursor: pointer;

            span:nth-child(1) {
              margin-right: 8px;
            }
          }
        }

        .content-wrap {
          margin-top: 2px;
          height: 120px;
          display: flex;
          flex-direction: row;
          padding-top: 10px;

          .content-left {
            line-height: 95px;
          }
        }
      }
    }
  }
}
</style>
