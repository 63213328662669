
//获取时间格式
export function getDate(type) {
    let myDate = new Date()
    let year = myDate.getFullYear()
    let month = String(myDate.getMonth() + 1)
    let day = myDate.getDate().toString()
    let hour = myDate.getHours().toString()
    let minutes = myDate.getMinutes().toString()
    let second = myDate.getSeconds().toString()
    if (month.length == 1) {
        month = '0' + month
    }
    if (day.length == 1) {
        day = '0' + day
    }
    if (hour.length == 1) {
        hour = '0' + hour
    }
    if (minutes.length == 1) {
        minutes = '0' + minutes
    }
    if (second.length == 1) {
        second = '0' + second
    }
    if (type.toLowerCase() == 'yyyy-mm-dd hh:mm') {
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes
    } else if (type.toLowerCase() == 'yyyy-mm-dd') {
        return year + '-' + month + '-' + day
    } else if (type.toLowerCase() == 'yyyy-mm-dd hh:mm:ss') {
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + second
    }
}

//时间戳转化date
export function timeToDate(type) {
    let myDate = new Date(type)
    let year = myDate.getFullYear()
    let month = String(myDate.getMonth() + 1)
    let day = myDate.getDate().toString()
    let hour = myDate.getHours().toString()
    let minutes = myDate.getMinutes().toString()
    let second = myDate.getSeconds().toString()
    if (month.length == 1) {
        month = '0' + month
    }
    if (day.length == 1) {
        day = '0' + day
    }
    if (hour.length == 1) {
        hour = '0' + hour
    }
    if (minutes.length == 1) {
        minutes = '0' + minutes
    }
    if (second.length == 1) {
        second = '0' + second
    }
    return year + '-' + month + '-' + day

}

export function previewFile(url) {
    // console.log("预览文件地址：" + url);
    let Base64 = require('js-base64').Base64;
    let watermark = 'VUE_APP_WATERMARK=双创金服'
    window.open(
        `http://preview.scgxfwz.com/onlinePreview?url=` +
        encodeURIComponent(Base64.encode(url)) +
        '&watermarkTxt=' +
        encodeURIComponent(watermark),
        '_blank'
    );
}