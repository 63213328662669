<template>
  <div class="match-list" v-if="matchList.length>0">
    <div
      class="item matchItem"
      v-for="(item, index) in matchList"
      :key="index"
      @click="toMatchDetail(item)"
    >
      <div class="match-item">
        <div class="item-top">
          <div class="img-wrap">
            <img class="img" :src="item.image" alt="赛事图片" />
          </div>
        </div>
        <div class="item-bot">
          <h3 class="item-title">
            {{ item.title }}
          </h3>
          <div class="item-content">
            <div class="match">
              <div class="subitem">
                <div class="label">
                  <span class="icon iconfont icon-tag-time"></span>
                  <span class="txt">赛事：</span>
                </div>
                <div class="content">
                  <span>{{ item.startTime || "" }}</span>
                  <span>{{ item.endTime || "" }}</span>
                </div>
              </div>
            </div>
            <div class="join">
              <div class="subitem">
                <div class="label">
                  <span class="icon iconfont icon-tag-time"></span>
                  <span class="txt">报名：</span>
                </div>
                <div class="content">
                  <span>{{ item.signupStartTime || "" }}</span>
                  <span>{{ item.signupEndTime || "" }}</span>
                </div>
              </div>
            </div>
            <div class="item-last">
              <div class="content address-content ignore" style="width: 80%">
                <span class="icon iconfont icon-tag-address"></span>
                <span>{{ item.address }}</span>
              </div>
              <div class="content view">
                <span class="icon iconfont icon-browse"></span>
                <span>{{ item.views }}</span>
              </div>
            </div>
          </div>
          <div
            class="match-status"
            :style="{ background: MatchStatusMap[item.timeStatus].bg }"
          >
            <!-- <span class="icon iconfont" :class="MatchStatusMap[item.timeStatus].icon"></span> -->
            <span class="txt">{{ item.timeStatus }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="m-pager">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-size="limit"
          :total="pageCount"
          @current-change="changePageClick"
        >
        </el-pagination>
      </div>
  </div>
  <div class="empty" v-else>
    <el-empty description="暂无数据"></el-empty>
  </div>
</template>

<script>
import { getCompetitionPage } from "@/api/station/station.js";
const MatchStatusMap = {
  未开始: {
    bg: "linear-gradient(to left, #18EA63, #00E9F4)",
    icon: "icon-match-end",
  },
  报名中: {
    bg: "linear-gradient(to right, #F7D941, #FAAC1F)",
    icon: "icon-join-record",
  },
  进行中: {
    bg: "linear-gradient(to left, #FA6E29, #F63361)",
    icon: "icon-match-promotion",
  },
  已结束: {
    bg: "linear-gradient(to left, #9F9F9F, #D0D0D0)",
    icon: "icon-match-end",
  },
};
export default {
  props: {
    stationId: String,
  },
  data() {
    return {
      matchList: [],
      MatchStatusMap: MatchStatusMap,
      pageCount:'',
      limit:12,
    };
  },
  methods: {
    getMatchList(page = 1) {
      let params = {
        stationId: this.stationId,
        page: page,
        limit: this.limit,
      };
      getCompetitionPage(params).then((res) => {
        this.matchList = res.data.list;
        this.pageCount =Number(res.data.total) 
      });
    },
    //切换页面
    changePageClick(page) {
      this.getMatchList(page);
    },
    //赛事详情
    toMatchDetail(item) {
      this.$router.push({
        path: "/match/matchDetail/" + item.id,
      });
    },
  },
  created() {
    this.getMatchList();
  },
};
</script>

<style lang="scss" scoped>
.match-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin: 20px;
  min-height: 440px;
  .first-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .i-top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 18px;
      padding: 16px 20px;
      width: 100%;
      height: 280px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

      .i-top-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 16px;
        border-radius: 6px;
        box-sizing: border-box;
        box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
        cursor: pointer;

        .i-top-item-left {
          flex-shrink: 0;
          margin-right: 16px;

          .icon {
            font-size: 28px;
            color: #fff;
          }
        }

        .i-top-item-right {
          flex: 1;
          color: #fff;

          .subtitle {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .title {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .i-center {
      flex: 1;
      padding: 10px 0;
      height: 196px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

      .title-wrap {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 10px;

        .title {
          display: flex;
          align-items: center;
          padding: 4px 20px;
          font-size: 18px;
          position: relative;
        }

        .title::after {
          content: " ";
          position: absolute;
          bottom: 0;
          background-color: #ffdc0b;
          width: 2em;
          height: 3px;
          border-radius: 2px;
          overflow: hidden;
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-shrink: 0;
          padding: 10px 20px;
          cursor: pointer;

          .icon {
            font-size: 12px;
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;

        a:hover {
          border: none !important;
        }

        .item-dy {
          padding: 14px 20px;
          cursor: pointer;
        }

        .item-dy:hover .item-dec,
        .item-dy:hover .item-dynamic .content {
          color: #0068c6 !important;
        }

        .item-dy:hover .item-dynamic .circle {
          background-color: #0068c6;
        }

        .item-dec {
          padding-left: 14px;
          margin-top: 8px;
          font-size: 12px;
          padding-right: 42px;
          color: #999;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .item-dynamic {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .circle {
            margin-top: -1px;
            margin-right: 8px;
            width: 6px;
            height: 6px;
            background-color: #e5e5e5;
            border-radius: 50%;
          }

          .content {
            flex: 1;
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .time {
            min-width: 3.5em;
            flex-shrink: 0;
            margin-left: 10px;
            text-align: right;
            font-size: 12px;
            color: #999;
          }
        }
      }
    }
  }

  .item {
    width: calc(31% - 1px);
    margin-right: 25px;
    margin-bottom: 25px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 4px 4px 12px rgba($color: #000000, $alpha: 0.05);

    .match-item {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      background-color: #fff;
      border-radius: 12px;
      overflow: hidden;
      cursor: pointer;

      .item-top {
        flex-shrink: 0;

        .img-wrap {
          width: 100%;
          height: 160px;
        }
      }

      .item-bot {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 15px;
        padding-bottom: 47px;
        .item-title {
          min-height: 36px;
          font-size: 15px;
          color: #000;
        }
        .match-status {
          position: absolute;
          bottom: 0;
          right: -10px;
          width: 84px;
          height: 30px;
          padding: 6px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 60px 0 0 0px;

          .txt {
            // margin-left: 4px;
            font-size: 14px;
            // font-weight: bold;
            color: #fff;
          }

          .icon {
            color: #fff;
          }
        }
        .item-content {
          position: relative;
          display: flex;
          flex-direction: column;
          // margin-top: 8px;

          .subitem {
            flex-shrink: 0;
            display: flex;
            align-items: self-start;
            margin-top: 8px;
            font-size: 12px;
            line-height: 1;
            color: #666;

            .label {
              flex-shrink: 0;
            }

            .content {
              display: flex;
              flex-direction: column;

              span {
                margin-bottom: 6px;
              }
            }

            .address-content {
              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .icon {
              font-size: 12px;
              margin-right: 4px;
            }
          }

          .item-last {
            display: flex;
            justify-content: space-between;
            align-items: self-start;
            margin-top: 8px;
            font-size: 12px;
            line-height: 1;
            color: #666;
            .ignore {
              flex: 1;
              display: -webkit-box;
              word-break: break-all;
              text-overflow: ellipsis;
              overflow: hidden;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
            .view {
              margin-left: 1em;
            }
            .content {
              span {
                margin-bottom: 6px;
              }
            }

            .icon {
              font-size: 12px;
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

  .item:nth-child(3n) {
    margin-right: 0;
  }

  // .matchItem:hover {
  //   box-shadow: 4px 4px 12px rgba($color: #000000, $alpha: 0.15);
  // }

  /* Shrink */
  .matchItem {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }

  .matchItem:hover,
  .matchItem:focus,
  .matchItem:active {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
}
.empty {
      min-height: 440px;
      display: flex;
      justify-items: center;
      justify-content: center;
    }
.m-pager {
    margin: 24px 0;
    width: 100%;
    .el-pagination {
      display: flex;
      justify-content: center;

      /deep/ .btn-prev {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-prev:hover:enabled {
        color: #409eff;
      }

      /deep/ .number {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .more {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-next {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .active {
        color: #fff;
        background-color: #409eff;
      }

      /deep/ .btn-next:hover:enabled {
        color: #409eff;
      }
    }
  }
</style>