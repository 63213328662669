<template>
  <div class="match-sub-page page">
    <!-- 顶部 -->
    <page-top></page-top>
    <!-- 头部 -->
    <Header></Header>
    <!-- 主体 -->
    <main class="main">
      <section class="breadcrumb-plate w">
        <div class="label">我的位置：</div>
        <breadcrumb />
      </section>

      <section class="content w">
        <div class="c-left">
          <div class="info-plate plate">
            <div class="title">
              <span>{{ getFormKindInfo.fileName }}</span>
            </div>
            <div class="subtitle">
              <div class="subtitle-item">
                <div class="s_i_left">
                  <span>报名时间</span>
                </div>
                <div class="s_i_right">
                  <span>{{ getFormKindInfo.signupTime || "" }}</span>
                </div>
              </div>
              <div class="subtitle-item">
                <div class="s_i_left">
                  <span>报名条件</span>
                </div>
                <div class="s_i_right">
                  <span>{{ getFormKindInfo.kindDesc || "无" }}</span>
                </div>
              </div>
              <div class="subtitle-item">
                <div class="s_i_left">
                  <span>报名流程</span>
                </div>
                <div class="s_i_right">
                  <span>{{ getFormKindInfo.processDesc || "无" }}</span>
                </div>
              </div>
              <div class="subtitle-item">
                <div class="s_i_left">
                  <span>表格下载</span>
                </div>
                <div
                  class="s_i_right"
                  style="color: #619cd1; cursor: pointer"
                  @click="downloadFile"
                >
                  <span>{{ getFormKindInfo.fileName || "无" }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="info-title" v-if="formData && formData.length">
            填写申请信息
          </div>
          <div class="c-left-content" v-if="formData && formData.length">
            <div class="project-choose">
              <div
                class="form-wrap"
                v-for="(item, index) in formData"
                :key="index"
              >
                <!-- input -->
                <div class="item-wrap" v-if="item.type == 'input'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="item-content-left">
                      <div class="input-wrap">
                        <input
                          class="input"
                          :class="{ ban: !item.rule.isUpdate }"
                          :placeholder="item.placeholder"
                          v-model="item.remark"
                          :disabled="!item.rule.isUpdate"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- textarea -->
                <div class="item-wrap" v-if="item.type == 'textarea'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content textarea-content">
                    <div class="item-content-left">
                      <textarea
                        class="textarea"
                        :class="{ ban: !item.rule.isUpdate }"
                        v-model="item.remark"
                        maxlength="-1"
                        :disabled="!item.rule.isUpdate"
                        resize="none"
                        :placeholder="item.placeholder"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <!-- select -->
                <div class="item-wrap" v-if="item.type == 'select'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <el-select
                      v-model="item.remark"
                      :placeholder="item.placeholder"
                      style="width: 240px"
                      :disabled="!item.rule.isUpdate"
                      @change="changeSelect(item)"
                      @focus="clickSelect(item)"
                    >
                      <el-option
                        v-for="subitem in getSelectValue(item.defaultValue)"
                        :key="subitem.value"
                        :label="subitem.label"
                        :value="subitem.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>

                <!-- datetimePicker -->
                <div class="item-wrap" v-if="item.type == 'datePicker'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <el-date-picker
                      v-model="item.remark"
                      type="date"
                      placeholder="选择日期"
                      style="width: 240px"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                </div>

                <!-- areaSelect籍贯 -->
                <div class="item-wrap" v-if="item.type == 'areaSelect'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="area-content">
                      <div class="area-item">
                        <el-select
                          v-model="provinceCode"
                          @change="provinceChange(index)"
                          placeholder="请选择省"
                        >
                          <el-option
                            v-for="item in provinceOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="area-item">
                        <el-select
                          v-model="cityCode"
                          @change="cityChange(index)"
                          placeholder="请选择市"
                        >
                          <el-option
                            v-for="item in cityOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="area-item">
                        <el-select
                          v-model="districtCode"
                          @change="districtChange(index)"
                          placeholder="请选择区"
                        >
                          <el-option
                            v-for="item in districtOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <div class="item-content-left" style="margin-top: 20px">
                      <div class="input-wrap">
                        <input
                          class="input"
                          type="span"
                          v-model="areaInput"
                          placeholder="请输入详细地址"
                          style="padding: 8px 0"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- radio -->
                <div class="item-wrap" v-if="item.type == 'radio'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="radio-content">
                      <el-radio
                        v-model="item.remark"
                        :label="subitem.label"
                        @input="changeRadio(item)"
                        v-for="(subitem, subindex) in getRadioList(
                          item.defaultValue
                        )"
                        :key="subindex"
                        style="margin-bottom: 4px; white-space: pre-wrap"
                        >{{ subitem.value }}</el-radio
                      >

                      <div class="item-content-left" v-if="item.switchYn">
                        <div class="input-wrap">
                          <input
                            class="input"
                            type="span"
                            v-model="item.value"
                            placeholder="请输入"
                            style="padding: 8px 0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- checkbox -->
                <div class="item-wrap" v-if="item.type == 'checkbox'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="checkbox-content">
                      <el-checkbox-group
                        v-model="item.remark"
                        @change="changeCheckbox(item)"
                      >
                        <el-checkbox
                          v-for="(subitem, subindex) in getSelectValue(
                            item.defaultValue
                          )"
                          :label="subitem.label"
                          :key="subindex"
                          >{{ subitem.value }}</el-checkbox
                        >
                      </el-checkbox-group>

                      <div class="item-content-left" v-if="item.switchYn">
                        <div class="input-wrap">
                          <input
                            class="input"
                            type="span"
                            v-model="item.value"
                            placeholder="请输入"
                            style="padding: 8px 0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- dict学校 -->
                <div class="item-wrap" v-if="item.type == 'dict'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <el-autocomplete
                      class="autocomplete"
                      @select="handleSelect"
                      @change="changeSchool"
                      :fetch-suggestions="selectScoolList"
                      placeholder="请输入高校名称"
                      :trigger-on-focus="false"
                      v-model="item.remark"
                    />
                  </div>
                </div>

                <!-- invite -->
                <div class="item-wrap" v-if="item.type == 'invite'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div
                    class="subtitle"
                    :class="{ ban: !item.rule.isUpdate }"
                    v-if="item.rule.minNum"
                  >
                    至少添加{{ item.rule.minNum }}名团队成员<span
                      v-if="item.rule.maxNum && item.rule.maxNum != '0'"
                      >，至多{{ item.rule.maxNum }}名</span
                    >
                    <span v-if="item.rule.placeholder"
                      >， {{ item.rule.placeholder }}</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="member-wrap">
                      <div
                        class="input"
                        :class="{ ban: !item.rule.isUpdate }"
                        @click="toAddMember(item)"
                      >
                        <div
                          class="member-list"
                          v-if="getRemark(item.remark, 'array').length"
                        >
                          <div
                            :class="{ ban: !item.rule.isUpdate }"
                            v-for="(subitem, subindex) in getRemark(
                              item.remark,
                              'array'
                            )"
                            :key="subindex"
                          >
                            {{ subitem.name }};
                          </div>
                        </div>
                        <div class="memer-list" v-else>
                          <span
                            :class="{ ban: !item.rule.isUpdate }"
                            class="span"
                            >去添加</span
                          >
                        </div>
                      </div>
                      <div class="more">
                        <div
                          class="refresh"
                          style="
                            font-size: 14px;
                            padding: 0 3px;
                            margin-right: 10px;
                          "
                          v-if="!isRefreshMember"
                        >
                          <span
                            class="span"
                            :class="{ ban: !item.rule.isUpdate }"
                            >刷新</span
                          >
                          font-size: 14px; padding: 0 3px; margin-right: 10px; "
                          @click.stop="refreshMember" v-if="!isRefreshMember" >
                          <span
                            class="span"
                            :class="{ ban: !item.rule.isUpdate }"
                            >刷新</span
                          >
                        </div>
                        <div
                          class="refresh"
                          style="
                            font-size: 14px;
                            padding: 0 3px;
                            margin-right: 10px;
                          "
                          v-else
                        >
                          <span
                            class="span"
                            :class="{ ban: !item.rule.isUpdate }"
                            >...</span
                          >
                          font-size: 14px; padding: 0 3px; margin-right: 10px; "
                          v-else >
                          <span
                            class="span"
                            :class="{ ban: !item.rule.isUpdate }"
                            >...</span
                          >
                        </div>
                        <div
                          :class="{ ban: !item.rule.isUpdate }"
                          style="font-size: 16px"
                        >
                          {{ item.remark ? item.remark.length : 0 }}
                        </div>
                        <div class="">
                          <span
                            class="icon iconfont icon-more"
                            :class="{ ban: !item.rule.isUpdate }"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- json -->
                <div class="item-wrap" v-if="item.type == 'json'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="item-content-left">
                      <div class="add-teacher">
                        <div
                          class="teacher-list"
                          v-if="getRemark(item.remark, 'array').length"
                        >
                          <div
                            class="teacher-item"
                            v-for="(subitem, subindex) in getRemark(
                              item.remark,
                              'array'
                            )"
                            :key="subindex"
                            @click="changeTeacher(item, subindex)"
                          >
                            <div
                              class="wrap"
                              :class="{ ban: !item.rule.isUpdate }"
                            >
                              <span class="name">{{
                                getRemark(subitem.teacher_name, "object").value
                              }}</span>
                            </div>
                            <div
                              class="delete"
                              :class="{ ban: !item.rule.isUpdate }"
                              @click.stop="deleteTeacherList(subindex)"
                            >
                              删除
                            </div>
                          </div>
                        </div>
                        <div class="teacher-list" v-else>
                          <div class="teacher-item">
                            <span class="span" style="color: #808080"
                              >去添加</span
                            >
                          </div>
                        </div>
                        <div class="btn-wrap">
                          <button
                            class="btn"
                            :class="{ banBgc: !item.rule.isUpdate }"
                            @click="jumpToAddTeacher(item)"
                          >
                            添加导师
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- image -->
                <div class="item-wrap" v-if="item.type == 'image'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="subtitle">
                    <span
                      style="color: #999; font-size: 12px; overflow: hidden"
                      >{{ item.rule.placeholder || "" }}</span
                    >
                  </div>
                  <div class="item-content" @click="changeImgIndex(index)">
                    <el-upload
                      :action="fileUploadUrl"
                      :data="fileUploadParam"
                      list-type="picture-card"
                      :limit="1"
                      :before-upload="beforeImgUpload"
                      :on-success="handleImgUploadSuccess"
                      :on-preview="handleImgPreview"
                      :on-remove="handleImgRemove"
                      :file-list="item.remark ? item.remark : []"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </div>
                </div>

                <!-- file -->
                <div class="item-wrap" v-if="item.type == 'file'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="subtitle">
                    <span
                      style="color: #999; font-size: 12px; overflow: hidden"
                      >{{ item.rule.placeholder || "" }}</span
                    >
                  </div>
                  <div class="item-content" @click="changeFileIndex(index)">
                    <el-upload
                      class="upload-demo"
                      :action="fileUploadUrl"
                      :data="fileUploadParam"
                      :before-upload="beforeFileUpload"
                      :on-preview="handleFilePreview"
                      :on-remove="handleFileRemove"
                      :on-success="handleFileUploadSuccess"
                      :on-error="handleFileUploadError"
                      :before-remove="beforeFileRemove"
                      multiple
                      :limit="1"
                      :on-exceed="handleFileExceed"
                      :file-list="item.value ? item.value : []"
                      :disabled="item.disabled"
                    >
                      <el-button size="small" type="primary"
                        >点击上传</el-button
                      >
                    </el-upload>
                  </div>
                </div>
              </div>
            </div>

            <div class="foot">
              <div class="btn-wrap">
                <div class="btn btn-default" @click="tempSave">暂存草稿</div>
                <div class="btn btn-primary" @click="submit">提交</div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-right">
          <div class="plate1">
            <div class="title">相关报名</div>
            <div class="k-bot">
              <div
                class="k-item"
                @click="toFormKind(item)"
                v-for="(item, index) in formKindOtherList"
                :key="index"
              >
                <div class="i-top-item-left">
                  <span class="icon iconfont icon-join-record"></span>
                </div>
                <div class="i-top-item-right">
                  <div class="subtitle">{{ item.headline || "" }}</div>
                  <div class="title">{{ item.kindName || "" }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="plate1" @click="toMatchDetail">
            <div class="title">推荐赛事</div>
            <div class="title-border"></div>
            <div class="img-wrap">
              <img class="img" :src="mainRecommendMatch.image" alt="" />
            </div>
            <div class="content">{{ mainRecommendMatch.title }}</div>
          </div>
        </div>
      </section>

      <div class="popup-wrap" v-if="showPopup">
        <el-dialog
          :visible.sync="formData[teacherArrayIndex].switchYn"
          :close-on-click-modal="false"
        >
          <match-sub-item
            :formData="popupData"
            :index="currentTeacherIndex"
            @getTeacherData="getTeacherData"
          ></match-sub-item>
        </el-dialog>
        <el-dialog
          :visible.sync="formData[projectMemberIndex].switchYn"
          :close-on-click-modal="false"
        >
          <match-add-member
            :matchId="matchId"
            :categoryId="categoryId"
            :memberData="memberData"
            :rules="addmemberRules"
          ></match-add-member>
        </el-dialog>
      </div>
    </main>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import matchSubItem from "@/views/match/components/matchSubItem";
import matchAddMember from "@/views/match/components/matchAddMember";

import {
  getMatchFormKind,
  getFormKindDetail,
  customMatchKindApply,
  getMatchProjectMember,
  getMatchSub,
  getRecommend,
} from "@/api/match/match";
import { getqiniuToken } from "@/api/common/common";
import { getScoolList, getAcademyList, getMajorList } from "@/api/school";
import { getProvinceList, getConfigAreaList } from "@/api/area";
import { regionData } from "element-china-area-data";
export default {
  components: {
    pageTop,
    Header,
    Footer,
    Breadcrumb,
    matchSubItem,
    matchAddMember,
  },

  data() {
    return {
      applyId: "", //办理id
      matchId: "", //大赛ID
      matchName: "", //大赛名称
      status: 0, //认证状态
      authInfo: {}, //认证信息

      //表单数据
      formData: [], //表单列表
      form: {}, //提交表单数据
      isUpdate: true,

      //赛区
      zones: [],
      comArea: "", //选择的赛区
      comAreaList: [], //赛区显示列表

      //赛道
      categories: [], //赛道列表
      categoryId: "", //赛道ID
      comTrack: "", //当前选择的赛道
      comTrackList: [], //赛道显示列表

      //areaSelect 籍贯
      provinceOptions: [],
      provinceCode: null,
      province: "",
      cityOptions: [],
      cityCode: null,
      city: "",
      districtOptions: [],
      districtCode: null,
      district: "",
      areaInput: "",
      areaList: regionData, //城市列表

      //dict学校
      school: null,
      searchSchoolList: [], //学校列表
      schoolIndex: null, //学校的index
      schoolId: "", //学校id
      //院系
      academyIndex: null, //院系的index
      academyId: "", //院系id
      academyList: [], //院系列表
      //专业
      majorIndex: null, //专业的index
      majorList: [], //专业列表

      //invite 团队成员
      isRefreshMember: false,
      projectMemberIndex: null, //邀请成员的index
      addmemberRules: {},
      memberData: {},

      //json导师
      teacherList: [], //导师列表
      teacherArrayIndex: null, //导师的index
      currentTeacherIndex: null,

      //image
      imgIndex: 0, //图片类型索引
      dialogVisible: false, //是否预览
      dialogImageUrl: "", //预览图片地址

      //file
      fileIndex: 0, //文件类型索引
      fileList: [], //文件列表
      fileUploadUrl: "https://up-z2.qiniup.com", //上传文件地址
      fileUploadParam: {},

      //common
      showPopup: false,
      popupData: [],

      kindId: "", //配套id
      kindInfo: {}, //信息
      getFormKindInfo: [], //配套报名信息
      formKindOtherList: [], //其他配套报名
      mainRecommendMatch: {}, //主要推荐赛事
      otherRecMatchList: [], //其他推荐赛事
    };
  },
  created() {
    this.applyId = this.$route.params.applyId;
    this.kindId = this.$route.params.kindId;
    this.matchId = this.$route.params.matchId;

    getProvinceList().then((res) => {
      this.provinceOptions = res.data;
    });
  },
  mounted() {},
  methods: {
    //获取数据
    fetchData() {
      console.log("初始化数据");
      this.judgeFetchData();
      this.getMatchFormKind();
      this.queryRecommend();
    },
    //获取信息
    getMatchFormKind() {
      getMatchFormKind({ id: this.matchId }).then((res) => {
        if (!res.data) return;
        res.data.forEach((item) => {
          if (item.id == this.kindId) {
            this.getFormKindInfo = item;
          } else {
            this.formKindOtherList.push(item);
          }
        });
      });
    },
    //获取推荐赛事
    queryRecommend() {
      getRecommend({ limit: 5 }).then((res) => {
        if (!res.data) return;
        if (res.data.length >= 1) {
          this.mainRecommendMatch = res.data[0];
          this.otherRecMatchList = res.data.slice(1);
        }
      });
    },
    //表单数据
    judgeFetchData() {
      if (this.applyId) {
        this.$confirm("是否获取最近的暂存数据?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.getKindFormListData();
          })
          .catch(() => {
            this.getKindForm();
          });
      } else {
        this.getKindForm();
      }
    },
    //获取表单方法
    getKindForm() {
      getMatchSub({ competitionId: this.matchId, kindId: this.kindId }).then(
        (res) => {
          if (!res.data) return;
          this.formData = res.data.formList;
          this.applyId = res.data.applyId;
          this.isUpdate = res.data.isUpdate;
          this.formData.forEach((item, index) => {
            if (item.type == "checkbox") {
              this.formData[index].remark = [];
            }
            if (item.type == "image") {
              this.formData[index].remark = [];
            }
            if (item.type == "file") {
              this.formData[index].remark = [];
            }
            if (item.field == "school") {
              this.schoolIndex = index;
            }
            if (item.field == "academy") {
              this.academyIndex = index;
            }
            if (item.field == "major") {
              this.majorIndex = index;
            }
            if (item.field == "project_member") {
              this.projectMemberIndex = index;
              this.addmemberRules = item.rule;
            }
            if (item.field == "teacher_Array") {
              this.teacherArrayIndex = index;

              if (typeof item.remark == "string") {
                this.formData[index].remark = this.getRemark(
                  item.remark,
                  "array"
                );
              }
            }
            if (item.type == "image") {
              item.value = [];
            }
            if (item.type == "file") {
              item.value = [];
            }
          });
        }
      );
    },
    //回显数据方法
    getKindFormListData() {
      getFormKindDetail({ applyId: this.applyId }).then((res) => {
        if (!res.data) return;
        //获取其他表单信息
        this.formData = res.data.formList;
        this.applyId = res.data.applyId;
        this.isUpdate = res.data.isUpdate;
        this.formData.forEach((item, index) => {
          if (item.type == "areaSelect") {
            if (item.remark.indexOf("#") > -1) {
              let area = item.remark.split("#");
              item.remark = area[0];
              this.areaInput = area[1];
            }
            this.getArea(item.remark);
          }
          if (item.type == "checkbox") {
            this.formData[index].remark = item.remark
              ? item.remark.split(",")
              : [];

            if (this.formData[index].remark.indexOf("其他") > -1) {
              this.formData[index].switchYn = true;
            }
          }
          if (item.type == "radio") {
            if (item.remark && item.remark.indexOf("其他") > -1) {
              this.formData[index].switchYn = true;
            }
          }
          if (item.field == "school") {
            this.schoolIndex = index;
            getScoolList({ name: item.remark }).then((res) => {
              if (!res.data.list) return;
              this.searchSchoolList = res.data.list;
              this.searchSchoolList.forEach((schoolitem) => {
                if (item.remark == schoolitem.name) {
                  this.schoolId = schoolitem.id;
                }
              });
              if (!this.schoolId) {
                if (this.academyIndex) {
                  this.formData[this.academyIndex].type = "input";
                }
                if (this.majorIndex) {
                  this.formData[this.majorIndex].type = "input";
                }
              }
              if (this.schoolId) {
                if (!this.academyIndex) return;
                getAcademyList(this.schoolId).then((res) => {
                  this.academyList = res.data;
                  this.formData[this.academyIndex].defaultValue = [];
                  this.academyList.forEach((item) => {
                    this.formData[this.academyIndex].defaultValue.push(
                      item.name
                    );
                    if (item.name == this.formData[this.academyIndex].remark) {
                      this.academyId = item.id;
                      return;
                    }
                  });
                  if (this.academyId) {
                    if (!this.majorIndex) return;
                    getMajorList(this.academyId).then((res) => {
                      this.majorList = res.data;
                      this.formData[this.majorIndex].defaultValue = [];
                      res.data.forEach((item) => {
                        this.formData[this.majorIndex].defaultValue.push(
                          item.name
                        );
                      });
                    });
                  }
                });
              }
            });
          }
          if (item.field == "academy") {
            this.academyIndex = index;
          }
          if (item.field == "major") {
            this.majorIndex = index;
          }
          if (item.field == "project_member") {
            this.projectMemberIndex = index;
            this.addmemberRules = item.rule;
            if (this.categoryId) {
              getMatchProjectMember({
                competitionId: this.matchId,
                categoryId: this.categoryId,
              }).then((res) => {
                if (res.code != 200) return;
                this.memberData = res.data;
                this.formData[this.projectMemberIndex].remark = [];
                if (res.data.projectMembers.length) {
                  res.data.projectMembers.forEach((item) => {
                    this.formData[this.projectMemberIndex].remark.push({
                      name: item.name,
                    });
                  });
                }
              });
            }
          }
          if (item.field == "teacher_Array") {
            this.teacherArrayIndex = index;
            if (typeof item.remark == "string") {
              let arr = [];
              this.getRemark(item.remark, "array").forEach((item) => {
                for (let key in item) {
                  item[key] = this.getRemark(item[key], "object");
                }
                arr.push(this.getRemark(item, "object"));
              });
              this.formData[index].remark = arr;
            }
          }
          if (item.type == "image") {
            item.value = [];
            let fileRemarkList = this.getRemark(item.remark, "array");
            fileRemarkList.forEach((item) => {
              this.formData[index].value.push({
                name: item.originalName,
                url: item.url,
              });
            });
          }
          if (item.type == "file") {
            item.value = [];
            let fileRemarkList = this.getRemark(item.remark, "array");
            fileRemarkList.forEach((item) => {
              this.formData[index].value.push({
                name: item.originalName,
                url: item.url,
              });
            });
          }
        });
      });
    },
    // select
    //获取选择框列表
    getSelectValue(data) {
      if (!data) return "";
      let list = [];
      if (typeof data == "string") {
        if (data.indexOf(",") != -1) {
          let temp = data.split(",");
          temp.forEach((item) => {
            list.push({ value: item, label: item });
          });
          return list;
        } else {
          list = [{ value: data, label: data }];
          return list;
        }
      } else if (data instanceof Array) {
        data.forEach((item) => {
          list.push({ value: item, label: item });
        });
        return list;
      }
    },

    //areaSelect
    //省 选择事件
    provinceChange(index) {
      if (this.provinceCode) {
        this.city = "";
        this.cityCode = "";
        this.cityOptions = [];
        this.district = "";
        this.districtCode = "";
        this.districtOptions = [];
      }
      this.provinceOptions.forEach((e) => {
        if (e.value == this.provinceCode) {
          this.province = e.label;
          this.provinceCode = e.value;
        }
      });
      this.formData[index].remark = "";
      if (this.provinceCode) {
        getConfigAreaList({ areaCode: this.provinceCode }).then((res) => {
          this.cityOptions = res.data;
          if (this.city) {
            this.cityChange(index);
          }
        });
      }
    },
    //市 选择事件
    cityChange(index) {
      if (this.cityCode) {
        this.district = "";
        this.districtCode = "";
        this.districtOptions = [];
      }
      this.cityOptions.forEach((e) => {
        if (e.value == this.cityCode) {
          this.city = e.label;
          this.cityCode = e.value;
        }
      });
      this.formData[index].remark = "";
      if (this.cityCode) {
        getConfigAreaList({ areaCode: this.cityCode }).then((res) => {
          this.districtOptions = res.data;
          if (this.district) {
            this.districtChange(index);
          }
        });
      }
    },
    //区 选择事件
    districtChange(index) {
      this.districtOptions.forEach((e) => {
        if (e.value == this.districtCode) {
          this.district = e.label;
          this.districtCode = e.value;
        }
      });
      this.formData[index].remark =
        this.province + "、" + this.city + "、" + this.district;
    },
    //获取认证地区
    getArea(item) {
      if (!item) {
        return;
      }
      const split = item.split("、");
      this.province = split[0];
      this.city = split[1];
      this.district = split[2];

      this.provinceOptions.forEach((e) => {
        if (e.label == this.province) {
          this.provinceCode = e.value;
          this.getAreaSelect();
        }
      });
    },
    //回显地区
    getAreaSelect() {
      this.provinceOptions.forEach((e) => {
        if (e.value == this.provinceCode) {
          this.province = e.label;
        }
      });
      if (this.provinceCode) {
        getConfigAreaList({ areaCode: this.provinceCode }).then((res) => {
          this.cityOptions = res.data;
          this.cityOptions.forEach((e) => {
            if (e.label == this.city) {
              this.cityCode = e.value;
            }
          });
          if (this.cityCode) {
            getConfigAreaList({ areaCode: this.cityCode }).then((res) => {
              this.districtOptions = res.data;
              this.districtOptions.forEach((e) => {
                if (e.label == this.district) {
                  this.districtCode = e.value;
                }
              });
            });
          }
        });
      }
    },

    //radio
    //获取单选框列表
    getRadioList(data) {
      if (!data) return "";
      let list = [];
      if (typeof data == "string") {
        if (data.indexOf(",") != -1) {
          let temp = data.split(",");
          temp.forEach((item) => {
            list.push({ value: item, label: item });
          });
          return list;
        } else {
          list = [{ value: data, label: data }];
          return list;
        }
      } else if (data instanceof Array) {
        data.forEach((item) => {
          list.push({ value: item, label: item });
        });
        return list;
      }
    },
    //改变radio
    changeRadio(item) {
      if (item.remark == "其他") {
        item.switchYn = true;
      } else {
        item.switchYn = false;
      }
    },
    //改变checkbox
    changeCheckbox(item) {
      console.log(item);
      if (item.remark && item.remark.indexOf("其他") != -1) {
        item.switchYn = true;
      } else {
        item.switchYn = false;
      }
    },

    // dict学校
    changeSchool(e) {
      if (!e) {
        this.formData[this.academyIndex].remark = "";
        this.formData[this.academyIndex].type = "select";
        this.formData[this.majorIndex].remark = "";
        this.formData[this.majorIndex].type = "select";
      }
    },
    //学校 触发事件
    handleSelect(item) {
      this.schoolId = item.id;
      this.school = item.value;
      this.academyId = "";
      this.majorId = "";
      this.formData[this.academyIndex].remark = "";
      this.formData[this.majorIndex].remark = "";
      if (item.id) {
        this.formData[this.academyIndex].type = "select";
        this.formData[this.majorIndex].type = "select";
        this.formData[this.majorIndex].defaultValue = "";
        getAcademyList(item.id).then((res) => {
          this.academyList = res.data;
          this.formData[this.academyIndex].defaultValue = [];
          res.data.forEach((item) => {
            this.formData[this.academyIndex].defaultValue.push(item.name);
          });
        });
      } else {
        this.formData[this.academyIndex].type = "input";
        this.formData[this.majorIndex].type = "input";
      }
    },
    // 远程查询学校列表
    selectScoolList(queryString, cb) {
      if (queryString != "") {
        this.schoolList = [];
        getScoolList({ name: queryString }).then((res) => {
          res.data.list.forEach((element) => {
            this.schoolList.push({
              value: element.name,
              id: element.id,
            });
          });
          cb(this.schoolList);
        });
      }
    },
    changeSelect(item) {
      if (item.field == "academy") {
        this.majorId = "";
        this.formData[this.majorIndex].remark = "";
        console.log(this.academyList);
        this.academyList.forEach((e) => {
          // 存remark
          if (e.name == item.remark) {
            this.academyId = e.id;
            this.academy = e.name;
          }
        });
        getMajorList(this.academyId).then((res) => {
          this.majorList = res.data;
          this.formData[this.majorIndex].defaultValue = [];
          res.data.forEach((item) => {
            this.formData[this.majorIndex].defaultValue.push(item.name);
          });
        });
      }
      if (item.field == "major") {
        this.majorList.forEach((e) => {
          if (e.id == item) {
            this.major = e.name;
            this.majorId = e.id;
          }
        });
      }
    },
    clickSelect(item) {
      if (item.field == "academy") {
        if (this.schoolIndex && !this.schoolId) {
          this.$message.error("请先选择学校");
        }
      }
      if (item.field == "major") {
        if (this.schoolIndex && !this.schoolId) {
          this.$message.error("请先选择学校");
          return;
        }
        if (this.academyIndex && !this.academyId) {
          this.$message.error("请先选择院系");
        }
      }
    },

    //invite 团队成员
    //添加成员
    toAddMember(item) {
      if (!item.rule.isUpdate) {
        return;
      }
      if (!this.categoryId) {
        return this.$message.error("请先选择赛道");
      }
      console.log(this.projectMemberIndex);
      this.showPopup = true;
      // this.formData[this.projectMemberIndex].switchYn = true;
    },
    //刷新成员
    refreshMember() {
      if (!this.categoryId) {
        return this.$message.error("请先选择赛道");
      }
      getMatchProjectMember({
        competitionId: this.matchId,
        categoryId: this.categoryId,
      }).then((res) => {
        console.log(res, "project_mumber");
        this.formData[this.projectMemberIndex].remark = [];
        if (res.data.projectMembers.length) {
          res.data.projectMembers.forEach((item) => {
            this.formData[this.projectMemberIndex].remark.push({
              name: item.name,
            });
          });
        }
      });
    },

    //json添加导师
    //导师
    jumpToAddTeacher(item) {
      if (!item.rule.isUpdate) {
        return;
      }
      this.currentTeacherIndex = null;
      this.formData[this.teacherArrayIndex].switchYn = true;
      this.showPopup = true;
      item.childList.forEach((subitem) => {
        subitem.remark = null;
      });
      this.popupData = item.childList;
    },
    closePopup() {
      this.formData[this.teacherArrayIndex].switchYn = false;
      this.formData[this.projectMemberIndex].switchYn = false;
      this.showPopup = false;
    },
    getTeacherData(data) {
      console.log(data);
      let arr = this.formData[this.teacherArrayIndex].remark
        ? this.formData[this.teacherArrayIndex].remark
        : [];
      if (data.index != null && data.index != undefined) {
        arr.splice(data.index, 1, data.form);
        this.formData[this.teacherArrayIndex].remark = arr;
      } else {
        arr.push(data.form);
        this.formData[this.teacherArrayIndex].remark = arr;
      }
      this.showPopup = false;
    },
    changeTeacher(item, index) {
      if (!item.rule.isUpdate) {
        return;
      }
      this.formData[this.teacherArrayIndex].switchYn = true;
      let remark = this.getRemark(item.remark, "array");
      let childList = item.childList;
      childList.forEach((childItem) => {
        if (!remark[index][childItem.field]) {
          childItem.remark = null;
          return;
        }
        if (typeof remark[index][childItem.field].value == "string") {
          childItem.remark = remark[index][childItem.field].value;
        } else {
          childItem.remark = remark[index][childItem.field].value;
        }
      });
      this.showPopup = true;
      this.currentTeacherIndex = index;
      this.popupData = childList;
    },
    deleteTeacherList(index) {
      this.formData[this.teacherArrayIndex].remark.splice(index, 1);
    },

    //image
    changeImgIndex(index) {
      this.imgIndex = index;
      getqiniuToken().then((res) => {
        this.fileUploadParam = { key: "", token: res.data };
      });
    },
    beforeImgUpload(file) {
      this.fileUploadParam.key =
        String(new Date().getTime()) +
        Math.floor(Math.random() * 50 + 50) +
        "." +
        file.name;
    },
    handleImgUploadSuccess(file) {
      if (
        !this.formData[this.imgIndex].remark ||
        this.formData[this.imgIndex].remark == "[]"
      ) {
        this.formData[this.imgIndex].remark = [
          {
            id: file.data.id,
            originalName: file.data.fname,
            url: this.$globalHeader.fileUrl + "/" + file.data.key,
          },
        ];
      } else {
        this.formData[this.imgIndex].remark.push({
          id: file.data.id,
          originalName: file.data.fname,
          url: this.$globalHeader.fileUrl + "/" + file.data.key,
        });
      }
      this.$message.success(`上传成功`);
    },
    handleImgUploadError() {
      this.$message.warning(`上传失败`);
    },
    handleImgPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleImgExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeImgRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleImgRemove(file, fileList) {
      console.log(file, fileList);
      this.formData[this.imgIndex].remark = [];
      fileList.forEach((item) => {
        this.formData[this.imgIndex].remark.push({
          id: item.response.data.id,
          originalName: item.response.data.fname,
          url: this.$globalHeader.fileUrl + "/" + item.response.data.key,
        });
      });
    },

    // url: config.BaseUrl + '/api/file/web/upload'
    //file
    changeFileIndex(index) {
      this.fileIndex = index;
      getqiniuToken().then((res) => {
        this.fileUploadParam = { key: "", token: res.data };
      });
    },
    beforeFileUpload(file) {
      this.fileUploadParam.key =
        String(new Date().getTime()) +
        Math.floor(Math.random() * 50 + 50) +
        "." +
        file.name;
    },
    handleFileUploadSuccess(file) {
      if (
        !this.formData[this.fileIndex].remark ||
        this.formData[this.fileIndex].remark == "[]"
      ) {
        this.formData[this.fileIndex].remark = [
          {
            id: file.data.id,
            originalName: file.data.fname,
            url: this.$globalHeader.fileUrl + "/" + file.data.key,
          },
        ];
      } else {
        this.formData[this.fileIndex].remark.push({
          id: file.data.id,
          originalName: file.data.fname,
          url: this.$globalHeader.fileUrl + "/" + file.data.key,
        });
      }
      this.$message.success(`上传成功`);
    },
    handleFileUploadError() {
      this.$message.warning(`上传失败`);
    },
    handleFilePreview(file) {
      console.log(file);
    },
    handleFileExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeFileRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleFileRemove(file, fileList) {
      console.log(file, fileList);
      this.formData[this.fileIndex].remark = [];
      fileList.forEach((item) => {
        this.formData[this.fileIndex].remark.push({
          id: item.response.data.id,
          originalName: item.response.data.fname,
          url: this.$globalHeader.fileUrl + "/" + item.response.data.key,
        });
      });
    },

    //common
    //配套报名
    toFormKind(item) {
      this.formKindOtherList = [];
      if (item.signupStatus && item.signupStatus != "2") {
        //报名结果页面
        this.$router.push({
          path: "/match/matchDetail/" + this.matchId + "/matchSubResult",
          query: {
            matchId: item.competitionId,
            kindId: item.id,
            applyId: item.applyId,
          },
        });
      } else {
        //报名页面
        this.$router.push({
          path:
            "/match/matchDetail/" +
            this.matchId +
            "/matchKindSub/" +
            item.competitionId +
            "/" +
            item.id,
          query: {
            applyId: item.applyId,
          },
        });
      }
    },
    toMatchDetail() {
      this.$router.replace({
        path: "/match/matchDetail/" + this.matchId,
      });
    },
    downloadFile() {
      window.open(this.getFormKindInfo.path);
    },

    //转换remark类型
    getRemark(data, type) {
      const dataType = typeof data;
      if (type == "array") {
        if (!data) {
          return [];
        }
        if (dataType == "string") {
          let data2 = data.replace(/:s*([0-9]{15,})s*(,?)/g, ': "$1" $2');
          return JSON.parse(data2);
        }
        if (dataType == "object") {
          return data;
        }
      }
      if (type == "string") {
        if (!data) {
          return "";
        }
        if (dataType == "string") {
          return data;
        }
        if (dataType == "object") {
          console.log("参数错误");
        }
      }
      if (type == "object") {
        if (!data) {
          return {};
        }
        if (dataType == "string") {
          return JSON.parse(data);
        }
      }
      return data;
    },
    //暂存
    tempSave() {
      //自定义表单
      this.disposeFormData("2");
      this.saveSub("2", "暂存成功");
    },
    //数据处理
    disposeFormData(signupStatus) {
      let isEmpty = true;
      let result = "";
      this.formData.forEach((item) => {
        if (!isEmpty) return;
        if (signupStatus != "2" && item.isNeed != 0) {
          if (item.type == "invite") return;
          if (!item.rule.isUpdate) return;
          if (typeof item.remark == "string") {
            if (!item.remark || !item.remark.replace(/\s/g, "").length) {
              this.$message.error(item.title + "未填写");
              isEmpty = false;
              return;
            }
          } else {
            if (!item.remark || !item.remark.length) {
              this.$message.error(item.title + "未填写");
              isEmpty = false;
              return;
            }
          }
        }
        if (item.type == "radio") {
          if (item.remark && item.remark.indexOf("其他") != -1) {
            this.form[item.field] = {
              id: item.id,
              value: item.remark + "#" + item.value,
              isUpdate: item.rule.isUpdate,
            };
          } else {
            this.form[item.field] = {
              id: item.id,
              value: item.remark,
              isUpdate: item.rule.isUpdate,
            };
          }
        } else if (item.type == "checkbox") {
          result = "";
          if (item.remark && item.remark.length) {
            item.remark.forEach((child) => {
              if (child != "其他") {
                result += (result.length ? "," : "") + child;
              }
            });
          }
          if (item.remark.indexOf("其他") != -1) {
            result += "," + "其他#";
            if (item.value != null) {
              result += item.value;
            }
          }
          this.form[item.field] = {
            id: item.id,
            value: result,
            isUpdate: item.rule.isUpdate,
          };
        } else if (item.type == "areaSelect" && this.areaInput) {
          item.remark = item.remark + "#" + this.areaInput;
          this.form[item.field] = {
            id: item.id,
            value: item.remark,
            isUpdate: item.rule.isUpdate,
          };
        } else {
          this.form[item.field] = {
            id: item.id,
            value: item.remark,
            isUpdate: item.rule.isUpdate,
          };
        }
        if (item.type == "image") {
          if (item.remark && item.remark.length == 0) {
            item.remark = null;
          }
        }
        if (item.type == "file") {
          if (item.remark && item.remark.length == 0) {
            item.remark = null;
          }
        }
      });

      return isEmpty;
    },
    //提交
    submit() {
      console.log(this.formData);
      //自定义表单
      let isEmpty = this.disposeFormData("1");
      if (!isEmpty) return;
      this.saveSub("1", "提交成功");
    },

    saveSub(signupStatus, message) {
      let param = {
        competitionId: this.matchId,
        kindId: this.kindId,
        form: this.form,
        signupStatus: signupStatus,
      };
      if (this.applyId) {
        param.applyId = this.applyId;
      }
      customMatchKindApply(param).then((res) => {
        if (res.code == 200) {
          this.applyId = res.data;
          this.$message.success(message);

          if (signupStatus != "2") {
            setTimeout(() => {
              this.$router.replace({
                path: "/match/matchDetail/" + this.matchId + "/matchSubResult",
                query: {
                  matchId: this.matchId,
                  kindId: this.kindId,
                  applyId: this.applyId,
                },
              });
            }, 1000);
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getParam() {
      if (!this.zones[this.comAreaList.indexOf(this.comArea)]?.id) {
        this.$message.error("赛区未选择");
        return false;
      }
      if (!this.categories[this.comTrackList.indexOf(this.comTrack)]?.id) {
        this.$message.error("赛道未选择");
        return false;
      }
      return {
        zoneId: this.zones[this.comAreaList.indexOf(this.comArea)].id,
        categoryId:
          this.categories[this.comTrackList.indexOf(this.comTrack)].id,
        competitionId: this.matchId,
        isUpdate: this.isUpdate,
      };
    },
  },
  watch: {
    $route: {
      handler() {
        this.applyId = this.$route.params.applyId;
        this.kindId = this.$route.params.kindId;
        this.matchId = this.$route.params.matchId;

        getProvinceList().then((res) => {
          this.provinceOptions = res.data;
        });
        this.fetchData();
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.match-sub-page {
  display: flex;
  flex-direction: column;

  .main {
    flex: 1;
    padding-bottom: 100px;
    background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }

      .el-breadcrumb {
        cursor: pointer;

        /deep/ .el-breadcrumb__inner.is-link {
          font-weight: normal;
        }
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .c-left {
        max-width: 865px;
        flex: 1;
        margin-right: 15px;
        background-color: #fff;

        .info-plate {
          display: flex;
          flex-direction: column;
          padding: 16px;
          background-color: #fff;
          border-bottom: 1px solid #f8f8f8;
          box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

          .title {
            line-height: 1.35;
            font-size: 18px;
            font-weight: bold;
            color: #000;
          }

          .subtitle {
            margin-top: 10px;

            .subtitle-item {
              display: flex;
              align-items: flex-start;
              padding: 6px 0;
              font-size: 16px;

              .s_i_left {
                flex-shrink: 0;
                margin-right: 22px;
                color: #999;
              }

              .s_i_right {
                flex: 1;
                line-height: 1.35;
                color: #999;
              }
            }

            .subtitle-content {
              display: flex;
              flex-direction: column;
              padding: 12px 16px;
              background-color: #199ed8;
              border-radius: 6px;

              .tips {
                margin-bottom: 10px;
                font-size: 14px;
                color: #fff;
              }

              .btn-wrap {
                display: flex;

                .btn {
                  padding: 8px 4px;
                  width: 80px;
                  text-align: center;
                  font-size: 13px;
                  color: #199ed8;
                  background-color: #fff;
                  border-radius: 4px;
                }
              }
            }
          }
        }

        .info-title {
          padding: 16px;
          font-size: 14px;
          color: #000;
          background-color: #f7f7f7;
        }

        .c-left-content {
          flex: 1;
          padding: 0 16px 12px;
          box-shadow: 0 0px 8px rgba(0, 0, 0, 0.05);

          // overflow-y: scroll;
          .item-wrap_old {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 3px;

            .item-title {
              display: flex;
              padding: 16px 0;
              font-size: 16px;

              .item-name {
                color: #0099cc;
              }

              .must {
                margin-right: 6px;
                color: red;
              }
            }

            .item-select {
              width: 100%;
              height: 40px;
              border-radius: 4px;
              background-color: #f5f5f5;

              .choose {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 100%;
                padding: 0 10px;
                font-size: 16px;
                color: #666;
                box-sizing: border-box;

                .name {
                  flex: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .icon {
                  font-size: 14px;
                }
              }
            }
          }

          .project-choose {
            .form-wrap {
              margin-bottom: 3px;

              .item-wrap {
                .subtitle {
                  margin-bottom: 12px;
                  line-height: 1.5;
                  font-size: 13px;
                  color: #999;
                }

                .item-title {
                  display: flex;
                  padding: 15px 0;

                  .item-name {
                    font-size: 16px;
                    line-height: 1.35;
                    color: #0099cc;
                  }
                }

                .item-content {
                  display: flex;
                  justify-content: space-between;
                  flex-direction: column;
                  //areaSelect
                  .area-content {
                    display: flex;

                    .area-item {
                      margin-right: 24px;
                    }
                  }

                  //dict
                  .el-autocomplete {
                    width: 240px;
                  }

                  //radio
                  .radio-content {
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    /deep/ .u-radio__icon-wrap {
                      margin-top: 2px;
                    }

                    /deep/ .u-radio {
                      align-items: flex-start;
                      overflow: unset;
                      height: 1.4rem;
                      line-height: 1.35 !important;
                      margin-bottom: 4px;

                      .u-radio__text {
                        line-height: 1.35 !important;
                      }
                    }
                  }

                  //checkbox
                  .checkbox-content {
                    /deep/ .el-checkbox-group {
                      // display: flex;
                      // flex-direction: column;
                      margin-bottom: 8px;
                    }
                  }

                  input {
                    border: none;
                    outline: none;
                  }

                  .item-content-left {
                    flex: 1;
                    display: flex;

                    .input-wrap {
                      flex: 1;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      padding: 0 12px;
                      height: 40px;
                      border-radius: 4px;
                      background-color: #f5f5f5;

                      .input {
                        flex: 1;
                        height: 100%;
                        background-color: transparent;
                      }

                      .icon {
                        flex-shrink: 0;
                      }

                      .btn {
                        margin-left: 10px;
                        padding: 0 8px;
                        font-size: 11px;
                        color: #fff;
                        background-color: #0099cc;
                        border-radius: 4px;
                      }
                    }

                    //添加导师
                    .add-teacher {
                      flex: 1;
                      display: flex;
                      justify-content: space-between;
                      padding-right: 10px;
                      background-color: #f2f2f2;
                      border-radius: 4px;

                      .teacher-list {
                        flex: 1;
                        display: flex;
                        flex-direction: column;

                        .teacher-item {
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          height: 40px;
                          padding: 0 12px;

                          .text {
                            color: #808080;
                          }

                          .wrap {
                            flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          }

                          .delete {
                            flex-shrink: 0;
                            margin-left: 12px;
                            font-size: 14px;
                            color: red;
                            cursor: pointer;
                          }
                        }
                      }

                      .btn-wrap {
                        flex-shrink: 0;
                        align-self: flex-end;
                        padding: 5px 0;

                        .btn {
                          flex: 1;
                          margin-left: 0;
                          padding: 0 8px;
                          height: 30px;
                          line-height: 30px;
                          font-size: 14px;
                          color: #fff;
                          background-color: #0099cc;
                          border-radius: 4px;
                        }
                      }
                    }

                    //上传图片
                    .upload-img {
                      position: relative;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 75px;
                      height: 75px;
                      border-radius: 6px;
                      background-color: #f2f2f2;

                      .icon {
                        font-size: 24px;
                        color: #bfbfbf;
                      }

                      .img {
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;
                      }

                      .delete-img {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 20px;
                        height: 20px;
                        transform: translate(50%, -50%);
                        border-radius: 50%;
                        background-color: red;

                        .icon {
                          font-size: 8px;
                          font-weight: bold;
                          color: #fff;
                        }
                      }
                    }

                    //上传文件
                    .upload-file {
                      flex: 1;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      padding: 0 12px;
                      width: 100%;
                      height: 40px;
                      border-radius: 4px;
                      background-color: #f2f2f2;

                      .img-wrap {
                        flex: 1;
                        display: flex;
                        align-items: center;

                        .img {
                          width: 30px;
                          height: 30px;
                        }

                        .img-name {
                          flex: 1;
                          line-height: 25px;
                          margin-left: 10px;
                          color: #666;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          white-space: nowrap;
                        }
                      }

                      .delete {
                        flex-shrink: 0;
                        margin-left: 12px;
                        font-size: 14px;
                        color: red;
                        cursor: pointer;
                      }
                    }
                  }

                  .member-wrap {
                    flex: 1;
                    display: flex;
                    padding: 10px;
                    background-color: #f2f2f2;
                    border-radius: 4px;

                    .input {
                      flex: 1;
                      display: flex;
                      align-items: center;
                      height: 22px;

                      .member-list {
                        display: flex;
                        align-items: center;

                        .text {
                          color: #808080;
                        }
                      }

                      .profile-item-wrap {
                        flex: 1;
                      }

                      .profile-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .img-wrap {
                          flex: 1;
                          display: flex;
                          align-items: center;
                          margin-right: 10px;
                          line-height: 22px;
                          border-radius: 6px;

                          .img {
                            width: 30px;
                            height: 30px;
                          }

                          .img-name {
                            flex: 1;
                            margin-left: 10px;
                            color: #666;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                          }
                        }

                        .delete {
                          flex-shrink: 0;
                          font-size: 14px;
                          color: red;
                          cursor: pointer;
                        }
                      }
                    }

                    .more {
                      flex-shrink: 0;
                      display: flex;
                      align-items: center;
                      margin-left: 12px;

                      .refresh {
                        .text {
                          color: #0099cc;
                        }
                      }

                      .icon {
                        font-size: 13px;
                        color: #666;
                      }
                    }
                  }
                }

                //textarea
                .textarea-content {
                  display: block;
                  padding: 16px;
                  border: 1px solid #ddd;

                  .textarea {
                    width: 100%;
                    height: 100px;
                    border: none;
                  }
                }
              }
            }
          }

          .foot {
            padding: 60px 0;

            .btn-wrap {
              display: flex;
              justify-content: center;
              align-items: center;
              .btn-default {
                background: linear-gradient(to right, #81cfff, #0099cc);
                flex: 4;
              }
              .btn-primary {
                background: linear-gradient(to right, #ffcd34, #ff9200);
                flex: 6;
              }
              .btn {
                margin: 10px;
                text-align: center;
                width: 60%;
                height: 50px;
                line-height: 50px;
                font-size: 24px;
                border-radius: 26px;
                color: #fff;
                background-color: #0099cc;
                cursor: pointer;
              }
            }
          }

          .ban {
            color: #c0c0c0 !important;
          }

          .banBgc {
            background-color: #c0c0c0 !important;
          }
        }
      }

      .c-right {
        flex-shrink: 0;

        .plate1 {
          width: 320px;
          min-height: 260px;
          padding: 16px 15px 20px;
          margin-bottom: 20px;
          background-color: #fff;
          box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
          cursor: pointer;

          .title {
            color: #000000;
            font-size: 18px;
            font-weight: 400;
          }

          .title-border {
            width: 26px;
            height: 4px;
            background: rgba(0, 90, 171, 1);
          }

          .img-wrap {
            margin-top: 15px;

            img {
              border-radius: 5px 5px 15px 15px;
            }
          }

          .content {
            color: #000000;
            margin-top: 10px;
            font-size: 18px;
            font-weight: 400;
          }

          .interval-space {
            height: 20px;
            background-color: #f7f7f7;
          }
        }

        .k-bot {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;

          .k-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 16px;
            margin-top: 12px;
            border-radius: 6px;
            box-sizing: border-box;
            box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
            cursor: pointer;

            .i-top-item-left {
              flex-shrink: 0;
              margin-right: 16px;

              .icon {
                font-size: 28px;
                color: #fff;
              }
            }

            .i-top-item-right {
              flex: 1;
              color: #fff;

              .subtitle {
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .title {
                font-size: 16px;
                color: #fff;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }

          .k-item:nth-child(1) {
            background: linear-gradient(to right bottom, #f24451, #ee1e27);
          }

          .k-item:nth-child(2) {
            background: linear-gradient(to right bottom, #f2b43f, #f0a41c);
          }

          .k-item:nth-child(3) {
            background: linear-gradient(to right bottom, #609bd1, #3982c3);
          }

          .k-item:nth-child(4) {
            background: linear-gradient(to right bottom, #b4b467, #a6a653);
          }

          .k-item:nth-child(5) {
            background: linear-gradient(to right bottom, #f24451, #ee1e27);
          }
        }
      }
    }
  }

  .popup-wrap {
    .mask {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 101;
      background: rgba(0, 0, 0, 0.5);
    }

    .popup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      height: 60%;
      padding: 66px 32px 0;
      overflow: auto;
      background-color: #fff;
      border-radius: 6px;
      z-index: 102;

      .close-btn {
        position: absolute;
        top: 8px;
        right: 12px;
        padding: 12px;
        color: #333;
        font-size: 16px;
        cursor: pointer;
      }
    }
  }
}
</style>
