<template>
  <div class="list-block" v-if="productList.length" @click="toMin">
    <div
      class="product-item hover-item"
      v-for="(item, index) in productList"
      :key="index"
    >
      <div class="cover">
        <img class="img" :src="item.productImg" />
      </div>
      <div class="content">
        <div class="title">
          <div class="title-txt">
            {{ item.productName || "" }}
          </div>
          <div class="title-free" v-if="item.isFree == '1'">限时免费</div>
        </div>

        <div class="desc">
          <div class="desc-content">
            {{ item.productDesc || "" }}
          </div>
        </div>
        <div class="extend">
          <div class="data">
            <div class="fee">
              <span class="tit">￥{{ item.productAmt || "0" }}</span
              >起
            </div>
            <div class="txt count">
              <span class="icon iconfont icon-files"></span>
              累计{{ item.orderNum || "0" }}次
            </div>
          </div>
          <div
            hover-class="hover-effect"
            class="button"
            :class="
              status != '0' && status ? 'button-primary' : 'button-disabled'
            "
          >
            去预约
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="empty" v-else>
    <el-empty description="暂无数据"></el-empty>
  </div>
</template>

<script>
import { getTeacherService } from "@/api/course/course.js";

export default {
  props: {
    teacherId: String,
    status: String,
  },
  data() {
    return {
      page: 1,
      limit: 100,
      productList: [],
    };
  },
  methods: {
    //获取服务列表
    getServiceList() {
      let params = {
        teacherId: this.teacherId,
        page: this.page,
        limit: this.limit,
      };
      getTeacherService(params).then((res) => {
        this.productList = res.data.list;
      });
    },
    toMin() {
      this.$emit('toMin',true)
    }
  },
  created() {
    this.getServiceList();
  },
  watch: {
    teacherId: {
      handler(val) {
        console.log("val", val);
        this.getServiceList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.list-block {
  background-color: #fff;
}
.product-item {
  display: flex;
  align-items: stretch;
  padding: 16px 30px;
  border-bottom: 2px solid #f2f2f2;

  .cover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin-right: 16px;
    background: #bdccf9;
    border-radius: 10px;

    .img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;

      .title-txt {
        // flex: 1;
        font-size: 18px;
        font-weight: bold;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .title-free {
        margin-left: 20px;
        color: #fff;
        font-size: 12px;
        background-color: #fd9733;
        border-radius: 6px;
        padding: 6px 12px;
      }
    }

    .desc {
      flex: 1;

      .desc-content {
        height: 32px;
        font-size: 16px;
        color: #666;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .extend {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex: 1;
      font-size: 14px;
      color: #888888;
      margin-top: 0.3em;

      .fee {
        font-size: 12px;
        color: #ff9103;
        margin-right: 0.5em;
        min-width: 190px;

        .tit {
          font-size: 18px;
          // margin-right: 0.1em;
        }
      }

      .icon {
        color: #666;
        font-size: 16px;
        margin-right: 0.35em;
      }

      .data {
        display: flex;
        align-items: baseline;

        .txt {
          font-size: 12px;
        }

        .count {
          // margin-left: 1em;
          margin-right: 0.3em;
          min-width: 140px;
        }
      }
    }
  }
}

.product-item:last-child {
  border: none;
}
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  border-radius: 24px;
  height: 30px;
  min-width: 130px;
  background-color: #1d9cff;
}

.button-primary {
  background: linear-gradient(135deg, #f7d941, #faac1f);
}

.button-disabled {
  background: linear-gradient(135deg, #c7c7c6, #b5b5b5);
}
</style>