import http from "@/utils/http.js"

//获取赛事列表
export function getMatchList(data) {
    return http({
        url: "/api/web/competition/page",
        method: "GET",
        data: data
    })
}

//获取推荐赛事
export function getRecommend(params) {
    return http({
        url: "/api/web/competition/recommend",
        method: "GET",
        data: params
    })
}

//获取赛事其他报名按钮
export function getMatchFormKind(data) {
    return http({
        url: "/api/web/competition/getFormKind/" + data.id,
        method: "GET",
    })
}

// 配套回显
export function getFormKindDetail(data) {
    return http({
        url: "/api/web/competition/getFormKindDetail/" + data.applyId,
        method: 'GET',
    })
}

//配套提交接口
export function customMatchKindApply(data) {
    return http({
        url: "/api/web/competition/customKindApply",
        method: "POST",
        data: data
    })
}

//赛事提交接口
export function customMatchApply(data) {
    return http({
        url: "/api/web/competition/customApply",
        method: "POST",
        data: data
    })
}

//获取赛事详情
export function getMatchDetail(data) {
    return http({
        url: "/api/web/competition/detail",
        method: "POST",
        data: data
    })
}
//获取赛事详情--仅部分信息
export function competitionDetail(id) {
    return http({
        url: "/api/web/competition/getCompetitionDetail",
        method: "GET",
        data: id
    })
}

//获取赛事赛程安排
export function getRaceCard(data) {
    return http({
        url: "/api/web/competition/getRaceCard/" + data.id,
        method: "GET",
    })
}

// 获取提交赛事项目表单
export function getMatchSub(data) {
    return http({
        url: "/api/web/competition/getConfigFormList",
        method: 'GET',
        data: data,
        showLoading: true
    })
}

// 提交赛事获取成员
export function getMatchProjectMember(data) {
    return http({
        url: "/api/web/competition/getProjectMember",
        method: 'GET',
        data: data
    })
}

// 提交赛事邀请成员
export function invitedMatchProjectMember(data) {
    return http({
        url: "/api/web/competition/invitedProjectMember",
        method: 'POST',
        data: data
    })
}

// 提交赛事删除邀请成员
export function removeMatchProjectMember(data) {
    return http({
        url: "/api/web/competition/removeProjectMember",
        method: 'DELETE',
        data: data,
    })
}

// 配套提交结果
export function getAuditResult(data) {
    return http({
        url: "/api/web/competition/getAuditResult/" + data.applyId,
        method: 'GET',
    })
}

// 被邀请人显示页面
export function inviteInfo(inviteId) {
    return http({
        url: "/api/web/competition/beInviteWeb/" + inviteId,
        method: 'GET',
    })
}
// 被邀请人操作邀请信息
export function beInvitedProjectMember(inviteId) {
    return http({
        url: "/api/web/competition/beInvitedProjectMember/" + inviteId,
        method: 'POST',
    })
}


// 报名回显
export function getProject(applyId) {
    return http({
        url: "/api/web/competition/getProject/" + applyId,
        method: 'GET',
    })
}

// 验证学校
export function isValidSchool(competitionId) {
    return http({
        url: "/api/web/competition/isValidSchool/" + competitionId,
        method: 'GET',
    })
}