<template>
  <div class="recommend">
    <div class="head">
    <h3 class="title">推荐政策</h3>
    <div class="more">更多<span class="icon iconfont icon-more"></span></div>
    </div>
    <div class="item" v-for="(item,index) in policyList" :key="index">
        <div class="item-sort" :class="{'item-sort-color':index>2}">
          {{index+1}}
        </div>
        <div class="item-title hover-effect">{{item.policyName}}</div>
    </div>
</div>
</template>

<script>

import { getPolicyPage } from "@/api/home/home.js";
export default {
  props: {
    
  },
  components: {

  },
  data() {
    return {
      policyList:[],
    };
  },
  
  methods: {
    getPolicyPage(){
      let params = {
        page:1,
        limit:6,
        policyArea:350200
      }
      getPolicyPage(params).then(res =>{
        this.policyList = res.data.policyList;
      })
    }
  },
  created() {
    this.getPolicyPage();
  },
};
</script>

<style lang="scss" scoped>

    .recommend {
    background-color: #fff;
    height: 300px;
    .head {
        padding: 10px 0;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .title {
        position: relative;
        font-size: 18px;
        font-weight: 400;
        padding: 6px 0;
        margin-left: 20px;
        }

        .title::after {
        position: absolute;
        content: ' ';
        overflow: hidden;
        height: 3px;
        width: 2em;
        left: 0;
        bottom: 0;
        border-radius: 2px;
        background-color: #2D7CCE;
        }

        .more {
        display: flex;
        align-items: center;
        padding: 6px 20px;
        cursor: pointer;

        .icon {
            font-size: 14px;
        }
        }
        .more:hover {
        color: #2D7CCE;
        }
      }
      .item{
        display: flex;
        padding-left: 10px;
        margin-bottom: 15px;
        .item-sort{
          text-align: center;
          width: 20px;
          height: 20px;
          line-height: 20px;
          background-color: #409EFF;
          color: #fff;
        }
        .item-sort-color{
          color: #666666 !important;
          background-color: #EAEAEA !important;
        }
        .item-title{
          margin-left: 10px;
          width: 260px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow:ellipsis;
        }
      }
    }


</style>
