<template>
  <div class="page">
    <div
      class="page-warp"
      v-if="displayPage"
      v-loading.fullscreen.lock="isLoading"
    >
      <!-- 顶部1 -->
      <page-top></page-top>
      <!-- 头部 -->
      <Header @chooseTab="getTab"></Header>
      <!-- 主体 -->
      <main class="main">
        <section class="breadcrumb-plate w">
          <div class="label">我的位置：</div>
          <breadcrumb />
        </section>
        <section class="titleBlock w">
          <div class="c-left">
            <div class="apply-block">
              <div class="cover">
                <img class="img" :src="policyData.cover" alt="" />
              </div>
              <div class="policy-list">
                <div class="title">
                  <span class="txt">{{ policyData.policyName }}</span>
                </div>
                <div class="count">
                  <div>
                    <span class="icon iconfont icon-browse"></span
                    >{{ policyData.viewQuantity || "0" }}
                  </div>
                  <div
                    class="hover-def"
                    :class="{ 'blue-color': isCollection }"
                    @click="cancelCollection"
                  >
                    <span class="icon iconfont icon-rss"></span>
                    想办：{{ collection || "0" }}
                  </div>
                </div>
                <!-- <div class="label-list">
                  <span class="is-light">{{ policyData.policyType }}</span>
                  <span
                    v-for="(subitem, index) in policyData.subTitleResults"
                    :key="index"
                    >{{ subitem.subTitle }}</span
                  >
                  <span class="is-light" v-if="policyData.handleType == 1"
                    >线上办理</span
                  >
                  <span class="is-light" v-if="policyData.handleType == 2"
                    >线下办理</span
                  >
                  <span class="is-light" v-if="policyData.handleType == 3"
                    >线上办理、线下办理</span
                  >
                </div> -->
                <div class="extend">
                  <div
                    class="fee"
                    :class="{ 'fee-size ': !policyData.policyRewardResult }"
                  >
                    {{ policyData.policyRewardResult?.rewardAmt || "未设置" }}
                  </div>

                  <div class="btn-warp">
                    <div
                      class="btn btn-primary hover-def"
                      :class="{ 'btn-none': isCollection }"
                      @click="toCollection"
                    >
                      我想办
                    </div>
                    <div
                      class="btn btn-apply hover-def"
                      :class="{ 'btn-width': isCollection }"
                      @click="toApply"
                    >
                      预审申请
                    </div>
                  </div>
                </div>
                <div class="tag">{{ policyData.policyType || "人才政策" }}</div>
              </div>
            </div>
            <div class="info-block" id="scroll-box" ref="DOM">
              <div class="policy-info-tab" id="infoTab" @click="changeTab">
                <div
                  class="tab-item"
                  id="tab-item0"
                  :class="{ 'active-tab': policyInfoTab == 0 }"
                >
                  基本信息
                </div>
                <div
                  class="tab-item"
                  id="tab-item1"
                  :class="{ 'active-tab': policyInfoTab == 1 }"
                >
                  条件要求
                </div>
                <div
                  class="tab-item"
                  id="tab-item2"
                  :class="{ 'active-tab': policyInfoTab == 2 }"
                >
                  办理材料
                </div>
                <div
                  class="tab-item"
                  id="tab-item3"
                  :class="{ 'active-tab': policyInfoTab == 3 }"
                >
                  办理流程
                </div>
                <div
                  class="tab-item"
                  id="tab-item4"
                  :class="{ 'active-tab': policyInfoTab == 4 }"
                >
                  联系方式
                </div>
              </div>
              <div id="rules0" class="base-block">
                <div class="header">
                  <span class="icon iconfont icon-match-promotion"></span
                  >基本信息
                </div>
                <div class="base-block-content">
                  <div class="three-column">
                    <div class="desc">
                      <div>发放方式</div>
                      <div>
                        {{
                          policyData.policyRewardResult?.provideType || "未设置"
                        }}
                      </div>
                    </div>
                    <div class="desc">
                      <div>办理时长</div>
                      <div>
                        {{
                          policyData.policyRewardResult?.handleDuration ||
                          "未设置"
                        }}
                      </div>
                    </div>
                    <div class="desc">
                      <div>政策类型</div>
                      <div>{{ policyData.policyType || "" }}</div>
                    </div>
                  </div>
                  <div class="three-column">
                    <div class="desc">
                      <div>政策级别</div>
                      <div>{{ policyData.policyLevel || "" }}</div>
                    </div>
                    <div class="desc">
                      <div>归口部门</div>
                      <div>{{ policyData.department || "" }}</div>
                    </div>
                    <div class="desc">
                      <div>有效时间</div>
                      <div>{{ policyData.effectiveTimeRange || "" }}</div>
                    </div>
                  </div>
                  <div class="one-column">
                    <div class="desc">
                      <div class="txtSpace">摘要</div>
                      <div>{{ policyData.policySummary }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="rules1" class="base-block">
                <div class="header">
                  <span class="icon iconfont icon-match-promotion"></span
                  >条件要求
                </div>
                <div class="base-block-content">
                  <div
                    class="one-column"
                    v-for="(item, index) in policyData.policyConditionList"
                    :key="index"
                  >
                    <div class="desc">
                      <div class="lable" v-if="!item.conditionName">
                        {{ "" }}
                      </div>
                      <div class="lable" v-if="item.conditionName">
                        {{
                          item.conditionName ==
                          policyData.policyConditionList[index - 1]
                            ?.conditionName
                            ? ""
                            : item.conditionName
                        }}
                      </div>
                      <div>{{ item.conditionValue || "" }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="rules2" class="base-block material-block">
                <div class="header">
                  <span class="icon iconfont icon-match-promotion"></span
                  >办理材料
                </div>
                <div class="material-content">
                  <el-table
                    :data="
                      policyData.applicationTypeList[0]?.policyMaterialList
                    "
                    style="width: 100%"
                  >
                    <el-table-column
                      prop="materialName"
                      label="材料名称"
                      width="220"
                    >
                    </el-table-column>
                    <el-table-column label="是否必要" width="180">
                      <template slot-scope="scope">
                        <div slot="isNeed" class="sub-title">
                          <span class="label need" v-if="scope.row.isNeed == 1"
                            >[必要]</span
                          >
                          <span class="label uneed" v-if="scope.row.isNeed == 2"
                            >[非必要]</span
                          >
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="materialDesc" label="材料说明">
                    </el-table-column>
                    <el-table-column label="操作">
                      <template slot-scope="scope">
                        <el-button
                          v-if="scope.row.materialUrl"
                          @click="handleClick(scope.row, 'prediv')"
                          type="text"
                          size="small"
                          >预览</el-button
                        >
                        <el-button
                          v-if="scope.row.downloadUrl"
                          @click="handleClick(scope.row, 'down')"
                          type="text"
                          size="small"
                          >下载</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div id="rules3" class="base-block process-block">
                <div class="header">
                  <span class="icon iconfont icon-match-promotion"></span
                  >办理流程
                </div>
                <div class="process-content">
                  <div
                    class="item"
                    v-for="(item, index) in policyData.handleProcessList"
                    :key="index"
                  >
                    <div class="circle"></div>
                    <div class="item-content">
                      <div class="title">
                        {{ item.stepName || "" }}
                      </div>
                      <div class="sub-title">
                        {{ item.stepDetail || "" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="rules4" class="base-block contact-block">
                <div class="header">
                  <span class="icon iconfont icon-match-promotion"></span
                  >联系方式
                </div>
                <div class="contact-content">
                  <div
                    class="item"
                    v-for="(item, index) in policyData.policyContactList"
                    :key="index"
                  >
                    <div class="left">
                      <span class="icon iconfont icon-school"></span>
                    </div>
                    <div class="right">
                      <div class="label">
                        {{ item.contactType || "" }}
                      </div>
                      <div class="text">
                        <div
                          class="text-item"
                          v-for="(subitem, subidnex) in item.contactDetail"
                          :key="subidnex"
                        >
                          {{ subitem.contactName || "" }}
                          {{ subitem.phone || "" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="regular" v-if="showFloatTab">
              <div class="policy-info-tab" @click="changeTab">
                <div
                  class="tab-item"
                  id="tab-item0"
                  :class="{ 'active-tab': policyInfoTab == 0 }"
                >
                  基本信息
                </div>
                <div
                  class="tab-item"
                  id="tab-item1"
                  :class="{ 'active-tab': policyInfoTab == 1 }"
                >
                  条件要求
                </div>
                <div
                  class="tab-item"
                  id="tab-item2"
                  :class="{ 'active-tab': policyInfoTab == 2 }"
                >
                  办理材料
                </div>
                <div
                  class="tab-item"
                  id="tab-item3"
                  :class="{ 'active-tab': policyInfoTab == 3 }"
                >
                  办理流程
                </div>
                <div
                  class="tab-item"
                  id="tab-item4"
                  :class="{ 'active-tab': policyInfoTab == 4 }"
                >
                  联系方式
                </div>
              </div>
            </div>
          </div>
          <div class="c-right">
            <div class="item">
              <PolicyRecom />
            </div>
            <div class="item">
              <PolicyHotHandle />
            </div>
            <div class="item">
              <PolicyNew />
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from "@/components/common/header.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import pageTop from "@/components/common/pageTop.vue";
import Footer from "@/components/common/footer.vue";
import PolicyRecom from "./components/policy-recom.vue";
import PolicyHotHandle from "./components/policy-hot-handle.vue";
import PolicyNew from "./components/policy-new.vue";
import {
  getPolicyDetail,
  policyCollection,
  getConductStatus,
} from "@/api/policy/policy.js";

export default {
  components: {
    Header,
    pageTop,
    Footer,
    Breadcrumb,
    PolicyRecom,
    PolicyHotHandle,
    PolicyNew,
  },
  data() {
    return {
      isLoading: true,
      displayPage: true,

      policyData: {
        applicationTypeList: [],
      },
      policyInfoTab: 0, //当前选择的tab
      showFloatTab: false, //显示固定tab
      oldScrollTop: 0, //旧滚动top
      scrollTop: 0, //滚动top
      isAddEventListener: true, //是否监听
      floatStyle: "hidden", //控制右侧漂浮按钮显示
      showFloat: true, //是否显示右侧漂浮按钮
      showSharePopup: false, //显示分享弹窗
      floatUp: false,
      qrcode: "", //分享二维码
      qrcodeName: "", //二维码下载文件名
      currentRouter: "",
      bannerImgHeight: "", //轮播图高度
      policyId: "", //政策id
      isCollection: false, //是否收藏
      collection: "", //想办人数
      canClickCollection: true, //防抖想办按钮

      conductStatus: 0, //办理状态
      conductId: "", //办理id
    };
  },
  methods: {
    fetchData() {
      //详情
      getPolicyDetail({
        id: this.policyId,
      }).then((res) => {
        if (!res) return;
        this.policyData = res.data;
        this.collection = res.data.handleQuantity;
        this.isCollection = res.data.policyHandleStatus;
        this.isLoading = false;
        this.setTDK();
      });

      setTimeout(() => {
        if (this.$el.querySelector(".page-wrap")) {
          this.pageHeight = this.$el.querySelector(".page-wrap").offsetHeight;
        }
        window.addEventListener("scroll", this.handleScroll, true);
      }, 500);
    },
    //获取header组件的点击事件
    getTab(data) {
      alert("12");
      if (data && typeof data == "string") {
        window.location.href = "/" + data;
        //  this.$router.push("/" + data);
      }
    },
    //获取轮播图图片高度
    // getBannerImgHeight() {
    //   if (!window.document.querySelector("#bannerImg1")) return;
    //   this.bannerImgHeight =
    //     window.document.querySelector("#bannerImg1").height;
    // },
    //切换tab
    changeTab(e) {
      this.policyInfoTab = e.target.id.substr(e.target.id.length - 1, 1);
      this.jump();
    },
    //锚点
    jump() {
      window.scrollTo({
        top: this.getTabTop(this.policyInfoTab),
        left: 0, //x 左右
        behavior: "smooth",
      });
    },
    //获取tab点击跳转的top
    getTabTop(index) {
      //获取rulesTab
      let rulesTab = window.document.querySelector("#scroll-box");
      //获取rulesTab的top
      let rulesTabTop = rulesTab.offsetTop;
      let rules =
        window.document.querySelector("#rules" + index).offsetTop +
        rulesTabTop -
        60;
      return rules;
    },
    // 获取页面滚动距离
    handleScroll() {
      const that = this;
      //   that.scrollTop = window.scrollY;
      //   //控制右侧悬浮
      //   if (that.scrollTop >= this.bannerImgHeight) {
      //     this.showFloat = true;
      //     setTimeout(() => {
      //       this.floatStyle = "visible";
      //     }, 300);
      //     if (this.scrollTop + window.innerHeight >= this.pageHeight - 120) {
      //       this.floatUp = true;
      //     } else {
      //       this.floatUp = false;
      //     }
      //   } else {
      //     this.showFloat = false;
      //     setTimeout(() => {
      //       this.floatStyle = "hidden";
      //     }, 300);
      //   }

      //获取rulesTab
      let rulesTab = window.document.querySelector("#scroll-box");
      if (!rulesTab) {
        this.isAddEventListener = false;
        return;
      }
      //获取rulesTab的top
      let rulesTabTop = rulesTab.offsetTop;
      //滚动top
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= rulesTabTop) {
        that.showFloatTab = true;
        //设置延时，等滚动页面停止时，改变tab的值
        setTimeout(() => {
          if (this.oldScrollTop == window.scrollY) {
            //滚动改变currentTab
            let rules0 =
              window.document.querySelector("#rules0").offsetTop +
              rulesTabTop -
              100;
            let rules1 =
              window.document.querySelector("#rules1").offsetTop +
              rulesTabTop -
              100;
            let rules2 =
              window.document.querySelector("#rules2").offsetTop +
              rulesTabTop -
              100;
            let rules3 =
              window.document.querySelector("#rules3").offsetTop +
              rulesTabTop -
              100;
            let rules4 =
              window.document.querySelector("#rules4").offsetTop +
              rulesTabTop -
              100;
            if (scrollTop > rules4) {
              this.policyInfoTab = 4;
            } else if (scrollTop > rules3) {
              this.policyInfoTab = 3;
            } else if (scrollTop > rules2) {
              this.policyInfoTab = 2;
            } else if (scrollTop > rules1) {
              this.policyInfoTab = 1;
            } else if (scrollTop > rules0) {
              this.policyInfoTab = 0;
            }
          } else {
            this.oldScrollTop = window.scrollY;
          }
        }, 20);
      } else {
        that.showFloatTab = false;
        this.policyInfoTab = 0;
      }
    },
    handleClick(item, str) {
      console.log("item,str", item, str);
      if (str == "preview") {
        window.open(item.materialUrl);
      } else if (str == "download") {
        window.open(item.downloadUrl);
      }
    },
    // 设置TDK
    setTDK() {
      if (this.displayPage) {
        document.title = this.policyData.policyName;
      }
    },
    toApply() {
      const that = this;
      getConductStatus({
        interpretateId: this.policyId,
      }).then((res) => {
        if (res.code != 200) {
          if (res.code == 401 || res.code == 1009) return;
          that.$message({
            type: "warning",
            message: "res.message",
          });
        } else {
          that.conductStatus = res.data?.auditStatus || 0;
          that.conductId = res.data?.handleDetailId || "";
          if (that.conductStatus != 0 && that.conductStatus != 6) {
            this.$confirm("当前已存在新的办理申请", "提示", {
              confirmButtonText: "去看看",
              cancelButtonText: "我知道了",
              type: "warning",
            }).then((res) => {
              if (res) {
                if (!that.conductId) {
                  that.$message({
                    type: "warning",
                    message: "请求失败，请刷新页面重试",
                  });
                  return;
                }
                console.log("----跳转结果详情页----", res, that.conductId);
                this.$router.push({
                  path:
                    "/personCenter/policyList/policyConductDetail/" +
                    that.conductId,
                });
              }
            });
          } else {
            this.$router.push({
              path: "/policy/policyDetail/" + this.policyId + "/policySub",
            });
          }
        }
      });
    },
    // 我想办
    toCollection() {
      if (!this.canClickCollection) return;

      policyCollection({
        id: this.policyId,
        type: "1",
      }).then((res) => {
        if (res.code == 200) {
          this.canClickCollection = false;
          this.isCollection = true;
          this.collection++;
          this.$message({
            type: "warning",
            message: "已标记为想办",
          });
          setTimeout(() => {
            this.canClickCollection = true;
          }, 1000);
        }
      });
    },
    cancelCollection() {
      if (!this.isCollection) return;
      if (!this.canClickCollection) return;
      this.canClickCollection = false;
      this.isCollection = false;
      policyCollection({
        id: this.policyId,
        type: "0",
      }).then((res) => {
        if (res.code == 200) {
          this.collection--;
          this.isCollection = false;
          this.$message({
            type: "info",
            message: "已取消标记想办",
          });
          setTimeout(() => {
            this.canClickCollection = true;
          }, 1000);
        }
      });
    },
  },
  watch: {
    $route: {
      handler() {
        let routeResult =
          this.$route.path.indexOf("policySub") == -1 &&
          this.$route.path.indexOf("policyResult") == -1;
        this.policyId = this.$route.params.id;
        if (this.$route.path.indexOf("policyDetail") != -1 && routeResult) {
          this.displayPage = true;
          setTimeout(() => {
            this.fetchData();
          }, 1000);
          this.isAddEventListener = true;
        } else {
          this.displayPage = false;
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
    isAddEventListener: {
      handler(newV) {
        if (!newV) {
          window.removeEventListener("scroll", this.handleScroll, true);
        } else {
          window.addEventListener("scroll", this.handleScroll, true);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;
    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }
    }
    .titleBlock {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: #fff;

      .c-left {
        flex: 1;
        .apply-block {
          position: relative;
          display: flex;
          flex-direction: row;
          min-width: 800px;
          padding: 20px;
          margin: 20px;
          overflow: hidden;
          border-radius: 8px;
          box-shadow: -2px 2px 20px 0 #dbefffad;
          .cover {
            margin-right: 20px;
            height: 104px;
            .img {
              width: 134px;
              height: 104px;
              border-radius: 6px;
            }
          }
          .policy-list {
            display: flex;
            flex: 1;
            flex-direction: column;
          }
          .title {
            width: 100%;
            .txt {
              margin-top: 4px;
              height: 26px;
              font-size: 18px;
              color: #000000;
              font-weight: bold;
            }
          }
          .label-list {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            min-height: 40px;
            margin-top: 12px;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              margin-right: 10px;
              // margin-bottom: 10px;
              padding: 0 10px;
              border-radius: 22px;
              font-size: 12px;
              color: #666;

              background-color: #eaeaea;
            }
            .is-light {
              color: #f7932e;
              background-color: #fee3b0;
            }
          }
          .count {
            flex: 1;
            display: flex;
            // justify-content: flex-end;
            align-items: center;
            font-size: 12px;
            color: #888;
            div {
              margin-right: 15px;
            }
            .icon {
              font-size: 14px;
              margin-right: 0.3em;
            }
            .blue-color {
              color: #0099cc;

              .icon {
                color: #0099cc;
              }
            }
          }
          .extend {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .fee {
              padding: 4px 15px;
              margin-top: 4px;
              font-size: 20px;
              background: linear-gradient(
                90deg,
                rgba(255, 246, 238, 1) 0%,
                rgba(255, 246, 238, 0) 100%
              );
              color: #fe3e3e;
              width: 350px;
            }
            .fee-size {
              padding: 8px 20px;
              font-size: 14px;
            }
            .btn-warp {
              display: flex;
              align-items: center;
              margin-left: 20px;
              .btn {
                padding: 8px 20px;
                color: #fff;
                min-width: 100px;
                text-align: center;
                font-size: 14px;
                border-radius: 6px;
                margin-left: 20px;
                background: linear-gradient(to right, #81cfff, #0099cc);
              }
              .btn-primary {
                background: linear-gradient(to right, #81cfff, #0099cc);
              }
              .btn-apply {
                background: linear-gradient(to right, #ffcd34, #ff9200);
              }
              .btn-none {
                display: none;
              }
              .btn-width {
                min-width: 150px;
              }
            }
          }

          .tag {
            background-color: #ff6969;
            color: #fff;
            width: 18%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            right: -40px;
            top: 10px;
            /* margin-top: 40px; */
            position: absolute;
            transform: rotate(45deg);
          }
        }
        .info-block {
          border-radius: 8px;
          border: 1px solid #e6eff4;
          box-shadow: -2px 2px 20px 0 #dbefffad;
          margin: 20px;
          padding: 20px;
          padding-top: 0;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          background-color: #fff;
        }
        .policy-info-tab {
          position: static;
          display: flex;
          border-bottom: 1px solid #e4e4e4;
          .tab-item {
            flex: 1;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 18px;
            color: #333;
            background-color: #fff;
            cursor: pointer;
          }
          .tab-item::before {
            content: " ";
            position: absolute;
            margin-left: -2em;
            top: 20px;
            width: 1px;
            height: 1em;
            overflow: hidden;
            background-color: #e4e4e4;
          }
          .tab-item:first-child::before {
            background-color: #fff !important;
          }
          .active-tab {
            color: #597af8;
            position: relative;
          }
          .active-tab::after {
            content: " ";
            position: absolute;
            width: 4em;
            height: 2px;
            overflow: hidden;
            background-color: #597af8;
            left: 50%;
            bottom: 0;
            margin-left: -2em;
            overflow-x: hidden;
            overflow-y: hidden;
          }
        }
        //固定tab
        .regular {
          position: fixed;
          top: 0;
          left: 41%;
          width: 41%;
          transform: translate(-50%, 0);
          display: flex;
          flex-direction: column;
          align-items: stretch;
          background-color: #fff;
          z-index: 100;
        }
        .base-block {
          display: flex;
          flex-direction: column;
          padding: 20px;
          border-bottom: 1px solid #e4e4e4;
          .header {
            font-size: 20px;
            font-weight: bold;
            .icon {
              font-size: 18px;
              margin-left: -20px;
              margin-right: 0.5em;
            }
          }
          .base-block-content {
            .three-column {
              margin-top: 15px;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              .desc {
                display: flex;
                padding-right: 10px;
                div:first-child {
                  min-width: 70px;
                  color: #666;
                }
              }
              .desc:last-child {
                padding-right: 0;
              }
            }

            .one-column {
              margin-top: 15px;
              display: flex;
              .desc {
                display: flex;
                div:first-child {
                  font-size: 14px;
                  color: #666;
                }
                .lable {
                  font-size: 14px;
                  min-width: 70px;
                  color: #666;
                }
                .txtSpace {
                  min-width: 100px;
                  letter-spacing: 2em;
                  margin-right: -2em;
                }
              }
            }
          }
        }
        .material-block {
          .material-content {
            margin-top: 15px;
          }

          .sub-title {
            color: #69707f;
            display: flex;
            justify-content: center;
            .label {
              flex-shrink: 0;
              color: #999;
              white-space: pre-wrap;
            }
            .need {
              color: #fe3e3e !important;
            }
          }
          /deep/ .el-table th.el-table__cell {
            background-color: #f0f5ff;
            text-align: center;
            color: #666;
          }
          /deep/ .el-table__body td.el-table__cell {
            text-align: center;
          }
        }
        .process-block {
          .process-content {
            margin-top: 20px;
            .item::before {
              content: "";
              position: absolute;
              top: 12px;
              left: 0px;
              height: 100%;
              width: 2px;
              background-color: #e6e6e6;
            }

            .item:last-child::before {
              content: "";
              position: absolute;
              top: 12px;
              left: 0px;
              height: 0;
              width: 2px;
              background-color: #e6e6e6;
            }

            .item {
              position: relative;
              display: flex;
              padding: 0 20px;
              padding-left: 20px;
              padding-bottom: 32px;

              .circle {
                position: absolute;
                top: 12px;
                left: 1px;
                transform: translate(-50%, -50%);
                flex-shrink: 0;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #4faaf7;
              }

              .item-content {
                .title {
                  margin-bottom: 10px;
                  font-size: 18px;
                  font-weight: bold;
                  color: #1d1e2c;
                }

                .sub-title {
                  margin-top: 8px;
                  white-space: pre-wrap;
                  font-size: 14px;
                  color: #69707f;
                }
              }
            }
            .item:last-child {
              padding-bottom: 0;
            }
          }
        }
        .contact-block {
          border-bottom: none;
          .contact-content {
            .item {
              display: flex;
              padding: 20px 0;

              .left {
                flex-shrink: 0;
                margin-right: 16px;

                .icon {
                  font-size: 18px;
                  color: #1d1e2c;
                }
              }

              .right {
                display: flex;
                flex-direction: column;

                .label {
                  margin-bottom: 12px;
                  font-size: 16px;
                  color: #69707f;
                }

                .text {
                  display: flex;
                  flex-direction: column;

                  .text-item {
                    margin-top: 4px;
                    font-size: 16px;
                    color: #1d1e2c;
                  }
                }
              }
            }
          }
        }
      }
      .c-right {
        margin: 20px;
        margin-left: 0px;
        flex-shrink: 0;
        width: 320px;
        background-color: #fff;
        .item {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>