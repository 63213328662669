<template>
  <div class="login-page page">
    <Header @chooseTab="getTab" :list="headerTab" :splitLine="true"></Header>

    <div class="main">
      <div class="login-plate">
        <!-- <el-form  v-if="loginType == 1" ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="off"
          label-position="left">
          <div class="change-login">
            <div class="mask" @click="changeLoginType">

            </div>
            <div class="img-wrap">
              <img class="img" src="@/assets/images/login/qrcode.png" alt="二维码">
            </div>
          </div>

          <div class="title-container">
            <h3 class="title">登录</h3>
          </div>

          <el-form-item prop="username">
            <span class="svg-container">
              <i class="icon iconfont icon-about"></i>
            </span>
            <el-input ref="username" v-model="loginForm.username" class="login_input" placeholder="手机号或邮箱" name="username"
              type="text" tabindex="1" auto-complete="off" />
          </el-form-item>

          <el-form-item prop="password">
            <span class="svg-container">
              <i class="icon iconfont icon-about"></i>
            </span>
            <el-input :key="passwordType" ref="password" v-model="loginForm.password" class="login_input" :type="passwordType"
              placeholder="密码" name="password" tabindex="2" @keyup.enter.native="handleLogin" />
            <span class="show-pwd" @click="showPwd">
              <span class="icon iconfont" :class="passwordType == 'password' ? 'icon-more' : 'icon-user'"></span>
            </span>
          </el-form-item>

          <div class="forget">
            <span @click="toRegister">
              注册账号
            </span>
            <span @click="toForget">
              忘记密码
            </span>
          </div>
          <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;"
            @click="handleLogin">登录</el-button>
        </el-form> -->

        <div class="login-box w">
          <div class="qrcode-plate">
            <!-- <div class="change-login">
            <div class="mask" @click="changeLoginType"></div>
            <div class="img-wrap">
              <img class="img" src="@/assets/images/login/qrcode.png" alt="二维码">
            </div>
          </div> -->

            <div class="title-container">
              <h3 class="title">扫码登录</h3>
            </div>

            <div class="qrcode">
              <el-table
                v-loading="loadingQRCode"
                :data="loginQRCode"
                element-loading-background="#fff"
                height="240px"
              >
              </el-table>
              <div class="img-wrap">
                <img class="img" :src="loginQRCode[0]" alt="" />
                <div class="qrcode-mask" v-if="successPopup">
                  <p class="text-tip">扫码成功</p>
                  <p class="second-tip">{{ second }}秒后自动返回首页</p>
                </div>
                <div class="qrcode-mask-error" v-if="errorPopup">
                  <!-- <p class="text-tip">二维码失效</p> -->
                  <p class="icon-wrap" @click="refreshQRCode">
                    <span class="icon el-icon-refresh-right"></span>
                  </p>
                  <p class="second-tip">二维码已失效，请刷新</p>
                </div>
              </div>
            </div>

            <div class="title-container">
              <h3 class="subtitle">微信扫一扫，授权登录</h3>
            </div>

            <!-- <div class="forget">
            <span @click="changeLoginType">
              密码登录
            </span>
            <span @click="toRegister">
              免费注册
            </span>
          </div> -->
          </div>
        </div>
      </div>
    </div>

    <easy-footer></easy-footer>
  </div>
</template>

<script>
import Header from "@/components/common/header.vue";
import easyFooter from "@/components/common/easyFooter.vue";
import { getQECode, checkQECodeStatus } from "@/api/login/login.js";
import { local } from "@/utils/storage.js";

export default {
  components: { Header, easyFooter },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号或邮箱"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("密码有误"));
      } else {
        callback();
      }
    };
    return {
      headerTab: [{ name: "返回首页", type: "home" }],
      loginForm: {
        username: "",
        password: "",
      }, //登录表单
      loginRules: {
        username: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
      }, //表单规则
      loading: false, //登录按钮loading
      passwordType: "password", //控制密码显示隐藏图标
      loginType: 1, //控制登录方式
      loginQRCode: [], //扫码登录二维码
      loadingQRCode: true, //加载二维码
      isPolling: false, //控制轮询
      successPopup: false, //成功弹窗
      errorPopup: false, //二维码失效弹窗
      second: 3, //返回首页倒计时
    };
  },
  mounted() {
    console.log("this.$route.query==首页=", this.$route.query);
    this.getQECode();
  },
  destroyed() {
    this.isPolling = false;
  },
  methods: {
    //点击tab
    getTab(data) {
      if (data && typeof data == "string") {
        this.$router.push("/" + data);
      }
    },
    //切换登录方式
    changeLoginType() {
      console.log("1");
      if (this.loginType == 1) {
        this.loginType = 2;
        this.getQECode();
      } else {
        this.loginType = 1;
        this.isPolling = false;
      }
    },
    //获取二维码
    getQECode() {
      this.loadingQRCode = true;
      this.loginQRCode = [];
      this.isPolling = true;
      getQECode()
        .then((res) => {
          this.loginQRCode.push(res.data.url);
          setTimeout(() => {
            this.loadingQRCode = false;
          }, 500);
          const code = res.data.code;
          const time = setInterval(() => {
            checkQECodeStatus(code).then((res) => {
              if (!this.isPolling) {
                clearInterval(time);
                return;
              }
              if (res.code == 4010) {
                this.errorPopup = true;
                clearInterval(time);
              }
              if (res.data) {
                clearInterval(time);
                local.Set("Authorization", res.data.access_token);
                local.Set("scCustom", res.data.scCustom);
                local.Set("isLogin", true);
                this.successPopup = true;
                setInterval(() => {
                  this.second--;
                }, 1000);
                setTimeout(() => {
                  let redirect = this.$route.query?.redirect;
                  let url = "/";
                  if (redirect) {
                    url = redirect;
                    console.log("首页跳转去url---",url);
                    this.$router.push(url);
                  } else {
                    this.$router.go(-1);
                  }
                }, 3000);
              }
            });
          }, 2000);
        })
        .catch((err) => {
          console.log("二维码生成失败", err);
        });
    },
    //刷新二维码
    refreshQRCode() {
      this.errorPopup = false;
      this.getQECode();
    },
    //显示隐藏密码
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    //去注册
    toRegister() {
      this.$router.push({ path: "/register" });
    },
    //忘记密码
    toForget() {
      this.$router.push({ path: "/forgetPass" });
    },
    //登录
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        console.log(this.loginForm);
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.loginForm)
            .then((res) => {
              if (res.code === 0) {
                this.$router.push({ path: this.redirect || "/" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
              this.loading = false;
            })
            .catch((rej) => {
              console.log(rej);
              this.loading = false;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  flex-direction: column;

  .main {
    flex: 1;
    background-color: #2d8bd5;

    .login-plate {
      flex: 1;
      width: 100%;
      height: 800px;
      min-height: 660px;
      margin: 0 auto;
      background: url(#{$bgImageUrl + '/static/icon/pc_login_img.jpg'}) center
        center / cover no-repeat rgb(255, 255, 255);
      background-size: auto 800px;
      background-color: #2d8bd5;

      .login-form {
        width: 450px;
        max-width: 100%;
        padding: 30px 35px 0;
        margin: 0 auto;
        overflow: hidden;
        background: #fff;
        height: 380px;
        top: 220px;
        left: 400px;
      }

      .login-box {
        position: relative;
        height: 100%;

        .qrcode-plate {
          position: absolute;
          width: 30%;
          height: 50%;
          padding: 30px 35px 0;
          overflow: hidden;
          background: #fff;
          top: 50%;
          right: 50px;
          transform: translate(0, -50%);

          .title-container {
            .title {
              margin: 0px;
            }

            .subtitle {
              margin-top: 24px;
              text-align: center;
              font-size: 16px;
              font-weight: normal;
            }
          }

          .qrcode {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin: 20px auto;
            width: 80%;
            height: 63%;

            .img-wrap {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
              border: 1px solid #ebeef5;
            }

            .qrcode-mask {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              height: 100%;
              background: #fff;
              border: 1px solid #ebeef5;

              .text-tip {
                font-size: 40px;
                text-align: center;
                color: green;
                margin-top: 30%;
              }

              .second-tip {
                margin-top: 8px;
                font-size: 13px;
                text-align: center;
                color: #666;
              }
            }

            .qrcode-mask-error {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 239px;
              height: 239px;
              background: rgba($color: #fff, $alpha: 0.95);
              border: 1px solid #ebeef5;

              .icon-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background: rgba($color: #fff, $alpha: 0.8);
                box-shadow: 2px 2px 2px rgba($color: #000000, $alpha: 0.05);

                .icon {
                  font-size: 56px;
                  color: #333;
                  cursor: pointer;
                }
              }

              .text-tip {
                font-size: 40px;
                text-align: center;
                color: green;
                margin-top: 30%;
              }

              .second-tip {
                margin-top: 8px;
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                color: #000;
              }
            }

            .el-table::before {
              left: 0;
              bottom: 0;
              width: 0;
              height: 1px;
            }
          }
        }
      }

      .forget {
        margin-bottom: 20px;
        display: flex;

        span {
          flex: 1;
          cursor: pointer;
          font-size: 14px;

          &:last-child {
            text-align: right;
          }
        }
      }

      .change-login {
        position: absolute;
        top: 0;
        right: 0;

        .img-wrap {
          width: 72px;
          height: 72px;
        }

        .mask {
          position: absolute;
          top: 0;
          right: 0;
          width: 0;
          height: 0;
          border: 36px solid;
          border-color: transparent transparent white white;
          cursor: pointer;
        }
      }

      .title-container {
        position: relative;

        .title {
          font-size: 22px;
          color: #000;
          margin: 0px auto 30px auto;
          text-align: center;
          font-weight: bold;
        }
      }

      .svg-container {
        position: absolute;
        right: 10px;
        top: 1px;
        color: grey;
        cursor: pointer;
        user-select: none;
      }

      .show-pwd {
        position: absolute;
        right: 10px;
        top: 1px;
        color: grey;
        cursor: pointer;
        user-select: none;
      }

      .el-form-item__content {
        line-height: 30px;
      }

      .el-input__inner {
        background-color: transparent;
      }
    }
  }
}
</style>
