<template>
  <div class="mien" v-if="data">
    <div class="cover">
      <img class="img" mode="aspectFill" :src="data.avatar" />
    </div>
    <div class="content">
      <div class="title">
        <div class="">
          {{ data.teacherName || "" }}
        </div>
        <div class="sex sex-male" v-if="data.sex == '1'">
          <span class="icon iconfont icon-sex-male"></span>
        </div>
        <div class="sex sex-female" v-else-if="data.sex == '2'">
          <span class="icon iconfont icon-sex-female"></span>
        </div>
      </div>
      <div class="desc">
        <div class="desc-content">
          {{ data.productNames || "" }}
        </div>
      </div>
      <div class="data">
        <div class="count">
          <div class="txt">
            <span class="icon iconfont icon-files"></span>
            <span class="num">{{ data.orderNum || "0" }}</span
            >场次
          </div>
          <div class="txt">
            <span class="icon iconfont icon-browse"></span>
            <span class="num">{{ data.viewQuantity || "0" }}</span
            >浏览
          </div>
        </div>
      </div>
    </div>
    <div
      class="states"
      :class="data.status != '0' && data.status ? 'states-s1' : 'states-s4'"
    >
      <span class="text">{{ data.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
  },
  components: {},
  data() {
    return {};
  },

  methods: {},
  created() {},
};
</script>

<style lang="scss" scoped>
.mien {
  margin: 20px 0;
  padding: 20px;
  width: 500px;
  background-color: #fff;
  box-shadow: -2px 2px 20px 0 #dbefffad;
  -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
  display: flex;
  align-items: stretch;
  padding: 24px 16px;
  position: relative;
  //   .warp{
  //       text-overflow: -o-ellipsis-lastline;
  //       overflow: hidden;
  //       text-overflow: ellipsis;
  //       display: -webkit-box;
  //       -webkit-line-clamp: 8;
  //       -webkit-box-orient: vertical;
  //   }
  .cover {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 120px;
    margin-right: 16px;

    .img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
    }

    .sex-male {
      background-color: #6cc5ff;
    }

    .sex-female {
      background-color: #ff9999;
    }

    .sex {
      margin-left: 0.3em;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      align-items: center;
      display: flex;
      justify-content: center;

      .icon {
        font-size: 10px;
        margin: 0;
        color: #fff;
      }
    }

    .desc {
      margin-top: 0.5em;
      .desc-content {
        min-height: 40px;
        font-size: 14px;
        color: #666;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .icon {
      color: #666;
      font-size: 14px;
      margin-right: 0.35em;
    }

    .data {
      display: flex;
      justify-content: space-between;
      margin-top: 0.8em;

      .count {
        flex: 1;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #888888;
        // margin-top: 0.5em;
        .icon {
          font-size: 16px;
        }
        .txt {
          font-size: 16px;
          margin-right: 0.5em;
          min-width: 140px;
          .num {
            margin-right: 0.35em;
          }
        }
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #fff;
        border-radius: 24px;
        height: 46px;
        // padding: 0 43px;
        min-width: 130px;
        background-color: #1d9cff;
      }

      .button-primary {
        background: linear-gradient(135deg, #f7d941, #faac1f);
      }

      .button-disabled {
        background: linear-gradient(135deg, #c7c7c6, #b5b5b5);
      }
    }
  }
  .states {
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    font-size: 16px;
    padding: 0 20px 0 30px;
    color: #fff;
    line-height: 1;
    border-radius: 0 16px 0 60px;
    background-color: #1d9cff;
  }

  .states-s1 {
    background: linear-gradient(135deg, #f7d941, #faac1f);
  }

  .states-s4 {
    background: linear-gradient(135deg, #c7c7c6, #b5b5b5);
  }
}
</style>
