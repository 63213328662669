<template>
  <div class="area-block">
    <div class="city-block">
      <div class="city-item">
        <div class="city-item-title">所在地市：</div>
        <div class="city-item-content">
          <div
            class="city-item-name hover-txt"
            :class="{ 'check-active': item.areaCode == cityCode }"
            v-for="(item, index) in cityList"
            :key="index"
            @click="changeCity(item.areaCode)"
          >
            {{ item.areaName }}
          </div>
        </div>
      </div>
      <div class="city-item">
        <div class="city-item-title">所在地区：</div>
        <div class="city-item-content">
          <div
            class="city-item-name hover-txt"
            :class="{ 'check-active': item.areaCode ==  districtCode}"
            v-for="(item, index) in cityChildren"
            :key="index"
            @click="changeCityChildren(item)"
          >
            {{ item.areaName }}
          </div>
        </div>
      </div>
    </div>
    <div class="station-block" v-if="stationList.length">
        <commonstation :list="stationList" :type="1"></commonstation>
         <div class="m-pager">
          <el-pagination
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            :page-size="limit"
            :total="pageCount"
            @current-change="changePageClick"
          >
          </el-pagination>
        </div>
    </div>
     <div class="empty" v-else>
         <el-empty description="暂无相关地区"></el-empty>
    </div>
  </div>
</template>

<script>
import { getAreaList, getAreaStationList } from "@/api/course/course.js";
import Commonstation from "@/components/station/commonStation.vue";

export default {
  components: {
    Commonstation,
  },
  data() {
    return {
      cityList: [],
      cityChildren: [],
      code: "350000", //省 /福建
      cityCode: "", //市 /厦门
      districtCode: "", //区
      stationList:[],
      pageCount:'',
      limit:10,
    };
  },
  methods: {
    //获取市区
    fetchdata() {
      getAreaList().then((res) => {
        this.cityList = res.data;
        this.cityList.forEach(item => {
        if (item.childernList) {
            item.childernList.unshift({
            areaCode: "",
            areaName: "全部",
            isActive: true,
            });
        }
        });
        this.formatChildrenData()
      });
    },
    //切换页面
    changePageClick(page) {
      this.getStationList(page);
    },
    //格式数据
    formatChildrenData() {
        this.cityChildren = []
        let filterChildren = []
        filterChildren = this.cityList.filter(
          item => item.areaCode == this.cityCode
        );
        this.cityChildren = filterChildren[0].childernList;
    },
    //点击城市
    changeCity(areaCode) {
        this.cityCode = areaCode
        
    },
    //点击地区
    changeCityChildren(item) {
        this.districtCode = item.areaCode
    },
    //根据code查服务站
    getStationList(page = 1) {
        const params = {
            city: this.cityCode,
            page: page,
            limit: this.limit,
        };
        if(this.districtCode) {
            params.district = this.districtCode
        }
        getAreaStationList(params).then((res) => {
            this.stationList = res.data.stationList
            this.pageCount = Number(res.data.total)

      });
    },
  },
  watch: {
      cityCode(newV){
          console.log("cityCode",newV);
          this.districtCode = ''
          this.getStationList()
          if(this.cityList.length) return this.formatChildrenData()
          
      },
      districtCode(newV) {
          console.log("districtCode",newV);
          this.getStationList()

      }
  },
  created() {
    this.fetchdata();
    this.cityCode = "350200"
  },
};
</script>

<style lang="scss" scoped>
.area-block {
  padding: 20px 0;
  margin: 0 30px;
}
.city-block {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  border-radius: 10px;
  border: 1px solid rgba(229, 229, 229, 1);
  box-shadow: -4px 3px 15px rgba(173, 213, 255, 0.5);
  backdrop-filter: blur(4px);
  .city-item {
    display: flex;
    align-items: flex-start;
    font-size: 20px;
    .city-item-title {
      color: #999;
      padding: 4px 0;
      min-width: 100px;
    }
    .city-item-content {
      display: flex;
      flex-wrap: wrap;
    }
    .city-item-name {
      text-align: center;
      min-width: 90px;
      padding: 4px 10px;
      color: #000;
    }
    .city-item-name:not(:last-child) {
      margin-right: 10px;
    }
    .check-active {
      background-color: #ddeef9;
      border-radius: 2px;
      color: #007aff;
    }
    .hover-txt:hover {
      cursor: pointer;
      color: #007aff;
    }
  }
  .city-item:last-child {
    margin-top: 10px;
  }
}
.station-block {
    margin-top: 30px;
}
.m-pager {
    padding: 24px 0;

    .el-pagination {
      display: flex;
      justify-content: center;

      /deep/ .btn-prev {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-prev:hover:enabled {
        color: #409eff;
      }

      /deep/ .number {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .more {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-next {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .active {
        color: #fff;
        background-color: #409eff;
      }

      /deep/ .btn-next:hover:enabled {
        color: #409eff;
      }
    }
  }
.empty {
      // min-height: 600px;
      height: auto;
      display: flex;
      justify-items: center;
      justify-content: center;
    }
</style>