<template>
  <section class="content w" v-loading="loading">
    <div class="c-left">
      <div class="info-block">
        <div class="info-main-content">
          <div class="info-cover" @click="toPreview(tutorDetail.avatar)">
            <img class="img" :src="tutorDetail.avatar" alt="" />
          </div>
          <div class="info-content">
            <div class="info-title">
              <div>{{ tutorDetail.teacherName }}</div>
              <div class="sex sex-male" v-if="tutorDetail.sex == '1'">
                <span class="icon iconfont icon-sex-male"></span>
              </div>
              <div class="sex sex-female" v-else-if="tutorDetail.sex == '2'">
                <span class="icon iconfont icon-sex-female"></span>
              </div>
            </div>

            <div class="info-desc">
              <div class="desc-content">
                <div class="txt">单位信息：</div>
                <div>{{ tutorDetail.unitName }}</div>
              </div>
              <div class="desc-content">
                <div class="txt">职务信息：</div>
                <div>{{ tutorDetail.job }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="info-desc-detail">
          <div class="intro" :class="isExpanded ? 'expanded' : 'baseinfo'">
            <div
              v-if="showExpanded"
              class="expanded-button"
              @click="toggleExpand"
            >
              {{ isExpanded ? "收起" : "展开全部" }}
            </div>
            <span class="txt" v-if="tutorDetail.description">简介：</span>
            {{ tutorDetail.description || "" }}
          </div>
        </div>
      </div>
      <div class="base-block">
        <div class="tab-block">
          <div
            class="tab-item hover-item"
            v-for="(item, index) in tabList"
            :key="index"
            @click="changeTab(item.id)"
            :class="{ 'tab-active': item.id == current }"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="picker-block">
          <div class="date" @click="getServiceTime()" v-if="current == 1">
            <span class="icon iconfont icon-tag-time2"></span>
            <span v-if="scheduleDateTime"
              >已选的服务时间: {{ scheduleDateTime.substring(5, 16) }}</span
            >
            <span v-else>{{ tutorDetail.text }}</span>
            <span class="icon iconfont icon-more-txt"></span>
          </div>
           <div v-if="current == 2" class="yearBlock">
            <el-date-picker
              v-model="currentYear"
              type="year"
              value-format="yyyy"
              prefix-icon="icon-tag-time2"
              suffix-icon="icon-toggle-down"
              :clearable="false"
              editable
              @change="yearChange"
            >
            <!-- <template slot="footer">
              <span class="icon iconfont icon-toggle-down"></span>
            </template> -->
            </el-date-picker>
            <i class="footer icon iconfont icon-toggle-down"></i>
          </div>
        </div>
        <div class="desc-block">
          <product-list
            v-if="current == 1"
            :teacherId="teacherId"
            :status="tutorDetail.status"
            @toMin="toMin"
          ></product-list>
          <trend-list
            v-if="current == 2"
            :teacherId="teacherId"
            :year="currentYear"
          ></trend-list>
        </div>
      </div>
    </div>
    <div class="c-right">
      <div class="item">
        <reTutor></reTutor>
      </div>
      <div class="item" @click="getQRCode('service')">
        <reService></reService>
      </div>
    </div>
    <el-dialog title="" :visible.sync="centerDialogVisible" width="30%" center>
      <div style="text-align: center">
        <img :src="baseURL + qrcodeImg" />
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { getTutorDetail } from "@/api/course/course.js";
// import { getQRCode } from "@/api/common/common.js";

import ProductList from "@/components/tutor/productList.vue";
import TrendList from "@/components/tutor/trendList.vue";
import reTutor from "@/components/tutor/reTutor.vue";
import reService from "@/components/tutor/reService.vue";

export default {
  components: {
    ProductList,
    TrendList,
    reTutor,
    reService,
  },
  data() {
    return {
      teacherId: "",
      tutorDetail: {},
      isExpanded: false,
      showExpanded: false,
      loading: false,
      current: 1,
      tabList: [
        {
          id: 1,
          title: "服务项目",
        },
        {
          id: 2,
          title: "导师动态",
        },
      ],
      currentYear: "2023",
      scheduleDateTime: "",
      qrcodeImg:'',
      centerDialogVisible:false,
      baseURL:'',
    };
  },
  created() {
    this.teacherId = this.$route.params.id;
    // 获取今年的年份
    const year = new Date().getFullYear();
    this.currentYear = year.toString();
    this.fetchData();
    this.getQRCode()
  },
  methods: {
    fetchData() {
      this.loading = true;

      getTutorDetail({
        teacherId: this.teacherId,
      }).then((res) => {
        if (res.code != 200) return;

        this.loading = false;

        this.tutorDetail = res.data;
        this.scheduleDateTime = "";
        if (
          this.tutorDetail.description &&
          this.tutorDetail.description.length > 84
        ) {
          this.showExpanded = true;
        }
      });
    },
    toMin() {
      this.centerDialogVisible = true;
    },
    getQRCode(e) {
				let firstJump = "pages/home";
				if (this.$route.path.split('/').indexOf("tutorDetail") > -1) {
					firstJump = "pages/common-detail/tutorDetail";
				}
        if(e== 'service') {
          this.centerDialogVisible = true;
					firstJump = "pages/strategy/serviceList";
        }

				// let param = {
				// 	"appId": "wx49ed84a58c6d9fc1",
				// 	"scene": "teacherId=" + this.teacherId,
				// 	"page": firstJump,
				// }
        this.baseURL = this.$baseURL;

					this.qrcodeImg = '/api/web/common/shareStream?appId=wx49ed84a58c6d9fc1&page=' + firstJump + '&scene=teacherId=' + this.teacherId
				// getQRCode(param).then(res => {
				// 	this.qrcodeImg = res.data
				// })
			},
    //图片预览
    toPreview(url) {
      window.open(url);
    },
    changeTab(e) {
      this.current = e;
    },
    yearChange() {},
    getServiceTime() {
      if (this.tutorDetail.status == "0") {
        this.$message({
          message: "抱歉，该导师近期预约已满，请选择其他导师",
        });
        return;
      }
      this.toMin()
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
  watch: {
    $route: {
      handler() {
        let id = this.$route.params.id;
        this.teacherId = id;

        this.fetchData();
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;

  .c-left {
    flex: 1;
    margin-right: 15px;

    box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
  }
  .c-right {
    margin-top: 20px;
    margin-right: 20px;
    flex-shrink: 0;
    width: 320px;
    background-color: #fff;

    .item {
      margin-bottom: 20px;
    }
  }
  .info-block {
    display: flex;
    flex-direction: column;
    padding: 30px;
    box-shadow: -4px 3px 15px rgba(173, 213, 255, 0.5);
    -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
    background-color: #fff;
    margin: 20px 0 40px 0;
    .info-main-content {
      display: flex;
    }
    .info-cover {
      width: 100px;
      height: 122px;
      margin-right: 20px;
      .img {
        height: 100%;
        width: 100%;
        border-radius: 10px;
      }
    }
    .info-content {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 24px;
      .info-title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
        .logo {
          height: 40px;
          width: 40px;
          margin-right: 20px;
          .img {
            height: 100%;
            width: 100%;
          }
        }
      }
      .sex-male {
        background-color: #6cc5ff;
      }

      .sex-female {
        background-color: #ff9999;
      }

      .sex {
        margin-left: 0.3em;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        align-items: center;
        display: flex;
        justify-content: center;

        .icon {
          font-size: 10px;
          margin: 0;
          color: #fff;
        }
      }
      .info-desc {
        flex-direction: column;
        font-size: 18px;
        .desc-content {
          display: flex;
          color: #383838;
          line-height: 1.5em;
          margin-bottom: 8px;
          .txt {
            min-width: 5em;
            color: #848fa5;
          }
        }
      }
    }
    .info-desc-detail {
      margin-top: 20px;
      font-size: 16px;
      .intro {
        cursor: pointer;
        // color: #888;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .intro::before {
        content: "";
        float: right;
        width: 0;
        // height: calc(100% - 34px);
        height: 100%;
        margin-bottom: 22px;
      }

      .baseinfo {
        max-height: 90px;
      }

      .expanded {
        display: block;
        overflow: visible;
        max-height: none;
        /* 展开时取消最大高度限制 */
      }

      .expanded-button {
        float: right;
        clear: both;
        color: #007aff !important;
      }

      .txt {
        min-width: 5em;
        color: #848fa5;
      }
    }
  }
  .base-block {
    box-shadow: -4px 3px 15px rgba(173, 213, 255, 0.5);
    -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
    background-color: #fff;
    margin: 20px 0 40px 0;
    margin-bottom: 0px;
    .tab-block {
      display: flex;
      align-items: center;
      font-size: 18px;
      border-bottom: 1px solid #eaeaea;
      .tab-item {
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 10px 10px;
      }

      .tab-item:not(:first-child)::before {
        content: "";
        position: absolute;
        top: 20%;
        left: 0;
        height: 60%;
        width: 1px;
        background-color: #eaeaea;
      }
      // .tab-item:hover {
      //   color: #007aff;
      // }

      // .tab-item:hover::after {
      //   background-color: #007aff;
      // }
      .tab-active {
        position: relative;
        color: #007aff;
      }
      .tab-active::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 4em;
        height: 4px;
        transform: translateX(-50%);
        background-color: #007aff;
        transition: background-color 0.3s;
      }
    }
    .picker-block {
      cursor: pointer;
      margin: 0 20px;
      padding: 16px 0 0;
      display: flex;
      justify-content: flex-end;
      font-size: 16px;
      .date {
        padding: 10px;
        background-color: #ddeef9;
        border-radius: 10px;
        color: #007aff;

        .icon {
          margin: 0 0.3em;
          font-size: 14px;
          color: #007aff;
        }
      }

      .yearBlock {
        position: relative;
        width: fit-content;
        /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
          width: 104px;
          font-size: 16px;
        }
        /deep/ .el-input__inner {
          border: none;
          background-color: #ddeef9;
          color: #007aff;
          border-radius: 10px;
          cursor: pointer;
        }
        /deep/ .el-input__icon {
          font-size: 14px;
          color: #007aff;
          margin: 0 0.3em;
          font-family: "iconfont" !important;
        }
        .footer {
          font-size: 12px;
          position: absolute;
          top: 50%;
          right: 15px;
          z-index: 9;
          color: #c0c4cc;
          color: #007aff;
          transform: translateY(-50%);
        
        }
        .year-picker {
          font-size: 16px;
          font-weight: bold;

          .icon {
            color: #666;
            margin-left: 0.35em;
            font-size: 14px;
          }
        }
      }
    }
    .desc-block {
      // padding: 30px;
      font-size: 17px !important;
      line-height: 2 !important;
      min-height: 440px;
    }
  }
}
</style>