export const local = {
    Set(key, value) {
        window.localStorage.setItem(key, JSON.stringify(value))
    },

    Get(key) {
        const value = window.localStorage.getItem(key)
        try {
            return JSON.parse(window.localStorage.getItem(key))
        } catch (error) {
            return value
        }
    },

    Remove(key) {
        window.localStorage.removeItem(key)
    },

    Clear() {
        window.localStorage.clear();
    }
}

export const session = {
    Set(key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value))
    },

    Get(key) {
        const value = window.sessionStorage.getItem(key)
        try {
            return JSON.parse(window.sessionStorage.getItem(key))
        } catch (error) {
            return value
        }
    },

    Remove(key) {
        window.sessionStorage.removeItem(key)
    },

    Clear() {
        window.sessionStorage.clear();
    }
}