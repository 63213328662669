<template>
  <div class="login-page page">
    <Header @chooseTab="getTab"></Header>

    <div class="main">
      <div class="login-plate">
        <el-form ref="registerForm" :model="registerForm" :rules="registerRules" class="login-form" auto-complete="off" label-position="left">
          <div class="title-container">
            <h3 class="title">注册</h3>
          </div>

          <el-form-item prop="username">
            <span class="svg-container">
              <svg-icon icon-class="user" />
            </span>
            <el-input ref="username" v-model="registerForm.username" placeholder="手机号或邮箱" name="username" type="text" tabindex="1" />
          </el-form-item>

          <div class="codeInput">
            <el-form-item prop="userCode" class="codeWidth">
              <span class="svg-container">
                <svg-icon icon-class="example" />
              </span>
              <el-input ref="userCode" v-model="registerForm.userCode" placeholder="验证码" name="userCode" type="text" tabindex="1"
                class="code" />
            </el-form-item>
            <el-button type="primary" class="getCode" size="medium" :disabled="disabled" @click="sendCode">
              {{ codeText}}
            </el-button>
          </div>

          <el-form-item prop="password">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input :key="passwordType" ref="password" v-model="registerForm.password" :type="passwordType" placeholder="密码"
              name="password" tabindex="2" auto-complete="off" />
            <span class="show-pwd" @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>

          <el-form-item prop="userMamage" style="background:#fff">
            <el-checkbox-group v-model="registerForm.userMamage">
              <el-checkbox name="type"><el-link href="/#/agreement" target="_blank">我已阅读并接受用户协议</el-link></el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:22px;" @click="handleLogin">
            立即注册
          </el-button>

          <div class="forget">
            <span @click="toLogin">
              已有帐号，去登录
            </span>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/common/header.vue"
import { getUserInfo } from "@/api/user/user.js"

export default {
  components: { Header },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号或邮箱'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入验证码'))
      } else {
        callback()
      }
    }
    return {
      showim: '',
      onlyHome: true,
      loginFlag: true,
      width: "1300px",
      registerForm: {
        username: '',
        password: '',
        userCode: '',
        userMamage: ''
      },
      registerRules: {
        username: [{ required: true, trigger: 'blur', validator: validateUsername }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
        userCode: [{ required: true, trigger: 'blur', validator: validateCode }],
        userMamage: [{ required: true, message: '请勾选用户协议', trigger: 'change' }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      seconds: 60,
      disabled: false,
      dialogVisible: false,
      codeText: '获取验证码'
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    //获取数据
    fetchData() {
      getUserInfo().then(res => {
        console.log(res);
      })
    },
    //点击tab
    getTab(data) {
      if (data && typeof data == 'string') {
        this.$router.push('/' + data)
      }
    },
    //显示隐藏密码
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    //  验证手机号
    checkMobile(str) {
      // var reg = /^1([35678][0-9]|4[579]|9[89])\d{8}$/
      if (str) {
        return true
      } else {
        return false
      }
    },
    // 获取验证码
    sendCode() {
      var _this = this
      if (this.checkMobile(this.registerForm.username)) {
        this.disabled = true
        var setSeconds = setInterval(function () {
          _this.seconds--
          _this.codeText = _this.seconds + '秒后获取'

          if (_this.seconds <= 0) {
            clearInterval(setSeconds)
            _this.codeText = '重新发送'
            _this.seconds = 60
            _this.disabled = false
          }
        }, 1000)

        // const data = {
        //   account: this.registerForm.username
        // }
        // getCode(data).then(res => {
        //   if (res.code === 0) {
        //     this.$message({ message: res.msg, type: 'success' })
        //   } else {
        //     this.$message({ message: res.msg, type: 'error' })
        //   }
        // })
      }
    },
    //注册
    handleLogin() {
      this.$refs.registerForm.validate(valid => {
        console.log(valid);
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/register', this.registerForm).then((res) => {
            if (res.code === 0) {
              this.$router.push({ path: this.redirect || '/' })
            } else {
              this.$message({ message: res.msg, type: 'error' })
            }
            this.loading = false
          }).catch((rej) => {
            console.log(rej)
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //去登录
    toLogin() {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  display: flex;
  flex-direction: column;

  .main {
    flex: 1;

    .login-plate {
      flex: 1;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      background-color: #fff;
      .title-container {
        position: relative;
        .title {
          font-size: 22px;
          color: #000;
          margin: 0px auto 40px auto;
          text-align: center;
          font-weight: bold;
        }
      }

      .login-form {
        position: relative;
        width: 450px;
        max-width: 100%;
        padding: 25px 35px 0;
        margin: 0 auto;
        overflow: hidden;
        background: #fff;
        height: 450px;
        top: 150px;
        left: 400px;
        .codeInput {
            display: flex;
            .codeWidth {
              flex: 1;
            }
            .getCode {
              flex-shrink: 0;
              margin-left: 40px;
              margin-bottom: 20px
            }
          }
        .show-pwd {
          position: absolute;
          right: 10px;
          top: 1px;
          color: grey;
          cursor: pointer;
          user-select: none;
        }
      }

      .forget {
        margin-bottom: 20px;
        display: flex;

        span {
          flex: 1;
          cursor: pointer;
          font-size: 14px;

          &:last-child {
            text-align: right;
          }
        }
      }

      .el-form-item {
        margin-bottom: 20px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        color: #454545;
        .el-form-item__content {
          line-height: 0px !important;
        }
        .el-checkbox-group{
          line-height: 0px !important;
        }
      }

      .el-input__inner {
        background-color: transparent;
      }
    }
  }
}
</style>