<template>
  <div class="match-invite-page page">
     <div class="page-wrap" v-if="displayPage">
      <!-- 顶部 -->
      <page-top></page-top>
      <!-- 头部 -->
      <Header @chooseTab="getTab" currentIndex="2"></Header>
      <!-- 主体 -->
      <main class="main">
        <section class="breadcrumb-plate w">
          <div class="label">我的位置：</div>
          <breadcrumb />
        </section>

      <section class="content w">
        <div class="c-left">
          <div class="policyGuidBlock">
            <div class="guid" @click="toPolicyMore()">
              <div class="total">
                <div class="load-animate">
                  <img
                    class="floor floor1"
                    mode="aspectFit"
                    :src="
                      $globalHeader.imgUrl + '/static/icon/v2/p-loading-01.png'
                    "
                  />
                  <img
                    class="floor floor2"
                    mode="aspectFit"
                    :src="
                      $globalHeader.imgUrl + '/static/icon/v2/p-loading-02.png'
                    "
                  />
                  <img
                    class="floor floor3"
                    mode="aspectFit"
                    :src="
                      $globalHeader.imgUrl + '/static/icon/v2/p-loading-03.png'
                    "
                  />
                  <div class="floor-round"></div>
                </div>
                <div class="count">
                  <div class="txt" v-if="showTotal">
                    <div class="num">{{ totalCount }}</div>
                    条
                  </div>
                  <div class="txt" v-else>
                    <div class="num">获取</div>
                  </div>
                </div>
              </div>
              <div class="right-content">
                <div class="title">为您匹配相关政策补贴</div>
                <div class="desc">
                  大量的人才就业、创业、奖励、补贴，勿错过时机，通过本站一手掌握和办理，完善资料有助于提高匹配推荐度。
                </div>
              </div>
            </div>
          </div>
          <!-- 关键词卡片 -->
          <div class="condition-card">
            <div class="tip-wrap">
              <div class="tip-title tip-margin">类型：</div>
              <div class="tip-content">
                <div class="tip-margin hover-text" :class="{'type-color':item.id == categoryId}"
                @click="clickPolicyType(item)" v-for="(item,index) in policyTypeList" :key="index"
                :title="item.categoryName">
                  {{item.categoryName}}
                </div>
              </div>
            </div>
            <div class="tip-wrap">
              <div class="tip-title tip-margin">关键词：</div>
              <div class="tip-content">
                <div class="tip-margin hover-text">全部</div>
                <div class="tip-margin">落户办理</div>
                <div class="tip-margin">就业培训</div>
                <div class="tip-margin">创业贷款</div>
                <div class="tip-margin">创业贷款</div>
                <div class="tip-margin">租房补贴</div>
                <div class="tip-margin">落户办理</div>
                <div class="tip-margin">创业贷款</div>
                <div class="tip-margin">租房补贴</div>
                <div class="tip-margin">落户办理</div>
                <div class="tip-margin">创业贷款</div>
                <div class="tip-margin">租房补贴</div>
                <div class="tip-margin">落户办理</div>
              </div>
            </div>
          </div>
          <!-- 政策列表 -->
          <div class="condition-card">
            <PolicyList :categoryId="categoryId"/>
          </div>

        </div>
        <div class="c-right">
          <div class="item">
            <PolicyRecom />
          </div>
          <div class="item">
            <PolicyHotHandle />
          </div>
          <div class="item">
            <PolicyNew />
          </div>
        </div>
      </section>
    </main>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
  <router-view></router-view>
  </div>

</template>

<script>
import { getPolicyType } from "@/api/policy/policy.js";

import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import PolicyList from "./components/policy-list.vue";
import PolicyRecom from "./components/policy-recom.vue";
import PolicyHotHandle from "./components/policy-hot-handle.vue";
import PolicyNew from "./components/policy-new.vue";

export default {
  name: "Policy",
  components: { pageTop, Header, Footer, Breadcrumb, PolicyList,
    PolicyRecom,
    PolicyHotHandle,
    PolicyNew,
  },
  data() {
    return {
      displayPage: true,
      totalCount: "",
      showTotal: false,
      policyTypeList:[],
      categoryId:null,//类型id
    };
  },
  methods: {
    //点击政策类型事件
    clickPolicyType(item){
      this.categoryId = item.id;
    },

    //获取全部政策类型
    policyType(){
      getPolicyType().then(res =>{
        this.policyTypeList = res.data;
      })
    },
    //获取header组件的点击事件
    getTab(data) {
      if (data && typeof data == "string") {
        this.$router.push("/" + data);
      }
    },
  },

  created() {
    this.policyType();
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.path == "/" || this.$route.path == "/policy") {
          this.displayPage = true;
        } else {
          this.displayPage = false;
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },


};
</script>

<style lang="scss" scoped>
.match-invite-page {
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: #fff;

      .c-left {
        flex: 1;
        margin-right: 15px;

        box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

        .policyGuidBlock {
          background-color: #cddfff;
          border-radius: 16px;
          margin: 20px;

          .guid {
            padding: 24px;
            background: linear-gradient(0deg, #86acff 0%, #a5deff 100%);
            display: flex;
            align-items: center;
            border-radius: 16px;

            .total {
              position: relative;
              width: 160px;
              height: 160px;
              color: #fff;

              .load-animate {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 1;

                .floor-round {
                  position: absolute;
                  z-index: 5;
                  width: 60%;
                  height: 60%;
                  border-radius: 50%;
                  top: 50%;
                  left: 50%;
                  margin-top: -30%;
                  margin-left: -30%;
                  background-color: rgba(255, 255, 255, 0.25);
                }

                .floor {
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  z-index: 1;
                  width: 100%;
                  height: 100%;
                }

                .floor1 {
                  z-index: 1;
                  animation: rotateR 37s linear infinite;
                }

                .floor2 {
                  z-index: 2;
                  animation: rotateL 19s linear infinite;
                }

                .floor3 {
                  z-index: 3;
                  animation-name: rotateR, opa;
                  animation-duration: 47s, 7s;
                  animation-timing-function: linear, linear;
                  animation-iteration-count: infinite, infinite;
                }
              }

              .count {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 2;

                .txt {
                  display: flex;
                  align-items: baseline;
                  justify-content: center;
                  font-size: 24px;

                  .num {
                    font-size: 28px;
                  }

                  .unit {
                    margin-left: 4px;
                  }
                }
              }
            }
            .right-content {
              flex: 1;
              margin-left: 24px;
              display: flex;
              flex-direction: column;

              .title {
                font-weight: bold;
                font-size: 24px;
              }

              .desc {
                line-height: 1.5;
                font-size: 18px;
                color: #4a4a4a;
                margin-top: 0.5em;
              }
            }
          }
        }

        .condition-card {
          border-radius: 8px;
          border: 1px solid #e6eff4;
          box-shadow: -2px 2px 20px 0 #dbefffad;
          -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
          margin: 20px;
          padding: 20px;
          display: flex;
          flex-direction: column;

          .tip-wrap {
            display: flex;
            .tip-margin{
              margin: 3px;
            }
            .tip-title{
              width: 8%;
              text-align: right;
            }
            .tip-content{
              display: flex;
              width: 70%;
              flex-wrap: wrap;
              .type-color{
                color: #4390E7;
              }
              div{
                text-align: center;
                width: 80px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow:ellipsis;
              }
            }
          }
        }
      }
      .c-right {
        margin-top: 20px;
        margin-right: 20px;
        flex-shrink: 0;
        width: 320px;
        background-color: #fff;

        .item{
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
