<template>
 <div class="list">
  <div class="item hover-item" v-for="(item, index) in list" :key="index" @click="toDetail(item)">
    <img class="cover-image" mode="aspectFit" :src="item.schoolIcon" />
    <div class="content">
      <div class="title" v-if="type==1">{{ item.serviceStation }}</div>
      <div class="title" v-else-if="type==2">{{ item.liaisonName }}</div>
      <div class="desc">
        <span>{{ item.city }}</span>
        <span>站长：{{ item.contactManList }}</span>
      </div>
      <div class="count">
        <span>政策服务({{ item.policyNum }})</span>&nbsp;&nbsp;
        <span>赛事活动服务({{ item.competitionNum }})</span>
      </div>
      <div class="extend">
        <div class="name" v-if="type==1">
          <span class="s">{{ item.liaisonNum }}个站</span>
          <div class="t">{{ item.schoolName }}</div>
          <span class="icon iconfont icon-more-t"></span>
        </div>
        <div class="name" v-else-if="type==2">
          <div class="t">{{ item.serviceStation }}</div>
          <span class="icon iconfont icon-more-t"></span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    list: Array,
    type:{
      type:Number,
      default:1, //1服务站 2联络站
    },
  },
  data() {
    return {};
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        path: "/station/stationDetail/" + item.stationId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .item {
    width: calc(50% - 26px);
    display: flex;
    position: relative;
    padding: 15px 0 15px 15px;
    border: 1px solid rgba(229, 229, 229, 1);
    box-shadow: -4px 3px 15px rgba(173, 213, 255, 0.5);
    border-radius: 10px;
    margin-bottom: 26px;
    .cover-image {
      width: 110px;
      height: 110px;
      border-radius: 10px;
      overflow: hidden;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 20px;

      .title {
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .desc {
        color: #999;
        font-size: 14px;
        margin-top: 0.2em;

        span:nth-child(2)::before {
          content: "|";
          margin: 0 0.5em;
          font-size: 14px;
          color: #ddd;
        }
      }

      .count {
        color: #488dcc;
        font-size: 14px;
        margin-top: 0.2em;

        text:nth-child(2) {
          margin-left: 0.5em;
        }
      }

      .extend {
        font-size: 14px;
        flex: 1;
        display: flex;
        align-items: flex-end;
        line-height: 1;
        color: #454545;
        margin-top: 0.5em;

        .name {
          display: flex;
          align-items: center;
          background-color: #f5f5f5;
          height: 28px;
          width: 60%;
          padding: 16px 20px;
          border-radius: 20px;
          overflow: hidden;

          .s {
            color: #488dcc;
            margin-right: 0.2em;
          }

          .t {
            flex: 1;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  .item::after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    height: 1px;
    background-color: #f0f0f0;
    overflow: hidden;
  }

  .item:last-child::after {
    display: none;
  }
}
</style>