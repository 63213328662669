<template >
  <div>
    <div class="match-dynamic-page" v-if="displayArticleList">
      <!-- 顶部1 -->
      <page-top></page-top>
      <!-- 头部 -->
      <Header @chooseTab="getTab"></Header>
      <!-- 主体 -->
      <main class="main">
        <section class="breadcrumb-plate w">
          <div class="label">我的位置：</div>
          <breadcrumb />
        </section>
        <section class="content w" ref="container">
          <div class="c-left">
            <div class="list">
              <div
                class="item"
                v-for="(item, index) in articleList"
                :key="index"
                @click="articleDetailClick(item.id)"
              >
                <div class="item-wrap">
                  <div class="i-left">
                    <div class="i-left-top">
                      {{ item.releaseTime.split("-")[2] }}
                    </div>
                    <div class="i-left-center"></div>
                    <div class="i-left-bottom">
                      {{ item.releaseTime.split("-")[1] }}
                    </div>
                    <div class="i-left-lowest">
                      {{ item.releaseTime.split("-")[0] }}
                    </div>
                  </div>
                  <div class="i-center">
                    <h2 class="title">{{ item.articleName }}</h2>
                    <p class="desc" v-html="item.articleContent"></p>
                  </div>
                  <div class="i-right" v-if="item.cover">
                    <div class="img-wrap">
                      <img class="img" :src="item.cover" alt="图片" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="m-bot" v-if="articleList.length > 0">
              <el-pagination
                background
                layout="prev, pager, next"
                prev-text="上一页"
                next-text="下一页"
                :page-size="pageSize"
                :total="pageCount"
                @current-change="changePageClick"
              >
              </el-pagination>
            </div>
            <el-empty
              description="暂无资讯"
              v-if="articleList.length == 0"
              style="height: 770px"
            ></el-empty>
          </div>

        </section>
      </main>
      <!-- 底部 -->
      <Footer></Footer>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import { getArticlePage } from "@/api/article/index.js";
export default {
  components: { pageTop, Header, Footer, Breadcrumb },
  data() {
    return {
      breadcrumbList: [],
      articleList: [], //资讯列表
      pageSize: 10, //分页数量
      pageCount: 0, //赛事列表总数
      displayArticleList: true,
      articleArea:350600,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    //统一获取数据
    fetchData() {
      this.getArticlePage();
    },
    //获取header组件的点击事件
    getTab(data) {
      alert("12");
      if (data && typeof data == "string") {
        window.location.href = "/" + data;
        //  this.$router.push("/" + data);
      }
    },
    //获取赛事动态
    getArticlePage(page = 1) {
      getArticlePage({
        page: page,
        limit: this.pageSize,
        articleArea:this.articleArea
      }).then((res) => {
        if (!res.data) return;
        this.articleList = res.data.list;
        this.pageCount = res.data.count;
      });
    },
    //切换页面
    changePageClick(page) {
      this.getArticlePage(page);
    },
    //跳转动态详情
    articleDetailClick(id) {
      this.displayArticleList = false;
      this.$router.push({
        path: "/articleList/articleDetail/" + id,
      });
    },
  },
  mounted() {},
  watch: {
    //监听路由，处理嵌套路由问题
    $route: {
      handler() {
        if (this.$route.path == "/articleList") {
          this.displayArticleList = true;
        } else {
          this.displayArticleList = false;
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.match-dynamic-page {
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }

      .el-breadcrumb {
        cursor: pointer;

        /deep/ .el-breadcrumb__inner.is-link {
          font-weight: normal;
        }
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .c-left {
        flex: 1;
        margin-right: 15px;
        background-color: #fff;
        box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

        .list {
          
          .item {
            padding: 0 30px;
            background-color: #fff;
            cursor: pointer;

            .item-wrap {
              display: flex;
              align-items: center;
              padding: 30px 0;
              border-bottom: 1px dashed #e4e4e4;
            }

            .i-left {
              line-height: 1.35;
              flex-shrink: 0;
              margin-right: 20px;
              font-family: Tahoma;
              width: 80px;
              position: relative;

              .i-left-top {
                text-align: left;
                padding-left: 16px;
                font-size: 22px;
              }

              .i-left-center {
                position: absolute;
                border-width: 0;
                left: 12px;
                top: 32px;
                width: 50px;
                height: 1px;
                overflow: hidden;
                background-color: #666666;
                transform: rotate(315deg);
              }

              .i-left-bottom {
                text-align: right;
                font-size: 20px;
                padding-right: 18px;
              }

              .i-left-lowest {
                text-align: center;
                font-size: 14px;
                color: #888888;
                margin-top: 6px;
              }
            }

            .i-center {
              flex: 1;

              .title {
                color: #000000;
                padding: 0;
                margin-bottom: 12px;
                font-weight: normal;
                font-size: 24px;
              }

              .desc {
                color: #999;
                font-size: 15px;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
              }
            }

            .i-right {
              flex-shrink: 0;
              padding-left: 30px;

              .img-wrap {
                width: 125px;
                height: 100px;
              }
            }
          }

         

          .item:hover .i-center .title {
            color: #2D7CCE !important;
          }

          .item:hover .i-center .desc {
            color: #2D7CCE !important;
          }
        }

        .m-bot {
          margin-top: 24px;
          margin-bottom: 24px;

          .el-pagination {
            display: flex;
            justify-content: center;

            /deep/ .btn-prev {
              width: 100px;
              height: 40px;
              border: 1px solid #e4e4e4;
              background-color: #fff;
            }

            /deep/ .btn-prev:hover:enabled {
              color: #409eff;
            }

            /deep/ .number {
              width: 40px;
              height: 40px;
              line-height: 40px;
              border: 1px solid #e4e4e4;
              background-color: #fff;
            }

            /deep/ .more {
              width: 40px;
              height: 40px;
              line-height: 40px;
              border: 1px solid #e4e4e4;
              background-color: #fff;
            }

            /deep/ .btn-next {
              width: 100px;
              height: 40px;
              border: 1px solid #e4e4e4;
              background-color: #fff;
            }

            /deep/ .btn-next:hover:enabled {
              color: #409eff;
            }

            /deep/ .active {
              color: #fff;
              background-color: #409eff;
            }
          }
        }
      }

      .c-right {
        flex-shrink: 0;
        width: 320px;
        
        .recommend {
          margin-bottom: 15px;
          background-color: #fff;
          
          .head {
            padding: 10px 0;
            display: flex;
            align-items: stretch;
            justify-content: space-between;

            .title {
            position: relative;
            font-size: 18px;
            font-weight: 400;
            padding: 6px 0;
            margin-left: 20px;
            }

            .title::after {
              position: absolute;
              content: ' ';
              overflow: hidden;
              height: 3px;
              width: 2em;
              left: 0;
              bottom: 0;
              border-radius: 2px;
              background-color: #2D7CCE;
            }

            .more {
              display: flex;
              align-items: center;
              padding: 6px 20px;
              cursor: pointer;

              .icon {
                font-size: 14px;
              }
            }
            .more:hover {
              color: #2D7CCE;
            }
          }

        }

        .plate1 {

          .list {

            .item {
              font-size: 16px;
              padding: 16px 0;
              margin: 0 20px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #f0f0f0;

              .item-title {
                flex: 1;
                font-weight: normal;
              }

              .item-cover {
                width: 70px;
                height: 56px;
                margin-left: 15px;
                overflow: hidden;
                border-radius: 3px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .item:last-child {
              border: none;
            }

            .item:hover {
              color: #2D7CCE !important;
              cursor: pointer;
            }

            .item-recommend {
              display: block;
              padding-top: 10px;

              .item-cover {
                margin: 0;
                width: 280px;
                height: 224px;
                overflow: hidden;
                border-radius: 5px;
                margin-bottom: 10px;
              }
            }
          }
        }

        .plate2{
          .list{
            padding-bottom: 20px;

            .item {
              margin: 0 20px;
              padding: 8px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .circle{
                margin-right: 8px;
                width: 6px;
                height: 6px;
                background-color: #e5e5e5;
                border-radius: 50%;
              }
              .title {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .time {
                min-width: 3em;
                text-align: right;
                font-size: 12px;
                color: #aaa;
                margin-left: 10px;
              }
            }
            .item:hover {
              cursor: pointer;
              color: #2D7CCE;

              .circle {
                background-color: #2D7CCE;
              }
            }
          }
        }
      }
    }
  }
}
</style>
