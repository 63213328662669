<template>
  <div class="policy-block">
    <div class="policy-list" v-if="this.rePolicyData.length > 0">
      <div
        class="policy-item hover-item"
        v-for="(item, index) in rePolicyData"
        @click="toDetail(item.interpretateId)"
        :key="index"
      >
        <div class="p-header">
          <div class="title">{{ item.policyName }}</div>
        </div>
        <div class="label-list">
            <span class="is-light">{{ item.policyType }}</span>
            <span
              v-for="(subitem, index) in item.subTitleResults"
              :key="index"
              >{{ subitem.subTitle }}</span
            >
            <span class="is-light" v-if="item.handleType == 1">线上办理</span>
            <span class="is-light" v-if="item.handleType == 2">线下办理</span>
            <span class="is-light" v-if="item.handleType == 3"
              >线上办理、线下办理</span
            >
        </div>
        <div class="policy-bottom">
          <div class="p-left">
            <div class="icon iconfont icon-riqi">
              <span>时间：{{item.effectiveTimeRange}}</span>
            </div>
          </div>

          <div class="p-right">
            <div class="icon iconfont icon-renshu">
              <span>{{item.handleQuantity}}人想办</span>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <el-empty  v-else description="暂无政策信息"></el-empty>
     <div class="m-bot" v-if="rePolicyData.length > 0">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-size="limit"
          :total="total"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>

  </div>
</template>

<script>
import { getPolicyPage,getPolicyRecDegreePage } from "@/api/policy/policy.js";
export default {
  props: {
    categoryId:null,
  },
  data() {
    return {
      rePolicyData:[],
      total:0,
      currentPage:1,
      limit:10
    };
  },
  methods: {
    //更多政策
    toMore() {
      this.$router.push("/policy");
    },
    //详情
    toDetail(id) {
      this.$router.push({ path: "/policy/policyDetail/" + id });
    },
    //获取政策列表
    getPlicyData() {
      const params = {
        page: this.currentPage,
        limit: this.limit,
        categoryId:this.categoryId,
        policyArea: 350200,
      };
      getPolicyPage(params).then((res) => {
        if (!res.data.policyList) return;
        this.rePolicyData = res.data.policyList;
        this.total = res.data.total;
      });
    },
    // 获取政策推荐列表
    policyRecDegreePage(){
      const params = {
        page: this.currentPage,
        limit: this.limit,
        categoryId:this.categoryId,
        policyArea: 350200,
      };
      getPolicyRecDegreePage(params).then(res =>{
        if (!res.data.policyList) return;
        this.rePolicyData = res.data.policyList;
        this.total = res.data.total;
      })
    },
    currentChange(e){
      this.currentPage = e;
      this.getPlicyData();
    },
  },
  created(){
    this.getPlicyData();
  },
  watch:{
    categoryId:{
      handler(){
        this.getPlicyData();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.policy-block {
  flex: 1;
  padding: 0 20px;
  min-height: 700px;
  height: auto;
  .policy-list {
    flex: 1;
    line-height: 1.5;
    .policy-item {
      padding: 10px 20px;
      background-color: #fff;
      border-bottom: 1px solid #f7f7f7;
    //   box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
      .p-header {
        .title {
          margin-bottom: 12px;
          font-size: 16px;
          font-weight: bold;
          color: #000;
          line-height: 1.35;
        }
      }
      .label-list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        min-height: 30px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 10px;
          border-radius: 22px;
          font-size: 12px;
          color: #666;

          background-color: #eaeaea;
        }
        .is-light {
          color: #f7932e;
          background-color: #fee3b0;
        }
      }
    }
    .policy-item:nth-child(3) {
        border:none
    }

    .policy-bottom{
      margin-top: 10px;
      display: flex;
      .p-left{
        flex: 1;
        div{
          span{
            padding-left: 5px;
          }
        }
      }

      .p-right{
        flex: 1;
        text-align: right;
        color: #4390E7;
        div{
          span{
           
            padding-left: 5px;
          }
        }
      }
    }
  }

  .m-bot {
    margin-top: 24px;
    margin-bottom: 24px;

    .el-pagination {
      display: flex;
      justify-content: center;

      /deep/ .btn-prev {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-prev:hover:enabled {
        color: #409eff;
      }

      /deep/ .number {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .more {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-next {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-next:hover:enabled {
        color: #409eff;
      }

      /deep/ .active {
        color: #fff;
        background-color: #409eff;
      }
    }
  }
}
</style>