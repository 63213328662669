import http from "@/utils/http.js"


export function getScoolList(data) {
    return http({
        url: "/api/system/school/getScoolList",
        method: "POST",
        data: data,
    })
}


export function getAcademyList(id) {
    return http({
        url: "/api/miniprogram/campus/getAcademyList/" + id,
        method: "GET",
    })
}

export function getMajorList(id) {
    return http({
        url: "/api/miniprogram/campus/getMajorList/" + id,
        method: "GET",
    })
}
