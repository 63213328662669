<template>
  <div class="recommend plate1">
    <div class="head">
    <h3 class="title">推荐赛事</h3>
    <div class="more">更多<span class="icon iconfont icon-more"></span></div>
    </div>
    <div class="item item-recommend">
        <div class="item-cover">
        <img class="img" :src="competition.image" alt="" />
        </div>
        <div class="item-title">{{competition.title}}</div>
    </div>
</div>
</template>

<script>
import { getRecommend } from "@/api/match/match.js";

export default {
  props: {
    
  },
  components: {

  },
  data() {
    return {
      list:[],
      competition:{
        title:"",
        image:""
      },
    };
  },
  
  methods: {
    getRecommend(){
      getRecommend({limit:1}).then(res =>{
        this.list = res.data;
        if(this.list){
          this.competition = this.list[0];
        }
      })
    }
  },
  created() {
    this.getRecommend();
  },
};
</script>

<style lang="scss" scoped>

    .recommend {
    background-color: #fff;
    
    .head {
        padding: 10px 0;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .title {
        position: relative;
        font-size: 18px;
        font-weight: 400;
        padding: 6px 0;
        margin-left: 20px;
        }

        .title::after {
        position: absolute;
        content: ' ';
        overflow: hidden;
        height: 3px;
        width: 2em;
        left: 0;
        bottom: 0;
        border-radius: 2px;
        background-color: #2D7CCE;
        }

        .more {
        display: flex;
        align-items: center;
        padding: 6px 20px;
        cursor: pointer;

        .icon {
            font-size: 14px;
        }
        }
        .more:hover {
        color: #2D7CCE;
        }
    }

    }

    .plate1 {
            .item {
              font-size: 16px;
              padding: 16px 0;
              margin: 0 20px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #f0f0f0;

              .item-title {
                flex: 1;
                font-weight: normal;
              }

              .item-cover {
                width: 70px;
                height: 56px;
                margin-left: 15px;
                overflow: hidden;
                border-radius: 3px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .item:last-child {
              border: none;
            }

            .item:hover {
              color: #2D7CCE !important;
              cursor: pointer;
            }

            .item-recommend {
              display: block;
              padding-top: 10px;

              .item-cover {
                margin: 0;
                width: 280px;
                height: 224px;
                overflow: hidden;
                border-radius: 5px;
                margin-bottom: 10px;
              }
            }
        }

</style>
