<template>
  <div class="recommend">
    <div class="head">
      <h3 class="title">推荐服务站</h3>
      <!-- <div class="more" @click="more">更多<span class="icon iconfont icon-more"></span></div> -->
    </div>
    <div class="item" v-for="(item, index) in serviceStationList" :key="index" @click="handleClick(item.stationId)">
      <div class="img">
        <img :src="item.schoolIcon" />
      </div>
      <div class="item-title hover-effect">{{ item.liaisonName }}</div>
      <div class="item-view">
        <span class="icon iconfont icon-browse"></span>{{ item.viewNum }}
      </div>
    </div>
    <div class="more" @click="toMore">查看更多<span class="icon iconfont icon-more"></span></div>
    <!-- 增高 -->
    <div class="shoe-lift"></div>
  </div>
</template>

<script>
import { getServiceStationList } from "@/api/station/station.js";
export default {
  props: {},
  components: {},
  data() {
    return {
      serviceStationList: [],
    };
  },

  methods: {
    getServiceStationList() {
      let params = {
        page: 1,
        limit: 6,
      };
      getServiceStationList(params).then((res) => {
        this.serviceStationList = res.data.stationList;
      });
    },
    handleClick(id) {
       this.$router.push({
        path: "/station/stationDetail/" + id,
      });
    },
    toMore() {
      this.$router.push( "/station");
    }
  },
  created() {
    this.getServiceStationList();
  },
};
</script>

<style lang="scss" scoped>
.recommend {
  width: 369px;
  background-color: #fff;
  box-shadow: -2px 2px 20px 0 #dbefffad;
  -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
  .head {
    padding: 10px 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .title {
      position: relative;
      font-size: 18px;
      font-weight: 400;
      padding: 6px 0;
      margin-left: 20px;
    }

    .title::after {
      position: absolute;
      content: " ";
      overflow: hidden;
      height: 3px;
      width: 2em;
      left: 0;
      bottom: 0;
      border-radius: 2px;
      background-color: #2d7cce;
    }
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 20px;
    font-size: 14px;
    .img {
      height: 24px;
      width: 24px;
      margin-right: 10px;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .item-title {
      flex: 1;
      margin-right: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .item-view {
      color: #666;
      font-size: 12px;
      min-width:50px;
      .icon {
        margin-right: 0.35em;
      }
    }
  }

  .more {
    text-align: center;
    // display: flex;
    // align-items: center;
    padding: 6px 20px;
    cursor: pointer;
    color: #2d7cce;

    .icon {
      font-size: 12px;
    }
  }
  // .more:hover {
  //   color: #2d7cce;
  // }
  .shoe-lift {
    height: 20px;
  }
}
</style>
