<template>
  <div class="plate-wrap">
    <div class="progress-plate">
      <div class="content-wrap">
        <div class="item" v-for="(item, index) in nodeDates" :key="index">
          <div class="circle"></div>
          <div class="item-content">
            <div class="title">
              {{ item.nodeName || "" }}
            </div>
            <div class="sub-title">
              <span class="text">{{ item.reviewStatus || "" }}</span>
              <span class="text">{{ item.redivItem || "" }}</span>
              <span class="text">{{ item.comment || "" }}</span>
            </div>
          </div>
        </div>
      </div>
        <div class="more hover-text" @click="toProgress('down')" v-if="isShowAll">
          <span class="text">查看全部</span>
          <span class="icon iconfont icon-toggle-down"></span>
        </div>
        <div class="more hover-text"  @click="toProgress('up')" v-else>
          <span class="text">收起</span>
          <span class="icon iconfont icon-toggle-up"></span>
        </div>
    </div>
    <div class="detail-plate">
      <div class="policy-info plate-common">
        <div class="title">
          <div class="title_1">项目信息</div>
          <div class="detail-btn hover-text" @click="toDetail(data.projectDate.competitionId)">
            <span class="text">查看详情</span>
            <span class="icon iconfont icon-more"></span>
          </div>
        </div>
        <div class="content" @click="toDetail(data.projectDate.competitionId)">
          <div class="item">
            <div class="item-left">
              <div class="item-cover">
                <img class="img" :src="data.projectDate?.image" alt="" />
              </div>
            </div>
            <div class="item-right">
              <span class="text">{{
                data.projectDate?.competitionTitle || ""
              }}</span>
              <span class="text"
                >截至{{ data.projectDate?.competitionTime || "" }}</span
              >
              <span class="text">{{ data.projectDate?.school || "" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="conduct-info plate-common">
        <div class="title">提交信息</div>
        <div class="content">
          <div class="item">
            <div class="item-left">
              <span class="text">项目主题：</span>
            </div>
            <span class="item-right">
              {{ data.projectDate?.projectTitle || "" }}
            </span>
          </div>
          <div class="item" v-if="data.projectDate?.file">
            <div class="item-left">
              <span class="text">项目计划书：</span>
            </div>
            <div class="item-right">
              <div class="item-file">
                <div class="material-name">
                  <span>{{ data.projectDate?.file.originalName || "" }}</span>
                </div>
                <div class="look" @click="lookFile">
                  <span>查看</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item" v-if="data.projectDate?.field">
            <div class="item-left">
              <span class="text">所属行业：</span>
            </div>
            <span class="item-right">
              {{ data.field || "" }}
            </span>
          </div>
          <div class="item" v-if="data.projectDate?.projectMember">
            <div class="item-left">
              <span class="text">项目成员：</span>
            </div>
            <span class="item-right">
              {{ data.projectDate.projectMember || "" }}
            </span>
          </div>
          
        </div>
        <div class="title" style="margin-top:20px">基本信息</div>
      <div class="content">
        <div class="item">
          <span class="item-left">记录编号：</span>
          <span class="item-right">{{ data.basicDate?.applyNo || "" }}</span>
        </div>
        <div class="item">
          <span class="item-left">提交时间：</span>
          <span class="item-right">{{ data.basicDate?.submitTime || "" }}</span>
        </div>
      </div>
      <div class="item-control">
        <div
            v-if="data.isCancelApplyBtn"
            class="btn cancle-btn"
            @click="toCancle(data.applyId)"
          >
            取消报名
          </div>
          <div
            class="btn delete-btn"
            v-if="data.isUpdateBtn"
            @click.stop="handleModify(data.applyId)"
          >
            修改
          </div>
      </div>
      </div>
    </div>

    <!-- <div class="info-plate plate-common">
      <div class="title">基本信息</div>
      <div class="content">
        <div class="item">
          <span class="item-left">记录编号：</span>
          <span class="item-right">{{ data.basicDate.applyNo || "" }}</span>
        </div>
        <div class="item">
          <span class="item-left">提交时间：</span>
          <span class="item-right">{{ data.basicDate.submitTime || "" }}</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getApplyDetail,getRecodeProgress,cancelApply,deleteApply } from "@/api/personal";
import * as util from "@/utils/util";
export default {
  data() {
    return {
      recordId: "",
      data: {},
      nodeDates:[],
      isShowAll:true
    };
  },
  created() {
    let id = this.$route.params.id;

    this.recordId = this.$route.params.id;
    this.fetchData(id);
  },
  methods: {
    fetchData(id) {
      getApplyDetail(id).then((res) => {
        if (!res.data) return;
        this.data = res.data;
        this.nodeDates = res.data.nodeDates
      });
    },
    toProgress(e) {
      this.isShowAll= !this.isShowAll
      if(e == 'down') {
        getRecodeProgress(this.data.applyId).then((res) => {
        if (!res.data) return;
        this.nodeDates = res.data
      });
      }else if(e == 'up') {
         this.nodeDates=this.nodeDates.slice(0,2)
      }
      
    },
    toCancle(applyId) {
      const that = this;
      that.$confirm("是否确定取消报名", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then((res) => {
          if (!res) return;
          cancelApply(applyId).then((res) => {
            if (res.data) {
              setTimeout(() => {
                that.fetchData();
                that.$message({
                  message: res.message,
                  type: "success",
                });
              }, 1000);
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleModify(applyId) {
       const that = this;
      that.$confirm("是否确定删除报名", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then((res) => {
          if (!res) return;
          deleteApply(applyId).then((res) => {
            if (res.data) {
              setTimeout(() => {
                that.fetchData();
                that.$message({
                  message: res.message,
                  type: "success",
                });
              }, 1000);
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    toDetail(id) {
         window.location.href = "/match/matchDetail/" + id;
    },
    lookFile() {
      console.log("this.data.file.path", this.data.file.path);
      util.previewFile(this.data.file.path);
    },
  },
};
</script>

<style lang="scss" scoped>
.plate-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;

  .progress-plate {
    padding: 32px 20px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
    .content-wrap {
      .item::before {
        content: "";
        position: absolute;
        top: 20px;
        left: 0px;
        height: 100%;
        width: 2px;
        background-color: #f2f2f2;
      }
      .item:first-child .circle {
        position: absolute;
        top: 20px;
        left: 2px;
        transform: translate(-50%, -50%);
        flex-shrink: 0;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: #4faaf7;
      }
      .item:first-child .item-content {
        .title {
          font-size: 28px !important;
          color: #4faaf7 !important;
        }
        .sub-title {
          color: #4faaf7 !important;
        }
      }
      .item:last-child::before {
        content: "";
        position: absolute;
        top: 24px;
        left: 0px;
        height: 0;
        width: 2px;
        background-color: #f2f2f2;
      }
      .item:last-child {
        padding-bottom: 0 !important;
      }
      .item {
        position: relative;
        display: flex;
        padding: 0 20px;
        padding-left: 20px;
        padding-bottom: 20px;
        .circle {
          position: absolute;
          top: 16px;
          left: 2px;
          transform: translate(-50%, -50%);
          flex-shrink: 0;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: #f2f2f2;
        }
        .item-content {
          .title {
            font-size: 20px;
            font-weight: bold;
            color: #666;
          }
          .sub-title {
            display: flex;
            flex-direction: column;
            margin-top: 16px;
            white-space: pre-wrap;
            font-size: 18px;
            color: #999;
            // .text {
            //   margin-bottom: 12px;
            // }
          }
        }
      }
    }
    .more {
      display: flex;
      justify-content: center;
      align-items: center;
        color: #999;

      .text {
        font-size: 17px;
      }
      .icon {
        margin-left: 0.5em;
        font-size: 14px;
      }
    }
  }

  .blue-bgc {
    background-color: #2f72ae !important;
  }

  .orange-bgc {
    background-color: #ff6600 !important;
  }

  .green-bgc {
    background-color: #339933 !important;
  }

  .gray-bgc {
    background-color: #c9c9c9 !important;
  }

  .blue-color {
    color: #2f72ae !important;
  }

  .orange-color {
    color: #ff6600 !important;
  }

  .green-color {
    color: #339933 !important;
  }

  .gray-color {
    color: #c9c9c9 !important;
  }

  .plate-common {
    padding: 32px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      font-size: 18px;
      font-weight: bold;
      color: #1d1e2c;

      .detail-btn {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #999;

        .text {
          font-size: 16px;
        }

        .icon {
          font-size: 14px;
        }
      }
    }

    .content {
      .item {
        display: flex;
        margin-top: 16px;
        font-size: 16px;
        color: #666;

        .item-left {
          display: flex;
          flex-shrink: 0;
          .item-cover {
            height: 94px;
            margin-right: 20px;

            img {
              width: 116px;
              height: 94px;
              border-radius: 6px;
            }
          }

          .text {
            flex: 1;
          }
        }

        .item-right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .text {
            // margin-bottom: 6px;
            line-height: 1.35;
          }
          span:nth-child(1) {
            padding-top: 4px;
            height: 28px;
            font-size: 18px;
            color: #666;
            font-weight: bold;
          }
          .material-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            padding: 24px;
            background-color: #f7f7f7;
            border-radius: 16px;

            .look {
              color: #2f72ae;
            }
          }
        }

        .item-file {
          display: flex;
          justify-content: space-between;
          color: #3a81c3;

          .material-name {
            display: -webkit-box;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .look {
            cursor: pointer;
            flex-shrink: 0;
          }
        }
      }

      .tips {
        display: flex;

        .item-left {
          flex-shrink: 0;
        }

        .item-right {
          flex: 1;
        }
      }
    }
  }

  .info-plate {
    margin-top: 16px;
    // border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  }

  .detail-plate {
    position: relative;
    margin-top: 20px;
    // border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);

    .conduct-info {
      padding-top: 0;
      .conduct-material {
        .item-left {
          padding-top: 20px;
        }
      }
    }
    .item-control {
      // position: absolute;
      bottom: 32px;
      right: 32px;
      display: flex;
      flex-direction: row-reverse;
      .btn {
        cursor: pointer;
        padding: 8px 24px;
        border-radius: 20px;
        text-align: center;
        margin-left: 16px;
        border: solid 1px transparent;
      }
      .cancle-btn {
        color: #ff6600;
        border: #ff6600 solid 1px;
      }
      .reapply-btn {
        color: #3892f8;
        border-color: #3892f8;
      }
      .delete-btn {
        color: #989898;
        border: #989898 solid 1px;
      }
      .detail-btn {
        border: #888 solid 1px;
      }
    }
  }

  .no-more {
    margin-top: 80px;
    margin-bottom: 160px;
    font-size: 16px;
    color: #999;
    text-align: center;
  }
}
</style>