<template>
  <div class="add-member-page page-common">
    <div class="add-member-container">
      <div class="add-member-wrap">
        <div class="top">
          已邀请{{
            memberData1.projectMembers.length || 0
          }}名成员加入，至少邀请{{ memberData1.minMemberNum || 0 }}人
        </div>
        <div class="list">
          <div
            class="item"
            v-for="(item, index) in memberData1.projectMembers"
            :key="index"
          >
            <div class="left">
              <div class="image-wrap">
                <img class="image" :src="item.avatar" />
              </div>
            </div>
            <div class="center">
              <div class="c_left">
                <div class="name">
                  {{ item.name }}
                </div>
                <div class="_name">
                  {{ item.nickname }}
                </div>
              </div>
              <div class="c_right">
                <div class="time">
                  {{ item.joinTime }}
                </div>
              </div>
            </div>
            <div class="right" @click="del(item)">
              <div class="icon-wrap">
                <span class="icon iconfont icon-close"></span>
              </div>
            </div>
          </div>
        </div>
        <div class="add-btn">
          <button class="btn" @click="invite">+ 点击邀请成员</button>
        </div>
        <div class="tips">转发的邀请24小时有效，过期需重新邀请</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMatchDetail,
  getMatchProjectMember,
  invitedMatchProjectMember,
  removeMatchProjectMember,
} from "@/api/match/match.js";
import { local } from "@/utils/storage.js";

export default {
  props: {
    memberData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    addmemberRules: {
      type: Object,
    },
    isUpData: {
      type: Boolean,
    },
    matchId: String,
    matchName: String,
    categoryId: String,
  },
  data() {
    return {
      studyDetailData: null, //课程数据
      matchDetailData: null, //赛事数据
      studyInfo: "", //课程进入
      matchInfo: "", //赛事进入
      studyImage: "",
      schoolName: "",
      studyTitle: "",
      invitePerson: "",
      inviteParam: {},

      inviteId: "", //邀请id
      inviteUrl: "",
      resultData: [], //返回的成员数据

      isShare: false,
      memberData1: {},
    };
  },
  created() {
    this.memberData1 = this.memberData;
    // this.fetchMatchData()
    // this.fetchData()
  },
  watch: {
    memberData: {
      handler() {
        this.memberData1 = this.memberData;
      },
      deep: true,
    },
  },
  methods: {
    //获取邀请成员信息
    fetchData() {
      getMatchProjectMember({
        competitionId: this.matchId,
        categoryId: this.categoryId,
      }).then((res) => {
        console.log(res);
        this.memberData1 = res.data;
      });
    },
    //获取赛事/课程信息
    fetchMatchData() {
      if (this.matchInfo) {
        getMatchDetail({ id: this.matchId }).then((res) => {
          console.log(res);
          this.matchDetailData = res.data.data;
        });
      }

      this.invitePerson = local.Get("userInfo")?.nickname || "11";
      this.inviteParam = {
        studyId: this.studyId,
        studyImage: this.studyImage,
        schoolName: this.schoolName,
        studyTitle: this.studyTitle,
        invitePerson: this.invitePerson,
      };
    },
    add() {
      if (this.studyInfo) {
        // invitedProjectMember({ studyId: this.studyId }).then(res => {
        //     console.log(res);
        //     this.inviteId = res.data
        //     console.log(this.$refs.share);
        // })
      }
      if (this.matchInfo) {
        console.log({
          competitionId: this.matchId,
          categoryId: this.categoryId,
        });
        invitedMatchProjectMember({
          competitionId: this.matchId,
          categoryId: this.categoryId,
        }).then((res) => {
          console.log(res);
          this.inviteId = res.data;
          console.log(this.$refs.share);
        });
      }
    },
    del(item) {
      const that = this;
      this.$confirm("是否确定删除", "提示", {
        cancelText: "取消",
        confirmText: "确定",
      }).then((res) => {
        if (res) {
          removeMatchProjectMember({
            competitionId: this.matchId,
            categoryId: this.categoryId,
            delCustomId: item.customId,
          }).then((res) => {
            that.fetchData();
            if (res.code == 200) {
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    invite() {
      invitedMatchProjectMember({
        competitionId: this.matchId,
        categoryId: this.categoryId,
      }).then((res) => {
        this.inviteId = res.data;
        this.inviteUrl =
          this.matchName +
          "\n" +
          location.origin +
          "/inviteMember?inviteId=" +
          res.data+ "&type=match";

        var textarea = document.createElement("textarea");
        textarea.value = this.inviteUrl;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        document.body.removeChild(textarea);
        this.$message({
          message: "链接已复制",
          type: "success",
        });
      });
    },
    toShare() {
      console.log("分享");
    },
  },
};
</script>

<style lang="scss" scoped>
.add-member-page {
  height: 100%;
  padding-bottom: 40px;
  background-color: #fff;

  .add-member-container {
    height: 100%;
    background-color: #fff;

    .add-member-wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 32px;

      .top {
        padding: 20px 0;
        font-size: 16px;
        color: #666;
      }

      .list {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 225px;
        margin-top: 12px;

        .item:last-child {
          margin-bottom: 0;
        }

        .item {
          display: flex;
          align-items: stretch;
          padding: 12px 16px;
          margin-bottom: 12px;
          background-color: #f2f2f2;
          border-radius: 12px;

          .left {
            flex-shrink: 0;
            margin-right: 12px;

            .image-wrap {
              width: 50px;
              height: 50px;
              border-radius: 50%;

              .image {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }

          .center {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .c_left {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .name {
                font-size: 16px;
                color: #333;
              }

              ._name {
                margin-top: 8px;
                font-size: 14px;
                color: #666;
              }
            }

            .c_right {
              flex-shrink: 0;
              margin-left: 10px;
              font-size: 14px;
              color: #666;
            }
          }

          .right {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            margin-left: 16px;

            .icon-wrap {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: red;

              .icon {
                font-size: 12px;
                color: #fff;
                cursor: pointer;
              }
            }
          }
        }

        .nodata {
          padding: 32px;
          font-size: 16px;
          color: #999;
        }
      }

      .add-btn {
        // position: fixed;
        bottom: 100px;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;

        .btn {
          width: 50%;
          margin: 0 auto;
          padding: 20px 0;
          margin-top: 24px;
          text-align: center;
          font-size: 28px;
          color: #199ed8;
          border: 1px solid #199ed8;
          border-radius: 12px;
        }
      }

      .tips {
        // position: fixed;
        bottom: 50px;
        left: 0;
        width: 100%;
        font-size: 20px;
        color: #999;
        text-align: center;
      }
    }
  }
}
</style>