<template>
  <div class="c-left-content">
    <div class="project-choose">
      <div class="form-wrap" v-for="(item, index) in formData" :key="index">
        <!-- input -->
        <div class="item-wrap" v-if="item.type == 'input'">
          <div class="item-title">
            <span class="item-name" :class="{ ban: !item.rule.isUpdate }">{{
              item.title
            }}</span>
            <span style="color: red; margin-right: 5px" v-if="item.isNeed != 0"
              >*</span
            >
          </div>
          <div class="item-content">
            <div class="item-content-left">
              <div class="input-wrap">
                <input
                  class="input"
                  :class="{ ban: !item.rule.isUpdate }"
                  :placeholder="item.placeholder"
                  v-model="item.remark"
                  :disabled="!item.rule.isUpdate"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- textarea -->
        <div class="item-wrap" v-if="item.type == 'textarea'">
          <div class="item-title">
            <span class="item-name" :class="{ ban: !item.rule.isUpdate }">{{
              item.title
            }}</span>
            <span style="color: red; margin-right: 5px" v-if="item.isNeed != 0"
              >*</span
            >
          </div>
          <div class="item-content textarea-content">
            <div class="item-content-left">
              <textarea
                class="textarea"
                :class="{ ban: !item.rule.isUpdate }"
                v-model="item.remark"
                maxlength="-1"
                :disabled="!item.rule.isUpdate"
                resize="none"
                :placeholder="item.placeholder"
              ></textarea>
            </div>
          </div>
        </div>

        <!-- select -->
        <div class="item-wrap" v-if="item.type == 'select'">
          <div class="item-title">
            <span class="item-name" :class="{ ban: !item.rule.isUpdate }">{{
              item.title
            }}</span>
            <span style="color: red; margin-right: 5px" v-if="item.isNeed != 0"
              >*</span
            >
          </div>
          <div class="item-content">
            <el-select
              v-model="item.remark"
              :placeholder="item.placeholder"
              style="width: 240px"
              :disabled="!item.rule.isUpdate"
            >
              <el-option
                v-for="subitem in getSelectValue(item.defaultValue)"
                :key="subitem.value"
                :label="subitem.label"
                :value="subitem.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- datetimePicker -->
        <div class="item-wrap" v-if="item.type == 'datePicker'">
          <div class="item-title">
            <span class="item-name" :class="{ ban: !item.rule.isUpdate }">{{
              item.title
            }}</span>
            <span style="color: red; margin-right: 5px" v-if="item.isNeed != 0"
              >*</span
            >
          </div>
          <div class="item-content">
            <el-date-picker
              v-model="item.remark"
              type="date"
              placeholder="选择日期"
              style="width: 240px"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- areaSelect籍贯 -->
        <div class="item-wrap" v-if="item.type == 'areaSelect'">
          <div class="item-title">
            <span class="item-name" :class="{ ban: !item.rule.isUpdate }">{{
              item.title
            }}</span>
            <span style="color: red; margin-right: 5px" v-if="item.isNeed != 0"
              >*</span
            >
          </div>
          <div class="item-content">
            <div class="area-content">
              <div class="area-item">
                <el-select
                  v-model="provinceCode"
                  @change="provinceChange(index)"
                  placeholder="请选择省"
                >
                  <el-option
                    v-for="item in provinceOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="area-item">
                <el-select
                  v-model="cityCode"
                  @change="cityChange(index)"
                  placeholder="请选择市"
                >
                  <el-option
                    v-for="item in cityOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="area-item">
                <el-select
                  v-model="districtCode"
                  @change="districtChange(index)"
                  placeholder="请选择区"
                >
                  <el-option
                    v-for="item in districtOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="item-wrap" v-if="item.type == 'areaSelect'">
                    <div class="item-title">
                        <span class="item-name" :class="{ 'ban': !item.rule.isUpdate }">{{ item.title
                        }}</span>
                        <span style="color: red; margin-right:5px;" v-if="item.isNeed != 0">*</span>
                    </div>
                    <div class="item-content">
                        <el-cascader v-model="item.remark" :options="areaList" style="width:240px">
                        </el-cascader>
                    </div>
                </div> -->

        <!-- radio -->
        <div class="item-wrap" v-if="item.type == 'radio'">
          <div class="item-title">
            <span class="item-name" :class="{ ban: !item.rule.isUpdate }">{{
              item.title
            }}</span>
            <span style="color: red; margin-right: 5px" v-if="item.isNeed != 0"
              >*</span
            >
          </div>
          <div
            class="tips"
            v-if="item.placeholder"
            style="
              font-size: 12px;
              color: #999;
              margin-bottom: 10px;
              line-height: 1.35;
            "
          >
            {{ item.placeholder }}
          </div>
          <div class="item-content">
            <div class="radio-content">
              <el-radio
                v-model="item.remark"
                :label="subitem.label"
                @input="changeRadio(item)"
                v-for="(subitem, subindex) in getRadioList(item.defaultValue)"
                :key="subindex"
                style="margin-bottom: 4px"
                >{{ subitem.value }}</el-radio
              >

              <input
                type="span"
                v-model="item.value"
                v-if="item.switchYn"
                :placeholder="item.placeholder"
                style="padding: 8px 0"
              />
            </div>
          </div>
        </div>

        <!-- checkbox -->
        <div class="item-wrap" v-if="item.type == 'checkbox'">
          <div class="item-title">
            <span class="item-name" :class="{ ban: !item.rule.isUpdate }">{{
              item.title
            }}</span>
            <span style="color: red; margin-right: 5px" v-if="item.isNeed != 0"
              >*</span
            >
          </div>
          <div class="item-content">
            <div class="checkbox-content">
              <el-checkbox-group
                v-model="item.remark"
                @change="changeCheckbox(item)"
              >
                <el-checkbox
                  v-for="(subitem, subindex) in getSelectValue(
                    item.defaultValue
                  )"
                  :label="subitem.label"
                  :key="subindex"
                  >{{ subitem.value }}</el-checkbox
                >
              </el-checkbox-group>
              <input
                type="span"
                v-model="item.value"
                v-if="item.switchYn"
                :placeholder="item.placeholder"
              />
            </div>
          </div>
        </div>

        <!-- image -->
        <div class="item-wrap" v-if="item.type == 'image'">
          <div class="item-title">
            <span class="item-name" :class="{ ban: !item.rule.isUpdate }">{{
              item.title
            }}</span>
            <span style="color: red; margin-right: 5px" v-if="item.isNeed != 0"
              >*</span
            >
          </div>
          <div class="subtitle">
            <span style="color: #999; font-size: 12px; overflow: hidden">{{
              item.rule.placeholder || ""
            }}</span>
          </div>
          <div class="item-content" @click="changeImgIndex(index)">
            <el-upload
              :action="fileUploadUrl"
              :data="fileUploadParam"
              list-type="picture-card"
              :limit="1"
              :before-upload="beforeImgUpload"
              :on-success="handleImgUploadSuccess"
              :on-preview="handleImgPreview"
              :on-remove="handleImgRemove"
              :file-list="item.remark ? item.remark : []"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </div>
        </div>

        <!-- file -->
        <div class="item-wrap" v-if="item.type == 'file'">
          <div class="item-title">
            <span class="item-name" :class="{ ban: !item.rule.isUpdate }">{{
              item.title
            }}</span>
            <span style="color: red; margin-right: 5px" v-if="item.isNeed != 0"
              >*</span
            >
          </div>
          <div class="subtitle">
            <span style="color: #999; font-size: 12px; overflow: hidden">{{
              item.rule.placeholder || ""
            }}</span>
          </div>
          <div class="item-content" @click="changeFileIndex(index)">
            <el-upload
              class="upload-demo"
              :action="fileUploadUrl"
              :data="fileUploadParam"
              :before-upload="beforeFileUpload"
              :on-preview="handleFilePreview"
              :on-remove="handleFileRemove"
              :on-success="handleFileUploadSuccess"
              :on-error="handleFileUploadError"
              :before-remove="beforeFileRemove"
              multiple
              :limit="1"
              :on-exceed="handleFileExceed"
              :file-list="item.value ? item.value : []"
              :disabled="item.disabled"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </div>
        </div>
      </div>
    </div>

    <div class="foot">
      <div class="btn-wrap">
        <div class="btn" @click="submit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
import { getqiniuToken } from "@/api/common/common";
import { getProvinceList, getConfigAreaList } from "@/api/area";

export default {
  props: {
    formData: {
      type: Array,
    },
    index: {
      type: Number,
    },
    isOpen: {
      type: Number,
    },
  },
  watch: {
    isOpen: {
      handler() {
        getProvinceList().then((res) => {
          this.provinceOptions = res.data;
          this.judgeFetchData();
        });
      },
      deep: true,
    },
  },
  data() {
    return {
      //common
      form: {},
      result: false,
      ///areaSelect 籍贯
      provinceOptions: [],
      provinceCode: null,
      province: "",
      cityOptions: [],
      cityCode: null,
      city: "",
      districtOptions: [],
      districtCode: null,
      district: "",
      areaList: regionData, //城市列表

      //json导师
      teacherList: [], //导师列表
      teacherArrayIndex: null, //导师的index

      //image
      imgIndex: 0, //图片类型索引
      dialogVisible: false, //是否预览
      dialogImageUrl: "", //预览图片地址

      //file
      fileIndex: 0, //文件类型索引
      fileList: [], //文件列表
      fileUploadUrl: "https://up-z2.qiniup.com", //上传文件地址
      fileUploadParam: {},
    };
  },
  mounted() {
    getProvinceList().then((res) => {
      this.provinceOptions = res.data;
      this.judgeFetchData();
    });
  },
  methods: {
    //表单数据
    judgeFetchData() {
      if (this.index == null) {
        this.formData.forEach((item, index) => {
          if (item.type == "input") {
            if (typeof this.index == "number") return;
            this.formData[index].remark = "";
          }
          if (item.type == "areaSelect") {
            this.provinceCode = "";
            this.cityCode = "";
            this.districtCode = "";
          }
          if (item.type == "image") {
            if (typeof this.index == "number") return;
            this.formData[index].value = [];
          }
          if (item.type == "file") {
            this.formData[index].value = [];
          }
        });
      } else {
        this.formData.forEach((item, index) => {
          if (item.type == "checkbox") {
            this.formData[index].remark = [];
          }
          if (item.field == "school") {
            this.schoolIndex = index;
          }
          if (item.field == "academy") {
            this.academyIndex = index;
          }
          if (item.field == "major") {
            this.majorIndex = index;
          }
          if (item.field == "project_member") {
            this.projectMemberIndex = index;
          }
          if (item.field == "teacher_Array") {
            this.teacherArrayIndex = index;
            if (typeof item.remark == "string") {
              this.formData[index].remark = this.getRemark(
                item.remark,
                "array"
              );
            }
          }
          if (item.type == "areaSelect") {
            this.getArea(item.remark, index);
          }
          if (item.type == "file") {
            let fileRemarkList = this.getRemark(item.remark, "array");
            this.formData[index].value = [];
            fileRemarkList.forEach((item) => {
              this.formData[index].value.push({
                name: item.originalName,
                url: item.url,
              });
            });
          }
        });
      }
    },

    // select
    //获取选择框列表
    getSelectValue(data) {
      if (!data) return "";
      let list = [];
      if (typeof data == "string") {
        if (data.indexOf(",") != -1) {
          let temp = data.split(",");
          temp.forEach((item) => {
            list.push({ value: item, label: item });
          });
          return list;
        } else {
          list = [{ value: data, label: data }];
          return list;
        }
      } else if (data instanceof Array) {
        data.forEach((item) => {
          list.push({ value: item, label: item });
        });
        return list;
      }
    },
    //省 选择事件
    provinceChange(index) {
      if (this.provinceCode) {
        this.city = "";
        this.cityCode = "";
        this.cityOptions = [];
        this.district = "";
        this.districtCode = "";
        this.districtOptions = [];
      }
      this.provinceOptions.forEach((e) => {
        if (e.value == this.provinceCode) {
          this.province = e.label;
          this.provinceCode = e.value;
        }
      });
      this.formData[index].remark = "";
      if (this.provinceCode) {
        getConfigAreaList({ areaCode: this.provinceCode }).then((res) => {
          this.cityOptions = res.data;
          if (this.city) {
            this.cityChange(index);
          }
        });
      }
    },
    //市 选择事件
    cityChange(index) {
      if (this.cityCode) {
        this.district = "";
        this.districtCode = "";
        this.districtOptions = [];
      }
      this.cityOptions.forEach((e) => {
        if (e.value == this.cityCode) {
          this.city = e.label;
          this.cityCode = e.value;
        }
      });
      this.formData[index].remark = "";
      if (this.cityCode) {
        getConfigAreaList({ areaCode: this.cityCode }).then((res) => {
          this.districtOptions = res.data;
          if (this.district) {
            this.districtChange(index);
          }
        });
      }
    },
    //区 选择事件
    districtChange(index) {
      this.districtOptions.forEach((e) => {
        if (e.value == this.districtCode) {
          this.district = e.label;
          this.districtCode = e.value;
        }
      });
      this.formData[index].remark =
        this.province + "、" + this.city + "、" + this.district;
    },
    //获取认证地区
    getArea(item) {
      const split = item.split("、");
      this.province = split[0];
      this.city = split[1];
      this.district = split[2];
      this.provinceOptions.forEach((e) => {
        if (e.label == this.province) {
          this.provinceCode = e.value;
          this.getAreaSelect();
        }
      });
    },
    //回显地区
    getAreaSelect() {
      this.provinceOptions.forEach((e) => {
        if (e.value == this.provinceCode) {
          this.province = e.label;
        }
      });
      if (this.provinceCode) {
        getConfigAreaList({ areaCode: this.provinceCode }).then((res) => {
          this.cityOptions = res.data;
          this.cityOptions.forEach((e) => {
            if (e.label == this.city) {
              this.cityCode = e.value;
            }
          });
          if (this.cityCode) {
            getConfigAreaList({ areaCode: this.cityCode }).then((res) => {
              this.districtOptions = res.data;
              this.districtOptions.forEach((e) => {
                if (e.label == this.district) {
                  this.districtCode = e.value;
                }
              });
            });
          }
        });
      }
    },

    //radio
    //获取单选框列表
    getRadioList(data) {
      if (!data) return "";
      let list = [];
      if (typeof data == "string") {
        if (data.indexOf(",") != -1) {
          let temp = data.split(",");
          temp.forEach((item) => {
            list.push({ value: item, label: item });
          });
          return list;
        } else {
          list = [{ value: data, label: data }];
          return list;
        }
      } else if (data instanceof Array) {
        data.forEach((item) => {
          list.push({ value: item, label: item });
        });
        return list;
      }
    },
    //改变radio
    changeRadio(item) {
      if (item.remark == "其他") {
        item.switchYn = true;
      } else {
        item.switchYn = false;
      }
    },
    //改变checkbox
    changeCheckbox(item) {
      if (item.remark.indexOf("其他")) {
        item.switchYn = true;
      } else {
        item.switchYn = false;
      }
    },

    //image
    changeImgIndex(index) {
      this.imgIndex = index;
      getqiniuToken().then((res) => {
        this.fileUploadParam = { key: "", token: res.data };
      });
    },
    beforeImgUpload(file) {
      this.fileUploadParam.key =
        String(new Date().getTime()) +
        Math.floor(Math.random() * 50 + 50) +
        "." +
        file.name;
    },
    handleImgUploadSuccess(file) {
      console.log(file);
      if (!this.formData[this.imgIndex].remark) {
        this.formData[this.imgIndex].remark = [
          {
            id: file.data.id,
            originalName: file.data.fname,
            url: this.$globalHeader.fileUrl + "/" + file.data.key,
          },
        ];
      } else {
        this.formData[this.imgIndex].remark.push({
          id: file.data.id,
          originalName: file.data.fname,
          url: this.$globalHeader.fileUrl + "/" + file.data.key,
        });
      }
      this.$message.success(`上传成功`);
    },
    handleImgUploadError() {
      this.$message.warning(`上传失败`);
    },
    handleImgPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleImgExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeImgRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleImgRemove(file, fileList) {
      console.log(file, fileList);
      this.formData[this.imgIndex].remark = [];
      fileList.forEach((item) => {
        this.formData[this.imgIndex].remark.push({
          id: item.response.data.id,
          originalName: item.response.data.fname,
          url: this.$globalHeader.fileUrl + "/" + item.response.data.key,
        });
      });
    },

    // url: config.BaseUrl + '/api/file/web/upload'
    //file
    changeFileIndex(index) {
      this.fileIndex = index;
      getqiniuToken().then((res) => {
        this.fileUploadParam = { key: "", token: res.data };
      });
    },
    beforeFileUpload(file) {
      this.fileUploadParam.key =
        String(new Date().getTime()) +
        Math.floor(Math.random() * 50 + 50) +
        "." +
        file.name;
    },
    handleFileUploadSuccess(file) {
      if (!this.formData[this.fileIndex].remark) {
        this.formData[this.fileIndex].remark = [
          {
            id: file.data.id,
            originalName: file.data.fname,
            url: this.$globalHeader.fileUrl + "/" + file.data.key,
          },
        ];
      } else {
        this.formData[this.fileIndex].remark.push({
          id: file.data.id,
          originalName: file.data.fname,
          url: this.$globalHeader.fileUrl + "/" + file.data.key,
        });
      }
      this.$message.success(`上传成功`);
    },
    handleFileUploadError() {
      this.$message.warning(`上传失败`);
    },
    handleFilePreview(file) {
      console.log(file);
    },
    handleFileExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeFileRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleFileRemove(file, fileList) {
      console.log(file, fileList);
      this.formData[this.fileIndex].remark = [];
      fileList.forEach((item) => {
        this.formData[this.fileIndex].remark.push({
          id: item.response.data.id,
          originalName: item.response.data.fname,
          url: this.$globalHeader.fileUrl + "/" + item.response.data.key,
        });
      });
    },
    //转换remark类型
    getRemark(data, type) {
      const dataType = typeof data;
      if (type == "array") {
        if (!data) {
          return [];
        }
        if (dataType == "string") {
          let data2 = data.replace(/:s*([0-9]{15,})s*(,?)/g, ': "$1" $2');
          return JSON.parse(data2);
        }
        if (dataType == "object") {
          return data;
        }
      }
      if (type == "string") {
        if (!data) {
          return "";
        }
        if (dataType == "string") {
          return data;
        }
        if (dataType == "object") {
          console.log("参数错误");
        }
      }
      if (type == "object") {
        if (!data) {
          return {};
        }
        if (dataType == "string") {
          return JSON.parse(data);
        }
      }
      return data;
    },
    //
    submit() {
      console.log("companyData", this.formData);
      let result = true;
      this.formData.forEach((item) => {
        if (!result) return;
        if (item.field == "company_name" && !item.remark) {
          this.$message.error(`公司名称未填写`);
          result = false;
          return;
        }
        if (item.isNeed != 0) {
          if (!item.remark) {
            this.$message.error(`${item.title}未填写`);
            result = false;
            return;
          }
          this.form[item.field] = {
            id: item.id,
            value: item.remark,
            isUpdate: item.rule.isUpdate,
          };
        } else {
          this.form[item.field] = {
            id: item.id,
            value: item.remark,
            isUpdate: item.rule.isUpdate,
          };
        }
      });
      console.log("companyForm", this.form);
      if (!result) return;
      this.$emit("getCompanyData", { form: this.form, index: this.index });
    },
  },
};
</script>

<style lang="scss" scoped>
.c-left-content {
  flex: 1;
  padding: 0 16px 12px;
  // overflow-y: scroll;

  .area-content {
    display: flex;

    .area-item {
      margin-right: 24px;
    }
  }

  .item-wrap_old {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 3px;

    .item-title {
      display: flex;
      padding: 16px 0;
      font-size: 16px;

      .item-name {
        color: #0099cc;
      }

      .must {
        margin-right: 6px;
        color: red;
      }
    }

    .item-select {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background-color: #f5f5f5;

      .choose {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 10px;
        font-size: 16px;
        color: #666;
        box-sizing: border-box;

        .name {
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon {
          font-size: 14px;
        }
      }
    }
  }

  .project-choose {
    .form-wrap {
      margin-bottom: 3px;

      .item-wrap {
        .subtitle {
          margin-bottom: 12px;
          line-height: 1.5;
          font-size: 13px;
          color: #999;
        }

        .item-title {
          display: flex;
          padding: 15px 0;

          .item-name {
            font-size: 16px;
            line-height: 1.35;
            color: #0099cc;
          }
        }

        .item-content {
          display: flex;
          justify-content: space-between;

          .radio-content {
            flex: 1;
            display: flex;
            flex-direction: column;

            /deep/ .u-radio__icon-wrap {
              margin-top: 2px;
            }

            /deep/ .u-radio {
              align-items: flex-start;
              overflow: unset;
              height: 1.4rem;
              line-height: 1.35 !important;
              margin-bottom: 4px;

              .u-radio__text {
                line-height: 1.35 !important;
              }
            }
          }

          input {
            border: none;
            outline: none;
          }

          .item-content-left {
            flex: 1;
            display: flex;

            .input-wrap {
              flex: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 12px;
              height: 40px;
              border-radius: 4px;
              background-color: #f5f5f5;

              .input {
                flex: 1;
                height: 100%;
                background-color: transparent;
              }

              .icon {
                flex-shrink: 0;
              }

              .btn {
                margin-left: 10px;
                padding: 0 8px;
                font-size: 11px;
                color: #fff;
                background-color: #0099cc;
                border-radius: 4px;
              }
            }

            //添加导师
            .add-teacher {
              flex: 1;
              display: flex;
              justify-content: space-between;
              padding-right: 10px;
              background-color: #f2f2f2;
              border-radius: 4px;

              .teacher-list {
                flex: 1;
                display: flex;
                flex-direction: column;

                .teacher-item {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 40px;
                  padding: 0 12px;

                  .text {
                    color: #808080;
                  }

                  .wrap {
                    flex: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }

                  .delete {
                    flex-shrink: 0;
                    margin-left: 12px;
                    font-size: 14px;
                    color: red;
                  }
                }
              }

              .btn-wrap {
                flex-shrink: 0;
                align-self: flex-end;
                padding: 5px 0;

                .btn {
                  flex: 1;
                  margin-left: 0;
                  padding: 0 8px;
                  height: 30px;
                  line-height: 30px;
                  font-size: 14px;
                  color: #fff;
                  background-color: #0099cc;
                  border-radius: 4px;
                }
              }
            }

            //上传图片
            .upload-img {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 75px;
              height: 75px;
              border-radius: 6px;
              background-color: #f2f2f2;

              .icon {
                font-size: 24px;
                color: #bfbfbf;
              }

              .img {
                width: 100%;
                height: 100%;
                border-radius: 6px;
              }

              .delete-img {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
                transform: translate(50%, -50%);
                border-radius: 50%;
                background-color: red;

                .icon {
                  font-size: 8px;
                  font-weight: bold;
                  color: #fff;
                }
              }
            }

            //上传文件
            .upload-file {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 12px;
              width: 100%;
              height: 40px;
              border-radius: 4px;
              background-color: #f2f2f2;

              .img-wrap {
                flex: 1;
                display: flex;
                align-items: center;

                .img {
                  width: 30px;
                  height: 30px;
                }

                .img-name {
                  flex: 1;
                  line-height: 25px;
                  margin-left: 10px;
                  color: #666;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .delete {
                flex-shrink: 0;
                margin-left: 12px;
                font-size: 14px;
                color: red;
              }
            }
          }

          .member-wrap {
            flex: 1;
            display: flex;
            padding: 10px;
            background-color: #f2f2f2;
            border-radius: 4px;

            .input {
              flex: 1;
              display: flex;
              align-items: center;
              height: 22px;

              .member-list {
                display: flex;
                align-items: center;

                .text {
                  color: #808080;
                }
              }

              .profile-item-wrap {
                flex: 1;
              }

              .profile-item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .img-wrap {
                  flex: 1;
                  display: flex;
                  align-items: center;
                  margin-right: 10px;
                  line-height: 22px;
                  border-radius: 6px;

                  .img {
                    width: 30px;
                    height: 30px;
                  }

                  .img-name {
                    flex: 1;
                    margin-left: 10px;
                    color: #666;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }

                .delete {
                  flex-shrink: 0;
                  font-size: 14px;
                  color: red;
                }
              }
            }

            .more {
              flex-shrink: 0;
              display: flex;
              align-items: center;
              margin-left: 12px;

              .refresh {
                .text {
                  color: #0099cc;
                }
              }

              .icon {
                font-size: 13px;
                color: #666;
              }
            }
          }
        }

        //textarea
        .textarea-content {
          display: block;
          padding: 16px;
          border: 1px solid #ddd;

          .textarea {
            width: 100%;
            height: 100px;
            border: none;
          }
        }
      }
    }
  }

  .foot {
    padding: 60px 0;

    .btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {
        text-align: center;
        width: 60%;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        border-radius: 26px;
        color: #fff;
        background-color: #0099cc;
        cursor: pointer;
      }
    }
  }

  .ban {
    color: #c0c0c0 !important;
  }

  .banBgc {
    background-color: #c0c0c0 !important;
  }
}
</style>
