import http from "@/utils/http.js"


export function getArticlePage(data){
    return http({
        url:"/api/web/article/getArticlePage",
        data:data,
        method:"GET",
    })
}


export function getArticleDetail(data){
    return http({
        url:"/api/web/article/getArticleDetail",
        data:data,
        method:"GET",
    })
}
