import http from "@/utils/http.js"

//课程列表
export function getCourseList(data) {
    return http({
        url: "/api/web/personal/getCourseList",
        method: "GET",
		data:data,
    })
}

//个人认证基础信息
export function getAuthDetail() {
    return http({
        url: "/api/web/personal/getAuthDetail",
        method: "GET",
    })
}


//导师列表
export function getTeacherList(data) {
    return http({
        url: "/api/web/course/getTeacherList",
        method: "GET",
		data:data,
    })
}

//模板列表
export function getCourseTemplatePage(data) {
    return http({
        url: "/api/web/course/getCourseTemplatePage",
        method: "GET",
		data:data,
    })
}

//项目攻略
export function getStrategyPage(data) {
    return http({
        url: "/api/web/course/getStrategyPage",
        method: "GET",
		data:data,
    })
}

//分页获取导师
export function getTeacherPage(data) {
    return http({
        url: "/api/web/course/getTeacherPage",
        method: "GET",
		data:data,
    })
}

//获取表单
export function getCourseForm(data) {
    return http({
        url: "/api/web/course/getCourseForm",
        method: "GET",
		data:data,
    })
}

//获取项目状态
export function getProjectAuditStatus(courseId) {
    return http({
        url: "/api/web/course/getProjectAuditStatus/"+courseId,
        method: "GET",
    })
}

//暂存
export function saveTempCourseApply(data) {
    return http({
        url: "/api/web/course/saveTempCourseFormList",
        method: "POST",
		data:data,
    })
}

//添加
export function saveCourseFormList(data) {
    return http({
        url: "/api/web/course/saveCourseFormList",
        method: "POST",
		data:data,
    })
}

//获取课程成员信息
export function getStudyProjectMember(data) {
    return http({
        url: "/api/web/course/getProjectMember/" + data.courseId,
        method: "GET",
    })
}

// 邀请成员
export function invitedStudyProjectMember(data) {
	return http({
		url: "/api/web/course/invitedProjectMember/" + data.courseId,
		method: 'POST',
	})
}

// 删除成员
export function removeStudyProjectMember(data) {
	return http({
		url: "/api/web/course/removeProjectMember/" + data.courseId + '/' + data.delCustomId,
		method: 'DELETE',
	})
}

// 被邀请成员操作( 同意 拒绝)
export function studyBeInvitedProjectMember(data) {
	return http({
		url: "/api/web/course/beInvitedProjectMember/" + data.inviteId + '/' + data.type,
		method: 'POST',
	})
}

// 被邀请人操作页面
export function studyBeInviteWeb(data) {
	return http({
		url: "/api/web/course/beInviteWeb/" + data.inviteId,
		method: 'GET',
	})
}


//分页查询双创导师推荐
export function getTutorPage(data) {
    return http({
        url: "/api/web/teacher/getTeacherPage",
        method: "GET",
		data:data,
    })
}

//获取导师详情基本信息
export function getTutorDetail(data) {
    return http({
        url: "/api/web/teacher/getTeacherDetailBase",
        method: "GET",
		data:data,
    })
}
//查询双创导师服务推荐
export function getTeacherService(data) {
    return http({
        url: "/api/web/teacher/getTeacherServicePage",
        method: "POST",
		data:data,
    })
}
//分页查询双创服务推荐
export function getServicePage(data) {
    return http({
        url: "/api/web/teacher/getServicePage",
        method: "POST",
		data:data,
    })
}
//获取导师动态列表
export function getTrendList(data) {
    return http({
        url: "/api/web/teacher/getTeacherTrendList",
        method: "GET",
		data:data,
    })
}
//更新导师动态的浏览量
export function updateViewNum(data) {
    return http({
        url: "/api/web/teacher/updateViewNum",
        method: "POST",
		data:data,
    })
}

//对导师动态进行点赞或者取消点赞
export function updateSupport(data) {
    return http({
        url: "/api/web/teacher/updateSupport",
        method: "POST",
		data:data,
    })
}
//获取站点的地区列表
export function getAreaList(data) {
    return http({
        url: "/api/web/station/getAreaList",
        method: "GET",
		data:data,
    })
}
//查询省下的市区
// export function getAreaChildren(data) {
//     return http({
//         url: "/api/common/area/getListChildren",
//         method: "GET",
// 		data:data,
//     })
// }

//查询省下的高效服务站列表
export function getAreaStationList(data) {
    return http({
        url: "/api/web/station/getServiceStationList",
        method: "GET",
		data:data,
    })
}