<template>
  <div class="match-dynamic-page page" v-loading.fullscreen.lock="loading">
    <!-- 顶部 -->
    <page-top></page-top>
    <!-- 头部 -->
    <Header @chooseTab="getTab"></Header>
    <!-- 主体 -->
    <main class="main">
      <section class="breadcrumb-plate w">
        <div class="label">我的位置：</div>
        <breadcrumb />
      </section>
      <section class="content w">
        <div class="c-left" v-if="articleInfo">
          <h1 class="article-title">{{ articleInfo.articleName || "" }}</h1>
          <div class="article-author">
            <!-- <span>{{ dynamicInfo.dynamicAuthor || "" }}</span> -->
            <!-- <span v-if="dynamicInfo.dynamicAuthor">&nbsp;&nbsp;</span> -->
            <span>{{ "发布于 " + articleInfo.releaseTime || "" }}</span>
          </div>
          <el-divider />
          <div
            class="article-content"
            v-html="articleInfo.articleContent"
          ></div>
        </div>
      </section>
    </main>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import { getArticleDetail } from "@/api/article/index.js";
export default {
  name: "DynamicDetail",
  components: { pageTop, Header, Footer, Breadcrumb },

  data() {
    return {
      articleInfo: null,
      loading: false,
    };
  },
  methods: {
    //获取header组件的点击事件
    getTab(data) {
      if (data && typeof data == "string") {
        this.$router.push("/" + data);
      }
    },
    //根据资讯id获取详情
    articleDetail(id) {
      getArticleDetail({articleId:id}).then((res) => {
        if (!res.data) return;
        this.articleInfo = res.data;
        this.matchId = res.data.sourceId;
        this.setTDK();
      });
    },
    setTDK() {
      document.title = this.articleInfo.articleName;
      const head = document.getElementsByTagName("head");
      const meta = document.createElement("meta");
      document.querySelector('meta[name="keywords"]');
      head[0].appendChild(meta);
      this.$route.meta.title = this.articleInfo.articleName;
    },
  },
  created() {
    let id = this.$route.params.id;
    this.articleDetail(id);
  },
};
</script>

<style lang="scss" scoped>
.match-dynamic-page {
  .main {
    flex: 1;
    padding-bottom: 80px;
   background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .c-left {
        flex: 1;
        margin-right: 15px;
        background-color: #fff;
        box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
        padding: 60px;

        .article-title {
          margin: 0;
          text-align: center;
          font-weight: 700;
          font-style: normal;
          font-size: 34px;
          color: #000000;
          line-height: 1.35;
        }

        .article-author {
          text-align: center;
          color: #666666;
          font-weight: 400;
          font-style: normal;
          margin-top: 30px;
          margin-bottom: 40px;
          font-size: 17px;
        }

        .article-content {
          font-size: 17px !important;
          line-height: 2 !important;
          min-height: 640px;
        }
      }

      .c-right {
        flex-shrink: 0;
        width: 320px;

        .recommend {
          margin-bottom: 15px;
          background-color: #fff;

          .head {
            padding: 10px 0;
            display: flex;
            align-items: stretch;
            justify-content: space-between;

            .title {
              position: relative;
              font-size: 18px;
              font-weight: 400;
              padding: 6px 0;
              margin-left: 20px;
            }

            .title::after {
              position: absolute;
              content: " ";
              overflow: hidden;
              height: 3px;
              width: 2em;
              left: 0;
              bottom: 0;
              border-radius: 2px;
              background-color: #2d7cce;
            }

            .more {
              display: flex;
              align-items: center;
              padding: 6px 20px;
              cursor: pointer;

              .icon {
                font-size: 14px;
              }
            }

            .more:hover {
              color: #2d7cce;
            }
          }
        }

        .plate1 {
          .list {
            .item {
              font-size: 16px;
              padding: 16px 0;
              margin: 0 20px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #f0f0f0;

              .item-title {
                flex: 1;
                font-weight: normal;
              }

              .item-cover {
                width: 70px;
                height: 56px;
                margin-left: 15px;
                overflow: hidden;
                border-radius: 3px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .item:last-child {
              border: none;
            }

            .item:hover {
              color: #2d7cce !important;
              cursor: pointer;
            }

            .item-recommend {
              display: block;
              padding-top: 10px;

              .item-cover {
                margin: 0;
                width: 280px;
                height: 224px;
                overflow: hidden;
                border-radius: 5px;
                margin-bottom: 10px;
              }
            }
          }
        }

        .plate2 {
          .list {
            padding-bottom: 20px;

            .item {
              margin: 0 20px;
              padding: 8px 0;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .circle {
                margin-right: 8px;
                width: 4px;
                height: 4px;
                background-color: #e5e5e5;
                border-radius: 50%;
              }

              .title {
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .time {
                min-width: 3em;
                text-align: right;
                font-size: 12px;
                color: #aaa;
                margin-left: 10px;
              }
            }

            .item:hover {
              cursor: pointer;
              color: #2d7cce;

              .circle {
                background-color: #2d7cce;
              }
            }
          }
        }
      }
    }
  }
}
</style>
