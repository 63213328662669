<template>
  <div class="footer">
    <div class="footer-wrap w">
      <div class="left plate">
        <div class="logo">
          <div class="img-wrap">
            <img
              class="img"
              :src="
                $globalHeader.imgUrl +
                '/static/yx/images/%E9%A6%96%E9%A1%B5_1/u5697.png'
              "
              alt="logo"
            />
          </div>
          <div class="content">双创金服</div>
        </div>
        <div class="content">
          <p>Copyright© 版权所有</p>
          <p>2019-2024厦门双创金服网络科技有限公司</p>
          <p>All Rights Reserved.</p>
          <p>
            备案号：<a target="_blank" href="https://beian.miit.gov.cn/"
              >闽ICP备2023012598号</a
            >
          </p>
        </div>
      </div>
      <div class="center plate">
        <div class="title">联系我们</div>
        <div class="content">
          <p>
            <span class="label">客服电话：</span>
            <span>15105964275</span>
          </p>
          <p>
            <span class="label">电子邮箱：</span>
            <span>258977789@qq.com</span>
          </p>
          <p>
            <span class="label">企业微信：</span>
            <span>15105964275</span>
          </p>
          <p>
            <span class="label">公司地址：</span>
            <span
              >厦门市集美区后溪镇珩圣路386号厦门北站商业广场南广场288号之八</span
            >
          </p>
        </div>
      </div>
      <div class="right plate">
        <div class="title">移动端</div>
        <div class="content">
          <div class="r-item r-item1">
            <div class="item-top">
              <div class="img-wrap">
                <img
                  class="img"
                  :src="$globalHeader.imgUrl + '/static/other/corp_concat.jpg'"
                  alt="企业微信二维码"
                />
              </div>
              <div class="txt">企业微信</div>
            </div>
            <div class="item-bot">
              <div class="txt">客服联系方式</div>
            </div>
            <div class="amplify amplify1">
              <div class="triangle"></div>
              <div class="item-top">
                <div class="img-wrap1">
                  <img
                    class="img"
                    :src="
                      $globalHeader.imgUrl + '/static/other/corp_concat.jpg'
                    "
                    alt="企业微信二维码"
                  />
                </div>
                <div class="txt">企业微信</div>
              </div>
            </div>
          </div>
          <div class="r-item r-item2">
            <div class="item-top">
              <div class="img-wrap">
                <img
                  class="img"
                  :src="$globalHeader.imgUrl + '/static/other/mini_concat.png'"
                  alt="小程序二维码"
                />
              </div>
              <div class="txt">小程序</div>
            </div>
            <div class="item-bot">
              <div class="txt">高校双创人才服务站</div>
            </div>
            <div class="amplify amplify2">
              <div class="triangle"></div>
              <div class="item-top">
                <div class="img-wrap1">
                  <img
                    class="img"
                    :src="
                      $globalHeader.imgUrl + '/static/other/mini_concat.png'
                    "
                    alt="小程序二维码"
                  />
                </div>
                <div class="txt">小程序</div>
              </div>
            </div>
          </div>
          <div class="r-item r-item3">
            <div class="item-top">
              <div class="img-wrap">
                <img
                  class="img"
                  :src="
                    $globalHeader.imgUrl + '/static/other/official_concat.jpg'
                  "
                  alt="公众号二维码"
                />
              </div>
              <div class="txt">公众号</div>
            </div>
            <div class="item-bot">
              <div class="txt">高校双创人才服务站</div>
            </div>
            <div class="amplify amplify3">
              <div class="triangle"></div>
              <div class="item-top">
                <div class="img-wrap1">
                  <img
                    class="img"
                    :src="
                      $globalHeader.imgUrl + '/static/other/official_concat.jpg'
                    "
                    alt="公众号二维码"
                  />
                </div>
                <div class="txt">公众号</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  color: #c9c9c9;
  background-color: #363636;
}

.footer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px 0;

  .plate {
    width: 28%;

    .title {
      margin-bottom: 20px;
      font-size: 16px;
      color: #fff;
    }

    .content {
      p {
        display: flex;
        align-items: flex-start;
        margin-bottom: 2px;

        .label {
          flex-shrink: 0;
        }

        a {
          color: #c9c9c9;
        }
      }
    }
  }

  .left {
    display: flex;
    flex-direction: column;

    .logo {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      margin-top: -8px;

      .img-wrap {
        width: 30px;
        height: 30px;
      }

      .content {
        margin-left: 12px;
        font-size: 32px;
        color: #fff;
      }
    }
  }

  .right {
    position: relative;
    width: 30%;

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .r-item {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 32%;
        cursor: pointer;
        z-index: 10;

        .item-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 10px;
          width: 100%;
          height: 100%;
          min-height: 108px;
          background-color: #fff;

          .img-wrap {
            max-width: 110px;
            height: 70px;
          }

          .txt {
            font-size: 10px;
            color: #999;
          }
        }

        .item-bot {
          transform: scale(0.8);
          text-align: center;
        }

        .txt {
          margin-top: 2px;
          font-size: 8px;
          color: #fff;
        }
      }

      .amplify {
        display: none;
        position: absolute;
        right: -80px;
        bottom: 150px;
        width: 400px;
        height: 400px;
        background-color: #fff;
        border: 1px solid #f2f2f2;
        border-bottom: 0;
        box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

        .item-top {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 400px;
          height: 400px;
          background-color: #fff;

          .img-wrap1 {
            width: 80%;
            height: 80%;

            .img {
              width: 100%;
              height: 100%;
            }
          }

          .txt {
            margin-top: 2px;
            font-size: 24px;
            color: #333;
          }
        }

        .triangle {
          position: absolute;
          bottom: -40px;
          right: 24%;
          transform: translate(-50%, 0);
          border: 20px solid #fff;
          border-color: #fff transparent transparent transparent;
        }
      }
    }

    .r-item1:hover {
      .amplify1 {
        display: flex;
      }
    }

    .r-item2:hover {
      .amplify2 {
        display: flex;
      }
    }

    .r-item3:hover {
      .amplify3 {
        display: flex;
      }
    }
  }
}
</style>
