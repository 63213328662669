<template>
  <a class="match-item">
    <div class="item-top">
      <div class="img-wrap">
        <img class="img" :src="item.image" alt="赛事图片" />
      </div>
    </div>
    <div class="item-bot">
      <h3 class="item-title">
        {{ item.title }}
      </h3>
      <div class="item-content">
        <div class="match">
          <div class="subitem">
            <div class="label">
              <span class="icon iconfont icon-tag-time"></span>
              <span class="txt">赛事：</span>
            </div>
            <div class="content">
              <span>{{ item.startTime || "" }}</span>
              <span>{{ item.endTime || "" }}</span>
            </div>
          </div>
        </div>
        <div class="join">
          <div class="subitem">
            <div class="label">
              <span class="icon iconfont icon-tag-time"></span>
              <span class="txt">报名：</span>
            </div>
            <div class="content">
              <span>{{ item.signupStartTime || "" }}</span>
              <span>{{ item.signupEndTime || "" }}</span>
            </div>
          </div>
        </div>
        <div class="item-last">
          <div class="content address-content ignore" style="width:80%">
            <span class="icon iconfont icon-tag-address"></span>
            <span>{{ item.address }}</span>
          </div>
          <div class="content view">
            <span class="icon iconfont icon-browse"></span>
            <span>{{ item.views }}</span>
          </div>
          <!-- <span class="subitem">
            <div class="label">
              <span class="icon iconfont icon-tag-address"></span>
              <span class="txt">地点：</span>
            </div>
            <div class="content address-content" style="width:80%">
              <span class="icon iconfont icon-tag-address"></span>
              <span>{{ item.address }}</span>
            </div>
          </span> -->
          <!-- <span class="subitem">
            <div class="label">
              <span class="icon iconfont icon-browse"></span>
            </div>
            <div class="content">
              <span class="icon iconfont icon-browse"></span>
              <span>{{ item.views }}</span>
            </div>
          </span> -->
        </div>
        
      </div>
      <div class="match-status" :style="{ background: MatchStatusMap[item.timeStatus].bg }">
          <!-- <span class="icon iconfont" :class="MatchStatusMap[item.timeStatus].icon"></span> -->
          <span class="txt">{{ item.timeStatus }}</span>
        </div>
    </div>
  </a>
</template>

<script>
const MatchStatusMap = {
  未开始: {
    bg: "linear-gradient(to left, #18EA63, #00E9F4)",
    icon: "icon-match-end",
  },
  报名中: {
    bg: "linear-gradient(to right, #F7D941, #FAAC1F)",
    icon: "icon-join-record",
  },
  进行中: {
    bg: "linear-gradient(to left, #FA6E29, #F63361)",
    icon: "icon-match-promotion",
  },
  已结束: {
    bg: "linear-gradient(to left, #9F9F9F, #D0D0D0)",
    icon: "icon-match-end",
  },
};
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      MatchStatusMap: MatchStatusMap,
    };
  },
};
</script>

<style lang="scss" scoped>
a:hover {
  border: none !important;
}

.match-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;

  .item-top {
    flex-shrink: 0;

    .img-wrap {
      width: 100%;
      height: 215px;
    }
  }

  .item-bot {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 15px;
    padding-bottom: 47px;
    .item-title {
      min-height: 46px;
      font-size: 16px;
      color: #000;
    }
    .match-status {
        position: absolute;
        bottom: 0;
        right: -20px;
        width: 120px;
        height: 36px;
        padding: 6px 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 60px 0 0 0px;

        .txt {
          // margin-left: 4px;
          font-size: 19px;
          // font-weight: bold;
          color: #fff;
        }

        .icon {
          color: #fff;
        }
      }
    .item-content {
      position: relative;
      display: flex;
      flex-direction: column;
      // margin-top: 8px;

      .subitem {
        flex-shrink: 0;
        display: flex;
        align-items: self-start;
        margin-top: 8px;
        font-size: 12px;
        line-height: 1;
        color: #666;

        .label {
          flex-shrink: 0;
        }

        .content {
          display: flex;
          flex-direction: column;

          span {
            margin-bottom: 6px;
          }
        }
      
        .address-content {
         
          span {

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .icon {
          font-size: 12px;
          margin-right: 4px;
        }
      }

      .item-last {
        display: flex;
        justify-content: space-between;
        align-items: self-start;
        margin-top: 8px;
        font-size: 12px;
        line-height: 1;
        color: #666;
          .ignore {
           flex: 1;
          display: -webkit-box;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
        }
        .view {
            margin-left: 1em;
          }
        .content {
          
          span {
            margin-bottom: 6px;
          }
        }
      
       

        .icon {
          font-size: 12px;
          margin-right: 4px;
        }
      }

      
    }
  }
}
</style>