<template>
  <div class="mien hover-big" v-if="data">
    <div class="m-right">
      <img :src="data.avatar"/>
    </div>
    <div class="m-left">
      <div class="name-job">
        <div class="name">
          {{data.teacherName}}
        </div>
        <div class="job">
          {{data.jub?data.jub:"总经理"}}
        </div>
      </div>
      <div class="des" :title="data.description">
        {{data.description}}
      </div>
    </div>

    
</div>
</template>

<script>

export default {
  props: {
    data:Object,
  },
  components: {

  },
  data() {
    return {
    };
  },
  
  methods: {

  },
  created() {
  },
};
</script>

<style lang="scss" scoped>

    .mien {
      margin-top: 20px;
      padding: 20px;
      width: 220px;
      background-color: #fff;
      box-shadow: -2px 2px 20px 0 #dbefffad;
      -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;
      .m-left {
        .name-job{
          margin-top: 10px;
          padding: 0px 30px;
          display: flex;
          line-height: 24px;
          justify-content:space-between;
        }
        .name{
          font-size: 18px;

        }
        .job{
          color: #C4C4C4;
          text-align: center;
        }
        .des{
          min-height: 188px;
          min-width: 180px;
          margin-top: 10px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 10;
          -webkit-box-orient: vertical;
        }
      }

      .m-right{
        text-align: center;        
        img{
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
      }
    }


</style>
