<template>
  <div class="plate-wrap">
    <div class="status-plate" :class="reviewStatus.class">
      <div class="title">
        <span>{{ reviewStatus.title }}</span>
      </div>
      <div class="subtitle">
        <span>{{ reviewStatus.subtitle }}</span>
      </div>
    </div>

    <div class="info-plate plate-common">
      <div class="title"> 订单信息 </div>
      <div class="content" v-if="orderDetail">
        <div class="item">
          <span class="item-left">订单单号：</span>
          <span class="item-right">{{ orderDetail.orderNo || "" }}</span>
        </div>
        <div class="item">
          <span class="item-left">下单时间：</span>
          <span class="item-right">{{ orderDetail.orderTime || "--" }}</span>
        </div>
        <div class="item">
          <span class="item-left">订单金额：</span>
          <span class="item-right">{{ orderDetail.orderAmt || "--" }}</span>
        </div>
        <div class="item tips">
          <span class="item-left">支付时间：</span>
          <span class="item-right">{{ orderDetail.payTime || "--" }}</span>
        </div>
        
      </div>
    </div>

    <div class="detail-plate">
      <div class="order-info plate-common">
        <div class="title">产品服务信息</div>
        <div class="content" v-if="orderDetail">
          <div class="item">{{orderDetail.productName || ''}}</div>
          <div class="item">
            <div class="item-left">
              <div class="item-cover">
                <img class="img" :src="orderDetail.productImage" alt="" />
              </div>
            </div>
            <div class="item-right">
              <span class="text">导师姓名：{{orderDetail.teacherName || '--'}}</span>
              <span class="text">起始时间：{{orderDetail.serviceDate+" "+orderDetail.servicePeriod|| '--'}}</span>
              <span class="text">服务时长：{{orderDetail.serviceNum+(orderDetail.productUnit||'') || '--'}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="conduct-info plate-common">
        <div class="title"> 申请信息 </div>
        <div class="content" v-if="orderDetail">
          <div class="item">
            <div class="item-left">
              <span class="text">真实姓名：</span>
            </div>
            <span class="item-right">
              {{ orderDetail.contactName || "--" }}
            </span>
          </div>
          <div class="item">
            <div class="item-left">
              <span class="text">联系电话：</span>
            </div>
            <span class="item-right">
              {{ orderDetail.phone || "--" }}
            </span>
          </div>
          <div class="item">
            <div class="item-left">
              <span class="text">备注信息：</span>
            </div>
            <span class="item-right">
              {{ orderDetail.remark || "--" }}
            </span>
          </div>
      </div>
      <div class="item-control" v-if="orderDetail">
          <div
            v-if="orderDetail.auditStatus == 1"
            class="btn cancle-btn"
            @click="toCancle(orderDetail.orderId)"
          >
            取消订单
          </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>
// removeOrder
import { getOrderDetail,removeOrder } from "@/api/personal";
const statusMap = {
		1: {
			title: "待确认",
			subtitle: "导师预约已提交,等待平台确认中",
			class: "blue-bgc",
			time: "申请时间"
		},
		2: {
			title: "已确认",
			subtitle: "您的订单已确认",
			class: "green-bgc",
			time: "审核时间"
		},
		3: {
			title: "已驳回",
			subtitle: "因服务时间存在异常，已经为您取消本次订单",
			time: "取消时间"
		},
		0: {
			title: "已取消",
			subtitle: "您已取消本次预约",
			time: "取消时间"
		},
	}
export default {
  data() {
    return {
        orderId: "",
        reviewStatus: {},
        orderDetail: null
    };
  },
  created() {
    let orderId = this.$route.params.id
    this.orderId = this.$route.params.id

    this.fetchData(orderId)
  },
  methods: {
      fetchData(orderId) {
          console.log("orderid",orderId);
          getOrderDetail({
              orderId:orderId
              }).then((res) => {
          console.log("orderidresres",res);

                if (!res.data) return;
                this.orderDetail = res.data;
                this.reviewStatus = statusMap[res.data?.auditStatus]

      });
      },
       toCancle(orderId) {
        const that = this;
        that.$confirm("是否确定取消订单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        
        })
          .then(() => {
            removeOrder(orderId).then((res) => {
                setTimeout(() => {
                  that.fetchData();
                  that.$message({
                    message: res.message,
                    type: "success",
                  });
                }, 1000);
            });
          })
          .catch(() => {
            that.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
      toDetail() {

      },
  },
};
</script>

<style lang="scss" scoped>
.plate-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;

  .status-plate {
    padding: 20px;
    color: #fff;
    // border-radius: 16px;
    background-color: #c9c9c9;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .subtitle {
      margin-top: 0.5em;
      font-size: 16px;
    }
  }

  .blue-bgc {
    background-color: #2f72ae !important;
  }

  .orange-bgc {
    background-color: #ff6600 !important;
  }

  .green-bgc {
    background-color: #339933 !important;
  }

  .gray-bgc {
    background-color: #c9c9c9 !important;
  }

  .blue-color {
    color: #2f72ae !important;
  }

  .orange-color {
    color: #ff6600 !important;
  }

  .green-color {
    color: #339933 !important;
  }

  .gray-color {
    color: #c9c9c9 !important;
  }

  .plate-common {
    padding: 20px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      font-size: 18px;
      font-weight: bold;
      color: #1d1e2c;

      .detail-btn {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #999;

        .text {
          font-size: 16px;
          color: #999;
        }

        .icon {
          font-size: 14px;
          color: #999;
        }
      }
    }

    .content {
        .tips {
            color: #FF6600;
        }
      .item {
        display: flex;
        margin-top: 16px;
        font-size: 16px;
        color: #666;

        .item-left {
          display: flex;
          flex-shrink: 0;
          .item-cover {
                height: 94px;
                margin-right: 20px;

                img {
                  width: 116px;
                  height: 94px;
                  border-radius: 6px;

                }
              }
    

          .text {
            flex: 1;
          }
        }

        .item-right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .text {
            // margin-bottom: 6px;
            line-height: 1.35;
          }

          .material-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            padding: 24px;
            background-color: #f7f7f7;
            border-radius: 16px;

            .look {
              color: #2f72ae;
            }
          }
        }

        .item-file {
          display: flex;
          justify-content: space-between;
          color: #3a81c3;

          .material-name {
            display: -webkit-box;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .look {
            cursor: pointer;
            flex-shrink: 0;
          }
        }
      }

      .tips {
        display: flex;

        .item-left {
          flex-shrink: 0;
        }

        .item-right {
          flex: 1;
        }
      }
    }
  }

  .info-plate {
    margin-top: 16px;
    // border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  }

  .detail-plate {
    position: relative;
    margin-top: 20px;
    // border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);

    .conduct-info {
      padding-top: 0;
      .conduct-material {
        .item-left {
          padding-top: 20px;
        }
      }
    }
    .item-control {
      // position: absolute;
      bottom: 20px;
      right: 20px;
      display: flex;
      flex-direction: row-reverse;

      .btn {
        cursor: pointer;
        padding: 8px 24px;
        border-radius: 20px;
        text-align: center;
        margin-left: 16px;
          border: solid 1px transparent;
      }
      .hover-btn:hover {
        color: #4faaf7;
        border-color: #4faaf7 !important;
      }
      .cancle-btn {
        color: #ff6600;
        border: #ff6600 solid 1px;
      }
      .reapply-btn {
        color: #3892f8;
        border-color: #3892f8;
      }
      .delete-btn {
        color: #989898;
        border: #989898 solid 1px;
      }
      .detail-btn {
        border: #888 solid 1px;
      }
    }
  }

  .no-more {
    margin-top: 80px;
    margin-bottom: 160px;
    font-size: 16px;
    color: #999;
    text-align: center;
  }
}
</style>