<template>
  <div class="school-block">
    <div class="header">共累计 {{ totalCount }} 个高校</div>
    <div class="list-wrap" v-if="schoolList.length">
      <div
        class="item hover-item"
        v-for="(item, index) in schoolList"
        :key="index"
        @click="stationDetail(item)"
      >
        <img class="cover-image" :src="item.stationIcon" />
        <div class="content">
          <div class="title">{{ item.schoolName }}</div>
          <div class="desc">{{ item.area }}</div>
        </div>
      </div>
    </div>
    <div class="empty" v-else>
    <el-empty description="暂无相关高校"></el-empty>
  </div>
  </div>
</template>

<script>
import { getSchoolList } from "@/api/station/station.js";

export default {
  data() {
    return {
      schoolList: [],
      totalCount: 0,
    };
  },
  created() {
    this.getSchool();
  },
  methods: {
    getSchool() {
      getSchoolList().then((res) => {
        this.schoolList = res.data;
        this.totalCount = this.schoolList.length;
      });
    },
    stationDetail(item) {
      this.$router.push({
        path: "/station/stationDetail/" + item.serviceStationId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.school-block {
  margin-bottom: 30px;
  padding: 40px;
  background-color: #fff;
  .header {
    font-size: 20px;
    color: #666;
  }
  .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px;
  }
  .item {
    width: calc(33% - 16px);
    display: flex;
    align-items: center;
    padding: 20px;
    margin-right: 28px;
    margin-bottom: 20px;
    border: 1px solid rgba(229, 229, 229, 1);
    box-shadow: -4px 3px 15px rgba(173, 213, 255, 0.5);
    border-radius: 10px;

    .cover-image {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      margin-right: 24px;
      overflow: hidden;
    }
    .content {
      flex: 1;

      .title {
        font-size: 18px;
        font-weight: 600;
      }

      .desc {
        font-size: 14px;
        margin-top: 1em;
        color: #888;
      }
    }
  }
  .item:nth-child(3n) {
    margin-right: 0;
  }
}
</style>