<template>
    <div class="message-page">
        <div class="message-container">
            <div class="top">
                <div class="item">
                    <div class="row">
                        <div class="left">
                            <div class="title">
                                允许推送提醒
                            </div>
                        </div>
                        <div class="right" @click="openAll">
                            <el-switch v-model="isOpenRemind" active-color="#2D973E" inactive-color="#f7f7f7"
                                @change="changeAll()" :disabled="!isSubscribe">
                            </el-switch>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tips">
                <div class="item tips-item" v-if="!isSubscribe">
                    <div class="row" @click="toFollow">
                        <div class="left">
                            <div class="title">
                                需要先关注公众号，以接收相关的提醒消息推送
                            </div>
                        </div>
                        <div class="right">
                            <div class="btn">
                                去关注
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item tip-item2">
                    <div class="row">
                        <div class="left">
                            <div class="title">
                                以下消息将通过微信公众号推送给您，如未接收到消息，请留意是否已关注微信公众号“高校双创人才服务站”
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bottom" v-if="isOpenRemind">
                <div class="item" v-for="(item, index) in subDate" :key="index">
                    <div class="row">
                        <div class="left">
                            <div class="title">
                                {{ item.typeName || '' }}
                            </div>
                        </div>
                        <div class="right" @click="openAll">
                            <el-switch v-model="item.openStatus" active-color="#2D973E" inactive-color="#f7f7f7"
                                @change="change(item, index)" :disabled="!isSubscribe">
                            </el-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="left">
                            <div class="subtitle">
                                {{ item.typeDesc || '' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom" v-else>
                <div class="item" v-for="(item, index) in subDate" :key="index">
                    <div class="row">
                        <div class="left">
                            <div class="title">
                                {{ item.typeName || '' }}
                            </div>
                        </div>
                        <div class="right" @click="openAll">
                            <el-switch v-model="isOpenRemind" active-color="#2D973E" inactive-color="#f7f7f7"
                                @change="change(item, index)" :disabled="!isSubscribe">
                            </el-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="left">
                            <div class="subtitle">
                                {{ item.typeDesc || '' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="showOutPopup">
            <subscribe :imageUrl="imageUrl" :topImg="$globalHeader.imgUrl + '/static/icon/competition_subscribe.png'">
                <span class="span" slot="content"
                    style="line-height: 40rpx;font-size: 24rpx;color: #1D1E2C;">提示：需要关注公众号以便接收消息推送；</span>
            </subscribe>
        </el-dialog>

        <!-- <div class="popup-wrap" v-if="showOutPopup" @close="close">
            <div class="json-popup popup">
                
            </div>
        </div> -->
        <!-- <el-dialog :show="showOutPopup" @close="close" mode="center">
            <subscribe :imageUrl="imageUrl">
                <span class="span" slot="content"
                    style="line-height: 40rpx;font-size: 24rpx;color: #1D1E2C;">提示：需要关注公众号以便接收消息推送；</span>
            </subscribe>
        </el-dialog> -->
    </div>
</template>

<script>
import subscribe from "@/components/common/subscribe.vue";

import { officialAccounts } from '@/api/common/common.js'
import { getSubscribe, updateSubscribe } from '@/api/subscribe/subscribe.js'
export default {
    components: { subscribe },
    data() {
        return {
            showOutPopup: false,
            imageUrl: null,

            subDate: {},
            isOpenAll: false,
            isOpenRemind: false,
            isSubscribe: false,
            isShowtips: false,
            isChanging: false,
        }
    },
    created() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            //订阅状态
            getSubscribe().then(res => {
                this.subDate = res.data.subDate
                this.isOpenAll = res.data.isOpenAll
                this.isSubscribe = res.data.isSubscribe
                this.isOpenRemind = res.data.isOpenRemind
            })
            //获取二维码
            if (this.imageUrl) return
            officialAccounts("personage_message_set").then(res => {
                this.imageUrl = res.data.linkUrl
            })
        },
        getStatus(item) {
            return item.dataStatus == 1 && item.openStatus
        },
        toFollow() {
            this.showOutPopup = true
        },
        close() {
            this.showOutPopup = false
            this.fetchData()
        },
        openAll() {
            if (!this.isSubscribe) {
                this.showOutPopup = true
            }
        },
        changeAll() {
            if (this.isChanging) return
            this.isChanging = true
            updateSubscribe({ isOpenRemind: this.isOpenRemind }).then(() => {
                this.isChanging = false
                this.fetchData()
            })
        },
        change(item, index) {
            if (this.isChanging) return
            this.isChanging = true
            if (this.subDate[index].dataStatus == 0) {
                this.subDate[index].dataStatus = 1
                if (this.subDate[index].openStatus == false) {
                    this.subDate[index].openStatus = true
                }
            }
            updateSubscribe({ templateTypeId: this.subDate[index].templateTypeId, openStatus: this.subDate[index].openStatus }).then(() => {
                this.isChanging = false
                this.fetchData()
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.message-page {
    background-color: #F2F2F2;

    .message-container {
        .item {
            display: flex;
            flex-direction: column;
            padding: 24px 0;
            margin: 0 16px;
            border-bottom: 1px solid #F8F8F8;
            box-sizing: border-box;

            .row {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .left {
                    flex: 1;
                    display: flex;
                    align-items: center;

                    .title {
                        line-height: 1.5;
                        font-size: 14px;
                        color: #1D1E2C;
                    }

                    .subtitle {
                        white-space: pre-wrap;
                        line-height: 1.5;
                        font-size: 12px;
                        color: #999999;
                    }
                }

                .right {
                    flex-shrink: 0;
                    margin-left: 12px;
                }
            }
        }

        .item:last-child {
            border-bottom: 0px solid #F8F8F8;
        }

        .top {
            background-color: #fff;
        }

        .tips {
            background-color: #F2F2F2;

            .tips-item {
                padding: 8px 16px;
                margin: 0;
                background-color: #FFD8B0;
                border-bottom: 0;

                .left {
                    .title {
                        font-size: 12px;
                        color: #FF6600;
                    }
                }

                .right {
                    cursor: pointer;

                    .btn {
                        padding: 2px 16px;
                        line-height: 25px;
                        font-size: 14px;
                        color: #fff;
                        background-color: #FF6600;
                        border-radius: 18px;
                    }
                }
            }

            .tip-item2 {
                padding: 16px;
                margin: 0;
                background-color: #F2F2F2;

                .left {
                    .title {
                        line-height: 1.5;
                        font-size: 12px;
                        color: #999999;
                    }
                }
            }
        }

        .bottom {
            background-color: #fff;
        }
    }


    /deep/ .el-dialog {
        width: 32%;
        border-radius: 12px;
    }

    /deep/ .el-dialog__header {
        padding: 0;

        .el-dialog__headerbtn {
            z-index: 1000;

            .el-dialog__close {
                font-size: 28px;
            }
        }
    }

    /deep/ .el-dialog__body {
        padding: 0;
    }

    .popup-wrap {
        z-index: 100;

        .mask {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 101;
            background: rgba(0, 0, 0, 0.5);
        }

        .popup {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 32%;
            min-width: 480px;
            min-height: 554px;
            height: auto;
            max-height: 70%;
            overflow: auto;
            background-color: #fff;
            border-radius: 12px;
            z-index: 102;

            .close-btn {
                position: absolute;
                top: 8px;
                right: 12px;
                padding: 12px;
                color: #333;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
}
</style>