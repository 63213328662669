<template>
  <div class="page">
    <div
      class="match-invite-page page"
      v-if="displayPage"
      v-loading.fullscreen.lock="isLoading"
    >
      <!-- 顶部 -->
      <page-top></page-top>
      <!-- 头部 -->
      <Header @chooseTab="getTab" currentIndex="3"></Header>
      <!-- 主体 -->
      <main class="main">
        <section class="breadcrumb-plate w">
          <div class="label">我的位置：</div>
          <breadcrumb />
        </section>

        <section class="content w">
          <div class="course-card">
            <!-- 课程 -->
            <div class="course-card-content">
              <div class="c-left" v-if="course">
                <div class="top">
                  <div class="logo">
                    <div class="img"><img :src="course.logo" /></div>
                    <h2>{{ course.schoolName }}</h2>
                  </div>

                  <div class="end-time" v-if="day == '已截稿'">
                    <span>{{ day }}</span>
                  </div>

                  <div class="end-time" v-else>
                    距离截稿还剩<span>{{ day }}</span
                    >天
                  </div>
                </div>

                <div class="base">
                  <div class="img"><img :src="course.image" /></div>
                  <div class="base-content">
                    <div class="title">{{ course.courseName }}</div>
                    <div class="avatar">
                      <div class="avatar-wrap">
                        <div
                          class="image-wrap"
                          v-for="(item, index) in course.avatarList"
                          :key="index"
                        >
                          <img class="image" :src="item" />
                        </div>
                      </div>
                      <div class="num">{{ course.submitNum }}人已提交课业</div>
                    </div>
                    <div class="time">
                      <div>截至：{{ course.signupEndTime }}</div>
                      <div>
                        {{ course.courseStatus }}
                      </div>
                    </div>

                    <div class="apply-btn">
                      <div @click="courseForm()">
                        <el-button type="primary" round style="width: 100%"
                          >去提交</el-button
                        >
                      </div>
                      <div class="hover-record" @click="courseRecord()">
                        提交记录
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 课程状态背景图  404 未登录  未认证 -->
              <div class="bg" :class="userStatusObj[userStatus].bgClass" v-else>
                <div class="bg-content">
                  <div class="title">
                    {{ userStatusObj[userStatus].title + schoolName }}
                  </div>
                  <div class="desc">
                    {{ userStatusObj[userStatus].desc + schoolName }}
                  </div>
                  <div
                    class="btn hover-def"
                    @click="loginOrAudit(userStatusObj[userStatus].router)"
                    v-if="userStatusObj[userStatus].isBtn"
                  >
                    <span>{{ userStatusObj[userStatus].buttom }}</span>
                    <span class="icon iconfont icon-more"></span>
                  </div>
                  <div class="font-size" v-else>
                    {{ userStatusObj[userStatus].buttom }}
                  </div>
                </div>
              </div>

              <div class="desc c-left" v-if="course">
                <div class="title">
                  <div>课程介绍</div>
                </div>
                <div v-html="course.courseDesc"></div>
              </div>

              <div class="teacher-mien">
                <div class="mien-head">
                  <div class="left-img">
                    <img
                      :src="
                        $globalHeader.imgUrl +
                        '/static/pc/competition/detail/web-competition-detail-plan-left.png'
                      "
                    />
                  </div>
                  <div class="title">导师风采</div>
                  <div class="right-img">
                    <img
                      :src="
                        $globalHeader.imgUrl +
                        '/static/pc/competition/detail/web-competition-detail-plan-right.png'
                      "
                    />
                  </div>
                </div>

                <!-- 导师列表 -->
                <div class="teacher-list">
                  <div v-for="(item, index) in teacherList" :key="index">
                    <!-- <TeacherMienItem :data="item"/> -->
                    <TeacherMienList :data="item" />
                  </div>
                </div>
                <div class="more-btn" v-if="teacherList.length">
                  <el-button
                    class="btn"
                    type="primary"
                    round
                    @click="moreTeacher"
                    >查看更多导师风采</el-button
                  >
                </div>
              </div>
            </div>
            <!-- 模板 项目 -->
            <div class="c-right">
              <div style="margin-bottom: 30px">
                <CourseTemplate />
              </div>
              <div>
                <CourseStrategy />
              </div>
            </div>
          </div>
        </section>
      </main>
      <!-- 底部 -->
      <Footer></Footer>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import CourseTemplate from "./components/courseTemplate.vue";
import CourseStrategy from "./components/courseStrategy.vue";
// import TeacherMienItem from "./components/teacher-mien-item.vue";
import TeacherMienList from "./components/teacherMienList.vue";
import { getCourseList, getTeacherList,getProjectAuditStatus } from "@/api/course/course.js";
import { local } from "@/utils/storage.js";
export default {
  name: "Course",
  components: {
    pageTop,
    Header,
    Footer,
    Breadcrumb,
    CourseTemplate,
    //  TeacherMienItem,
    TeacherMienList,
    CourseStrategy,
  },
  data() {
    return {
      isLoading: true,
      displayPage: true,
      course: null,
      teacherList: [],
      day: 0,
      schoolName: "",
      applyId:null,
      signupStatus:null,
      //用户状态选择课程背景图
      userStatusObj: {
        404: {
          bgClass: "bg-img-404",
          title: "",
          desc: "您的学校",
          buttom: "还没有新的双创项目内容",
          isBtn: false,
        },
        notLogIn: {
          bgClass: "bg-img-not-log",
          title: "创新创业项目",
          desc: "请先登录并完成认证，以便参与项目",
          buttom: "请登录",
          router: "/login",
          isBtn: true,
        },
        notAuth: {
          bgClass: "bg-img-not-log",
          title: "创新创业项目",
          desc: "请先完成认证，以便参与项目",
          buttom: "去认证",
          router: "/personCenter/auth",
          isBtn: true,
        },
      },
      userStatus: "404",
    };
  },
  methods: {
    //初始化
    init() {
      let isLogin = local.Get("isLogin");
      //登录状态拿课程信息
      if (isLogin) {
        //拿用户信息
        let scCustom = local.Get("scCustom");
        //没有认证
        if (scCustom.auditStatus != "2") {
          this.userStatus = "notAuth";
          return;
        }
        this.courseList();
        
      } else {
        this.userStatus = "notLogIn";
      }
      this.isLoading = false;
    },
    //查询项目状态
    projectAuditStatus(){
      getProjectAuditStatus(this.course.id).then(res =>{
        this.applyId = res.data.id;
        this.signupStatus = res.data.signupStatus;
      })
    },
    //登录或者认证
    loginOrAudit(path) {
      this.$router.push({ path: path });
    },
    //查看更多导师
    moreTeacher() {
      this.$router.push("/moreTeacher");
    },
    //导师列表
    getTeacherList() {
      getTeacherList({ limit: 6 }).then((res) => {
        this.teacherList = res.data;
      });
    },
    //获取课程
    courseList() {
      getCourseList().then((res) => {
        console.log(res);
        this.schoolName = res.data.school;
        if (res.data && res.data.taskList) {
          this.course = res.data.taskList[0];
          if(this.course.pressTime == '已截稿'){
            this.day = '已截稿';
          }else{
            this.day = this.course.pressTime.replace(/[^\d]/g, " ").trim();
          }
          this.projectAuditStatus();
        }
      });
    },
    //获取header组件的点击事件
    getTab(data) {
      if (data && typeof data == "string") {
        this.$router.push("/" + data);
      }
    },
    courseRecord() {
      this.$router.push("/personCenter/courseList");
    },
    courseForm() {
      let params = {
      };
      if(this.applyId != null){
        params.applyId = this.applyId;
      }
      if(this.signupStatus != null){
        params.signupStatus = this.signupStatus;
      }
      console.log("applyId",this.applyId,this.signupStatus);
      if(this.applyId && this.signupStatus == "1"){
        this.$confirm("当前课程已提交", "提示", {
              confirmButtonText: "去看看",
              cancelButtonText: "我知道了",
              type: "warning",
            })
            .then(() => {
              //路由
               window.location.href = "/personCenter/courseList/courseDetail/" + this.applyId;
            })
            .catch(() => {
              
            })
      }else{
        this.$router.push({
          path:"/course/form",
          query:params
        });
      }
      
      
    },
  },
  created() {
    // this.courseList();
    this.getTeacherList();
  },
  watch: {
    $route: {
      handler() {
        this.init();
        if (this.$route.path == "/course") {
          this.displayPage = true;
        } else {
          this.displayPage = false;
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.match-invite-page {
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }
    }
    .content {
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;
      background-color: #fff;
      background: linear-gradient(#fff 0%, #f4fbff 30%, #fff 100%);
      .course-card {
        display: flex;
        padding-left: 50px;
        padding-top: 40px;
        .course-card-content {
          display: flex;
          flex-direction: column;

          .bg-img-404 {
            background-image: url(#{$bgImageUrl + '/static/icon/v2/course-404banner.jpg'});
          }
          .bg-img-not-log {
            background-image: url(#{$bgImageUrl + '/static/icon/v2/course-login-auth.jpg'});
          }

          .bg {
            height: 200px;
            display: flex;
            flex-direction: row-reverse;
            color: #fff;
            border-radius: 10px;
            background-size: cover;
            .bg-content {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width: 50%;
              padding: 30px 10px;
              .title {
                font-size: 23px;
              }
              .desc {
                font-size: 18px;
              }
              .font-size {
                font-size: 18px;
              }
              .btn {
                width: 200px;
                text-align: center;
                border-radius: 20px;
                background: linear-gradient(to right, #ffcd34, #ff9200);
                padding: 8px 10px;
                font-size: 16px;
                color: #fff;
              }
            }
          }
        }
        .c-left {
          padding: 30px;
          width: 750px;
          box-shadow: -2px 2px 20px 0 #dbefffad;
          -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
          background-color: #fff;
          .top {
            display: flex;
            margin-bottom: 20px;
            align-items: center;
            .logo {
              flex: 1;
              display: flex;

              .img {
                width: 60px;
                height: 60px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }

              h2 {
                margin-left: 20px;
                line-height: 60px;
              }
            }

            .end-time {
              flex: 1;
              color: #ff5733;
              text-align: right;
              font-size: 22px;
              padding: 20px 40px;
              background: linear-gradient(to right, #fff, #fff6ee);
              span {
                padding: 0px 7px;
                font-size: 30px;
                font-weight: bold;
              }
            }
          }

          .base {
            display: flex;
            .img {
              width: 240px;
              height: 192px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
              }
            }
            .base-content {
              margin-left: 40px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .title {
                width: 430px;
              }
              .time-scope {
                color: #c4c4c4;
              }
              .avatar {
                // flex-shrink: 0;
                display: flex;
                justify-content: space-between;
                //  align-items:center
                // align-items: center;
                // position: relative;
                // margin-right: 16px;
                // padding-left: 20px;
                .avatar-wrap {
                  display: flex;
                  margin-left: 20px;
                  .image-wrap {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    margin-left: -20px;
                    .image {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }
                }
                .num {
                  line-height: 40px;
                }
              }
              .time {
                display: flex;
                justify-content: space-between;
              }
              .apply-btn {
                display: flex;
                justify-content: space-between;
                :nth-child(1) {
                  width: 320px;
                }
                .hover-record {
                  display: flex;
                  width: 60px;
                  align-items: center;
                  justify-content: right;
                  border-bottom: 5px solid #808080;
                  cursor: pointer;
                }
                .hover-record:hover {
                  color: #409eff;
                  border-bottom: 5px solid #409eff;
                }
              }
            }
          }
        }

        .desc {
          margin-top: 30px;
          font-size: 16px;
          line-height: 30px;
          .title {
            position: relative;
            top: -30px;
            left: -30px;
            width: 135px;
            height: 43px;
            background-color: #2a82e4;
            color: #fff;
            font-weight: bold;
            border-bottom-right-radius: 15px;
            div {
              padding-top: 7px;
              padding-left: 30px;
            }
          }
        }

        .c-right {
          margin-left: 40px;
          display: flex;
          flex-direction: column;
        }
      }

      .teacher-mien {
        width: 750px;
        margin-top: 50px;
        .mien-head {
          display: flex;
          .left-img {
            flex: 2;
            padding-right: 30px;
            text-align: right;
          }
          .right-img {
            flex: 2;
          }
          .title {
            flex: 2;
            text-align: center;
            font-size: 30px;
            line-height: 50px;
            color: #4ea4f9;
            font-weight: bold;
            letter-spacing: 15px;
            background: linear-gradient(180deg, #47b8f9 0%, #597af8 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          img {
            width: 73px;
            height: 49px;
          }
        }

        .teacher-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 20px;
        }
        .more-btn {
          margin-top: 40px;
          text-align: center;
          .btn {
            padding: 12px 40px;
          }
        }
      }
    }
  }
}
</style>
