<template>
  <div :class="{'body-bcg':displayPageChild}">
    <div class="main-body" v-if="displayPageChild && policyList.length">
      <div class="content-wrap" v-for="(item, index) in policyList" :key="index">
        <div class="content-header">
          <div class="content-id">单号：{{ item.handleNo }}</div>
          <div class="content-status">
            <span :style="{ color: statusColor(item.auditStatus) }">{{
              item.auditStatus | auditStatusFilter
              }}</span>
          </div>
        </div>
        <div class="content-inner">
          <div class="content-left">
            <img :src="item.cover" class="img" />
          </div>
          <div class="content-right">
            <div v-if="item.policyName">{{ item.policyName }}</div>
            <div class="txt">
              申请时间：
              {{ item.operateTime | formatDate }}
            </div>
            <!-- <div class="txt">评审时间：{{ item.operateTime }}</div> -->
          </div>
        </div>
        <div class="content-comment" v-if="item.auditResult">
          提示说明：{{ item.auditResult || "" }}
        </div>
        <div class="content-extend">
          <div class="score"></div>
          <div class="item-control">
            <div v-if="item.auditStatus == 1" class="btn cancle-btn" @click.stop="toCancle(item)">
              取消申请
            </div>
            <div class="btn delete-btn" v-if="
              item.auditStatus == 3 ||
              item.auditStatus == 4 ||
              item.auditStatus == 5 ||
              item.auditStatus == 6
            " @click.stop="deleteConduct(item)">
              删除
            </div>
            <div class="btn reapply-btn" v-if="
              item.auditStatus == 4 ||
              item.auditStatus == 5 ||
              item.auditStatus == 6
            " @click.stop="reConduct(item)">
              重新申请
            </div>
            <div class="btn detail-btn " @click.stop="toDetail(item.id)">详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty" v-else-if="displayPageChild && !policyList.length">
      <el-empty description="暂无数据"></el-empty>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { getPolicyPage, removeHandle } from "@/api/personal";
import { formatDate } from "@/utils/date";

const auditStatusMap = [
  { code: "1", name: "未评审" },
  { code: "2", name: "已评审" },
  { code: "3", name: "已取消" },
  { code: "4", name: "评审未通过" },
];
export default {
  data() {
    return {
      policyList: [],
      displayPageChild:true,
      limit:'99',
      page:'1'
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let params = {
        limit:this.limit,
        page:this.page
      }
      getPolicyPage(params).then((res) => {
        this.policyList = res.data.list;
      });
    },
    statusColor(status) {
      const colorMap = {
        1: "#3892F8", //未评审
        2: "#009933", //已晋级
        4: " #FF3300", //未晋级，
      };
      return colorMap[status];
    },
    toDetail(id) {
      this.$router.push({ path: "/personCenter/policyList/policyConductDetail/" + id });
        // window.location.href = "/personCenter/policyList/policyConductDetail/" + id;
    },
    toCancle(item) {
      const that = this;
      this.$confirm("是否确定取消申请", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then((res) => {
          if (!res) return;
          let params = {
            id: item.id,
            flag: false,
            wait: 1000,
          };
          removeHandle(params).then((res) => {
            if (res.data) {
              setTimeout(() => {
                that.fetchData();
                that.$message({
                  message: res.message,
                  type: "success",
                });
              }, 1000);
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
   
    deleteConduct(item) {
      const that = this;
      that.$confirm("是否确定删除申请", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then((res) => {
          if (!res) return;
          let params = {
            id: item.id,
            flag: true,
            wait: 1000,
          };
          removeHandle(params).then((res) => {
            if (res.data) {
              setTimeout(() => {
                that.fetchData();
                that.$message({
                  message: res.message,
                  type: "success",
                });
              }, 1000);
            }
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
     //重新申请
    reConduct(item) {
      this.$router.push({
        path: "/policy/policyDetail/" + item.interpretateId + "/policySub",
      });
    },
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy.MM.dd hh:mm");
    },

    statusColor(item) {
      if (item == "未晋级" || item == "评审未通过") {
        return "red";
      }
      if (item == "已晋级" || item == "初审通过") {
        return "green";
      }
      if (item == "已完成" || item == "已评审" || item == "2") {
        return "bule";
      }
    },
    auditStatusFilter(code) {
      let statusName = "";
      auditStatusMap.filter((item) => {
        if (code == item.code) {
          statusName = item.name;
        }
      });
      return statusName;
    },
  },
  watch: {
    $route: {
      handler() {
        this.fetchData();
        let routerList = this.$route.path.slice(1, this.$route.path.length).split('/')
        if (routerList && routerList.length == 2) {
          this.displayPageChild = true;

        } else {
          this.displayPageChild = false;
            
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.body-bcg {
  flex: 1;
  background-color: #fff;
}
.content-wrap {
  // margin-top: 2px;
  //   height: 120px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  //   padding-top: 10px;
  padding: 20px;
  background: #fff;

  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #eaeaea solid 1px;
    padding-bottom: 15px;
    .content-id {
      font-size: 16px;
      color: #999999;
    }
    .content-status {
      font-size: 16px;
      color: #999999;
    }
  }
  .content-inner {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
  }
  .content-left {
    height: 100px;
    .img {
      width: 120px;
      height: 100px;
      border-radius: 6px;
    }
  }

  .content-right {
    padding-top: 0px;
    margin-left: 20px;
    width: 100%;
    // border-bottom: #f5f5f5 solid 1px;
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    .txt {
      height: 34px;
      font-size: 16px;
      color: #999999;
      padding-top: 16px;
    }
    div:nth-child(1) {
      padding-top: 4px;
      height: 28px;
      font-size: 20px;
      color: #000000;
    }
    // div:nth-child(3) {
    //   padding-top: 14px;
    //   height: 26px;
    //   font-size: 18px;
    //   color: #666666;

    //   span {
    //     margin-left: 20px;
    //   }
    // }
  }
  .content-comment {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #f7f7f7;
    color: #3892f8;
    padding: 10px 15px;
    max-width: 884px;
    margin: 15px 0;
    font-size: 16px;
    line-height: 1.35em;
    white-space: pre-wrap;
    border-radius: 8px;
  }
  .content-extend {
    // margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    .score {
      padding-left: 8px;
      color: #3892f8;
      span {
        font-size: 20px;
        font-weight: bold;
        margin-right: 0.2em;
      }
    }
    .item-control {
      display: flex;
      .btn {
        padding: 8px 24px;
        border-radius: 20px;
        text-align: center;
        margin-left: 16px;
        cursor: pointer;
        border: solid 1px transparent;
      }
      .cancle-btn {
        color: #ff6600;
        border: #ff6600 solid 1px;
      }
      .reapply-btn {
        color: #3892f8;
        border: #3892f8 solid 1px;

      }
      .delete-btn {
        color: #989898;
        border: #989898 solid 1px;
      }
      .detail-btn {
        border: #888 solid 1px;
      }
    }
  }
}
.content-wrap:first-child {
  margin-top: 0;
}
.empty {
      min-height: 600px;
      height: auto;
      display: flex;
      justify-items: center;
      justify-content: center;
    }
</style>
