<template>
  <!-- 外部报名弹出层 -->
  <div class="outside">
    <div class="top">
      <div class="image-wrap">
        <img class="image" :src="topImg" />
      </div>
      <div class="close" @click="close()">
        <slot name="close-btn">
          <span class="icon iconfont icon-close"></span>
        </slot>
      </div>
    </div>
    <div class="center">
      <div class="item item1">
        <slot name="content">
          <span class="text">
            <span class="text1">提示：</span>
            <span class="text2">报名参赛需使用电脑端进行报名，可通过关注公众号获取电脑端报名地址；</span>
          </span>
        </slot>
      </div>
      <div class="item item3">
        <div class="left">
          <div class="item-top">
            <el-table v-loading="loadingQRCode" :data="loginQRCode" element-loading-background="#fff" height="240px">
            </el-table>
            <div class="item-top2">
              <div class="image-wrap" v-if="imageUrl">
                <img class="image" :src="imageUrl" />
              </div>
              <div class="noqrcode" v-else>
                <span class="text">二维码生成失败</span>
              </div>
            </div>
          </div>
          <div class="subtitle">扫一扫或长按进入</div>
          <div class="title">高校双创人才服务站</div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="item item4">
        <div class="text">1. 使用微信“扫一扫”，进入公众号；</div>
        <div class="text">2. 跳转至公众号，点击关注公众号；</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topImg: {
      typeof: String,
      default: "",
    },
    imageUrl: {
      typeof: String,
    },
  },
  data() {
    return {
      loadingQRCode: true, //加载二维码
      loginQRCode: [], //扫码登录二维码
    };
  },
  mounted() {
    if (this.imageUrl) {
      this.loginQRCode.push(this.imageUrl)
      setTimeout(() => {
        this.loadingQRCode = false
      }, 500);
    }
    console.log();
  },
  methods: {
    close() {
      this.$parent.closePopup();
    },
  },
};
</script>

<style lang="scss" scoped>
.outside {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;

  .top {
    position: relative;
    height: auto;

    .image-wrap {
      width: 100%;
      height: 100%;

      .image {
        width: 100%;
        height: 100%;
      }
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 16px;
      color: #000;
      cursor: pointer;
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    padding: 16px;

    .item {
      text-align: center;

      .text {
        display: flex;
        line-height: 20px;
        font-size: 12px;
        color: #1d1e2c;

        .text1 {
          flex-shrink: 0;
        }
      }
    }

    .item3 {
      display: flex;
      justify-content: center;
      align-items: center;

      .left {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 12px;

        .item-top {
          width: 240px;
          height: 240px;
        }

        .item-top2 {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          width: 240px;
          height: 240px;
        }

        .image-wrap {
          flex-shrink: 0;
          width: 240px;
          height: 240px;

          .image {
            width: 100%;
            height: 100%;
          }
        }

        .noqrcode {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 240px;
          height: 240px;

          .text {
            font-size: 24px;
            color: #69707f;
          }
        }

        .title {
          margin-top: 12px;
          font-size: 14px;
          color: #1d1e2c;
        }

        .subtitle {
          margin-top: 16px;
          font-size: 12px;
          color: #69707f;
        }
      }
    }
  }

  .bottom {
    .item4 {
      display: flex;
      flex-direction: column;
      padding: 12px 12px 8px;
      background-color: #e4e4e4;

      .text {
        margin-bottom: 10px;
        font-size: 14px;
        color: #69707f;
      }
    }
  }
}
</style>
