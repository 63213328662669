import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { MessageBox } from 'element-ui'
import animate from 'animate.css';
// js跨域请求插件
import { VueJsonp } from 'vue-jsonp';
Vue.use(VueJsonp);

Vue.config.productionTip = false

//引入elm提示框
Vue.prototype.$confirm = MessageBox.confirm;

//引入axios
import axios from 'axios' //引入axios
Vue.prototype.$axios = axios


//引入ele
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

const bus = new Vue()
Vue.prototype.$bus = bus;

Vue.prototype.$tip = new Vue()

//引入iconfont
import '@/assets/fonts/iconfont.css';

//引入基础样式
import '@/assets/style/base.css';


//引入全局方法11
import '@/utils/global.js';




new Vue({
  router,
  store,
  animate,
  render: h => h(App)
}).$mount('#app')


