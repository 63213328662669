<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
	import Vue from 'vue'
  // import { local } from "@/utils/storage.js";
  export default {
    beforeCreate() {
      Vue.prototype.$globalHeader = {
          fileUrl: "https://static.scjfu.com",
          imgUrl: "https://gx.scjfu.com"
        }
    },
  }
</script>
<style lang="scss">
</style>
