<template>
  <div class="match-invite-page page">
    <!-- 顶部 -->
    <page-top></page-top>
    <!-- 头部 -->
    <Header @chooseTab="getTab" currentIndex="3"></Header>
    <!-- 主体 -->
    <main class="main">
      <section class="breadcrumb-plate w">
        <div class="label">我的位置：</div>
        <breadcrumb />
      </section>

      <section class="content w">
        <div class="course-card">
          <!-- 课程 -->
          <div class="c-left">
            <div class="card">
              <div class="subInfo">
                <div class="item" v-for="(value,key,index) in authInfo" :key="index">
                  <div class="item-tit">
                    <span>{{authInfoMap[key]}}</span>
                  </div>
                  <div class="item-content">
                    <span>{{value}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="tip">请如实填写信息</div>
            <div class="card">
              <Form :formData="formData" :studyId="course?.id"/>

              <div class="foot">
                <div class="btn-wrap">
                  <div
                    class="btn btn-default"
                    @click="tempSave"
                    v-if="!isTempSave"
                  >
                    暂存草稿
                  </div>
                  <div class="btn btn-default" v-else>暂存中</div>
                  <div class="btn btn-primary" @click="submit" v-if="!isSave">
                    提交
                  </div>
                  <div class="btn btn-primary" v-else>提交中...</div>
                </div>
              </div>

            </div>
          </div>
          <!-- 模板 项目 -->
          <div class="c-right">
            <div style="margin-bottom: 30px">
              <CourseTemplate />
            </div>
            <div>
              <CourseStrategy />
            </div>
          </div>
        </div>
      </section>
    </main>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import CourseTemplate from "../components/courseTemplate.vue";
import CourseStrategy from "../components/courseStrategy.vue";
import Form from "@/components/form/form.vue";
import { getCourseList, getCourseForm,saveTempCourseApply,
        saveCourseFormList,getAuthDetail } from "@/api/course/course.js";
import { local } from "@/utils/storage.js";
export default {
  name: "Course",
  components: {
    pageTop,
    Header,
    Footer,
    Breadcrumb,
    CourseTemplate,
    CourseStrategy,
    Form,
  },
  data() {
    return {
      course: null,
      formData: [],
      formResult:null,
      isTempSave:false,
      isSave:false,
      applyId:null,
      signupStatus:null,
      form:{},
      authInfo:{},
      authInfoMap : {
        "school": "学校名称",
        
        "academy": "所属院系",
        "major": "所学专业",
        "name": "学生姓名",
        "studentNo": "学号"
      }
    };
  },
  methods: {
    //初始化
    init() {
      //路由参数
      this.applyId = this.$router.history.current.query.applyId;
      this.signupStatus = this.$router.history.current.query.signupStatus;
      let isLogin = local.Get("isLogin");
      //登录状态拿课程信息
      if (isLogin) {
        //拿用户信息
        let scCustom = local.Get("scCustom");
        //没有认证
        if (scCustom.auditStatus != "2") {
          this.userStatus = "notAuth";
          return;
        }
        this.courseList();
        this.authDetail();
      } else {
        this.userStatus = "notLogIn";
      }
    },
    //暂存
    tempSave(){
      this.isTempSave = true;
      this.formData.forEach(item => {
					this.form[item.field] = {
							id: item.id,
							value: item.value,
							isUpdate: item.rule.isUpdate
						}
				})
      //暂存
      let param = {
					applyId: this.applyId,
          courseId:this.course.id,
					form: this.form,
					signupStatus: '2'
				}
      saveTempCourseApply(param).then(res =>{
        if(res.code == 200){
          this.applyId = res.data;
          this.$message.success("暂存成功");
          
        }else{
          this.$message.error("暂存失败");
        }
      }).catch(mes =>{
          this.$message.error(mes);
      }).finally(() =>{
        this.isTempSave = false;
      })
    },
    //提交
    submit(){
      this.isSave = true;
      this.formData.forEach(item => {
					this.form[item.field] = {
							id: item.id,
							value: item.value,
							isUpdate: item.rule.isUpdate
						}
				})
      let param = {
          courseId:this.course.id,
					form: this.form,
					signupStatus: '1'
				}
      if(this.applyId){
        param.applyId = this.applyId;
      }
      saveCourseFormList(param).then(res =>{
        if(res.code == 200){
          this.applyId = res.data;
          this.$message.success("提交成功");
          this.$router.push("/course/result")
        }else{
          this.$message.error("提交失败");
        }
      }).catch(mes =>{
          this.$message.error(mes);
      }).finally(() =>{
        this.isSave = false;
      })
    },
    //获取表单
    courseForm(){
      let params = {
        courseId:this.course.id
      }
      if(this.applyId != null && this.signupStatus == "2"){
         this.$confirm("是否获取最近的暂存数据?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              params.applyId = this.applyId;
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            })
            .finally(() =>{
              getCourseForm(params).then((res) =>{
                this.formData = res.data.formList;
                this.formResult = res.data;
              });
            });
      }else{
        getCourseForm(params).then((res) =>{
          this.formData = res.data.formList;
          this.formResult = res.data;
        });
      }

      },

    //获取课程
    courseList() {
      getCourseList().then((res) => {
        console.log(res);
        this.schoolName = res.data.school;
        if (res.data && res.data.taskList) {
          this.course = res.data.taskList[0];
          this.day = this.course.pressTime.replace(/[^\d]/g, " ").trim();
          this.courseForm();
        }
      });
    },
    //获取认证信息
    authDetail(){
      getAuthDetail().then(res =>{
        this.authInfo.school = res.data.authInfo.school || ''
        this.authInfo.academy = res.data.authInfo.academy || ''
        this.authInfo.major = res.data.authInfo.major || ''
        this.authInfo.name = res.data.authInfo.name || ''
        this.authInfo.studentNo = res.data.authInfo.studentNo || ''
      })
    },
    //获取header组件的点击事件
    getTab(data) {
      if (data && typeof data == "string") {
        this.$router.push("/" + data);
      }
    },
  },
  created() {
    this.init();
  },
  mounted() {
    console.log("--------- 开启事件监听 ------------");
    this.$bus.$on("formDetail", (formDetail) => {
      this.formData.map((item) => {
        if (item.field == formDetail.field) {
          item.value = formDetail.value;
          item.remark = formDetail.remark;
        }
      });
    });
  },

  beforeDestroy() {
    console.log("--------- 取消事件监听 ------------");
    this.$bus.$off("formDetail");
  },
};
</script>

<style lang="scss" scoped>
.match-invite-page {
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }
    }
    .content {
      height: 100%;
      justify-content: space-between;
      align-items: flex-start;
      background-color: #fff;
      background: linear-gradient(#fff 0%, #f4fbff 30%, #fff 100%);
      .course-card {
        display: flex;
        padding: 40px 0px 0px 40px;

        .c-left {
          display: flex;
          flex-direction: column;
          width: 750px;

          .card {
            padding: 20px 5px;
            box-shadow: -2px 2px 20px 0 #dbefffad;
            -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
            background-color: #fff;
            .subInfo {
              padding-left: 15px;
              color: #666;
              font-size: 15px;

              .item {
                display: flex;
                align-items: flex-start;
                padding: 4px 0;


                .item-tit {
                  flex-shrink: 0;
                  width: 75px;
                  text-align: justify;
                  text-align-last: justify;
                }

                .item-tit::after {
                  content: "：";
                }

                .item-content {
                  flex: 1;
                  color: #333;
                  display: flex;
                  flex-direction: column;

                  .file {
                    color: #199ED8;
                    padding-bottom: 3px;
                  }
                }
              }
            }
            .foot {
              padding: 60px 0;

              .btn-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                .btn-default{
                  flex: 4;
                  background: linear-gradient(to right, #81cfff, #0099CC);
                }
                .btn-primary{
                  flex: 6;
                  flex: 6;
                  background: linear-gradient(to right, #FFCD34, #FF9200);
                }
                .btn {
                  margin: 10px;
                  text-align: center;
                  width: 60%;
                  height: 50px;
                  line-height: 50px;
                  font-size: 24px;
                  border-radius: 26px;
                  color: #fff;
                  background-color: #0099cc;
                  cursor: pointer;
                }
              }
            }
          }
          .tip {
            font-size: 16px;
            color: #a8a7a7;
            margin: 10px;
          }
        }

        .c-right {
          margin-left: 40px;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>
