import http from "@/utils/http.js"

//查询政策列表
export function getPolicyPage(data) {
    return http({
        url: "/api/web/policy/getPolicyPage",
        method: "GET",
		data:data,
    })
}

//获取分页推荐政策
export function getPolicyRecDegreePage(data) {
    return http({
        url: "/api/web/policy/getPolicyRecDegreePage",
        method: "GET",
        data: data
    })
}

//获取分页推荐政策
export function getHotHandlePolicy(data) {
    return http({
        url: "/api/web/policy/getHotHandlePolicy",
        method: "GET",
        data: data
    })
}

//获取分页最新政策
export function getNewPolicy(data) {
    return http({
        url: "/api/web/policy/getNewPolicy",
        method: "GET",
        data: data
    })
}

//查询政策类型
export function getPolicyType() {
    return http({
        url: "/api/web/policy/getPolicyType",
        method: "GET",
    })
}

// 获取政策详情
export function getPolicyDetail(data) {
	return http({
		url: "/api/web/policy/getPolicyById/" + data.id,
		method: 'GET',
	})
}
//获取政策详情--仅部分信息
export function getPolicyCrumbsl(data) {
    return http({
        url: "/api/web/policy/getPolicyCrumbs",
        method: "GET",
        data: data
    })
}
// 政策想办
export function policyCollection(data) {
	return http({
		url: "/api/web/policy/updatePolicyHandle/" + data.id + '/' + data.type,
		method: 'POST',
	})
}
//查询办理状态
export function getConductStatus(data) {
	return http({
		url: "/api/web/handleDetail/getAuditStatus",
		data: data,
		method: 'GET'
	})
}

//添加办理
export function saveHandle(data) {
	return http({
		url: "/api/web/handleDetail/saveHandle",
		data: data,
		method: 'POST'
	})
}

//回显办理数据
export function getHandleValidateApplication(data) {
	return http({
		url: "/api/web/handleDetail/getHandleValidateApplication",
		data: data,
		method: 'GET'
	})
}


