<!-- 
    参数格式

list        非必填          [{name:展示名称,type:跳转路由}]

bgColor     非必填          "#ff"  -   背景颜色

<slot name="logo-wrap">     插槽 控制logo 和 tab
<slot name="tab-wrap">

-->

<template>
  <div class="header">
    <div class="main w">
      <div class="left">
        <slot name="logo-wrap">
          <h1 class="logo-wrap">
            <div class="img-wrap">
              <img
                class="img"
                :src="$globalHeader.imgUrl + '/static/icon/v2/pc-home-logo.png'"
                alt="高校双创人才服务站logo"
              />
            </div>
          </h1>
        </slot>
        <div class="split-line" v-if="splitLine"></div>
        <slot name="tab-wrap">
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in resultList"
              :key="index"
              @click="chooseTab(item)"
              :class="{ active: currentIndex == index }"
            >
              {{ item.name }}
            </div>
          </div>
        </slot>
        <slot name="searchAll" v-if="!splitLine">
          <div class="searchBlock">
            <div class="input-block">
              <input
                type="text"
                class="input"
                placeholder="搜索"
                v-model="keyword"
                v-on:keyup.enter="search()"
              />
              <span class="icon iconfont icon-search" @click="search()"></span>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list", "currentIndex", "splitLine"],
  data() {
    return {
      resultList: [],
      defaultList: [
        { name: "首页", type: "home" },
        { name: "赛事活动", type: "match" },
        { name: "人才政策", type: "policy" },
        { name: "高校双创", type: "course" },
      ],
      keyword: "",
    };
  },
  created() {
    if (!this.list || !this.list.length) {
      this.resultList = this.defaultList;
    } else {
      this.resultList = this.list;
    }
  },
  methods: {
    chooseTab(item) {
      if (item.type == "home") {
        this.$router.push("/");
      } else {
        if (item.type && typeof item.type == "string") {
          //  this.$router.replace("/" + item.type);
          window.location.href = "/" + item.type;
        }
      }
    },
    search() {
      if (this.keyword) {
        this.$router.push({
          path: "/searchResult",
          query: {
            keyWord: this.keyword,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #efefef;

  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    .left {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .logo-wrap {
        padding: 0;
        margin: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        margin-right: 20px;
        max-width: 310px;

        .img-wrap {
          margin-right: 20px;
          height: 46px;
        }

        .title {
          margin-left: 12px;
          font-size: 20px;
        }
      }

      .split-line {
        width: 1px;
        height: 40px;
        transform: translate(-20px, 0);
        background-color: #f1f1f1;
      }
      .searchBlock {
        margin-left: 20px;
        width: 372px;
        color: #c2c2c2;
        font-size: 16px;
        .input-block {
          display: flex;
          align-items: center;
          background: #ffffff;
          border: 2px solid #ffffff;
          border-radius: 28px;
          padding-left: 20px;
          .tag {
            margin: 12px 6px;
            padding: 0 15px;
            border-right: 1px solid #eaeaea;
          }
          .icon {
            font-size: 20px;
            margin: 0.5em;
            padding-right: 10px;
            color: #514f4f;
            cursor: pointer;
          }
          .input {
            color: #353535;
            width: 372px;
          }
          .input::placeholder {
            color: #c2c2c2;
          }
        }
        .lables {
          display: flex;
          justify-content: space-around;
          color: #999;
          font-size: 16px;
          cursor: pointer;
          .item {
            padding: 20px 10px;
          }
          // .item:first-child {
          //   margin-left: 0;
          // }
          // .item:last-child {
          //   margin-right: 0;
          // }
        }
      }
      .list {
        height: 100%;
        display: flex;
        align-items: center;

        .item {
          position: relative;
          height: 100%;
          line-height: 100px;
          padding: 0 25px;
          font-size: 20px;
          cursor: pointer;
        }

        .item:hover {
          color: #597af8;
        }

        .active {
          color: #597af8;
        }

        .active::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 28px;
          transform: translate(-50%, 0);
          width: calc(100% - 56px);
          height: 3px;
          border-radius: 2px;
          background-color: #597af8;
          overflow: hidden;
        }
      }
    }
  }
}
</style>