<template>
  <div class="match-invite-page page">
    <!-- 顶部 -->
    <page-top></page-top>
    <!-- 头部 -->
    <Header @chooseTab="getTab"></Header>
    <!-- 主体 -->
    <main class="main">
      <section class="breadcrumb-plate w">
        <div class="label">我的位置：</div>
        <breadcrumb />
      </section>

      <section class="content w">
        <div class="c-left">
          <div class="invite-title" v-if="inviteInfo.inviteName">
            {{ inviteInfo.inviteName + "邀请您加入：" }}
          </div>
          <div class="invite-wap">
            <div class="invite-left" v-if="type == 'match'">
              <img :src="inviteInfo.competitionImage" />
            </div>
            <div class="invite-left" v-else-if="type == 'study'">
              <img :src="inviteInfo.courseImage" />
            </div>
            <div class="invite-right">
              <div class="competition-title">
                {{ inviteInfo.competitionTitle || inviteInfo.courseTitle }}
              </div>
              <div class="category-title">
                {{ inviteInfo.categoryTitle || inviteInfo.courseSchool }}
              </div>
            </div>
          </div>
          <div class="invite-footer">
            <el-button
              type="primary"
              v-if="type == 'match'"
              round
              class="el-btn"
              @click="hangleInvie()"
              style="background-color: #0099cc; width: 300px"
              >同意</el-button
            >
            <el-button
              type="primary"
              v-else-if="type == 'study'"
              round
              class="el-btn"
              @click="handleAgree()"
              style="background-color: #0099cc; width: 300px"
              >同意</el-button
            >
          </div>
        </div>
      </section>
    </main>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import { inviteInfo, beInvitedProjectMember } from "@/api/match/match";
import {
  studyBeInviteWeb,
  studyBeInvitedProjectMember,
} from "@/api/course/course";

export default {
  name: "inviteMember",
  components: { pageTop, Header, Footer, Breadcrumb },
  data() {
    return {
      inviteId: null,
      inviteInfo: {},
      type: "",
    };
  },
  methods: {
    //获取header组件的点击事件
    getTab(data) {
      if (data && typeof data == "string") {
        this.$router.push("/" + data);
      }
    },
    getInviteInfo(inviteId) {
      console.log("inviteIdinviteId==", inviteId);
      if (this.type == "study") {
        studyBeInviteWeb({
          inviteId: inviteId,
        }).then((res) => {
          if (!res.data) return;
          this.inviteInfo = res.data;
        });
      }
      if (this.type == "match") {
        inviteInfo(inviteId).then((res) => {
          if (!res.data) return;
          this.inviteInfo = res.data;
        });
      }
    },
    //处理同意按钮
    hangleInvie() {
     
      if (this.type !== "match") return;
      beInvitedProjectMember(this.inviteId).then((res) => {
        if (res.data) {
          this.$message({
            message: res.message,
            type: "success",
          });
        } else {
          if (res.code == 401) {
            this.$confirm("您暂未登录, 是否前往登录?", "登录提醒", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  path: "/login",
                  query: {
                    redirect: "/inviteMember?inviteId=" + this.inviteId + '&type=' + this.type,
                  },
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else if (res.code == 1077) {
            this.$confirm(res.message + ", 是否前往实名?", "认证提醒", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  path: "/personCenter",
                  query: {
                    code: "auth",
                    redirect: "/inviteMember?inviteId=" + this.inviteId + '&type=' + this.type,
                  },
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
    handleAgree() {
      if (this.type !== "study") return;
      studyBeInvitedProjectMember({
        inviteId: this.inviteId,
        type: 1,
      }).then((res) => {
        if (res.data) {
          this.$message({
            message: res.message,
            type: "success",
          });
        } else {
          if (res.code == 401) {
            this.$confirm("您暂未登录, 是否前往登录?", "登录提醒", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  path: "/login",
                  query: {
                    redirect: "/inviteMember?inviteId=" + this.inviteId + '&type=' + this.type,
                  },
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else if (res.code == 1077) {
            this.$confirm(res.message + ", 是否前往实名?", "认证提醒", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push({
                  path: "/personCenter",
                  query: {
                    code: "auth",
                    redirect: "/inviteMember?inviteId=" + this.inviteId + '&type=' + this.type,
                  },
                });
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },
  },
  created() {
    console.log("this.$route.query===", this.$route.query.inviteId);
    this.inviteId = this.$route.query?.inviteId;
    this.type = this.$route.query?.type;
    if (this.inviteId && this.type) {
      this.getInviteInfo(this.inviteId);
    } else {
      this.$confirm("链接已过期，请重新生成", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push({
            path: "/",
          });
        })
        .catch(() => {
          this.$router.push({
            path: "/",
          });
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.match-invite-page {
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .c-left {
        flex: 1;
        margin-right: 15px;
        background-color: #fff;
        box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
        padding: 70px 60px;
        padding-left: 300px;
        .invite-title {
          font-size: 18px;
          color: #000000;
          padding-bottom: 20px;
        }
        .invite-wap {
          margin: auto;

          display: flex;
          flex-direction: row;

          .invite-left {
            img {
              width: 160px;
              height: 160px;
              border-radius: 10px;
            }
          }
          .invite-right {
            margin-left: 30px;
            .competition-title {
              padding-top: 20px;
              font-size: 18px;
              font-weight: 400;
              color: #000000;
            }
            .category-title {
              padding-top: 50px;
              font-size: 16px;
            }
          }
        }
        .invite-footer {
          padding-top: 50px;
          padding-left: 100px;
        }
      }
    }
  }
}
</style>