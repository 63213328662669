import http from "@/utils/http.js"


export function getProvinceList(){
    return http({
        url:"/api/web/area/getProvinceList",
        method:"GET",
    })
}


export function getConfigAreaList(data){
    return http({
        url:"/api/web/area/getConfigAreaList",
        method:"GET",
        data:data,
    })
}

