<template>
  <div class="station-block" v-if="list.length">
    <div class="header">
      <div class="title">
        <span class="txt">人才服务站</span>
      </div>
      <div class="more hover-effect" @click="toMore">
        查看更多<span class="icon iconfont icon-more"></span>
      </div>
    </div>
    <div class="list">
      <div
        class="item hover-item"
        v-for="(item, index) in list"
        :key="index"
        @click="stationDetail(item)"
      >
        <img class="cover-image" mode="aspectFit" :src="item.schoolIcon" />
        <div class="content">
          <div class="title">{{ item.serviceStation }}</div>
          <div class="desc">
            <span>{{ item.city }}</span>
            <span>站长：{{ item.contactManList }}</span>
          </div>
          <div class="count">
            <span>政策服务({{ item.policyNum }})</span>&nbsp;&nbsp;
            <span>赛事活动服务({{ item.competitionNum }})</span>
          </div>
          <div class="extend">
            <div class="name">
              <span class="s">{{ item.liaisonNum }}个站</span>
              <div class="t">{{ item.schoolName }}</div>
              <span class="icon iconfont icon-more-t"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    toMore() {
      this.$router.push({
        path: "/station",
      });
    },
    stationDetail(item) {
      this.$router.push({
        path: "/station/stationDetail/" + item.stationId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.station-block {
  flex: 1;
  padding: 0 20px;
  margin: 20px 0;
  border-left: 1px solid #f2f2f2;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: 600;
    color: #000;
  }
  .more {
    line-height: 1;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #888;
    .icon {
      font-size: 16px;
    }
  }
}
.list {
  margin-top: 4px;
  .item {
    display: flex;
    position: relative;
    padding: 15px 0 15px 15px;

    .cover-image {
      width: 100px;
      height: 100px;
      border-radius: 10px;
      overflow: hidden;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 20px;

      .title {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .desc {
        color: #999;
        font-size: 12px;
        margin-top: 0.5em;

        span:nth-child(2)::before {
          content: "|";
          margin: 0 0.5em;
          font-size: 12px;
          color: #ddd;
        }
      }

      .count {
        color: #488dcc;
        font-size: 12px;
        margin-top: 0.5em;

        text:nth-child(2) {
          margin-left: 0.5em;
        }
      }

      .extend {
        font-size: 12px;
        flex: 1;
        display: flex;
        align-items: flex-end;
        line-height: 1;
        color: #454545;
        margin-top: 0.5em;

        .name {
          display: flex;
          align-items: center;
          background-color: #f5f5f5;
          height: 28px;
          padding: 0 10px;
          border-radius: 20px;
          overflow: hidden;

          .s {
            color: #488dcc;
            margin-right: 0.5em;
          }

          .t {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  .item::after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    content: "";
    height: 1px;
    background-color: #f0f0f0;
    overflow: hidden;
  }

  .item:last-child::after {
    display: none;
  }
}
</style>