<template>
  <div class="recommend">
    <div class="head">
      <h3 class="title">服务项目</h3>
      <div class="more">更多<span class="icon iconfont icon-more"></span></div>
    </div>
    <div
      class="item hover-effect"
      v-for="(item, index) in list"
      :key="index"
      @click="toDetail(item.interpretateId)"
    >
      <div class="item-cover">
        <img class="img" :src="item.productImg" alt="" />
      </div>
      <div class="item-content">
        <div class="item-title">{{ item.productName }}</div>
        <div class="item-desc">{{ item.productDesc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getServicePage } from "@/api/course/course.js";
export default {
  props: {},
  components: {},
  data() {
    return {
      list: [],
    };
  },

  methods: {
    getServicePage() {
      let params = {
        page: 1,
        limit: 5,
      };
      getServicePage(params).then((res) => {
        this.list = res.data.list;
      });
    },
    //详情
    toDetail() {
      //   this.$router.push({
      //     path: "/moreTeacher/tutorDetail/" + id,
      //   });
    },
  },
  created() {
    this.getServicePage();
  },
};
</script>

<style lang="scss" scoped>
.recommend {
  background-color: #fff;
  height: 346px;
  box-shadow: -2px 2px 20px 0 #dbefffad;
  -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
  .head {
    padding: 10px 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    .title {
      position: relative;
      font-size: 18px;
      font-weight: 400;
      padding: 6px 0;
      margin-left: 20px;
    }

    .title::after {
      position: absolute;
      content: " ";
      overflow: hidden;
      height: 3px;
      width: 2em;
      left: 0;
      bottom: 0;
      border-radius: 2px;
      background-color: #2d7cce;
    }

    .more {
      display: flex;
      align-items: center;
      padding: 6px 20px;
      cursor: pointer;

      .icon {
        font-size: 14px;
      }
    }
    .more:hover {
      color: #2d7cce;
    }
  }
  .item {
    display: flex;
    padding: 0 20px;
    margin-bottom: 15px;
    .item-cover {
      flex-shrink: 0;
      width: 39px;
      height: 39px;
      margin-right: 10rpx;
      .img {
        height: 100%;
        width: 100%;
        border-radius: 5px;
      }
    }
    .item-content {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      .item-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .item-desc {
        font-size: 12px;
        margin-top: 0.3em;
        color: #999;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
