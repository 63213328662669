import http from "@/utils/http.js"


export function getUserInfo() {
    return http({
        url: "/api/miniprogram/home/getLocationList",
        method: "GET"
    })
}

// 获取高校数据（实名）
export function getSchoolData(data) {
    return http({
        url: "/api/system/school/getScoolList",
        data: data,
        method: 'POST'
    })
}

// 获取院系
export function getYarnData(data) {
    return http({
        url: "/api/web/campus/getAcademyList/" + data.schoolId,
        method: 'GET',
        showLoading: true
    })
}

// 获取专业
export function getMajorData(data) {
    return http({
        url: "/api/web/campus/getMajorList/" + data.academyId,
        method: 'GET',
        showLoading: true
    })
}


// 获取身份认证信息
export function getAuthInfo(data) {
    return http({
        url: "/api/web/personal/getAuthDetail",
        data: data,
        method: 'GET'
    })
}


// 关于我们
export function getAboutUs() {
    return http({
        url: "/api/web/home/getAboutUs",
        method: 'GET'
    })
}