<template>
  <div class="recommend">
    <div class="head">
    <h3 class="title">模板下载</h3>
    <div class="more" @click="more">更多<span class="icon iconfont icon-more"></span></div>
    </div>
    <div class="item" v-for="(item,index) in courseTemplateList" :key="index">
        <div class="img">
          <img :src="$globalHeader.imgUrl+'/static/icon/v2/course-documents-icon.png'"/>
        </div>
        <div class="item-title hover-effect" @click="download(item)">{{item.name}}</div>
    </div>
    <!-- 增高 -->
    <div class="shoe-lift"></div>

     <el-dialog
      title="模板下载"
      :visible.sync="templateVisible"
      width="35%"
      top="20px"
      >
      <div class="dialog">
        <div class="item" v-for="(item,index) in courseTemplateAll" :key="index">
          <div class="img">
            <img :src="$globalHeader.imgUrl+'/static/icon/v2/course-documents-icon.png'"/>
          </div>
          <div class="item-title hover-effect" @click="download(item)">{{item.name}}</div>
        </div>
      </div>

    </el-dialog>

</div>
</template>

<script>

import { getCourseTemplatePage } from "@/api/course/course.js";
export default {
  props: {
    
  },
  components: {

  },
  data() {
    return {
      courseTemplateList:[],
      courseTemplateAll:[],
      templateVisible:false,
    };
  },
  
  methods: {
    download(item){
      window.open(item.path);
    },
    getCourseTemplatePage(){
      let params = {
        page:1,
        limit:4,
      }
      getCourseTemplatePage(params).then(res =>{
        this.courseTemplateList = res.data.list;
      })
    },

    // 获取全部
    getCourseTemplateList(){
      let params = {
        page:1,
        limit:999,
      }
      getCourseTemplatePage(params).then(res =>{
        this.courseTemplateAll = res.data.list;
      })
    },
    more(){
      this.getCourseTemplateList();
      this.templateVisible = true;
    },
  },
  created() {
    this.getCourseTemplatePage();
  },
};
</script>

<style lang="scss" scoped>

    .recommend {
    background-color: #fff;
    box-shadow: -2px 2px 20px 0 #dbefffad;
    -webkit-box-shadow: -2px 2px 20px 0 #dbefffad;
    .head {
        padding: 10px 0;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .title {
        position: relative;
        font-size: 18px;
        font-weight: 400;
        padding: 6px 0;
        margin-left: 20px;
        }

        .title::after {
        position: absolute;
        content: ' ';
        overflow: hidden;
        height: 3px;
        width: 2em;
        left: 0;
        bottom: 0;
        border-radius: 2px;
        background-color: #2D7CCE;
        }

        .more {
        display: flex;
        align-items: center;
        padding: 6px 20px;
        cursor: pointer;

        .icon {
            font-size: 14px;
        }
        }
        .more:hover {
        color: #2D7CCE;
        }
      }
      
      .shoe-lift{
        height: 20px;
      }
    }

    .dialog{
      height:700px;
      overflow-y: scroll;
    }

    .item{
        display: flex;
        padding-left: 20px;
        margin-bottom: 15px;
        .img{
          width: 30px;
          height: 30px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .item-title{
          margin-left: 10px;
          width: 260px;
          line-height: 30px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow:ellipsis;
        }
      }


</style>
