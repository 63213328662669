<template>
  <div class="plate-wrap">
    <div class="status-plate" :class="reviewStatus.class">
      <div class="title">
        <span>{{ reviewStatus.title }}</span>
      </div>
      <div class="subtitle">
        <span>{{ reviewStatus.subtitle }}</span>
      </div>
    </div>

    <div class="info-plate plate-common">
      <div class="title"> 单据信息 </div>
      <div class="content">
        <div class="item">
          <span class="item-left">业务单号：</span>
          <span class="item-right">{{ policyData.handleNo || "" }}</span>
        </div>
        <div class="item" v-if="reviewStatus.time">
          <span class="item-left">{{ reviewStatus.time }}：</span>
          <span class="item-right">{{ policyData.time || "" }}</span>
        </div>
        <div class="item" v-if="policyData.auditResult">
          <span class="item-left">提示说明：</span>
          <span class="item-right">{{ policyData.auditResult || "" }}</span>
        </div>
        
      </div>
    </div>

    <div class="detail-plate">
      <div class="policy-info plate-common">
        <div class="title">
          <div class="title_1">政策信息</div>
          <div class="detail-btn hover-text" @click="toDetail">
            <span class="text">政策详情</span>
            <span class="icon iconfont icon-more"></span>
          </div>
        </div>
        <div class="content">
          <div class="item">{{policyData.policyName || ''}}</div>
          <div class="item" @click="toDetail">
            <div class="item-left">
              <div class="item-cover">
                <img class="img" :src="policyData.cover" alt="" />
              </div>
            </div>
            <div class="item-right">
              <span class="text">政策类型：{{ policyData.policyType || "" }}</span>
              <span class="text">归口部门：{{ policyData.department || "" }}</span>
              <span class="text">政策级别：{{ policyData.policyLevel || "" }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="conduct-info plate-common">
        <div class="title"> 申请信息 </div>
        <div class="content">
          <div class="item">
            <div class="item-left">
              <span class="text">真实姓名：</span>
            </div>
            <span class="item-right">
              {{ policyData.realName || "" }}
            </span>
          </div>
          <div class="item">
            <div class="item-left">
              <span class="text">身份证号：</span>
            </div>
            <span class="item-right">
              {{ policyData.idCode || "" }}
            </span>
          </div>
          <div class="item">
            <div class="item-left">
              <span class="text">联系电话：</span>
            </div>
            <span class="item-right">
              {{ policyData.phone || "" }}
            </span>
          </div>
          <div class="item" v-if="policyData.fileList?.length">
            <div class="item-left">
              <span class="text">申请材料：</span>
            </div>
            <div class="item-right">
              <div class="material-wrap" v-for="(subitem,index) in policyData.fileList" :key="index">
                <div class="material-name">
                  <span v-if="subitem.originalName">{{subitem.originalName}}</span>
                </div>
                <div class="look" @click="lookFile(subitem)">
                  <span>查看</span>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="item-control">
        <div
            v-if="policyData.auditStatus == 1"
            class="btn cancle-btn"
            @click="toCancle()"
          >
            取消申请
          </div>
          <div
            class="btn delete-btn"
             v-if="
              policyData.auditStatus == 3 ||
              policyData.auditStatus == 4 ||
              policyData.auditStatus == 5 ||
              policyData.auditStatus == 6
            "
            @click.stop="deleteConduct()"
          >
            删除
          </div>
          <div
            class="btn reapply-btn"
            v-if="
              policyData.auditStatus == 4 ||
              policyData.auditStatus == 5 ||
              policyData.auditStatus == 6
            "
            @click.stop="reConduct()"
          >
            重新申请
          </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getPolicyDetail,removeHandle } from "@/api/personal";
import * as util from '@/utils/util';
const statusMap = {
		1: {
			title: "待审核",
			subtitle: "等待平台工作人员初步审核材料",
			class: "blue-bgc",
			time: "申请时间"
		},
		2: {
			title: "初审通过",
			subtitle: "本次申请已通过初审，正在递交办理申请",
			class: "blue-bgc",
			time: "审核时间"
		},
		3: {
			title: "已完成",
			subtitle: "关注政策，掌握未来",
			class: "green-bgc",
			time: "审核时间"
		},
		4: {
			title: "初审未通过",
			subtitle: "您本次的申请未通过初步审核，请核查并重新提交",
			class: "orange-bgc",
			time: "审核时间"
		},
		5: {
			title: "办理未通过",
			subtitle: "您本次的申请办理未通过",
			class: "orange-bgc",
			time: "审核时间"
		},
		6: {
			title: "已取消",
			subtitle: "您已取消本次申请",
			time: "取消时间"
		},
	}
export default {
  data() {
    return {
        policyId: "",
        conductId: "",
        conductStatus: "",
        reviewStatus: {},
        policyData: {}
    };
  },
  created() {

    let id = this.$route.params.id;

    this.conductId = this.$route.params.id
    this.fetchData(id)
  },
  methods: {
      fetchData(id) {
          console.log("ididididid===",id);
          getPolicyDetail({
              id:id
              }).then((res) => {
          console.log("ididididid=res==",res);
            if (!res.data) return;
            this.policyData = res.data;
            this.policyId = res.data?.interpretateId
            this.conductStatus = res.data?.auditStatus
            this.reviewStatus = statusMap[res.data?.auditStatus]
            console.log("this.reviewStatus--",this.reviewStatus);

      });
      },
      toCancle() {
        const that = this;
        this.$confirm("是否确定取消申请", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then((res) => {
            if (!res) return;
            let params = {
              id: that.conductId,
              flag: false,
              wait: 1000,
            };
            removeHandle(params).then((res) => {
              if (res.data) {
                setTimeout(() => {
                  that.fetchData(that.conductId);
                  that.$message({
                    message: res.message,
                    type: "success",
                  });
                }, 1000);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
      },
      deleteConduct() {
        const that = this;
        this.$confirm("是否确定删除申请", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then((res) => {
            if (!res) return;
            let params = {
              id: that.conductId,
              flag: true,
              wait: 1000,
            };
            removeHandle(params).then((res) => {
              if (res.data) {
                setTimeout(() => {
                  that.$message({
                    message: res.message,
                    type: "success",
                  });

                  that.$router.push({
                    path:"/personCenter/policyList"
                  })
                }, 1000);
              }
            });
          })
          .catch(() => {
            that.$message({
              type: "info",
              message: "已取消",
            });
          });
      },
      reConduct() {
        this.$router.push({
          path: "/policy/policyDetail/" + this.policyId + "/policySub",
        });
      },
      toDetail() {
          window.location.href = "/policy/policyDetail/" + this.policyId;
      },
      lookFile() {
        console.log("this.policyData.file.path",this.policyData.file.path);
        util.previewFile(this.policyData.file.path)
      }
  },
};
</script>

<style lang="scss" scoped>
.plate-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;

  .status-plate {
    padding: 20px;
    color: #fff;
    // border-radius: 16px;
    background-color: #c9c9c9;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);

    .title {
      font-size: 18px;
      font-weight: bold;
    }

    .subtitle {
      margin-top: 0.5em;
      font-size: 16px;
    }
  }

  .blue-bgc {
    background-color: #2f72ae !important;
  }

  .orange-bgc {
    background-color: #ff6600 !important;
  }

  .green-bgc {
    background-color: #339933 !important;
  }

  .gray-bgc {
    background-color: #c9c9c9 !important;
  }

  .blue-color {
    color: #2f72ae !important;
  }

  .orange-color {
    color: #ff6600 !important;
  }

  .green-color {
    color: #339933 !important;
  }

  .gray-color {
    color: #c9c9c9 !important;
  }

  .plate-common {
    padding: 20px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 4px;
      font-size: 18px;
      font-weight: bold;
      color: #1d1e2c;

      .detail-btn {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: #999;

        .text {
          font-size: 16px;
        }

        .icon {
          font-size: 14px;
        }
      }
    }

    .content {
      .item {
        display: flex;
        margin-top: 16px;
        font-size: 16px;
        color: #666;

        .item-left {
          display: flex;
          flex-shrink: 0;
          .item-cover {
                height: 94px;
                margin-right: 20px;

                img {
                  width: 116px;
                  height: 94px;
                  border-radius: 6px;

                }
              }
    

          .text {
            flex: 1;
          }
        }

        .item-right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .text {
            // margin-bottom: 6px;
            line-height: 1.35;
          }

          .material-wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            padding: 24px;
            background-color: #f7f7f7;
            border-radius: 16px;

            .look {
              color: #2f72ae;
            }
          }
        }

        .item-file {
          display: flex;
          justify-content: space-between;
          color: #3a81c3;

          .material-name {
            display: -webkit-box;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }

          .look {
            cursor: pointer;
            flex-shrink: 0;
          }
        }
      }

      .tips {
        display: flex;

        .item-left {
          flex-shrink: 0;
        }

        .item-right {
          flex: 1;
        }
      }
    }
  }

  .info-plate {
    margin-top: 16px;
    // border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  }

  .detail-plate {
    position: relative;
    margin-top: 20px;
    // border-radius: 16px;
    background-color: #fff;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
    .item-control {
      // position: absolute;
      bottom: 20px;
      right: 20px;
      display: flex;
      flex-direction: row-reverse;
      .btn {
        cursor: pointer;
        padding: 8px 24px;
        border-radius: 20px;
        text-align: center;
        margin-left: 16px;
        background: #fff;
        z-index: 999;
        border: solid 1px transparent;
      }
      .cancle-btn {
        color: #ff6600;
        border: #ff6600 solid 1px;
      }
      .reapply-btn {
        color: #3892f8;
        border-color: #3892f8;
      }
      .delete-btn {
        color: #989898;
        border: #989898 solid 1px;
      }
      .detail-btn {
        border: #888 solid 1px;
      }
    }
    .conduct-info {
      padding-top: 0;
      
      .conduct-material {
        .item-left {
          padding-top: 20px;
        }
      }
    }
  }

  .no-more {
    margin-top: 80px;
    margin-bottom: 160px;
    font-size: 16px;
    color: #999;
    text-align: center;
  }
}
</style>