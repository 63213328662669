var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{'body-bcg':_vm.displayPageChild}},[(_vm.displayPageChild && _vm.policyList.length)?_c('div',{staticClass:"main-body"},_vm._l((_vm.policyList),function(item,index){return _c('div',{key:index,staticClass:"content-wrap"},[_c('div',{staticClass:"content-header"},[_c('div',{staticClass:"content-id"},[_vm._v("单号："+_vm._s(item.handleNo))]),_c('div',{staticClass:"content-status"},[_c('span',{style:({ color: _vm.statusColor(item.auditStatus) })},[_vm._v(_vm._s(_vm._f("auditStatusFilter")(item.auditStatus)))])])]),_c('div',{staticClass:"content-inner"},[_c('div',{staticClass:"content-left"},[_c('img',{staticClass:"img",attrs:{"src":item.cover}})]),_c('div',{staticClass:"content-right"},[(item.policyName)?_c('div',[_vm._v(_vm._s(item.policyName))]):_vm._e(),_c('div',{staticClass:"txt"},[_vm._v(" 申请时间： "+_vm._s(_vm._f("formatDate")(item.operateTime))+" ")])])]),(item.auditResult)?_c('div',{staticClass:"content-comment"},[_vm._v(" 提示说明："+_vm._s(item.auditResult || "")+" ")]):_vm._e(),_c('div',{staticClass:"content-extend"},[_c('div',{staticClass:"score"}),_c('div',{staticClass:"item-control"},[(item.auditStatus == 1)?_c('div',{staticClass:"btn cancle-btn",on:{"click":function($event){$event.stopPropagation();return _vm.toCancle(item)}}},[_vm._v(" 取消申请 ")]):_vm._e(),(
            item.auditStatus == 3 ||
            item.auditStatus == 4 ||
            item.auditStatus == 5 ||
            item.auditStatus == 6
          )?_c('div',{staticClass:"btn delete-btn",on:{"click":function($event){$event.stopPropagation();return _vm.deleteConduct(item)}}},[_vm._v(" 删除 ")]):_vm._e(),(
            item.auditStatus == 4 ||
            item.auditStatus == 5 ||
            item.auditStatus == 6
          )?_c('div',{staticClass:"btn reapply-btn",on:{"click":function($event){$event.stopPropagation();return _vm.reConduct(item)}}},[_vm._v(" 重新申请 ")]):_vm._e(),_c('div',{staticClass:"btn detail-btn",on:{"click":function($event){$event.stopPropagation();return _vm.toDetail(item.id)}}},[_vm._v("详情")])])])])}),0):(_vm.displayPageChild && !_vm.policyList.length)?_c('div',{staticClass:"empty"},[_c('el-empty',{attrs:{"description":"暂无数据"}})],1):_vm._e(),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }