<template>
  <div class="search-page">
    <!-- 顶部 -->
    <page-top></page-top>
    <!-- 头部 -->
    <Header @chooseTab="getTab"></Header
    ><!-- 头部 -->
    <div class="search-result">
      <!-- 1.搜索框 -->
      <div class="search">
        <div class="search-input">
          <el-input
            v-model="keyWord"
            placeholder="请输入关键字进行搜索"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              class="el-button"
              @click="searchKeyWord"
              >搜索</el-button
            >
          </el-input>
        </div>
        <!-- 2. bar -->
        <div class="select-item">
          <div
            class="item hover-def"
            @click="clickBar(item)"
            v-for="(item, index) in navBarList"
            :key="index"
          >
            <div>
              <img
                :src="
                  currentBar.barName == item.barName
                    ? item.currentIcon
                    : item.icon
                "
              />
            </div>
            <span
              :class="{ barNameColor: currentBar.barName == item.barName }"
              >{{ item.barName }}</span
            >
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <div class="content-result">
        <div class="list-wrap" v-if="searchResult.length">
          <div
            class="item-wrap"
            v-for="(item, index) in searchResult"
            :key="index"
          >
            <div class="item">
              <searchItem :data="item" @clickMore="clickMore" />
            </div>
          </div>
        </div>
        <div class="empty-wrap" v-else>
          <el-empty description="暂无搜索信息"></el-empty>
        </div>

        <div class="recom-warp">
          <div class="recom">
            <competitionRecom />
          </div>
          <div class="recom">
            <policyRecom />
          </div>
        </div>
      </div>

      <div style="height: 100px"></div>
    </div>
    <el-dialog title="" :visible.sync="centerDialogVisible" width="30%" center>
      <div style="text-align: center">
        <img
          :src="$globalHeader.imgUrl + '/static/miniprogram/personCenter.png'"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import searchItem from "./components/search-item.vue";
import competitionRecom from "./components/competition-recom.vue";
import policyRecom from "./components/policy-recom.vue";
import {
  search,
  specificationSearch,
  searchFiler,
} from "@/api/search/search.js";
let CURRENTBAR = {
  barName: "",
  icon: "",
  currentIcon: "",
  type: "",
};
export default {
  props: {},
  components: {
    pageTop,
    Header,
    searchItem,
    competitionRecom,
    policyRecom,
  },
  data() {
    return {
      headerList: [], //header的tab列表
      keyWord: "",
      searchResult: [],
      page: 1,
      limit: 4,
      currentBar: { ...CURRENTBAR },
      isSuperposition: false, //是否叠加数组
      centerDialogVisible: false, //小程序二维码弹窗
      navBarList: [
        {
          barName: "人才政策",
          icon: this.$globalHeader.imgUrl + "/static/icon/icon-02-black.png",
          currentIcon:
            this.$globalHeader.imgUrl + "/static/icon/icon-02-blue.png",
          type: "cont_policy_interpretate",
        },
        {
          barName: "赛事活动",
          icon: this.$globalHeader.imgUrl + "/static/icon/icon-03-black.png",

          currentIcon:
            this.$globalHeader.imgUrl + "/static/icon/icon-03-blue.png",
          type: "mat_competition",
        },
        {
          barName: "双创项目",
          icon: this.$globalHeader.imgUrl + "/static/icon/icon-04-black.png",
          currentIcon:
            this.$globalHeader.imgUrl + "/static/icon/icon-04-blue.png",
          type: "camp_course",
        },
        {
          barName: "人才服务站",
          icon: this.$globalHeader.imgUrl + "/static/icon/icon-05-black.png",
          currentIcon:
            this.$globalHeader.imgUrl + "/static/icon/icon-05-blue.png",
          type: "stat_service_station_list",
        },
        {
          barName: "导师信息",
          icon: this.$globalHeader.imgUrl + "/static/icon/icon-06-black.png",
          currentIcon:
            this.$globalHeader.imgUrl + "/static/icon/icon-06-blue.png",
          type: "camp_teacher",
        },
        {
          barName: "服务项目",
          icon:
            this.$globalHeader.imgUrl + "/static/icon/teacher-icon01-black.png",
          currentIcon:
            this.$globalHeader.imgUrl + "/static/icon/teacher-icon01-blue.png",
          type: "camp_product_list",
        },
      ],
      isPopList: [
        "cont_dynamic",
        "camp_product_list",
        "camp_course_template",
        "cont_strategy",
      ], //要弹二维码窗口的类型数组
    };
  },

  methods: {
    //搜索
    searchKeyWord() {
      this.page = 1;
      this.limit = 4;
      let params = {
        key: this.keyWord,
        type: this.currentBar.type,
        page: this.page,
        limit: this.limit,
        area: 350200,
      };
      let seachAndFiler = this.currentBar.type ? searchFiler : search;
      seachAndFiler(params).then((res) => {
        this.searchResult = res.data;
      });
    },
    //点击bar
    clickBar(item) {
      this.isSuperposition = false;
      this.page = 1;
      this.limit = 4;
      if (this.currentBar.barName == item.barName) {
        this.currentBar = { ...CURRENTBAR };
        return;
      }
      this.currentBar = item;
      this.searchFilerType();
    },
    //过滤类型搜索
    searchFilerType() {
      let params = {
        key: this.keyWord,
        type: this.currentBar.type,
        page: this.page,
        limit: this.limit,
        area: 350200,
      };
      searchFiler(params).then((res) => {
        if (this.isSuperposition && this.searchResult.length) {
          //没有更多  删除 更多文字
          if (res.data[0].itemList.length < this.limit) {
            this.searchResult[0].moreName = "";
          }
          //数组叠加
          this.searchResult[0].itemList = this.searchResult[0].itemList.concat(
            res.data[0].itemList
          );
        } else {
          this.searchResult = res.data;
        }
      });
    },
    //点击更多
    clickMore(type) {
      if (this.isPopList.indexOf(type) > -1) {
        this.centerDialogVisible = true;
        return;
      }

      this.limit = 10;
      //开启数组叠加
      this.isSuperposition = true;
      if (this.currentBar.type != type) {
        this.searchResult = [];
        this.navBarList.forEach((e) => {
          if (e.type == type) {
            this.currentBar = e;
          }
        });
      }
      this.searchFilerType();
      this.page = this.page + 1;
    },
    //指定类型搜索
    searchKeyWordAndType() {
      let params = {
        key: this.keyWord,
        type: this.currentBar.type,
        page: this.page,
        limit: this.limit,
        area: 350200,
      };
      specificationSearch(params).then((res) => {
        this.searchResult = res.data;
      });
    },
    getTab(data) {
      if (data && typeof data == "string") {
        window.location.href = "/" + data;
        //  this.$router.push("/" + data);
      }
    },
  },
  created() {
    this.keyWord = this.$route.query.keyWord;
    this.currentBar.barName = this.$route.query.barName;
    if (this.currentBar.barName) {
      this.navBarList.forEach((e) => {
        if (e.barName == this.currentBar.barName) {
          this.currentBar = e;
        }
      });
    }
    this.searchKeyWord();
  },
};
</script>

<style lang="scss" scoped>
//输入框按钮
::v-deep .el-input__inner {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: none;
  height: 50px;
}

::v-deep .el-input__icon.el-icon-circle-close {
  font-size: 25px;
  color: rgb(187, 186, 186);
  padding-top: 6px;
  padding-right: 30px;
}
//搜索按钮
::v-deep .el-input-group__append {
  background-color: #409eff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-size: 16px;
}

.search-page {
  height: 100%;
  height: auto;
  background-color: #efefef;

  .search-result {
    max-width: 1200px;
    margin: 0 auto;
    .search {
      background: #fff;
      padding: 30px 200px;
      .search-input {
        width: 92%;
        .el-button {
          color: #fff;
          background-color: #409eff;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          border-top-right-radius: 14px;
          border-bottom-right-radius: 14px;
        }
      }

      .select-item {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;

        .item {
          margin-right: 70px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          div {
            width: 48px;
            height: 48px;
            text-align: center;
            line-height: 48px;
            box-shadow: 0 3px 12px 0 rgba(131, 196, 245, 0.38);
            border-radius: 50%;
          }
          span {
            margin-top: 10px;
          }
          .barNameColor {
            color: #2a79ee;
          }
          img {
            width: 50%;
            height: 50%;
          }
        }
      }
    }
    .content-result {
      display: flex;
      .list-wrap {
        width: 73.5%;
        flex-direction: column;
      }
      .empty-wrap {
        width: 73.5%;
        background-color: #fff;
        margin-top: 20px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .recom-warp {
        .recom {
          margin-top: 20px;
          flex-shrink: 0;
          width: 320px;
        }
      }
    }
    .item-wrap {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      .item {
        width: 100%;
        margin-right: 20px;
        padding: 20px 0px 10px 60px;
        background-color: #fff;
      }
    }
  }
}
</style>
