import Vue from 'vue'
import VueRouter from 'vue-router'
import $router from '../router'
import { local } from "@/utils/storage.js";

//引入页面文件
import Home from '@/views/home/index.vue'
import Match from '@/views/match/match.vue'
import MatchList from '@/views/match/pages/matchList.vue'
import MatchDetail from '@/views/match/pages/matchDetail.vue'
import MatchSub from '@/views/match/pages/matchSub.vue'
import MatchKindSub from '@/views/match/pages/matchKindSub.vue'
import MatchSubResult from '@/views/match/pages/matchSubResult.vue'

import MatchDynamic from '@/views/dynamics/matchDynamics.vue'
import DynamicDetail from '@/views/dynamics/dynamicDetail.vue'

import ArticleList from '@/views/article/articleList.vue'
import ArticleDetail from '@/views/article/articleDetail.vue'

import PersonCenter from '@/views/personal/index.vue'
import AuthDetail from '@/views/personal/pages/authDetail.vue'
import CourseList from '@/views/personal/pages/courseList.vue'
import MatchRecordList from '@/views/personal/pages/matchRecordList.vue'
import PolicyList from '@/views/personal/pages/policyList.vue'
import OrderList from '@/views/personal/pages/orderList.vue'
import CourseDetail from '@/views/personal/pages/courseDetail.vue'
import MatchRecordDetail from '@/views/personal/pages/matchRecordDetail.vue'
import PolicyConductDetail from '@/views/personal/pages/policyConductDetail.vue'
import OrderDetail from '@/views/personal/pages/orderDetail.vue'

import MessageRemind from '@/views/personal/pages/messageRemind.vue'
import AboutUs from '@/views/personal/pages/aboutUs.vue'

import Policy from '@/views/policy/index.vue'
import PolicyDetail from '@/views/policy/policyDetail.vue'
import PolicySub from '@/views/policy/policySub.vue'
import PolicySubResult from '@/views/policy/policySubResult.vue'

import Course from '@/views/course/index.vue'
import CourseForm from '@/views/course/pages/courseForm.vue'
import CourseResult from '@/views/course/pages/courseResult.vue'
import MoreTeacher from '@/views/course/more-teacher/index.vue'
import TutorDetail from '@/views/course/more-teacher/tutorDetail.vue'

import Phone from '@/views/phone/index.vue'

import InviteMember from '@/views/match/pages/inviteMember.vue'

import SearchResult from '@/views/search/searchResult.vue'

import Station from '@/views/station/station.vue'
import StationDetail from '@/views/station/stationDetail.vue'
import ApplySub from '@/views/station/applySub.vue'

import Login from '@/views/login/login.vue'
import Register from '@/views/register/register.vue'
import School from '@/views/school/index.vue'
import page404 from '@/views/common/404.vue'
Vue.use(VueRouter)


//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '高校双创人才服务站-创新创业人才服务平台',
      content: {
        keywords: '大学生人才、创新创业、双创项目、创新创业大赛、互联网+、创业交流、创新创业孵化、导师、评委、高校人才服务站',
        description: '提供大学生创新创业服务、高校人才服务、人才政策服务、组织创新创业大赛、创新创业项目、互联网+大赛、创业孵化、创新创业培训交流、双创基地、新媒体'
      },
      breadcrumb: '首页'
    },
  },
  {
    path: '/match',
    name: 'Match',
    component: Match,
    meta: {
      title: '高校双创人才服务站-创新创业人才服务平台',
      content: {
        keywords: '大学生人才、创新创业、双创项目、创新创业大赛、互联网+、创业交流、创新创业孵化、导师、评委、高校人才服务站',
        description: '提供大学生创新创业服务、高校人才服务、人才政策服务、组织创新创业大赛、创新创业项目、互联网+大赛、创业孵化、创新创业培训交流、双创基地、新媒体'
      },
      breadcrumb: '赛事推荐'
    },
    children: [
      {
        path: 'list',
        name: 'MatchList',
        component: MatchList,
        meta: {
          breadcrumb: '赛事列表',
          requiresAuth: false
        }
      },
      //赛事详情
      {
        path: 'matchDetail/:id',
        name: 'MatchDetail',
        component: MatchDetail,
        meta: {
          title: '赛事详情',
          content: {
            keywords: '赛事报名、赛事动态、赛事介绍、赛事流程',
            description: '提供高校大学生赛事活动、赛事组织、赛事报名、赛事交流、项目评审、导师评审服务'
          },
          breadcrumb: '赛事详情',
        },
        children: [
          //赛事报名
          {
            path: 'matchSub/:matchId',
            name: 'MatchSub',
            component: MatchSub,
            meta: {
              breadcrumb: '赛事报名',
              requiresAuth: true
            }
          },
          //赛事报名 回显
          {
            path: 'matchSub/:matchId/:applyId',
            name: 'MatchSub',
            component: MatchSub,
            meta: {
              breadcrumb: '赛事报名',
              requiresAuth: true
            }
          },
          //配套报名
          {
            path: 'matchKindSub/:matchId/:kindId',
            name: 'MatchKindSub',
            component: MatchKindSub,
            meta: {
              breadcrumb: '赛事配套报名',
              requiresAuth: true
            }
          },
          //配套报名 回显
          {
            path: 'matchKindSub/:matchId/:kindId/:applyId',
            name: 'MatchKindSub',
            component: MatchKindSub,
            meta: {
              breadcrumb: '赛事配套报名',
              requiresAuth: true
            }
          },
          //报名结果
          {
            path: 'matchSubResult',
            name: 'MatchSubResult',
            component: MatchSubResult,
            meta: {
              breadcrumb: '报名结果',
              requiresAuth: true
            }
          },
        ]
      },
    ],
  },

  //赛事动态
  {
    path: '/matchDynamic',
    name: 'MatchDynamic',
    component: MatchDynamic,
    meta: {
      title: '赛事动态 - 动态新闻资讯',
      content: {
        keywords: '创新创业、大赛资讯动态、活动、互联网+、创新创业交流',
        description: '提供高校大学生创新创业大赛、赛事活动最新资讯动态、掌握最新赛事进程、赛事交流沟通'
      },
      breadcrumb: '赛事动态',
    },
    children: [{
      path: 'dynamicDetail/:id',
      name: 'DynamicDetail',
      component: DynamicDetail,
      meta: {
        title: '{{赛事动态标题}}-赛事动态',
        content: {
          keywords: '{{赛事动态关联的赛事主题名称}}、{{赛事动态关联的赛事设置的活动地址}}、发布于{{赛事动态设置的发布时间}}',
          description: '提供高校大学生创新创业大赛、赛事活动最新资讯动态、掌握最新赛事进程、赛事交流沟通'
        },
        breadcrumb: '动态详情',
      },
    }],
  },

  //资讯
  {
    path: '/articleList',
    name: 'ArticleList',
    component: ArticleList,
    meta: {
      title: '资讯列表',
      content: {
        keywords: '创新创业、大赛资讯动态、活动、互联网+、创新创业交流',
        description: '提供高校大学生创新创业大赛、赛事活动最新资讯动态、掌握最新赛事进程、赛事交流沟通'
      },
      breadcrumb: '资讯信息',
    },
    children: [{
      path: 'articleDetail/:id',
      name: 'ArticleDetail',
      component: ArticleDetail,
      meta: {
        title: '{{资讯信息标题}}-资讯信息',
        content: {
          keywords: '{{赛事动态关联的赛事主题名称}}、{{赛事动态关联的赛事设置的活动地址}}、发布于{{赛事动态设置的发布时间}}',
          description: '提供高校大学生创新创业大赛、赛事活动最新资讯动态、掌握最新赛事进程、赛事交流沟通'
        },
        breadcrumb: '资讯详情',
      },
    }],
  },

  //搜索
  {
    path: '/searchResult',
    name: 'SearchResult',
    component: SearchResult,
    meta: {
      title: '搜索',
      // content: {
      //   keywords: '创新创业、大赛资讯动态、活动、互联网+、创新创业交流',
      //   description: '提供高校大学生创新创业大赛、赛事活动最新资讯动态、掌握最新赛事进程、赛事交流沟通'
      // },
      // breadcrumb: '搜索信息',
    },
  },
  //个人中心
  {
    path: '/personCenter',
    name: 'PersonCenter',
    component: PersonCenter,
    meta: {
      breadcrumb: '个人中心',
      requiresAuth: true
    },
    children: [
      {
        path: 'auth',
        name: 'Auth',
        component: AuthDetail,
        meta: {
          breadcrumb: '实名认证',
          requiresAuth: true
        }
      },
      {
        path: 'courseList',
        name: 'CourseList',
        component: CourseList,
        meta: {
          breadcrumb: '我的双创项目',
          requiresAuth: true
        },
        children: [
          {
            path: 'courseDetail/:id',
            name: 'CourseDetail',
            component: CourseDetail,
            meta: {
              breadcrumb: '双创项目详情',
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'matchRecordList',
        name: 'MatchRecordList',
        component: MatchRecordList,
        meta: {
          breadcrumb: '我的参赛记录',
          requiresAuth: true
        },
        children: [
          {
            path: 'matchRecordDetail/:id',
            name: 'MatchRecordDetail',
            component: MatchRecordDetail,
            meta: {
              breadcrumb: '项目详情',
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'policyList',
        name: 'PolicyList',
        component: PolicyList,
        meta: {
          breadcrumb: '政策办理记录',
          requiresAuth: true
        },
        children: [
          {
            path: 'policyConductDetail/:id',
            name: 'PolicyConductDetail',
            component: PolicyConductDetail,
            meta: {
              breadcrumb: '政策办理详情',
              requiresAuth: true
            }
          },
        ]
      },
      {
        path: 'orderList',
        name: 'OrderList',
        component: OrderList,
        meta: {
          breadcrumb: '我的订单记录',
          requiresAuth: true
        },
        children: [
          {
            path: 'orderDetail/:id',
            name: 'OrderDetail',
            component: OrderDetail,
            meta: {
              breadcrumb: '订单详情',
              requiresAuth: true
            }
          },
        ]
      },

      {
        path: 'messageRemind',
        name: 'MessageRemind',
        component: MessageRemind,
        meta: {
          breadcrumb: '消息提醒设置',
          requiresAuth: false,
        },
      },
      {
        path: 'aboutUs',
        name: 'AboutUs',
        component: AboutUs,
        meta: {
          breadcrumb: '关于我们',
          requiresAuth: false,
        },
      }
    ]
  },
  //政策
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
    meta: {
      title: '人才政策',
      breadcrumb: '人才政策',
      requiresAuth: false
    },
    children: [{
      path: 'policyDetail/:id',
      name: 'PolicyDetail',
      component: PolicyDetail,
      meta: {
        title: '政策详情',
        breadcrumb: '政策详情',
      },
      children: [
        {
          path: 'policySub',
          name: 'PolicySub',
          component: PolicySub,
          meta: {
            title: '提交材料预审',
            breadcrumb: '提交材料预审',
          }
        },
        {
          path: 'policySubResult',
          name: 'PolicySubResult',
          component: PolicySubResult,
          meta: {
            title: '政策预审结果',
            breadcrumb: '政策预审结果',
          }
        },
      ]
    }],
  },
  //课程
  {
    path: '/course',
    name: 'Course',
    component: Course,
    meta: {
      title: '高校双创',
      breadcrumb: '高校双创',
      requiresAuth: false
    },
    children: [{
      path: 'form',
      name: 'CourseForm',
      component: CourseForm,
      meta: {
        title: '提交报名',
        breadcrumb: '提交报名',
        requiresAuth: true
      }
    },
    {
      path: 'result',
      name: 'CourseResult',
      component: CourseResult,
      meta: {
        title: '提交报名结果',
        breadcrumb: '提交报名结果',
        requiresAuth: true
      }
    }]
  },
  //服务站
  {
    path: '/station',
    name: 'Station',
    component: Station,
    meta: {
      title: '人才服务站',
      breadcrumb: '人才服务站',
      requiresAuth: false
    },
    children: [{
      path: 'stationDetail/:id',
      name: 'StationDetail',
      component: StationDetail,
      meta: {
        title: '服务站详情',
        breadcrumb: '服务站详情',
        requiresAuth: false

      },
    },
    {
      path: 'applySub',
      name: 'ApplySub',
      component: ApplySub,
      meta: {
        title: '申请信息',
        breadcrumb: '申请信息',
        requiresAuth: false
      },
    },
    ],
  },
  {
    path: '/moreTeacher',
    name: 'MoreTeacher',
    component: MoreTeacher,
    meta: {
      title: '导师评委',
      breadcrumb: '导师评委',
      requiresAuth: false
    },
    children: [{
      path: 'tutorDetail/:id',
      name: 'TutorDetail',
      component: TutorDetail,
      meta: {
        title: '导师详情',
        breadcrumb: '导师详情',
        requiresAuth: false

      },
    },
    ],
  },

  {
    path: '/phone',
    name: 'Phone',
    component: Phone,
    meta: {
      title: '高校双创',
      breadcrumb: '高校双创',
      requiresAuth: false
    },
  },

  {
    path: '/inviteMember',
    name: 'InviteMember',
    component: InviteMember,
    meta: {
      title: '成员邀请',
      breadcrumb: '成员邀请',
      requiresAuth: false
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/school',
    name: 'School',
    component: School,
  },
  {
    path: '/404',
    name: 'page404',
    component: page404
  },
  // {
  //   path: '*', // 页面不存在的情况下会跳到404页面
  //   redirect: '/404',
  //   name: 'notFound',
  //   hidden: true
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




//路由变化时执行
router.beforeEach(function (to, from, next) {
  //回到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  window.pageYOffset = 0;
  //动态修改TDk
  if (to.meta.content) {
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.matched.length === 0) {
    next({
      path: "/404",
    })
  }

  //判断移动端
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  if (flag) {
    if (!local.Get("isPc")) {
      local.Set("isPc", true)
      if (to.name == 'Phone') {
        next()
      } else {
        next({
          path: "/phone",
          query: {
            option: to.fullPath
          }
        });
      }
    } else {
      next()
    }
  } else {
  //判断是否需要登录
  if (to.meta.requiresAuth) {
    let scCustom = local.Get("scCustom");
    let isLogin = local.Get("isLogin");
    if (isLogin) {
      next(); //表示已经登录
      if (scCustom.auditStatus == 2) {
        next()
      } else {
        if (to.path.indexOf("personCenter") != -1) {
          next()
          return
        }
        //未认证
        Vue.prototype.$confirm("您尚未认证, 是否前往认证?", "认证提醒", {
          confirmButtonText: "去认证",//确认按钮文字更换
          cancelButtonText: "取消",//取消按钮文字更换
          showClose: true,//是否显示右上角关闭按钮
          type: "warning",
        }).then(res => {
          if (res) {
            next({
              path: "/personCenter/auth",
              query: { redirect: to.path } //登录后再跳回此页面时要做的配置
            });
          }
        }, err => {
          console.log('取消认证', err);
          $router.back()
        })
      }
    } else {
      //未登录
      Vue.prototype.$confirm("您暂未登录, 是否前往登录?", "登录提醒", {
        confirmButtonText: "去登录",//确认按钮文字更换
        cancelButtonText: "取消",//取消按钮文字更换
        showClose: true,//是否显示右上角关闭按钮
        type: "warning",
      }).then(res => {
        if (res) {
          next({
            path: "/login",
            query: { redirect: to.path } //登录后再跳回此页面时要做的配置
          });
        }
      })
    }
  } else {
    //表示不需要登录
    next(); //继续往后走
  }
  }
})
router.afterEach(function () {
  local.Set('isPc', false)
})











export default router
