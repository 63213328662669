
import request from "./request.js"


const http = ({ url, method = 'GET', data }) => {
    // let header = {
	// 	// 'content-type': 'application/x-www-form-urlencoded', // 默认值
	// 	// 自定义请求头
	// 	// 'access-token': app.globalData.access_token, // 访问令牌
	// 	// 'user-token': app.globalData.user_token, // 登录令牌
	// 	// 'version': 'v1.0' // 版本号
	// 	inviteCode: uni.getStorageSync('inviteCode') || '',
	// 	openId: uni.getStorageSync('openid'),
	// 	Authorization: `Bearer ${token}`
	// }
    if (method == "GET") {
        return request.get(url, {
            params: data
        })
    }
    if (method == "POST") {
        return request.post(url, data)
    }
    if (method == "DELETE") {
        return request.delete(url, {
            data: data
        })
    }
    if (method == "PUT") {
        return request.put(url, data)
    }
    
}



export default http;

/** 
 * 请求使用http
 * url          //接口
 * method       //方式
 * data         //请求参数
*/