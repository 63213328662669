<template>
  <div class="common-info" @click="handleEnter('personCenter')">
    <div class="user-block" v-if="loginStatus">
      <div class="cover">
        <img class="img" :src="baseInfo.avatar" alt="" />
      </div>
      <div class="sub">
        <div class="nickname">{{ baseInfo.nickname || "" }}</div>
        <div class="realname">{{ baseInfo.name || "" }}</div>
        <div
          class="userauth"
          v-if="baseInfo?.auditStatus != '2'"
          @click="handleAuthClick"
        >
          完成身份认证
          <span class="icon iconfont icon-more"></span>
        </div>
      </div>
    </div>
    <div class="user-block hover-effect" v-else @click="toLogin">
      <div class="cover">
        <div class="icon">
          <span class="iconfont icon-user"></span>
        </div>
      </div>
      <div class="sub">
        <div class="nickname">请先登录</div>
      </div>
    </div>
    <div class="auth-block" v-if="baseInfo?.auditStatus == '2'">
      <div class="userTag userTag-verify" v-if="baseInfo?.auditStatus == '2'">
        <span class="icon iconfont icon-verify"></span>已认证
      </div>
      <div class="userTag userTag-student" v-if="baseInfo?.identityType == '1'">
        <span class="icon iconfont icon-school"></span>学生
      </div>
    </div>
    <div class="menu-block">
      <div
        class="item hover-item"
        v-for="(item, index) in menuList"
        :key="index"
        @click.stop="handleEnter(item.url)"
      >
        <div class="item-link">
          <div class="ico">
            <span class="icon iconfont" :class="item.icon"></span>
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="join-block" v-if="competitionList.length">
      <div class="header">
        <div class="title">{{ title }}</div>
        <div class="ico">
          <span
            class="icon iconfont icon-pager-left"
            :class="{ isLight: isPreviousPageAvailable }"
            @click.stop="returnPage"
          ></span>
          <span
            class="icon iconfont icon-pager-right"
            :class="{ isLight: isNextPageAvailable }"
            @click.stop="nextPage"
          ></span>
        </div>
      </div>
      <div v-for="(item, index) in competitionList" :key="index">
        <div class="join-process" v-if="isCurrentPage(index)">
          <div class="title">{{ item.title }}</div>
          <el-scrollbar class="content">
            <el-timeline>
              <el-timeline-item
                v-for="(t, i) in item.list"
                :key="i"
                :color="t.color"
              >
                <span :class="{ currentLight: t.color }">{{ t.nodeName }}</span>
                <div>
                  <slot>
                    <span class="time" :class="{ currentLight: t.color }">{{
                      t.reviewStatus
                    }}</span>
                  </slot>
                </div>
              </el-timeline-item>
            </el-timeline>
          </el-scrollbar>
        </div>
      </div>
    </div>
    <div class="join-block" v-else-if="materialList.length">
      <div class="header">
        <div class="title">
          {{ title }}
        </div>
      </div>
      <div class="banner">
        <el-carousel
          :interval="5000"
          arrow="hover"
          :autoplay="false"
          :loop="false"
          indicator-position="none"
        >
          <el-carousel-item v-for="(item, index) in materialList" :key="index">
            <div class="img-wrap">
              <el-image :src="item.materialUrl" @click.stop="onPreview(index)">
              </el-image>
            </div>
            <elImageViewer
              v-if="showViewer"
              :initial-index="currentIndex"
              :on-close="onClose"
              :url-list="srcList"
              :z-index="1999"
            >
            </elImageViewer>
          </el-carousel-item>
          <!-- :on-switch="onSwitch" -->
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { getProgressDetail, getBaseInfo } from "@/api/personal/index";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { local } from "@/utils/storage.js";

export default {
  props: {},
  components: {
    ElImageViewer,
  },
  data() {
    return {
      baseInfo: {}, //用户信息
      loginStatus: false,
      menuList: [
        {
          name: "参赛记录",
          icon: "icon-sign-record",
          url: "personCenter/matchRecordList",
        },
        {
          name: "政策记录",
          icon: "icon-policy-record",
          url: "personCenter/policyList",
        },
        {
          name: "双创记录",
          icon: "icon-couse-record",
          url: "personCenter/courseList",
        },
        {
          name: "我的券包",
          icon: "icon-kabao",
          url: "",
        },
        {
          name: "我的收藏",
          icon: "icon-collection",
          url: "",
        },
        {
          name: "项目资料",
          icon: "icon-files",
          url: "",
        },
      ],
      current: "1",
      competitionList: [],
      title: "",

      showViewer: false,
      materialList: [],
      srcList: [],
      currentIndex: 0,
    };
  },
  computed: {
    isPreviousPageAvailable() {
      return this.current > 1;
    },
    isNextPageAvailable() {
      return this.current < this.competitionList.length;
    },
  },
  created() {
    this.initCustom();
    this.initPersonDetail();
  },
  methods: {
    //初始化用户信息
    initPersonDetail() {
      this.loading = true;
      getProgressDetail()
        .then((res) => {
          if (!res.data) return;
          this.title = res.data.title;
          this.materialList = res.data.material;
          this.materialList.forEach((item) => {
            this.srcList.push(item.materialUrl);
          });
          this.competitionList = res.data.participant;
          this.competitionList.forEach((item) => {
            item.list[0].color = "#F7932E";
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //展示用户信息
    initCustom() {
      let isLogin = local.Get("isLogin");
      if (isLogin) {
        getBaseInfo().then((res) => {
          this.baseInfo = res.data;
          this.loginStatus = res.data ? true : false;
        });
      }
    },
    returnPage() {
      if (this.isPreviousPageAvailable) {
        this.current--;
      }
    },
    nextPage() {
      if (this.current && this.isNextPageAvailable) {
        this.current++;
      }
    },
    onPreview(index) {
      this.showViewer = true;
      this.currentIndex = index;
    },
    // onSwitch(index) {
    //   if(index == this.srcList.length-1) {
    //        this.$message({
    //         message: '到最后一张啦',
    //         customClass:'zZindex'
    //       })
    //    }
    // },
    onClose() {
      this.showViewer = false;
    },
    //去登录
    toLogin() {
      this.$router.push({ path: "/login" });
    },
    //认证按钮
    handleAuthClick() {
      this.$router.push("/personCenter/auth");
    },
    isCurrentPage(index) {
      return this.current == index + 1;
    },
    handleEnter(url) {
      if (url && typeof url == "string") {
        this.$router.push("/" + url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.common-info {
  flex: 0 0 20%;
}
.user-block {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  .cover {
    height: 64px;
    min-width: 64px;
    border-radius: 50%;
    border: #dcdcdc solid 1px;
    overflow: hidden;
    .img {
      height: 100%;
      width: 100%;
    }
    .icon {
      background-color: #dadada;
      color: #bbbbbb;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      .iconfont {
        font-size: 42px;
      }
    }
  }
  .sub {
    margin-left: 15px;
    .nickname {
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }
    .realname {
      font-size: 16px;
      color: #888;
    }
    .userauth {
      margin-top: 10px;
      padding: 4px 16px;
      background-color: #e0e9fa;
      // background: linear-gradient(to left, rgb(173, 203, 255), rgb(224, 233, 250));
      color: #6985f8;
      text-align: center;
      border-radius: 6px;
      cursor: pointer;
    }
    .userauth:hover {
      opacity: 0.9;
    }
  }
}

.auth-block {
  display: flex;
  margin-bottom: 10px;
  .userTag {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    height: 28px;
    border-radius: 20px;
    background: #adcbff;
    color: #4a8bfe;
    padding: 0 16px;
    text-shadow: 1px 1px rgba(255, 255, 255, 0.15);

    .icon {
      margin-right: 8px;
    }
  }

  .userTag-verify {
    background: #c9d3fc;
    color: #6985f8;
  }
  .userTag-student {
    margin-left: 12px;
    background-color: #fdd07b;
    color: #f7932e;
  }
}
.menu-block {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  border-radius: 10px;
  margin-bottom: 12px;
  .item {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 30%;
    .item-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      // padding: 18px;
      // padding-bottom: 0;

      .ico {
        height: 46px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          font-size: 23px;
        }
      }

      .name {
        color: #353535;
        // font-size: 24px;
        text-align: center;
        // margin-top: 0.75em;
      }
    }
  }
}
.join-block {
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0;
    .title {
      font-size: 16px;
    }
    .ico {
      display: flex;
      .icon {
        padding: 0 4px;
        font-size: 16px;
        color: #888;
      }
      .isLight {
        color: #597af8;
      }
    }
  }
  .join-process {
    height: 200px;
    // overflow-y: scroll;
    border-radius: 10px;
    background-color: #f9f9f9;
    padding: 0 20px;
    .title {
      font-size: 16px;
      line-height: 2.5em;
      display: -webkit-box;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .content {
      height: 160px;
      margin-left: 4px;
      font-size: 12px;
      .currentLight {
        color: #f7932e;
      }
      .time {
        font-size: 12px;
      }
    }
  }
  /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  /deep/ .el-timeline-item__node {
    background-color: #b6cffd;
  }
  /deep/ .el-timeline-item__tail {
    left: 4px;
    border-left: 4px solid #b6cffd;
  }
  /deep/ .el-timeline-item__node--normal {
    left: 0;
  }
  /deep/ .el-timeline-item__timestamp.is-bottom {
    margin-top: 4px;
  }
  .banner {
    height: 200px;
    .img-wrap {
      width: 100%;
      height: 200px;
      display: flex;
      justify-content: center;
      background-color: #fff;
      cursor: pointer;
    }
    .image-slot {
      z-index: 3000;
      height: 100px;
      background: #597af8;
    }
  }
}
/deep/ .el-carousel__container {
  height: 200px;
}
</style>
<style>
.zZindex {
  z-index: 3000 !important;
}
</style>