<template>
  <div class="match-invite-page page">
    <!-- 顶部 -->
    <page-top></page-top>
    <!-- 头部 -->
    <Header @chooseTab="getTab"></Header>
    <!-- 主体 -->
    <main class="main">
      <section class="breadcrumb-plate w">
        <div class="label">我的位置：</div>
        <breadcrumb />
      </section>

      <div v-if="displayPage">
      <section class="content w">
        <div class="teacher-banner">
          <img
            :src="
              this.$globalHeader.imgUrl +
              '/static/pc/course/teacher/more-teacher.png'
            "
          />
        </div>

        <div class="teacher-list" v-loading="loading">
          <div class="teacher-item" v-for="(item, index) in teacherList" :key="index" @click="toDetail(item)">
            <!-- <TeacherMienItem :data="item" /> -->
            <TutorItem  :data="item" />
          </div>
        </div>

        <div class="m-bot" v-if="teacherList.length > 0">
          <el-pagination
            background
            layout="prev, pager, next"
            prev-text="上一页"
            next-text="下一页"
            :page-size="limit"
            :total="total"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </section>
      </div>
      <router-view></router-view>
    </main>
    <!-- 底部 -->
    <Footer></Footer>
  </div>

</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
// import TeacherMienItem from "../components/teacherMienItem.vue";
import TutorItem from "@/components/tutor/tutorItem.vue";

import { getTutorPage } from "@/api/course/course.js";
export default {
  name: "MoreTeacher",
  components: { pageTop, Header, Footer, Breadcrumb, TutorItem },
  data() {
    return {
      teacherList: [],
      loading: false,
      total: 0,
      currentPage: 1,
      limit: 10,
      displayPage: true,
    };
  },
  methods: {
    //导师列表
    getTeacherPage() {
      this.loading = true;
      let params = {
        page: this.currentPage,
        limit: this.limit,
      };
      getTutorPage(params).then((res) => {
        this.teacherList = res.data.list;
        this.loading = false;
        this.total = res.data.count;
      });
    },
    //分页点击事件
    currentChange(e) {
      this.currentPage = e;
      this.getTeacherPage();
    },
    //获取header组件的点击事件
    getTab(data) {
      if (data && typeof data == "string") {
        this.$router.push("/" + data);
      }
    },
    toDetail(item) {
      this.$router.push({
        path: "/moreTeacher/tutorDetail/" + item.id,
      });
    }
  },
  created() {
    this.getTeacherPage();
  },
  watch: {
    $route: {
      handler() {
        let routerList = this.$route.path
          .slice(1, this.$route.path.length)
          .split("/");
        if (routerList && routerList.length == 1) {
          this.displayPage = true;
        } else {
          this.displayPage = false;
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.match-invite-page {
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;

    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }
    }
    .content {
      background-color: #fff;
      padding: 20px;
      .teacher-banner {
        img {
          width: 100%;
          height: 100%;
        }
      }

      .teacher-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        .teacher-item {
              width: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
        }
        .teacher-item:hover {
            cursor: pointer;
            transform: scale(1.01);
        }
      }
    }
    .m-bot {
      margin-top: 24px;
      margin-bottom: 24px;

      .el-pagination {
        display: flex;
        justify-content: center;

        /deep/ .btn-prev {
          width: 100px;
          height: 40px;
          border: 1px solid #e4e4e4;
          background-color: #fff;
        }

        /deep/ .btn-prev:hover:enabled {
          color: #409eff;
        }

        /deep/ .number {
          width: 40px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #e4e4e4;
          background-color: #fff;
        }

        /deep/ .more {
          width: 40px;
          height: 40px;
          line-height: 40px;
          border: 1px solid #e4e4e4;
          background-color: #fff;
        }

        /deep/ .btn-next {
          width: 100px;
          height: 40px;
          border: 1px solid #e4e4e4;
          background-color: #fff;
        }

        /deep/ .btn-next:hover:enabled {
          color: #409eff;
        }

        /deep/ .active {
          color: #fff;
          background-color: #409eff;
        }
      }
    }
  }
}
</style>
