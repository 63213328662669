<template>
	<!-- 表单组件-普通单行文本输入 -->
	<div class="formModule" v-if="formDetail">
		<div class="formModule-header">
			<div class="title">
				<span class="order-num">{{index}}.</span>
				<span class="txt">{{formDetail.title}}</span>
			</div>
			<div class="extend">
				<div class="isMust" v-if="formDetail.isNeed=='1'">必填</div>
			</div>
		</div>
		<div class="formModule-desc">填写 {{formDetail.rule.placeholder?'| '+formDetail.rule.placeholder:''}}</div>
		<div class="formModule-content" @click="showSelect(formDetail,index)">
			<div class="mod-select">
				<div class="select">
					<div class="placeholder">
						<el-select v-model="formDetail.value" clearable placeholder="请选择">
							<el-option
								v-for="item in toPickerList(formDetail.defaultValue)"
								:key="item"
								:label="item"
								:value="item">
							</el-option>
						</el-select>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			formItem: {
				type: Object
			},
			index: {
				type: Number
			}
		},
		data() {
			return {
				formDetail:  this.formItem,
			};
		},
		methods: {
			//清除输入框的值
			clearValue() {
				this.formDetail.value = null
			},
			toPickerList(data) {
				if (!data) return
				if (data instanceof Array) return data
				if (typeof data == 'string') {
					return data.split(',')
				} else if (typeof data == 'object') {
					return console.log('数据结构错误toPickerList');
				}
			},
		},
		watch: {
			"formDetail.value": {
				handler() {
					this.$bus.$emit('formDetail', this.formDetail)
				}
			},
			"formDetail.switchYn": {
				handler() {
					this.$bus.$emit('pageHidden', this.formDetail.switchYn)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .el-input__inner{
		border: none !important;
		background-color: #f7f7f7 !important;
	}
	::v-deep .el-select{
		width: 100%;
	}

	::v-deep .el-select .el-input .el-select__caret{
		font-size: 16px;
	}

	.formModule {
		font-size: 14px;
		padding: 15px;
		height: auto;
		background-color: #ffffff;

		.formModule-header {
			display: flex;
			margin-bottom: 10px;

			.title {
				font-size: 15px;
				font-weight: 600;

				.txt::after {
					content: "：";
				}
			}

			.extend {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin-left: 10px;
			}
		}

		.isMust {
			color: #ff0000;
			font-size: 11px;
		}

		.formModule-desc {
			margin-bottom: 12px;
			font-size: 12px;
			color: #9b9999;
			line-height: 1.35;
		}

		.formModule-content {}

		.mod-select {
			background-color: #f7f7f7;
			overflow: hidden;
			display: flex;
			align-items: stretch;
			border-radius: 6px;
			margin: 6px 0;

			.select {
				flex: 1;
				display: flex;
				align-items: center;
				background-color: transparent;
				border: none;
				font-size: 14px;
				min-height: 28px;
				line-height: 1.35;

				.tit {
					flex: 1;
					// @include overflow-line(1);
				}

				.placeholder {
					flex: 1;
					color: #777777;
					// @include overflow-line(1);
				}
			}
		}


	}
</style>
