<template>
  <div class="footer">
    <div class="footer-wrap w">
      <p>
        COPYRIGHT© 厦门双创金服网络科技有限公司 版权所有 备案号：<a
          target="_blank"
          href="https://beian.miit.gov.cn/"
          >闽ICP备2023012598号</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  color: #c9c9c9;
  background-color: #fff;

  a {
    color: #c9c9c9;
  }
}

.footer-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 52px 0;
}
</style>