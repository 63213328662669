<template>
  <div class="match-detail-page page">
    <div
      class="page-wrap"
      v-if="displayPage"
      v-loading.fullscreen.lock="isLoading"
    >
      <!-- 顶部 -->
      <page-top></page-top>

      <!-- 调用一次面包屑用于修改面包屑title和path -->
      <div class="breadcrumb" style="display: none">
        <breadcrumb />
      </div>

      <!-- 主体 -->
      <main class="main" id="main">
        <!-- <div class="banner-wrap">
          <div class="img-wrap">
            <div class="bannerimg" :style="{
              background:
                'url(' +
                (matchDetailData.backgroundImage == null
                  ? matchDetailData.image
                  : matchDetailData.backgroundImage) +
                ') center top/cover no-repeat white',
            }"></div>
          </div>
        </div> -->
        <div class="banner-wrap1">
          <div class="img-wrap">
            <img
              id="bannerImg1"
              class="img"
              :src="matchDetailData.backgroundImage"
              alt="banner"
            />
          </div>
        </div>

        <div class="match-detail-wrap">
          <section class="w match-info-plate">
            <h1 class="match-title">
              {{ matchDetailData.title }}
            </h1>
            <div class="match-info">
              <div class="item">
                <div class="label">
                  <span class="icon iconfont icon-tag-time"></span>
                  <span class="label-txt">开始时间：</span>
                </div>
                <div class="content">
                  <span class="content-txt">{{
                    matchDetailData.start_time || ""
                  }}</span>
                </div>
              </div>
              <div class="item">
                <div class="label">
                  <span class="icon iconfont icon-tag-time"></span>
                  <span class="label-txt">结束时间：</span>
                </div>
                <div class="content">
                  <span class="content-txt">{{
                    matchDetailData.end_time || ""
                  }}</span>
                </div>
              </div>
              <div class="item">
                <div class="label">
                  <span class="icon iconfont icon-tag-address"></span>
                  <span class="label-txt">举办地址：</span>
                </div>
                <div class="content">
                  <span class="content-txt">{{
                    matchDetailData.address || ""
                  }}</span>
                </div>
              </div>
            </div>

            <div
              class="match-info"
              v-if="matchDetailData.organize && matchDetailData.organize.length"
            >
              <div class="item">
                <div class="label">
                  <span class="icon iconfont icon-tag-org"></span>
                  <span class="label-txt">{{
                    matchDetailData.organize[0].organizeType + ":"
                  }}</span>
                </div>
                <div class="content">
                  <span
                    class="content-txt"
                    v-for="(item, index) in matchDetailData.organize[0]
                      .organizeNames"
                    :key="index"
                    >{{ item }}</span
                  >
                  <span class="more" @click="handleOrganizeClick()"
                    >更多({{
                      matchDetailData.organize[0].organizeNames.length
                    }})<span class="iconfont icon-more"></span
                  ></span>
                </div>
              </div>
              <div class="item">
                <div class="label">
                  <span class="icon iconfont icon-contacts"></span>
                  <span class="label-txt">联系方式：</span>
                </div>
                <div class="content">
                  <span class="content-txt"
                    >{{ matchDetailData.contact || "" }}
                    {{ matchDetailData.contact_number || "" }}</span
                  >
                </div>
              </div>
              <div class="item">
                <div class="qrcode-wrap">
                  <div class="qrcode-item">
                    <div class="img-wrap">
                      <img
                        class="img"
                        :src="
                          $globalHeader.imgUrl + '/static/other/corp_concat.jpg'
                        "
                        alt="赛事官方微信"
                      />
                    </div>
                    <div class="dec">
                      <span class="txt">扫一扫</span>
                    </div>
                  </div>
                  <!-- <div class="qrcode-item">
                    <div class="img-wrap">
                      <img class="img" :src="$globalHeader.imgUrl + '/static/other/official_concat.jpg'" alt="赛事官方公众号" />
                    </div>
                    <div class="dec">
                      <span class="txt">扫一扫</span>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>

            <div
              class="match-dynamic"
              v-if="matchDynamicList && matchDynamicList.length"
            >
              <div class="head">
                <div class="label">
                  <span class="icon iconfont icon-join-record"></span>
                  <span class="label-txt">赛事动态：</span>
                </div>
                <div class="head-more">
                  <span class="more" @click="toMatchDynamicMore()"
                    >更多<span class="iconfont icon-more"></span
                  ></span>
                </div>
              </div>
              <div class="list">
                <div
                  class="item"
                  v-for="(item, index) in matchDynamicList"
                  :key="index"
                  @click="toMatchDynamic(item)"
                >
                  <div class="item-content">
                    <div class="title">
                      {{ item.title || "" }}
                    </div>
                    <div class="desc">
                      {{ item.dynamicContent || "" }}
                    </div>
                    <div class="time" v-if="item.time">{{ item.time }}</div>
                  </div>
                  <div class="item-cover" v-if="item.cover">
                    <img class="img" :src="item.cover" alt="动态图片" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="w match-banner-plate">
            <div class="match-banner-left" v-if="!isShowNodeData">
              <span class="slogan">{{ nodeContent }}</span>
            </div>

            <div class="match-banner-left" v-else>
              <div
                class="match-banner-item"
                v-if="
                  matchDetailData.nodeData &&
                  matchDetailData.nodeData.projectNumber != null
                "
              >
                <div class="match-banner-item-top">
                  <span class="num">{{
                    matchDetailData.nodeData.projectNumber
                  }}</span
                  >例
                </div>
                <div class="match-banner-item-bot">
                  <span class="txt">已报名项目</span>
                </div>
              </div>
              <div
                class="line"
                v-if="
                  matchDetailData.nodeData &&
                  matchDetailData.nodeData.projectNumber != null &&
                  matchDetailData.nodeData.personNumber != null
                "
              ></div>
              <div
                class="match-banner-item"
                v-if="
                  matchDetailData.nodeData &&
                  matchDetailData.nodeData.personNumber != null
                "
              >
                <div class="match-banner-item-top">
                  <span class="num">{{
                    matchDetailData.nodeData.personNumber
                  }}</span
                  >人
                </div>
                <div class="match-banner-item-bot">
                  <span class="txt">已参与人数</span>
                </div>
              </div>
            </div>

            <div class="match-banner-right">
              <div
                class="btn-wrap"
                v-if="
                  matchDetailData.isApplyData &&
                  matchDetailData.isApplyData.apply
                "
              >
                <div
                  class="btn-apply"
                  @click="toDownloadFile"
                  v-if="matchDetailData.fileList.length"
                >
                  <span class="btn">项目模板</span>
                </div>
                <div class="btn-apply" @click="toMatchSub">
                  <span class="btn">{{
                    matchDetailData.isApplyData
                      ? matchDetailData.isApplyData.title
                      : ""
                  }}</span>
                </div>
              </div>

              <div class="btn-over-wrap" v-else>
                <span class="btn">{{
                  matchDetailData.isApplyData
                    ? matchDetailData.isApplyData.title
                    : ""
                }}</span>
              </div>
            </div>
          </section>

          <section
            class="w kind-wrap"
            v-if="matchFormKindList && matchFormKindList.length"
          >
            <div class="k-top">
              <div class="title">
                {{ matchDetailData.title }} - 配套活动事项报名
              </div>
            </div>
            <div class="k-bot">
              <div
                class="k-item"
                @click="toFormKind(item)"
                v-for="(item, index) in matchFormKindList"
                :key="index"
              >
                <div class="i-top-item-left">
                  <span class="icon iconfont icon-join-record"></span>
                </div>
                <div class="i-top-item-right">
                  <div class="subtitle">{{ item.headline || "" }}</div>
                  <div class="title">{{ item.kindName || "" }}</div>
                </div>
              </div>
            </div>
          </section>

          <section class="w match-rules-plate" id="scroll-box" ref="DOM">
            <div class="match-rules-tab" id="rulesTab" @click="changeTab">
              <div
                class="tab-item"
                id="tab-item0"
                :class="{ 'active-tab': matchRulesTab == 0 }"
              >
                赛程安排
              </div>
              <div
                class="tab-item"
                id="tab-item1"
                :class="{ 'active-tab': matchRulesTab == 1 }"
              >
                赛区赛道
              </div>
              <div
                class="tab-item"
                id="tab-item2"
                :class="{ 'active-tab': matchRulesTab == 2 }"
              >
                组织单位
              </div>
              <div
                class="tab-item"
                id="tab-item3"
                :class="{ 'active-tab': matchRulesTab == 3 }"
              >
                大赛介绍
              </div>
            </div>
            <div class="match-rules-plan" id="rules0">
              <div class="match-rules-title">赛程安排</div>
              <div class="match-rules-content">
                <div class="match-rules-progress">
                  <div
                    class="progress-item"
                    :class="item.isCurrentNodePass ? 'progress-item-sign' : ''"
                    v-for="(item, index) in matchProgressList"
                    :key="index"
                  >
                    <div class="time">
                      <span>{{ item.time }}</span>
                    </div>
                    <div
                      class="icon-box icon-big"
                      v-if="item.name && item.name.includes('开始')"
                    >
                      <span
                        class="iconfont icon-match-promotion"
                        v-if="item.isCurrentNodePass && !item.isCurrentNode"
                      ></span>
                      <span
                        class="iconfont icon-tag-address"
                        v-if="item.isCurrentNode"
                      ></span>
                      <span
                        class="iconfont icon-circle"
                        v-if="!item.isCurrentNode && !item.isCurrentNodePass"
                      ></span>
                    </div>
                    <div class="icon-box" v-else>
                      <span
                        class="iconfont icon-match-promotion"
                        v-if="item.isCurrentNodePass && !item.isCurrentNode"
                      ></span>
                      <span
                        class="iconfont icon-tag-address"
                        v-if="item.isCurrentNode"
                      ></span>
                      <span
                        class="iconfont icon-circle"
                        v-if="!item.isCurrentNode && !item.isCurrentNodePass"
                      ></span>
                    </div>
                    <div class="stage">
                      <span>{{ item.name }}</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="match-rules-content-tips">
                  大赛组委会评审组从报名项目中按五个赛道每个赛道各评选10个项目进入决赛，初赛采用网络评审，参赛团队不需要到现场。
                </div> -->
              </div>
            </div>
            <div
              class="match-rules-plan"
              id="rules1"
              v-if="matchDetailData.zones || matchDetailData.categories"
            >
              <div class="match-rules-title">赛区/赛道安排</div>
              <div class="match-rules-content">
                <div
                  class="match-rules-content-saiqu"
                  v-if="matchDetailData.zones && matchDetailData.zones.length"
                >
                  <template v-for="(item, index) in matchDetailData.zones">
                    <div class="saiqu-item" :key="index" v-if="item.title">
                      {{ item.title }}
                    </div>
                  </template>
                </div>
                <div
                  class="match-rules-content-zubie"
                  v-if="
                    matchDetailData.categories &&
                    matchDetailData.categories.length
                  "
                >
                  <div
                    class="zubie-item"
                    v-for="(item, index) in matchDetailData.categories"
                    :key="index"
                  >
                    <div class="zubie-item-title">
                      {{ item.title || "" }}
                    </div>
                    <div class="zubie-item-content">
                      {{ item.categoryDesc || "" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="match-rules-plan" id="rules2">
              <div class="match-rules-title">组织单位</div>
              <div class="match-rules-content">
                <div class="match-organize-content">
                  <div
                    v-for="(item, index) in matchDetailData.organize"
                    :key="index"
                    class="match-organize"
                  >
                    <div class="organize-type">
                      {{ item.organizeType + ":" }}
                    </div>
                    <div class="organize-list">
                      <div
                        v-for="(item1, index1) in item.organizeNames"
                        :key="index1"
                        class="organize-content"
                      >
                        <span>{{ item1 }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="match-rules-plan" id="rules3">
              <div class="match-rules-title">大赛介绍</div>
              <div class="match-rules-content">
                <div
                  class="match-rules-content-v"
                  v-html="matchDetailData.content"
                ></div>
              </div>
            </div>
          </section>

          <div class="regular w" v-if="showFloatTab">
            <div class="match-rules-tab" @click="changeTab">
              <div
                class="tab-item"
                id="tab-item0"
                :class="{ 'active-tab': matchRulesTab == 0 }"
              >
                赛程安排
              </div>
              <div
                class="tab-item"
                id="tab-item1"
                :class="{ 'active-tab': matchRulesTab == 1 }"
              >
                赛区赛道
              </div>
              <div
                class="tab-item"
                id="tab-item2"
                :class="{ 'active-tab': matchRulesTab == 2 }"
              >
                组织单位
              </div>
              <div
                class="tab-item"
                id="tab-item3"
                :class="{ 'active-tab': matchRulesTab == 3 }"
              >
                大赛介绍
              </div>
            </div>
          </div>
        </div>

        <!-- 右侧悬浮 -->
        <div class="float-box">
          <div class="float-wrap">
            <div class="btn-warp">
              <div class="item share" @click="toPopup('share')">
                <div class="icon iconfont icon-shareTo"></div>
                <div class="text">转发分享</div>
              </div>
              <div class="item share" @click="toPopup('contact')">
                <div class="icon iconfont icon-service"></div>
                <div class="text">联系客服</div>
              </div>
              <div class="item scan" @click="toPopup('code')">
                <div class="code">
                  <img class="img" :src="$globalHeader.imgUrl + '/static/other/mini_concat.png'" alt="二维码" />
                </div>
                <div class="text">扫码访问</div>
              </div>
            </div>
            
            <!-- <div class="item browse">
              <div class="icon iconfont icon-browse"></div>
              <div class="text">浏览人数</div>
              <div class="count">{{ matchDetailData.views }}</div>
            </div> -->
            <div
              class="item back-top"
              @click="backToTop"
              :style="{ visibility: floatStyle }"
            >
              <div class="icon iconfont icon-totop"></div>
              <div class="text">返回顶部</div>
            </div>
          </div>
        </div>
        <!-- 分享弹窗 -->
        <div class="popup-wrap share-popup" v-if="showSharePopup">
          <el-dialog
            :visible.sync="showSharePopup"
            :close-on-click-modal="true"
            title="转发分享"
          >
            <div class="img-wrap">
              <img class="img" :src="qrcode" alt="二维码" />
            </div>
            <div class="tips">微信扫一扫或发送图片</div>
            <div class="address">
              {{ currentRouter }}
            </div>
            <div class="btn-wrap">
              <div class="btn-item" @click="copyRouter">复制链接</div>
              <div class="btn-item" @click="saveQRCode">保存图片</div>
            </div>
          </el-dialog>
        </div>

        <!-- 客服弹窗 -->
        <div class="popup-wrap share-popup" v-if="showContactPopup">
          <el-dialog
            :visible.sync="showContactPopup"
            :close-on-click-modal="true"
            title="联系客服"
          >
            <div class="img-wrap">
              <img class="img" :src="$globalHeader.imgUrl + '/static/other/corp_concat.jpg'" alt="二维码" />
            </div>
            <div class="tips">请使用微信“扫一扫”，添加客服</div>
          </el-dialog>
        </div>

        <!-- 手机站点弹窗 -->
        <div class="popup-wrap share-popup" v-if="showCodePopup">
          <el-dialog
            :visible.sync="showCodePopup"
            :close-on-click-modal="true"
            title="扫码访问"
          >

            <div class="img-wrap">
              <img class="img" :src="$globalHeader.imgUrl + '/static/other/mini_concat.png'" alt="二维码" />
            </div>
            <div class="tips">请使用微信“扫一扫”，进入小程序操作</div>
          </el-dialog>
        </div>

        <!-- 赛事弹窗 -->
        <div class="popup-wrap" v-if="showOutPopup">
          <div class="mask"></div>
          <div class="json-popup popup">
            <match-out-popup
              :imageUrl="matchDetailData.linkUrl"
              :topImg="
                $globalHeader.imgUrl + '/static/icon/competition_subscribe.png'
              "
            >
            </match-out-popup>
          </div>
        </div>
        <!-- 项目模板弹窗 -->
        <div class="popup-wrap" v-if="showCategoryPopup">
          <el-dialog
            :visible.sync="showCategoryPopup"
            :close-on-click-modal="true"
            title="项目模板"
          >
            <div
              class="file-list"
              v-for="(item, index) in matchDetailData.fileList"
              :key="index"
            >
              <a :href="item.path">{{ item.fileName }}</a>
            </div>
          </el-dialog>
        </div>
      </main>
      <!-- 底部 -->
      <Footer></Footer>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import Breadcrumb from "@/components/common/breadcrumb";
import pageTop from "@/components/common/pageTop.vue";
import Footer from "@/components/common/footer.vue";
import matchOutPopup from "@/views/match/components/matchOutPopup";

import {
  getMatchDetail,
  getMatchFormKind,
  getRaceCard,
  isValidSchool,
} from "@/api/match/match.js";
import { shareQRCode } from "@/api/common/common.js";
import { getDynamicList } from "@/api/match/matchDynamic.js";
import { local } from "@/utils/storage.js";
export default {
  components: { pageTop, Footer, matchOutPopup, Breadcrumb },
  data() {
    return {
      isLoading: true,
      isLogin: false,
      displayPage: true,
      matchId: "", //赛事id
      bannerImgHeight: "", //轮播图高度
      pageHeight: "",

      matchFormKindList: [], //赛事配套列表
      matchRulesTab: 0, //当前选择的tab
      matchDetailData: {}, //赛事详情数据
      organizeList: [], //主办单位列表
      matchDynamicList: [], //赛事动态列表
      matchProgressList: [], //赛程列表
      showOutPopup: false, //显示外部报名
      showCategoryPopup: false, //显示项目模板弹窗

      showFloatTab: false, //显示固定tab
      oldScrollTop: 0, //旧滚动top
      scrollTop: 0, //滚动top
      isAddEventListener: true, //是否监听

      floatStyle: "hidden", //控制右侧漂浮按钮显示
      showFloat: true, //是否显示右侧漂浮按钮
      showSharePopup: false, //显示分享弹窗
      floatUp: false,
      qrcode: "", //分享二维码
      qrcodeName: "", //二维码下载文件名
      currentRouter: "",
      isShowNodeData: false,
      nodeContent: "",
      showContactPopup:false,//显示客服弹窗
      showCodePopup:false,//显示站点弹窗

    };
  },
  created() {
    this.matchId = this.$route.params.id;
    this.isLogin = local.Get("isLogin");
  },
  mounted() {},
  methods: {
    fetchData() {
      //赛事详情
      getMatchDetail({ id: this.matchId }).then((res) => {
        this.isLoading = false;
        this.matchDetailData = res.data;
        //修改图片大小
        let content = this.matchDetailData.content;
        if (content.includes(`<img style="width:100%;"`)) {
          content = content.replaceAll(
            `<img style="width:100%;"`,
            `<br/><img style="width:50%;margin-top:10px;"`
          );

          this.matchDetailData.content = content;
        }

        if (res.data.nodeList && res.data.nodeList.length) {
          res.data.nodeList.forEach((item) => {
            if (item.isCurrentNodePass) {
              this.isShowNodeData = item.displayApplyStatus;
              this.nodeContent = item.applyContent;
            }
          });
        }
        this.setTDK();
        setTimeout(() => {
          this.getBannerImgHeight();
          if (this.$el.querySelector(".page-wrap")) {
            this.pageHeight = this.$el.querySelector(".page-wrap").offsetHeight;
          }
          window.addEventListener("scroll", this.handleScroll, true);
        }, 500);
      });
      //赛事动态
      getDynamicList({
        page: 1,
        limit: 4,
        isExclude: false,
        sourceId: this.matchId,
      }).then((res) => {
        this.matchDynamicList = res.data;
      });
      getRaceCard({ id: this.matchId }).then((res) => {
        this.matchProgressList = res.data;
      });

      this.getQRCode();
      // if (this.matchId == "1628991138096312321") {
      this.getMatchFormKind();
      //  }
    },
    //获取分享二维码
    getQRCode() {
      shareQRCode({
        appId: "wx49ed84a58c6d9fc1",
        page: "pages/home",
        scene: "matchDetails:" + this.matchId,
        envVersion: "trial",
      }).then((res) => {
        this.qrcode = res.data;
        this.currentRouter = location.origin + this.$route.path;
      });
    },
    // 设置TDK
    setTDK() {
      if (this.displayPage) {
        document.title = this.matchDetailData.title;
      }
    },
    //获取赛事配套报名
    getMatchFormKind() {
      getMatchFormKind({ id: this.matchId }).then((res) => {
        this.matchFormKindList = res.data;
      });
    },
    //获取轮播图图片高度
    getBannerImgHeight() {
      if (!window.document.querySelector("#bannerImg1")) return;
      this.bannerImgHeight =
        window.document.querySelector("#bannerImg1").height;
    },
    //切换tab
    changeTab(e) {
      this.matchRulesTab = e.target.id.substr(e.target.id.length - 1, 1);
      this.jump();
    },
    //锚点
    jump() {
      window.scrollTo({
        top: this.getTabTop(this.matchRulesTab),
        left: 0, //x 左右
        behavior: "smooth",
      });
    },
    // 获取页面滚动距离
    handleScroll() {
      const that = this;
      that.scrollTop = window.scrollY;
      //控制右侧悬浮
      if (that.scrollTop >= this.bannerImgHeight) {
        this.showFloat = true;
        setTimeout(() => {
          this.floatStyle = "visible";
        }, 300);
        if (this.scrollTop + window.innerHeight >= this.pageHeight - 120) {
          this.floatUp = true;
        } else {
          this.floatUp = false;
        }
      } else {
        this.showFloat = false;
        setTimeout(() => {
          this.floatStyle = "hidden";
        }, 300);
      }

      //获取rulesTab
      let rulesTab = window.document.querySelector("#scroll-box");
      if (!rulesTab) {
        this.isAddEventListener = false;
        return;
      }
      //获取rulesTab的top
      let rulesTabTop = rulesTab.offsetTop;
      //滚动top
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= rulesTabTop) {
        that.showFloatTab = true;
        //设置延时，等滚动页面停止时，改变tab的值
        setTimeout(() => {
          if (this.oldScrollTop == window.scrollY) {
            //滚动改变currentTab
            let rules0 =
              window.document.querySelector("#rules0").offsetTop +
              rulesTabTop -
              100;
            let rules1 =
              window.document.querySelector("#rules1").offsetTop +
              rulesTabTop -
              100;
            let rules2 =
              window.document.querySelector("#rules2").offsetTop +
              rulesTabTop -
              100;
            let rules3 =
              window.document.querySelector("#rules3").offsetTop +
              rulesTabTop -
              100;
            if (scrollTop > rules0) {
              if (scrollTop > rules1) {
                if (scrollTop > rules2) {
                  if (scrollTop > rules3) {
                    this.matchRulesTab = 3;
                  } else {
                    this.matchRulesTab = 2;
                  }
                } else {
                  this.matchRulesTab = 1;
                }
              } else {
                this.matchRulesTab = 0;
              }
            }
          } else {
            this.oldScrollTop = window.scrollY;
          }
        }, 20);
      } else {
        that.showFloatTab = false;
        this.matchRulesTab = 0;
      }
    },

    //分享
    toPopup(e) {
      if(e == 'share') {
        this.showSharePopup = true;
      }else if(e== 'contact') {
        this.showContactPopup = true
      }else if(e== 'code') {
        this.showCodePopup = true
      }
    },
    //复制链接
    copyRouter() {
      let interlinkage = "";
      interlinkage = this.matchDetailData.title + "\n" + this.currentRouter;
      var textarea = document.createElement("textarea");
      textarea.value = interlinkage;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      this.$message({
        message: "链接已复制",
        type: "success",
      });
    },

    // 保存图片
    saveQRCode() {
      this.qrcodeName = this.matchDetailData.title + ".png";
      //创建a标签进行下载
      var doc = document.createElement("a");
      doc.href = this.qrcode;
      doc.download = this.qrcodeName;
      document.body.appendChild(doc);
      doc.click();
      document.body.removeChild(doc); // 删除临时实例
    },
    //返回顶部
    backToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    //获取tab点击跳转的top
    getTabTop(index) {
      //获取rulesTab
      let rulesTab = window.document.querySelector("#scroll-box");
      //获取rulesTab的top
      let rulesTabTop = rulesTab.offsetTop;
      let rules =
        window.document.querySelector("#rules" + index).offsetTop +
        rulesTabTop -
        60;
      return rules;
    },
    //组织单位锚点
    handleOrganizeClick() {
      window.scrollTo({
        top: this.getTabTop(2),
        left: 0, //x 左右
        behavior: "smooth",
      });
    },
    toFormKind(item) {
      if (this.matchDetailData && !this.matchDetailData.isApplyData.apply) {
        this.$message.error(`报名时间已结束`);
        return;
      }

      if (item.signupStatus && item.signupStatus != "2") {
        //报名结果页面
        this.$router.push({
          path: "/match/matchDetail/" + this.matchId + "/matchSubResult",
          query: {
            matchId: item.competitionId,
            kindId: item.id,
            applyId: item.applyId ? item.applyId : 0,
          },
        });
      } else {
        //报名页面
        this.$router.push({
          path:
            "/match/matchDetail/" +
            this.matchId +
            "/matchKindSub/" +
            item.competitionId +
            "/" +
            item.id +
            (item.applyId ? "/" + item.applyId : ""),
        });
      }
    },
    //赛事报名
    toMatchSub() {
      isValidSchool(this.matchId).then(res =>{
					if (res.code == 1077) {
            this.$confirm('认证您的身份，以便参与赛事', '提示', {
              confirmButtonText: '去认证',
              cancelButtonText: '稍后认证',
            }).then(() => {
              this.$router.push({
                   path: "/personCenter/auth",
                   query:{
                    isBack:true,//是否返回上一级页面
                   }
                });
            })
            return;
          }
					if (!res.data) {
            this.$message('比赛不在学校范围内');
						return 
					} else {
						if (this.matchDetailData.linkType == 2) {
              this.showOutPopup = true;
              return;
            }
            this.$router.push({
              path:
                "/match/matchDetail/" + this.matchId + "/matchSub/" + this.matchId,
            });
					}
      })
    },
    //项目模板弹窗
    toDownloadFile() {
      this.showCategoryPopup = true;
    },
    closePopup() {
      this.showOutPopup = false;
    },
    //赛事动态
    toMatchDynamic(item) {
      this.$router.push({
        path:
          "/matchDynamic/dynamicDetail/" + item.id + "?matchId=" + this.matchId,
      });
    },
    //更多赛事动态
    toMatchDynamicMore() {
      this.$router.push({
        path: "/matchDynamic?matchId=" + this.matchId,
      });
    },
  },
  watch: {
    $route: {
      handler() {
        let routeResult =
          this.$route.path.indexOf("matchSub") == -1 &&
          this.$route.path.indexOf("matchKindSub") == -1 &&
          this.$route.path.indexOf("matchSubResult") == -1 &&
          this.$route.path.indexOf("dynamicDetail") == -1;

        if (this.$route.path.indexOf("matchDetail") != -1 && routeResult) {
          this.displayPage = true;
          setTimeout(() => {
            this.fetchData();
          }, 1000);
          this.isAddEventListener = true;
        } else {
          this.displayPage = false;
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
    isAddEventListener: {
      handler(newV) {
        if (!newV) {
          window.removeEventListener("scroll", this.handleScroll, true);
        } else {
          window.addEventListener("scroll", this.handleScroll, true);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.match-detail-page {
  display: flex;
  flex-direction: column;
  background-color: #efefef;

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    background-color: #efefef;

    .banner-wrap {
      flex-shrink: 0;
      min-width: 1200px;
      // height: 660px;

      .img-wrap {
        width: 100%;
        height: 100%;

        .bannerimg {
          // height: 660px;
          background-color: #fff;
        }
      }
    }

    .banner-wrap1 {
      flex-shrink: 0;
      min-width: 1200px;

      .img-wrap {
        width: 100%;
        height: 100%;
      }
    }

    .match-detail-wrap {
      flex: 1;

      //第一部分内容 - 赛事内容
      .match-info-plate {
        padding: 20px 0;
        background-color: #fff;

        // 通用类
        .label {
          margin-bottom: 16px;
          font-size: 20px;

          .icon {
            margin-right: 10px;
            font-size: 20px;
          }

          .label-txt {
            font-weight: bold;
          }
        }

        .content {
          font-size: 18px;
          color: #333;

          .content-txt {
            margin-right: 20px;
          }
        }

        .more {
          white-space: nowrap;
          display: inline-block;
          font-size: 14px;
          color: #999;
          cursor: pointer;
        }

        .more:hover {
          color: #2d7cce;
        }

        // 赛事标题
        .match-title {
          padding: 30px 80px;
          text-align: center;
          font-size: 40px;
          font-weight: bold;
          color: #000;
        }

        // 赛事信息
        .match-info {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          padding: 20px 30px;
          margin: 10px 0;

          .item {
            padding: 0 20px;
            flex: 1;

            .label-txt {
            }
          }

          .qrcode-wrap {
            display: flex;
            align-items: center;
            margin-top: -20px;

            .qrcode-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100px;
              margin-right: 10px;
              padding: 8px 0;
              border: 1px solid #f0f0f0;

              .img-wrap {
                padding: 0 8px;
                width: 100%;
                height: 80px;
              }

              .dec {
                .txt {
                  font-size: 12px;
                }
              }
            }
          }
        }

        //赛事动态
        .match-dynamic {
          padding: 0 50px;
          margin: 10px 0;

          .head {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .label {
              margin-bottom: 0;
            }
          }

          .list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: stretch;

            .item {
              width: 48.5%;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              padding: 24px 0;
              border-bottom: 1px dashed #f2f2f2;
              cursor: pointer;

              .item-content {
                flex: 1;

                .title {
                  font-size: 16px;
                  margin-bottom: 10px;
                  word-break: break-all;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                }

                .desc {
                  font-size: 14px;
                  line-height: 1.25;
                  color: #999;
                  word-break: break-all;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                }

                .time {
                  font-size: 12px;
                  color: #aaa;
                  margin-top: 0.35em;
                }
              }

              .item-cover {
                margin-left: 20px;
                overflow: hidden;
                border-radius: 3px;
                width: 100px;
                height: 80px;

                .img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .item:hover {
              color: #2d7cce !important;

              .title,
              .desc {
                color: #2d7cce !important;
              }
            }

            // .item:nth-child(odd) {
            //   margin-right: 20px;
            // }
            // .item:nth-child(even) {
            //   margin-left: 20px;
            // }
          }
        }
      }

      //第二部分内容 - 报名横幅
      .match-banner-plate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 48px;
        margin-bottom: 20px;
        background: linear-gradient(to right, #0068c6, #0989fe);

        .match-banner-left {
          flex-shrink: 0;
          display: flex;
          align-items: center;

          .slogan {
            color: #fff;
            font-size: 30px;
          }

          .match-banner-item {
            display: flex;
            flex-direction: column;

            .match-banner-item-top {
              font-size: 20px;
              color: #fff;

              .num {
                margin-right: 8px;
                font-size: 48px;
              }
            }

            .match-banner-item-bot {
              font-size: 16px;
              color: #dfeee8;
            }
          }

          .line {
            margin: 0 40px;
            height: 58px;
            width: 2px;
            background-color: #5aa1e1;
            transform: rotate(20deg);
          }
        }

        .match-banner-right {
          flex-shrink: 0;

          .btn-wrap {
            display: flex;

            .btn-apply {
              display: flex;
              align-items: center;
              height: 80px;
              padding: 0 72px;
              background-color: #fff;
              font-size: 32px;
              line-height: 1;
              color: #2d7cce;
              border-radius: 40px;
              cursor: pointer;
              margin-left: 20px;
            }
          }
          .btn-over-wrap {
            display: flex;
            align-items: center;
            height: 80px;
            padding: 0 72px;
            background-color: #fff;
            font-size: 32px;
            line-height: 1;
            color: #666666;
            border-radius: 40px;
            cursor: not-allowed;
            background-color: rgba(228, 228, 228, 1);
          }

          .btn-wrap:hover {
            box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
          }
        }
      }

      //第三部分内容 - 配套活动
      .kind-wrap {
        margin-bottom: 18px;
        padding: 24px 20px;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

        .k-top {
          padding-bottom: 24px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #000;
        }

        .k-bot {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          width: 100%;

          .k-item {
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            margin: 0 8px;
            border-radius: 5px;
            box-sizing: border-box;
            box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
            line-height: 1.25;
            cursor: pointer;

            .i-top-item-left {
              flex-shrink: 0;
              margin-right: 10px;

              .icon {
                font-size: 40px;
                color: #fff;
              }
            }

            .i-top-item-right {
              flex: 1;
              color: #fff;

              .subtitle {
                font-size: 16px;
              }

              .title {
                font-size: 14px;
                opacity: 0.75;
                margin-top: 0.35em;
              }
            }
          }

          .k-item:hover {
            opacity: 0.9;
          }

          .k-item:nth-child(1) {
            background: linear-gradient(to right bottom, #f24451, #ee1e27);
          }

          .k-item:nth-child(2) {
            background: linear-gradient(to right bottom, #f2b43f, #f0a41c);
          }

          .k-item:nth-child(3) {
            background: linear-gradient(to right bottom, #609bd1, #3982c3);
          }

          .k-item:nth-child(4) {
            background: linear-gradient(to right bottom, #b4b467, #a6a653);
          }

          .k-item:nth-child(5) {
            background: linear-gradient(to right bottom, #f24451, #ee1e27);
          }
        }
      }

      //第四部分内容 - 赛事规则和安排
      .match-rules-plate {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding-bottom: 66px;
        background-color: #fff;

        .match-rules-tab {
          display: flex;

          .tab-item {
            flex: 1;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 20px;
            color: #333;
            border: 1px solid #e4e4e4;
            border-left: none;
            background-color: #f5f5f5;
            cursor: pointer;
          }

          .active-tab {
            border-bottom: 1px solid #fff;
            background-color: #fff;
            border-right: none;
          }
        }

        .match-rules-plan {
          .match-rules-title {
            padding: 28px 54px;
            text-align: center;
            font-size: 24px;
            font-weight: bold;
            color: #000;
          }

          .match-rules-content {
            padding: 0 54px;

            .match-rules-progress {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 20px 50px;

              .progress-item {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                position: relative;
                color: #666666;
                font-size: 12px;
                cursor: pointer;
                animation: ease-out 0.5s infinite;

                .icon-box {
                  padding: 0.5em;
                  background-color: #fff;
                  z-index: 2;
                  color: #aaaaaa;

                  .iconfont {
                    font-size: 16px;
                  }
                }

                .icon-big {
                  padding: 0.8em 0.5em;

                  .iconfont {
                    font-size: 36px;
                  }
                }

                .icon-big + .stage {
                  font-size: 16px;
                }
              }

              .progress-item::before {
                content: " ";
                overflow: hidden;
                position: absolute;
                top: 50%;
                left: -48px;
                transform: translate(0, -50%);
                width: 100px;
                height: 4px;
                background-color: #cfcfcf;
              }

              .progress-item:first-child::before {
                background: linear-gradient(to left, #ddd, #fff) !important;
              }

              .progress-item:last-child::after {
                content: " ";
                overflow: hidden;
                position: absolute;
                top: 50%;
                right: -48px;
                transform: translate(0, -50%);
                width: 100px;
                height: 4px;
                background: linear-gradient(to right, #ddd, #fff) !important;
              }

              .progress-item-sign {
                color: #0068c6;

                .icon-box {
                  color: #0068c6;
                }
              }

              .progress-item-sign::before {
                background-color: #60b4ff;
              }

              .progress-item-sign:first-child::before {
                background: linear-gradient(to right, #fff, #60b4ff) !important;
              }
              .progress-item-sign:last-child::after {
                background: linear-gradient(to left, #fff, #60b4ff) !important;
              }

              .progress-item:hover {
                color: #353535;

                .icon-box {
                  color: #555;
                }
              }
              .progress-item-sign:hover {
                color: #0068c6;

                .icon-box {
                  color: #0068c6;
                }
              }
            }

            .match-rules-content-tips {
              margin: 20px 40px;
              padding: 12px 40px;
              text-align: center;
              font-size: 12px;
              color: #666;
              background-color: #f9f9f9;
            }

            .match-rules-content-saiqu {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 20px;

              .saiqu-item {
                width: 49%;
                padding: 8px 20px;
                margin-bottom: 12px;
                font-size: 24px;
                color: #333;
                background-color: #f5f5f5;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }

            .match-rules-content-zubie {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: stretch;

              .zubie-item {
                display: flex;
                flex-direction: column;
                width: 49%;
                margin-bottom: 20px;

                .zubie-item-title {
                  flex-shrink: 0;
                  padding: 12px 20px;
                  font-size: 24px;
                  color: #333;
                  background-color: #9fd1ff;
                }

                .zubie-item-content {
                  flex: 1;
                  padding: 18px 20px;
                  font-size: 16px;
                  color: #666;
                  background-color: #e6f3ff;
                  white-space: pre-wrap;
                }
              }
            }

            .match-rules-content-v {
              font-size: 18px;
              line-height: 2em;
            }

            .match-organize-content {
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              padding: 10px 0px;

              .match-organize {
                display: flex;
                margin: 10px 0;
                flex-direction: row;

                .organize-type {
                  margin-right: 15px;
                  min-width: 82px;
                  font-size: 16px;
                  font-weight: 700;
                  color: #000000;
                }

                .organize-list {
                  min-width: 700px;

                  .organize-content {
                    display: flex;
                    flex-wrap: wrap;

                    span {
                      min-width: 350px;
                      margin-bottom: 10px;
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      //固定tab
      .regular {
        position: fixed;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: #fff;
        z-index: 100;

        .match-rules-tab {
          display: flex;

          .tab-item {
            flex: 1;
            height: 60px;
            line-height: 60px;
            text-align: center;
            font-size: 20px;
            color: #333;
            border: 1px solid #e4e4e4;
            border-left: none;
            background-color: #f5f5f5;
            cursor: pointer;
          }

          .active-tab {
            border-bottom: 1px solid #fff;
            background-color: #fff;
            border-right: none;
          }
        }
      }
    }

    .float-box {
      position: fixed;
      left: calc(50% + 650px);
      bottom: 240px;
      .float-wrap {
        display: flex;
        flex-direction: column;
        .btn-warp {
          display: flex;
          flex-direction: column;
          background: #fafafa;

        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 3px 16px 8px 16px;
          min-width: 72px;
          border-bottom: 1px solid #eaeaea;
          background-color: #fafafa;
          border-radius: 3px;
          color: #666666;
          transition: all 0.3s;
          cursor: pointer;
          position: relative;

          .icon {
            margin: 5px 0;
            font-size: 28px;
            color: #bbbbbb;
          }

          .text {
            font-size: 12px;
          }
          .count {
            position: absolute;
            top: 5px;
            right: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ff0000;
            color: #fff;
            min-width: 18px;
            height: 18px;
            line-height: 1;
            border-radius: 9px;
            font-size: 10px;
            padding: 0 5px;
            overflow: hidden;
          }
        }

        .item:hover {
          background-color: #fff;
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
        }

        .share {
          .icon {
            color: #5776F7;
          }
        }
        .scan {
          border-bottom: none;
          .code {
            width: 40px;
            height: 40px;
            margin: 5px 0;
            .img {
              height: 100%;
              width: 100%;
            }
          }
        }
        .back-top {
          visibility: hidden;
          margin-top: 10px;
          border: 1px solid #eaeaea;

        }
      }
    }

    .popup-wrap {
      .mask {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1001;
        background: rgba(0, 0, 0, 0.5);
      }

      .popup {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 32%;
        min-width: 480px;
        min-height: 554px;
        height: auto;
        max-height: 70%;
        overflow: auto;
        background-color: #fff;
        border-radius: 12px;
        z-index: 1002;

        .close-btn {
          position: absolute;
          top: 8px;
          right: 12px;
          padding: 12px;
          color: #333;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .file-list {
        margin-bottom: 20px;
        font-size: 18px;
        a {
          color: #0068c6;
        }
      }
    }

    .share-popup {
      /deep/.el-dialog__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 32px;
      }

      /deep/.el-dialog {
        min-width: 567px;
        width: 30%;
        border-radius: 24px;
      }

      /deep/ .el-dialog__header {
        .el-dialog__title {
          font-size: 20px;
          color: #000;
        }
      }

      /deep/.el-dialog__headerbtn {
        .el-dialog__close {
          font-size: 28px;
        }
      }

      .img-wrap {
        width: 300px;
        height: 300px;
        overflow: hidden;
        margin-bottom: 26px;
      }

      .tips {
        margin-bottom: 20px;
        font-size: 16px;
        color: #999;
      }

      .address {
        margin-bottom: 20px;
        padding: 0 12px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        border-radius: 6px;
        background-color: #f2f2f2;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .btn-wrap {
        display: flex;
        justify-content: center;
        padding-bottom: 24px;

        .btn-item {
          display: inline-block;
          margin: 0 20px;
          padding: 8px 16px;
          font-size: 16px;
          color: #333;
          border-radius: 2px;
          background-color: #f2f2f2;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
