<template>
<div :class="{'body-bcg':displayPageChild}">
  <div class="main-body" v-if="displayPageChild && orderList.length">
    <div class="content-wrap" v-for="(item, index) in orderList" :key="index">
      <div class="content-header">
        <div class="content-id">单号：{{ item.orderNo }}</div>
        <div class="content-status">
          <span :style="{ color: statusColor(item.auditStatus) }">{{
            item.auditStatus | auditStatusFilter
          }}</span>
        </div>
      </div>
      <div class="content-inner">
        <div class="content-left">
          <img :src="item.productImage" class="img" />
        </div>
        <div class="content-right">
          <div v-if="item.productName">{{ item.productName }}</div>
          <div class="txt">总金额：{{ item.orderAmt }}元</div>
          <div class="txt">
            下单时间：
            {{ item.orderTime | formatDate }}
          </div>
          <!-- <div class="txt">评审时间：{{ item.operateTime }}</div> -->
        </div>
      </div>
      <!-- <div class="content-comment" v-if="item.comment">
        {{item.comment || ''}}
      </div> -->
      <div class="content-extend">
        <div class="score">
          <span>{{ item.score ? item.score + "分" : "" }}</span>
        </div>
        <div class="item-control">
          <div
            v-if="item.auditStatus == 1"
            class="btn cancle-btn"
            @click="toCancle(item.orderId)"
          >
            取消订单
          </div>
          <div class="btn detail-btn" @click="toDetail(item.orderId)">详情</div>
        </div>
      </div>
    </div>
  </div>
  <div class="empty" v-else-if="displayPageChild && !orderList.length">
    <el-empty description="暂无数据"></el-empty>
  </div>
  <router-view></router-view>
  </div>
</template>

<script>
import { getOrderPage, removeOrder } from "@/api/personal";
import { formatDate } from "@/utils/date";

const auditStatusMap = [
  { code: "0", name: "已取消" },
  { code: "1", name: "待确认" },
  { code: "2", name: "已确认" },
  { code: "3", name: "已驳回" },
];
export default {
  data() {
    return {
      orderList: [],
      displayPageChild:true,
      limit:'99',
      page:'1'
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let params = {
        limit:this.limit,
        page:this.page
      }
      getOrderPage(params).then((res) => {
        this.orderList = res.data.list;
      });
    },
    statusColor(status) {
      const colorMap = {
        0: "#bbbbbb", //已取消
        1: "#3892F8", //待确认
        2: "#009933", //已确认
        3: " #FF3300", //已驳回，
      };
      return colorMap[status];
    },
    toDetail(orderId) {
      this.$router.push({ path: "/personCenter/orderList/orderDetail/" + orderId });
    },
    toCancle(orderId) {
      const that = this;
      that.$confirm("是否确定取消订单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then((res) => {
          if (!res) return;
          removeOrder(orderId).then((res) => {
              setTimeout(() => {
                that.fetchData();
                that.$message({
                  message: res.message,
                  type: "success",
                });
              }, 1000);
          });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
  },
  filters: {
    formatDate(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy.MM.dd hh:mm");
    },

    statusColor(item) {
      if (item == "未晋级" || item == "评审未通过") {
        return "red";
      }
      if (item == "已晋级" || item == "初审通过") {
        return "green";
      }
      if (item == "已完成" || item == "已评审" || item == "2") {
        return "bule";
      }
    },
    auditStatusFilter(code) {
      let statusName = "";
      auditStatusMap.filter((item) => {
        if (code == item.code) {
          statusName = item.name;
        }
      });
      return statusName;
    },
  },
  watch: {
    $route: {
      handler() {
        let routerList = this.$route.path.slice(1, this.$route.path.length).split('/')
        if (routerList && routerList.length == 2) {
          this.displayPageChild = true;

        } else {
          this.displayPageChild = false;
            
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.body-bcg {
  flex: 1;
  background-color: #fff;
}
.content-wrap {
  // margin-top: 2px;
  //   height: 120px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  //   padding-top: 10px;
  padding: 20px;
  background: #fff;

  .content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: #eaeaea solid 1px;
    padding-bottom: 15px;
    .content-id {
      font-size: 16px;
      color: #999999;
    }
    .content-status {
      font-size: 16px;
      color: #999999;
    }
  }
  .content-inner {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
  }
  .content-left {
    height: 100px;
    .img {
      width: 120px;
      height: 100px;
      border-radius: 6px;
    }
  }

  .content-right {
    padding-top: 0px;
    margin-left: 20px;
    width: 100%;
    // border-bottom: #f5f5f5 solid 1px;
    font-family: "Arial Normal", "Arial";
    font-weight: 400;
    font-style: normal;
    .txt {
      height: 34px;
      font-size: 16px;
      color: #999999;
      padding-top: 16px;
    }
    div:nth-child(1) {
      padding-top: 4px;
      height: 28px;
      font-size: 20px;
      color: #000000;
    }
    // div:nth-child(3) {
    //   padding-top: 14px;
    //   height: 26px;
    //   font-size: 18px;
    //   color: #666666;

    //   span {
    //     margin-left: 20px;
    //   }
    // }
  }
  .content-comment {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: #f7f7f7;
    color: #3892f8;
    padding: 10px 15px;
    max-width: 884px;
    margin: 15px 0;
    font-size: 16px;
    line-height: 1.35em;
    white-space: pre-wrap;
    border-radius: 8px;
  }
  .content-extend {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    .score {
      padding-left: 8px;
      color: #3892f8;
      span {
        font-size: 20px;
        font-weight: bold;
        margin-right: 0.2em;
      }
    }
    .item-control {
        cursor: pointer;

      display: flex;
      .btn {
        padding: 8px 24px;
        border-radius: 20px;
        text-align: center;
        margin-left: 16px;
        border: solid 1px transparent;
      }
      .cancle-btn {
        color: #ff6600;
        border: #ff6600 solid 1px;
      }
      .reapply-btn {
        color: #3892f8;
        border-color: #3892f8;
      }
      .delete-btn {
        color: #989898;
        border: #989898 solid 1px;
      }
      .detail-btn {
        border: #888 solid 1px;
      }
    }
  }
}
.content-wrap:first-child {
  margin-top: 0;
}
.empty {
  min-height: 600px;
  height: auto;
  display: flex;
  justify-items: center;
  justify-content: center;
}
</style>
