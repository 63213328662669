import Vue from 'vue'


Vue.prototype.$copy = function (content = '', tipscontent = '复制成功!') {
    var input = document.createElement("input"); // 创建input对象
    console.log(content);
    input.value = content; // 设置复制内容
    document.body.appendChild(input); // 添加临时实例
    input.select(); // 选择实例内容
    document.execCommand("Copy"); // 执行复制
    document.body.removeChild(input); // 删除临时实例
    this.$message.success(tipscontent);
}

