<template>
  <div
    class="top-wrap"
    :style="{ 'background-color': bgcolor ? bgcolor : '#f7f7f7' }"
  >
    <div class="top w">
      <div class="left">
        <span class="icon iconfont icon-tag-address"></span>
        <span class="location" @click="selectLocation">{{
          locationInfo ? locationInfo.locationName : ""
        }}</span>
        <router-link to="/" class="link" v-if="isShowHome">首页</router-link>
      </div>
      <div class="right">
        <div class="item" style="margin-right: 20px">
          欢迎访问 高校双创人才服务站！
        </div>
        <div>
          <el-dropdown @command="handleCommand" v-if="loginStatus">
            <span class="el-dropdown-link">
              {{ scCustom.name ? scCustom.name : scCustom.nickname }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="personCenter"
                >个人中心</el-dropdown-item
              >
              <el-dropdown-item command="loginOut" divided
                >退出</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <div class="item btn-item" @click="toLogin" v-if="!loginStatus">
          立即登录
        </div>
        <div class="item btn-item" @click="toLogin" v-if="!loginStatus">
          立即注册
        </div>
        <!-- <div class="item btn-item" v-if="!loginStatus">运营方入口</div> -->
        <div class="item" style="color: #c5c5c5">|</div>
        <div class="item contact">
          <span class="icon iconfont icon-service"></span>
          <span class="txt" style="color: #597af8">联系客服</span>
          <div class="contact-box">
            <div class="triangle"></div>
            <div class="contact-wrap">
              <div class="title">联系客服</div>
              <div class="img-wrap">
                <img
                  class="img"
                  :src="$globalHeader.imgUrl + '/static/other/corp_concat.jpg'"
                  alt="二维码"
                />
              </div>
              <div class="subtitle">识别二维码添加客服</div>
              <div class="phone">联系电话</div>
              <div class="phone">18805919699</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="地区选择"
      :visible.sync="locationVisible"
      width="40%"
      top="40px"
      :before-close="handleClose"
    >
      <location
        @closeLocation="closeLocation"
        :visible="locationVisible"
        :loginStatus="loginStatus"
      />
    </el-dialog>
  </div>
</template>

<script>
import { getLocation, getCurrentLocation } from "@/api/common/common.js";
import { local } from "@/utils/storage.js";
import location from "@/components/common/location.vue";

export default {
  props: {
    bgcolor: {
      type: String,
    },
    isShowHome: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    location,
  },
  data() {
    return {
      locationInfo: null, //定位
      scCustom: {}, //用户信息
      loginStatus: false,
      locationVisible: false,
    };
  },
  created() {
    this.fetchData();
    this.initCustom();
  },
  methods: {
    handleClose(done) {
      done();
    },
    closeLocation(item) {
      this.locationInfo = {
        areaCode: item.areaCode,
        locationName: item.city,
      };
      this.locationVisible = false;
      this.$emit("selectData");
    },
    //选择地区
    selectLocation() {
      this.locationVisible = true;
    },
    fetchData() {},
    //获取用户定位i信息
    getUserLocation(loginStatus) {
      if (loginStatus) {
        getCurrentLocation().then((res) => {
          this.locationInfo = res.data;
          if (!this.locationInfo) {
            this.getUserLocation(false);
          }
        });
      } else {
        getLocation().then((res) => {
          this.locationInfo = {
            locationName: res.data,
          };
        });
      }
    },
    //去登录
    toLogin() {
      this.$router.push({ path: "/login" });
    },
    //展示用户信息
    initCustom() {
      let user = local.Get("scCustom");
      this.scCustom = user ? user : {};
      this.loginStatus = user ? true : false;
      this.getUserLocation(this.loginStatus);
    },
    //个人中心
    handleCommand(command) {
      if (command == "personCenter") {
        window.location.href = "/personCenter";
      } else if (command == "loginOut") {
        this.$confirm("确定要退出登录吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.logout();
        });
      }
    },
    //退出登录
    logout() {
      local.Remove("Authorization");
      local.Remove("scCustom");
      local.Remove("isLogin");
      window.location.href = "/login";
    },
  },
};
</script>

<style lang="scss" scoped>
.top-wrap {
  // background: linear-gradient(to bottom, #f2f2f2, #f7f7f7);
  background: #f7f7f7;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  .left {
    display: flex;
    align-items: center;
    padding-right: 12px;
    font-size: 14px;
    cursor: pointer;
    color: #989898;

    .icon {
      font-size: 16px;
      margin-right: 4px;
    }

    .link {
      margin-left: 20px;
      color: #989898;
    }
  }

  .right {
    display: flex;
    align-items: center;

    .item {
      padding: 0 8px;
      font-size: 16px;
      color: #0f0f0f;
    }
    .el-dropdown {
      margin: 0 10px;
    }
    .el-dropdown-link {
      cursor: pointer;
      color: #989898;
    }

    .btn-item {
      color: #989898;
      padding: 0 0.35em;
      cursor: pointer;
    }
    .btn-item:hover {
      text-decoration: underline;
    }

    .item:last-child {
      cursor: pointer;
    }

    .contact {
      position: relative;

      .icon {
        font-size: 14px;
        margin-right: 4px;
        color: #597af8;
      }

      .contact-box {
        position: absolute;
        top: 32px;
        right: -20px;
        padding: 16px 24px 24px;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 6px;
        z-index: 10;
        box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.08);

        .triangle {
          position: absolute;
          top: -24px;
          right: 45px;
          transform: translate(-50%, 0);
          border: 12px solid #fff;
          border-color: transparent transparent #fff transparent;
        }

        .title {
          margin-bottom: 12px;
          text-align: center;
          font-size: 14px;
          color: #000;
        }

        .contact-wrap {
          display: flex;
          flex-direction: column;

          .img-wrap {
            width: 252px;
            height: 252px;
            overflow: hidden;
            border-radius: 6px;
          }

          .subtitle {
            margin: 12px 0;
            text-align: center;
            font-size: 14px;
            color: #333;
          }

          .phone {
            text-align: center;
            font-size: 14px;
            color: #333;
          }
        }
      }
    }

    .contact:hover .contact-box {
      display: flex;
    }
  }
}
</style>
