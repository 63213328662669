<template>
  <div class="news-block">
    <div class="header">
      <div class="title">
        <span class="txt">资讯推荐</span>
      </div>
      <div class="more hover-effect" @click="toMore">
        查看更多<span class="icon iconfont icon-more"></span>
      </div>
    </div>
    <div class="list">
      <div
        class="item animation-pulse"
        v-for="(item, index) in dynamicList"
        :key="index"
        @click="dynamicDetailClick(item.id)"
      >
        <div class="title">
          <div class="spot"></div>
          <div class="txt">{{ item.articleName }}</div>
        </div>
        <div class="time">{{ item.time }}</div>
      </div>
    </div>
    <div class="coupon">
      <div class="header">
        <div class="title">
          <span class="txt">人才补贴/优惠福利</span>
        </div>
        <div class="more hover-effect" @click="handleMoreClick()">
          更多<span class="icon iconfont icon-more"></span>
        </div>
      </div>
      <div class="coupon-list">
        <div class="item" v-for="(item, index) in couponData" :key="index">
          <div class="price" v-if="item.couponType == 1">
            <span :id="`price${index}`">¥{{ item.couponAmt }}</span>
          </div>
          <div class="price" v-if="item.couponType == 2">
            <span :id="`price${index}`">{{ item.couponAmt }}折</span>
          </div>
          <div class="title">
            {{ item.merchantResult.merchantName || "" }}
          </div>
          <div class="cata">{{ item.couponCategory[0] || "" }}</div>
          <div class="itemline"></div>
          <div class="btn">立即领取</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    couponData: {
      type: Array,
      default: () => [],
    },
    dynamicList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      aaa: false,
    };
  },
  methods: {
    //点击更多
    handleMoreClick() {
      this.centerDialogVisible = true;
      this.$emit("moreDialog", this.centerDialogVisible);
    },
    //更多赛事动态
    toMore() {
      this.$router.push("/articleList");
    },
    //跳转动态详情
    dynamicDetailClick(id) {
      this.$router.push({
        path: "/articleList/articleDetail/" + id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$bgImageUrl: "https://gx.scjfu.com";
.news-block {
  flex: 1;
  border-right: 1px solid #f2f2f2;
  padding: 0 20px;
  margin: 20px 0;
  position: relative;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      font-size: 19px;
      font-weight: 600;
      color: #000;
    }
    .more {
      line-height: 1;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #888;
      .icon {
        font-size: 16px;
      }
    }
  }
  .list {
    cursor: pointer;
    margin: 12px 0;
    min-height: 263px;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      padding: 8px 0;
      .title {
        flex: 1;
        display: flex;
        align-items: center;
      }
      .spot {
        min-height: 6px;
        min-width: 6px;
        border-radius: 50%;
        background-color: #000;
      }
      .txt {
        margin-left: 0.5em;
        display: -webkit-box;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .time {
        color: #888;
        margin-left: 40px;
      }
    }
    .item:hover {
      color: #597af8;
      cursor: pointer;
      .spot {
        background-color: #597af8;
      }
    }
    // .item::before {
    //   content: "•";
    //   margin-right: 0.35em;
    //   color: #000;
    // }
  }
}

.coupon {
  margin-top: 40px;
  width: 100%;
  padding: 16px 0;
  background: #fff url(#{$bgImageUrl + '/static/icon/v2/bg-index23434.jpg'})
    no-repeat center top;
  background-size: 100% 248px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-size: 19px;
      font-weight: 600;
      color: #000;
    }

    .sub {
      flex: 1;
      font-size: 12px;
      color: #555;
      margin-top: 8px;
    }

    .more {
      display: flex;
      align-items: center;
      padding: 0 20px;
      font-size: 16px;
      color: #888;
      .icon {
        font-size: 16px;
      }
    }
  }

  .coupon-list {
    display: flex;
    align-items: stretch;
    padding: 20px 10px;
    padding-bottom: 0;
    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 10px;
      background: #f6e0b6;
      color: #f08319;
      text-align: center;
      line-height: 1;
      padding: 14px 5px 10px 5px;
      border-radius: 10px;

      .price {
        font-size: 19px;
        // font-weight: bold;
      }

      .title {
        margin-top: 0.8em;
        font-size: 12px;
        font-weight: 500;
      }

      .cata {
        flex: 1;
        color: #814913;
        font-size: 12px;
        margin-top: 0.9em;
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        border-radius: 20px;
        color: #fff;
        font-size: 12px;
        -webkit-transform: scale(0.92);
        background: linear-gradient(92deg, #f2a056 0%, #ed6b3d 100%);
        // margin: 10px 5px 0 5px;
        margin-top: 4px;
      }

      .itemline {
        height: 1px;
        font-size: 0;
        border-bottom: 2px dashed #fff6ed;
        margin: 4px 0;
        position: relative;
      }

      .itemline::before {
        position: absolute;
        content: "";
        overflow: hidden;
        height: 10px;
        width: 10px;
        background-color: #fff;
        border-radius: 50%;
        bottom: -4px;
        left: -11px;
      }

      .itemline::after {
        position: absolute;
        content: "";
        overflow: hidden;
        height: 10px;
        width: 10px;
        background-color: #fff;
        border-radius: 50%;
        bottom: -4px;
        right: -11px;
      }
    }
    .item:last-child {
      margin: 0;
    }
    .item:hover {
      cursor: pointer;
      -webkit-transform: scale(1.03);
      transform: scale(1.03);
    }
  }
}
</style>