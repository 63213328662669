<template>
  <!-- 表单组件-附件上传 -->
  <div class="formModule">
    <div class="formModule-header">
      <div class="title">
        <span class="order-num">{{ index }}.</span
        ><span class="txt">{{ formDetail.title }}</span>
      </div>
      <div class="extend">
        <div class="isMust" v-if="formDetail.isNeed == '1'">必填</div>
      </div>
    </div>
    <div class="formModule-desc">
      上传
      {{
        formDetail.rule.placeholder ? "| " + formDetail.rule.placeholder : ""
      }}
    </div>
    <div class="formModule-content">
      <div
        class="mod-fileUpload"
        v-for="(item, index) in formDetail.value"
        :key="index"
      >
        <div class="file-item">
          <div class="tit">{{ item.originalName || "" }}</div>
        </div>
      </div>
      <div class="op-outer">
        <div @click="changeFileIndex()">
          <el-upload
            :action="fileUploadUrl"
            :data="fileUploadParam"
            :before-upload="beforeFileUpload"
            :on-preview="handleFilePreview"
            :on-remove="handleFileRemove"
            :on-success="handleFileUploadSuccess"
            :on-error="handleFileUploadError"
            :before-remove="beforeFileRemove"
            multiple
            :limit="1"
            :on-exceed="handleFileExceed"
            :file-list="fileList"
            :disabled="formDetail.disabled"
          >
            <el-button class="upload-btn">点击上传</el-button>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getqiniuToken } from "@/api/common/common";
export default {
  props: {
    formItem: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      formDetail: this.formItem,
      fileUploadUrl: "https://up-z2.qiniup.com", //上传文件地址
      fileUploadParam: {},
      fileList: [],
    };
  },
  methods: {
    changeFileIndex() {
      getqiniuToken().then((res) => {
        this.fileUploadParam = { key: "", token: res.data };
      });
    },
    beforeFileUpload(file) {
      this.fileUploadParam.key =
        String(new Date().getTime()) +
        Math.floor(Math.random() * 50 + 50) +
        "." +
        file.name;
    },
    handleFilePreview(file) {
      console.log(file);
    },
    handleFileExceed() {
      this.$message.warning(`抱歉，最多只能上传 1个文件`);
    },
    beforeFileRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleFileUploadSuccess(file) {
      this.formDetail.value = this.formDetail.value
        ? this.formDetail.value
        : [];
      if (this.formDetail.value == "") {
        this.formDetail.value = [];
      }

      this.formDetail.value.push({
        id: file.data.id,
        originalName: file.data.fname,
        url: this.$globalHeader.fileUrl + "/" + file.data.key,
      });
      this.$message.success(`上传成功`);
    },
    handleFileUploadError() {
      this.$message.warning(`上传失败`);
    },
    handleFileRemove(file, fileList) {
      console.log("------移除--------");
      console.log(file, fileList);
      this.formDetail.value = [];
      this.fileList = [];
    },
  },
  created() {
    if (this.formDetail.value) {
      this.formDetail.value.forEach((item) => {
        this.fileList.push({
          name: item.originalName,
          url: item.url,
        });
      });
    }
  },
  watch: {
    "formDetail.value": {
      handler() {
        console.log("上传文件触发");
        console.log(this.formDetail);
        this.$bus.$emit("formDetail", this.formDetail);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.formModule {
  font-size: 14px;
  padding: 15px;

  .formModule-header {
    display: flex;
    margin-bottom: 10px;

    .title {
      font-size: 15px;
      font-weight: 600;

      .txt::after {
        content: "：";
      }
    }

    .extend {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 10px;
    }
  }

  .isMust {
    color: #ff0000;
    font-size: 11px;
  }

  .formModule-desc {
    margin-bottom: 10px;
    font-size: 12px;
    color: #9b9999;
    line-height: 1.35;
  }

  .formModule-content {
    .mod-fileUpload {
      background-color: #f7f7f7;
      display: flex;
      align-items: stretch;
      border-radius: 12rpx;
      overflow: hidden;
      margin: 6px 0;

      .file-item {
        flex: 1;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        padding: 8px;
        font-size: 14px;
        min-height: 28px;
        line-height: 1.35;

        .tit {
          flex: 1;
        }
      }
    }

    .op-outer {
      margin: 6px 0;

      .upload-btn {
        color: #ffffff;
        background-color: #199ed8;
        border-radius: 6px;
        font-size: 14px;
        width: 140px;
      }
    }
  }
}
</style>