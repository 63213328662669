<template>
  <div class="list-block" v-if="trendList.length">
    <div
      class="trend-item"
      hover-class="hover-effect"
      v-for="(item, index) in trendList"
      :key="index"
      @click="addView(item.id)"
    >
      <div class="item-detail">
        <div class="item-info" v-if="item.trendTitle">
          <span class="title">{{ item.trendTitle }}</span>
        </div>
        <div class="video">
          <!-- <video
            class="video-content"
            :src="item.videoUrl"
            controls
          ></video> -->
           <video
            class="video-content"
            src="https://static.scjfu.com/170434992871982.VID_20231130_192307.mp4"
            controls
          ></video>
        </div>
      </div>
      <div class="item-extend">
        <div class="time">
          {{ item.releaseTime }}
        </div>
        <div class="person">
          <div class="person-content">
            <span class="icon iconfont icon-browse"></span>
            浏览{{ item.viewNum || "0" }}次
          </div>
          <div
            class="person-content hover-effect"
            :class="{ 'person-active': item.isSupport }"
            @click="addUp(item)"
          >
            <span
              class="icon iconfont"
              :class="item.isSupport ? 'icon-up-active' : 'icon-up'"
            ></span>
            赞
          </div>
        </div>
      </div>
    </div>
    <div class="m-pager">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-size="limit"
          :total="pageCount"
          @current-change="changePageClick"
        >
        </el-pagination>
      </div>
  </div>
  <div class="empty" v-else>
    <el-empty description="暂无数据"></el-empty>
  </div>
</template>

<script>
import { getTrendList,updateViewNum,updateSupport } from "@/api/course/course.js";

export default {
  props: {
    teacherId: String,
    year: String,
  },
  data() {
    return {
      trendList: [],
      pageCount:'',
      limit:10,
    };
  },
  methods: {
    getTrendList(page = 1) {
      let params = {
        teacherId: this.teacherId,
        year:this.year,
        page: page,
        limit: this.limit,
      };
      getTrendList(params).then((res) => {
        this.trendList = res.data.list;
        this.pageCount = Number(res.data.count)

      });
    },
    //点浏览
    addView(id) {
        let params = {
            trendId: id
        }
        updateViewNum(params).then()
    },
    //点赞
    addUp(item) {
      item.isSupport = !item.isSupport
      let params = {
        trendId: item.id,
        isSupport: item.isSupport
      }
      updateSupport(params).then()
    },
    //切换页面
    changePageClick(page) {
      this.getTrendList(page);
    },
  },
  created() {
      this.getTrendList();
  },
  watch: {
    year: {
      handler() {
        this.getTrendList();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.trend-item {
  padding: 20px 0;
  margin: 0 30px;
  border-bottom: 1px solid #f2f2f2;
  .item-detail {
    display: flex;
    flex-direction: column;

    .video {
      flex-shrink: 0;
      width: 300px;
      height: 200px;
      margin-right: 20px;

      .video-content {
        width: 100%;
        height: 100%;
      }
    }

    .item-info {
      display: flex;
      flex-direction: column;
      padding-bottom: 24px;

      .title {
        line-height: 1.35;
        font-size: 18px;
        color: #333;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .item-extend {
    display: flex;
    justify-content: space-between;
    color: #888;
    font-size: 16px;
    margin-top: 24px;

    .time {
      flex: 1;
      margin-right: 40px;
    }

    .person {
      display: flex;

      .icon {
        font-size: 14px;
        margin-right: 0.35em;
      }

      .person-content {
        margin-left: 20px;
      }

      .person-active {
        color: #6985f8;
      }
    }
  }
}

.trend-item:last-child {
  border: none;
}
.m-pager {
    padding: 24px 0;

    .el-pagination {
      display: flex;
      justify-content: center;

      /deep/ .btn-prev {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-prev:hover:enabled {
        color: #409eff;
      }

      /deep/ .number {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .more {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-next {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .active {
        color: #fff;
        background-color: #409eff;
      }

      /deep/ .btn-next:hover:enabled {
        color: #409eff;
      }
    }
  }
</style>