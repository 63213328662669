<template>
  <div class="search-wrap">
    <div class="search-title">{{data.title}}</div>
    <el-divider></el-divider>
    <div class="item hover-def" v-for="(item,index) in data.itemList" :key="index">
        <div class="item-warp hover-bg" @click="route(item)">
            <!-- 封面 -->
            <div class="search-img">
                <img :src="item.cover"/>
            </div>
            <!-- 文本 -->
            <div class="search-content">
                <div v-html="item.title"></div>
                <div>{{item.label}}</div>
            </div>
        </div>
        <el-divider></el-divider>
    </div>
    <div style="color:#4390E7">
        <span class="hover-def" @click="clickMore">{{data.moreName}}</span>
    </div>
  </div>
</template>

<script>


export default {
  props: {
    data:Object
  },
  components: {

  },
  data() {
    return {

    };
  },
  
  methods: {
    clickMore(){
        this.$emit('clickMore',this.data.type)
    },
    route(item){
        if(this.data.type == 'mat_competition' || this.data.type == 'cont_dynamic'){
            this.$router.push({
                path: item.route,
            });
        }
        
    }
  },
  created() {

  },
};
</script>

<style lang="scss" scoped>
//分割线
::v-deep .el-divider--horizontal{
  width: 90%;
  margin: 12px 0px;
  background-color: #f2f3f5;
}

    .search-title{
        font-size: 18px;
    }
    .item{
        .item-warp{
            display: flex;
            width: 90%;
            padding: 10px;
            .search-img{
                width:95px;
                height:95px;

                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                }
            }
            
            .search-content{
                width: 80%;
                max-width: 600px;
                div{
                    margin-left: 20px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow:ellipsis;
                }
                :nth-child(1){
                    margin-top: 10px;
                }
                :nth-child(2){
                    margin-top: 40px;
                    color: #99999A;
                }
            }
            
        }
    }
    

</style>
