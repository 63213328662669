<template>
  <div class="banner-plate">
    <el-carousel :interval="5000" arrow="hover">
      <!-- <el-carousel :interval="5000" arrow="hover" :height="bannerImgHeight + 'px'" > -->
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <div class="img-wrap">
          <!-- <div
            @click="handleBannerClick(item)"
            class="bannerimg"
            :style="{
              background:
                'url(' + item.imagePath + ') center top/cover no-repeat white',
            }"
          ></div> -->
          <img
            @click="handleBannerClick(item)"
            class="img"
            :src="item.imagePath + '?imageView2/5/w/1300/h/600'"
          />
          <!-- <img class="banner-img" :src="item.imagePath"> -->
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
/**
 * list         轮播图列表
 * imgUrl       图片地址
 * autoHeight   true自适应高度  false固定高度
 */
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  // watch: {
  //     list: {
  //         handler(newV) {
  //             if (newV.length) {
  //                 setTimeout(() => {
  //                     this.getBannerImgHeight()
  //                 }, 50);
  //             }
  //         }
  //     }
  // },
  data() {
    return {
      bannerImgHeight: "", //轮播图高度
    };
  },
  methods: {
    //获取轮播图图片高度
    // getBannerImgHeight() {
    //     this.bannerImgHeight = window.document.querySelector('#bannerImg1').height
    // },
    handleBannerClick(item) {
      if (item.typeId == 1) {
        window.location.href = item.path;
      } else {
        window.open(item.path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-plate {
  height: 400px;

  .el-carousel {
    height: 400px;
    width: auto;

    /deep/.el-carousel__container {
      height: 400px;
    }

    .img-wrap {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      background-color: #fff;
      cursor: pointer;
      .banner-img {
        height: 400px;
      }

      .bannerimg {
        cursor: pointer;
        height: 400px;
        background: url("https://t3.chei.com.cn/ncss/cy/web/img/banner_top__8th_1_n.jpg")
          center center / cover no-repeat rgb(255, 255, 255);
      }
    }
  }

  /deep/.el-carousel__arrow {
    width: 72px;
    height: 72px;
    font-size: 32px;
    line-height: 1 !important;
  }
}
</style>
