import Vue from 'vue'

let baseURL
//切换环境
if (process.env.NODE_ENV == 'development') {
    //   baseURL = 'http://192.168.1.101:8081';
    // baseURL = 'http://127.0.0.1:8081';
    baseURL = 'https://gx.scjfu.com';
}
else if (process.env.NODE_ENV == 'debug') {
    baseURL = 'http://dev.scjfu.com';
}
else if (process.env.NODE_ENV == 'production') {
    baseURL = 'https://gx.scjfu.com';
}
// console.log(process.env.NODE_ENV); // 打印结果为 'development'
Vue.prototype.$baseURL = baseURL


export default {
    baseURL
};
