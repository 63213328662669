<!-- 
    参数格式

list        非必填          [{name:展示名称,type:跳转路由}]

bgColor     非必填          "#ff"  -   背景颜色

<slot name="logo-wrap">     插槽 控制logo 和 tab
<slot name="tab-wrap">

-->

<template>
  <div class="header">
    <div class="main w">
      <div class="left">
        <slot name="logo-wrap">
          <h1 class="logo-wrap">
            <div class="img-wrap">
              <img
                class="img"
                :src="$globalHeader.imgUrl + '/static/icon/v2/pc-home-logo.png'"
                alt="高校双创人才服务站logo"
              />
            </div>
          </h1>
        </slot>
        <div class="split-line" v-if="splitLine"></div>
        <slot name="tab-wrap" v-if="$route.name == 'Login'">
          <div class="list">
            <div
              class="item"
              v-for="(item, index) in resultList"
              :key="index"
              @click="chooseTab(item)"
              :class="{ active: currentIndex == index }"
            >
              {{ item.name }}
            </div>
          </div>
        </slot>
        <slot name="searchAll" v-else>
          <div class="searchBlock">
            <div class="input-block">
              <span class="tag">全部</span>
              <span class="icon iconfont icon-search"></span>
              <input
                v-model="searchKey"
                type="text"
                @change="searchChange"
                class="input"
                placeholder="请输入关键词搜索"
              />
            </div>
            <div class="lables">
              <div
                class="item hover-effect"
                v-for="(item, index) in lableList"
                :key="index"
                @click="searchModule(item)"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </slot>
      </div>
      <div class="right">
        <div class="img-wrap">
          <img
            class="img"
            :src="$globalHeader.imgUrl + '/static/other/mini_concat.png'"
            alt="二维码"
          />
        </div>
        <div class="r-content">
          <!-- <i class="title">手机端</i> -->
          <i class="subtitle">扫码访问</i>
        </div>
        <div class="right-box">
          <div class="img-wrap">
            <img
              class="img"
              :src="$globalHeader.imgUrl + '/static/other/mini_concat.png'"
              alt="二维码"
            />
          </div>
          <span>扫一扫访问手机端站点</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list", "currentIndex", "splitLine"],
  data() {
    return {
      resultList: [],
      showTab: false,
      searchKey: "", //搜索内容
      defaultList: [
        { name: "首页", type: "home" },
        { name: "赛事动态", type: "matchDynamic" },
        { name: "人才政策", type: "policy" },
        { name: "高校双创", type: "course" },
      ],
      lableList: [
        "人才政策",
        "赛事活动",
        "双创项目",
        "人才服务站",
        "导师信息",
        "服务项目",
      ],
    };
  },
  created() {
    if (!this.list || !this.list.length) {
      this.resultList = this.defaultList;
    } else {
      this.resultList = this.list;
    }
  },
  methods: {
    searchChange() {
      console.log("回车事件");
      //搜索结果页面
      this.$router.push({
        path: "/searchResult",
        query: {
          keyWord: this.searchKey,
        },
      });
    },
    searchModule(barName) {
      this.$router.push({
        path: "/searchResult",
        query: {
          keyWord: this.searchKey,
          barName: barName,
        },
      });
    },
    chooseTab(item) {
      if (item.type == "home") {
        this.$router.push("/");
      } else {
        if (item.type && typeof item.type == "string") {
          //  this.$router.replace("/" + item.type);
          window.location.href = "/" + item.type;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #f7faff;

  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;

    .left {
      flex-shrink: 0;
      display: flex;
      align-items: center;

      .logo-wrap {
        padding: 0;
        margin: 0;
        line-height: 1;
        display: flex;
        align-items: center;
        margin-right: 80px;
        max-width: 415px;

        .img-wrap {
          margin-right: 20px;
          height: 55px;
        }

        .title {
          margin-left: 12px;
          font-size: 20px;
        }
      }

      .split-line {
        width: 1px;
        height: 40px;
        transform: translate(-20px, 0);
        background-color: #f1f1f1;
      }
      .searchBlock {
        margin-top: 20px;
        width: 587px;
        color: #c2c2c2;
        font-size: 16px;
        .input-block {
          display: flex;
          align-items: center;
          background: #ffffff;
          border: 2px solid #597af8;
          border-radius: 28px;
          .tag {
            margin: 12px 6px;
            padding: 0 15px;
            border-right: 1px solid #eaeaea;
          }
          .icon {
            font-size: 17px;
            margin-right: 0.5em;
          }
          .input {
            color: #353535;
            width: 470px;
          }
          .input::placeholder {
            color: #c2c2c2;
          }
        }
        .lables {
          display: flex;
          justify-content: space-around;
          color: #999;
          font-size: 16px;
          cursor: pointer;
          .item {
            padding: 20px 10px;
          }
          // .item:first-child {
          //   margin-left: 0;
          // }
          // .item:last-child {
          //   margin-right: 0;
          // }
        }
      }
      .list {
        height: 100%;
        display: flex;
        align-items: center;

        .item {
          position: relative;
          height: 100%;
          line-height: 100px;
          padding: 0 28px;
          font-size: 20px;
          cursor: pointer;
        }

        .item:hover {
          color: #2d7cce;
        }

        .active {
          color: #2d7cce;
        }

        .active::after {
          content: "";
          position: absolute;
          left: 50%;
          bottom: 20px;
          transform: translate(-50%, 0);
          width: calc(100% - 56px);
          height: 3px;
          border-radius: 2px;
          background-color: #2d7cce;
          overflow: hidden;
        }
      }
    }

    .right {
      flex-shrink: 0;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 10px 0;
      padding: 6px 12px;
      // border: 1px solid #f2f2f2;
      cursor: pointer;
      border-radius: 3px;
      .img-wrap {
        width: 70px;
        height: 70px;
        // border-radius: 50%;
        overflow: hidden;
        background-color: #fff;
        padding: 4px;
        border-radius: 6px;
      }

      .r-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // margin-left: 12px;
        margin-top: 0.3em;
        .title {
          font-size: 15px;
          font-weight: bold;
          color: #000;
        }

        .subtitle {
          font-size: 16px;
          color: #597af8;
        }
      }

      .right-box {
        border-radius: 3px 0 3px 3px;
        box-sizing: border-box;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 12px 24px;
        top: 52px;
        right: 0;
        background-color: #fff;
        border: 1px solid #f2f2f2;
        box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
        z-index: 10;

        .img-wrap {
          width: 160px;
          height: 160px;
          overflow: hidden;
          border-radius: 0;
        }

        span {
          padding: 10px 0;
          font-size: 14px;
        }
      }
    }

    .right:hover {
      .right-box {
        display: flex;
      }
    }
  }
}
</style>