<template>
  <div class="home-page page">
    <div class="page-wrap" v-if="displayPage">
      <!-- 顶部 -->
      <page-top @selectData="fetchData"></page-top>
      <!-- 头部 -->
      <home-header :list="headerList" currentIndex="0"></home-header>
      <!-- 主体 -->
      <main class="main">
        <!-- 轮播图 -->
        <!-- <section class="banner-wrap">
          <banner :list="bannerList"></banner>
        </section> -->

        <!-- <section class="kind-wrap w">
          <div class="k-top">
            <div class="title">
              2023年海峡两岸女大学生创新创业大赛 - 配套活动事项报名
            </div>
          </div>
          <div class="k-bot">
            <div class="k-item" @click="toFormKind(item)" v-for="(item, index) in matchFormKindList" :key="index">
              <div class="i-top-item-left">
                <span class="icon iconfont icon-join-record"></span>
              </div>
              <div class="i-top-item-right">
                <div class="subtitle">{{ item.headline || "" }}</div>
                <div class="title">{{ item.kindName || "" }}</div>
              </div>
            </div>
          </div>
        </section> -->
        <!-- 跳转入口 -->
        <section class="commonBlock w">
          <div class="common-enter">
            <div class="enter-list">
              <div
                class="item"
                v-for="(item, index) in enterList"
                :key="index"
                @click="handleEnter(item.url)"
              >
                <div class="item-link">
                  <div class="ico">
                    <img class="img" :src="item.icon" />
                  </div>
                  <div class="name" :class="{ txtW2: item.url == 'home' }">
                    {{ item.barName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="extend">
              <div
                class="item"
                v-for="(item, index) in fastList"
                :key="index"
                @click="handleEnter(item.url)"
              >
                <div class="cover">
                  <img class="img" :src="item.icon" />
                </div>
                <div class="content">
                  <div class="title">{{ item.title }}</div>
                  <div class="desc">{{ item.desc }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="common-match">
            <div class="match-cover">
              <!-- <img
                class="img"
                src="https://static.scgxfwz.com/ylNM6ypfBsac1KVj.jpg"
                alt="banner"
              /> -->
              <banner :list="bannerList"></banner>
            </div>
            <!-- 最新动态 -->
            <div class="dynamic">
              <div class="header">
                <div class="title">
                  <img
                    class="img"
                    :src="$globalHeader.imgUrl + '/static/icon/v2/dynamic.png'"
                    alt="news"
                  />
                </div>
                <div
                  class="more hover-effect"
                  @click="handleEnter('matchDynamic')"
                >
                  更多<span class="icon iconfont icon-more"></span>
                </div>
              </div>
              <div class="list">
                <div
                  class="item hover-effect animation-pulse"
                  v-for="(item, index) in dynamicTitle"
                  @click="dynamicDetailClick(item.id)"
                  :key="index"
                >
                  <div class="title">
                    <div class="spot"></div>
                    <div class="txt">{{ item.title }}</div>
                  </div>
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 个人中心 -->
          <user></user>
        </section>
        <!-- 资讯福利 -->
        <section class="recommendBlock w">
          <news-coupon
            :couponData="couponPlateData"
            :dynamicList="articleList"
            @moreDialog="handleMoreClick"
          ></news-coupon>
          <!-- 政策推荐 -->
          <policy :rePolicyData="rePolicyData"></policy>
          <!-- 人才服务站推荐 -->
          <station-list :list="stationList"></station-list>
        </section>
        <!-- 大赛推荐 -->
        <section class="matchBlock w">
          <div class="header">
            <div class="title">
              <!-- <div class="ico">
                <img
                  class="img"
                  :src="$globalHeader.imgUrl + '/static/icon/mod_match.png'"
                  alt="图标"
                />
              </div> -->
              <span class="txt">大赛推荐</span>
            </div>
            <div class="more hover-effect" @click="handleEnter('match')">
              更多<span class="icon iconfont icon-more"></span>
            </div>
            <!-- <div class="match-tabs">
              <div class="item-wrap">
                <div class="item" @click="handleMatchTab(0)">
                  <span :class="currentMatchIndex == 0 ? 'active' : ''"
                    >全部</span
                  >
                </div>
                <div class="item" @click="handleMatchTab(1)">
                  <span :class="currentMatchIndex == 1 ? 'active' : ''"
                    >进行中</span
                  >
                </div>
                <div class="item" @click="handleMatchTab(2)">
                  <span :class="currentMatchIndex == 2 ? 'active' : ''"
                    >已结束</span
                  >
                </div>
              </div>
              <div class="item-wrap" @click="handleMyMatch()">
                <div class="item">
                  <span>我的报名</span>
                </div>
              </div>
            </div> -->
          </div>
          <div class="match-content" v-loading="loading">
            <match-list :matchList="matchList"></match-list>
          </div>
          <div class="btn-more" @click="handleEnter('match')">
            <span>查看更多赛事活动</span>
            <span class="icon iconfont icon-more-txt"></span>
          </div>
          <!-- <div class="m-pager">
            <el-pagination
              background
              layout="prev, pager, next"
              prev-span="上一页"
              next-span="下一页"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pageSize"
              :total="pageCount"
              @current-change="changePageClick"
            >
            </el-pagination>
          </div> -->
        </section>
      </main>
      <!-- 底部 -->
      <Footer></Footer>
    </div>
    <el-dialog title="" :visible.sync="centerDialogVisible" width="30%" center>
      <div style="text-align: center">
        <img
          :src="$globalHeader.imgUrl + '/static/miniprogram/personCenter.png'"
        />
      </div>
    </el-dialog>
    <router-view></router-view>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import homeHeader from "@/components/home/homeHeader.vue";
import Banner from "@/components/common/banner.vue";
import Footer from "@/components/common/footer.vue";
import MatchList from "@/views/match/components/matchList.vue";
import StationList from "@/components/home/stationList.vue";
import User from "@/components/home/user.vue";
import Policy from "@/components/home/policy.vue";
import newsCoupon from "@/components/home/newsCoupon.vue";
// import MatchPlate from "@/components/plate/matchPlate.vue";
import {
  getHomeBannerList,
  getPolicyPage,
  getStationList,
  getCouponPage,
  getDynamicData,
} from "@/api/home/home.js";
import { getMatchList, getMatchFormKind } from "@/api/match/match.js";
import { getDynamicPage, getArticlePage } from "@/api/match/matchDynamic.js";
import { local } from "@/utils/storage.js";

export default {
  components: {
    pageTop,
    homeHeader,
    Banner,
    Footer,
    MatchList,
    StationList,
    User,
    Policy,
    newsCoupon,
  },
  data() {
    return {
      isLogin: false,
      displayPage: true,
      loading: false,
      headerList: [], //header的tab列表
      bannerImgHeight: "", //轮播图图片高度
      bannerList: [], //轮播图列表

      currentMatchIndex: 0, //比赛当前tab
      matchList: [], //比赛列表数据
      matchDynamicList: [], //赛事动态列表
      matchFormKindList: [], //赛事配套列表

      pageSize: 12, //分页数量
      pageCount: 0, //赛事列表总数

      centerDialogVisible: false,

      enterList: [
        {
          barName: "首页",
          icon: this.$globalHeader.imgUrl + "/static/pc/home.png",
          url: "home",
        },
        {
          barName: "赛事活动",
          icon: this.$globalHeader.imgUrl + "/static/icon/icon-03.png",
          url: "match",
        },
        {
          barName: "人才政策",
          icon: this.$globalHeader.imgUrl + "/static/icon/icon-02.png",
          url: "policy",
        },
        {
          barName: "高校双创",
          icon: this.$globalHeader.imgUrl + "/static/icon/icon-04.png",
          url: "course",
        },
      ],
      fastList: [
        {
          title: "人才服务站",
          desc: "驻各高校服务站、联络站、联络员",
          icon: this.$globalHeader.imgUrl + "/static/icon/10.png",
          url: "station",
        },
        {
          title: "导师评委",
          desc: "找导师指导、找赛事评委",
          icon: this.$globalHeader.imgUrl + "/static/pc/teacher.png",
          url: "moreTeacher",
        },
      ],
      dynamicTitle: [],

      couponPlateData: [],
      stationList: [],
      rePolicyData: [],
      articleList: [],
    };
  },
  created() {
    this.isLogin = local.Get("isLogin");
    this.fetchData();
  },
  methods: {
    //统一获取数据
    fetchData() {
      this.getMatchData();
      // this.getMatchFormKind();
      this.getHomeBannerList();
      this.getMatchDynamicList();
      this.getPplicyData();
      this.getStationList();
      this.getCoupon();
      this.getDynamicData();
    },
    //获取首页banner
    getHomeBannerList() {
      getHomeBannerList().then((res) => {
        if (!res.data) return;
        this.bannerList = res.data.bannerList;
      });
    },
    //获取赛事动态
    getMatchDynamicList() {
      getDynamicPage({ page: 1, limit: 7 }).then((res) => {
        if (!res || !res.data) return;
        this.matchDynamicList = res.data.list;
        this.matchDynamicList.forEach((e) => {
          if (e.dynamicContent.length > 50) {
            e.dynamicContent = e.dynamicContent.substring(0, 50) + "...";
          }
        });
      });
      getArticlePage({ page: 1, limit: 8 }).then((res) => {
        if (!res || !res.data) return;
        this.articleList = res.data.list;
      });
    },
    //获取政策推荐
    getPplicyData() {
      const params = {
        page: 1,
        limit: 3,
        policyArea: "350000",
      };
      getPolicyPage(params).then((res) => {
        if (!res.data.policyList) return;
        this.rePolicyData = res.data.policyList;
      });
    },
    getStationList() {
      const params = {
        page: 1,
        limit: 4,
      };
      getStationList(params).then((res) => {
        this.stationList = res.data.stationList;
      });
    },
    //获取优惠券数据
    getCoupon() {
      const couponParam = {
        page: 1,
        limit: 4,
      };
      getCouponPage(couponParam).then((res) => {
        this.couponPlateData = res.data?.couponResultList;
      });
    },
    getDynamicData() {
      const params = {
        isExclude: false,
        limit: 2,
      };
      getDynamicData(params).then((res) => {
        if (res.data) {
          this.dynamicTitle = res.data;
        }
      });
    },
    //跳转动态详情
    dynamicDetailClick(id) {
      this.$router.push({
        path: "/matchDynamic/dynamicDetail/" + id,
      });
    },
    //获取赛事配套报名
    getMatchFormKind() {
      getMatchFormKind({ id: "1628991138096312321" }).then((res) => {
        this.matchFormKindList = res.data;
      });
    },
    //根据分类获取赛事数据
    getMatchData(page = 1) {
      getMatchList({
        filtrateValue: this.currentMatchIndex,
        page: page,
        limit: 12,
      }).then((res) => {
        if (!res.data) return;
        this.matchList = res.data.list;
        this.pageCount = res.data.count;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },
    //配套报名
    toFormKind(item) {
      if (item.applyId) {
        //报名结果页面
        this.$router.push({
          path: "/match/matchDetail/" + this.matchId + "/matchSubResult",
          query: {
            matchId: item.competitionId,
            kindId: item.id,
            applyId: item.applyId,
          },
        });
      } else {
        //报名页面
        this.$router.push({
          path:
            "/match/matchDetail/" +
            this.matchId +
            "/matchKindSub/" +
            item.competitionId +
            "/" +
            item.id,
        });
      }
    },
    //快速跳转
    handleEnter(url) {
      if (url == "home") {
        this.$router.push("/");
      } else {
        if (url && typeof url == "string") {
          //  this.$router.replace("/" + url);
          window.location.href = "/" + url;
        }
      }
    },
    //点击更多
    handleMoreClick() {
      this.centerDialogVisible = true;
    },
    //赛事切换tab
    handleMatchTab(type) {
      console.log(type);
      this.loading = true;
      this.currentMatchIndex = type;
      this.getMatchData();
    },
    //我的报名
    handleMyMatch() {
      this.$router.push("/personCenter");
    },
    //切换页面
    changePageClick(page) {
      this.loading = true;
      this.getMatchData(page);
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.path == "/" || this.$route.path == "/match") {
          this.displayPage = true;
        } else {
          this.displayPage = false;
        }
      },
      // 一进页面就执行
      immediate: true,
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
$bgImageUrl: "https://gx.scjfu.com";
@import "../../assets/style/home/home.scss";
</style>
