<template>
    <div class="about-us">
        <div v-html="aboutUsData"></div>
    </div>
</template>

<script>
import { getAboutUs } from '@/api/user/user.js'
export default {
    data() {
        return {
            aboutUsData: ''
        }
    },
    created() {
        console.log('create');
        this.fetchData()
    },
    methods: {
        fetchData() {
            getAboutUs().then(res => {
                this.aboutUsData = res.data.aboutUs
            })
        }
    }
}
</script>

<style>
.about-us {
    padding: 32rpx;
}
</style>