import http from "@/utils/http.js"

//获取定位列表
export function getLocation() {
    return http({
        url: "/api/web/home/getIp",
        method: "GET",
    })
}

//获取七牛云token
export function getqiniuToken() {
    return http({
        url: "/api/file/qiniu/getToken",
        method: "GET",
    })
}

// 公众号二维码
export function officialAccounts(data = '') {
    return http({
        url: "/api/web/common/officialAccounts/" + data,
        method: 'GET',
    })
}

// 分享二维码
export function shareQRCode(data) {
    return http({
        url: "/api/web/common/share",
        method: 'POST',
        data: data
    })
}

// 获取全部定位
export function getAllLocation() {
	return http({
		url: "/api/web/home/getAllLocation",
		method: 'GET',
	})
}

// 保存定位
export function saveCurrentLocation(data) {
	return http({
		url: "/api/web/home/saveCurrentLocation",
		method: 'POST',
        data:data
	})
}

// 获取搜索定位信息
export function getSearchLocation(data) {
	return http({
		url: "/api/web/home/getSearchLocation/"+data,
		method: 'GET',
	})
}

// 获取用户定位信息
export function getCurrentLocation() {
	return http({
		url: "/api/web/home/getCurrentLocation",
		method: 'GET',
	})
}
//分享小程序二维码
export function getQRCode(data) {
	return http({
		url: "/api/web/common/shareStream",
		method: "GET",
		data: data,
	})
}

