<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- to为点击跳转 title为路由中的meta属性定义的标题 -->
      <el-breadcrumb-item
        v-for="(item, index) in breadList"
        :key="index"
        :to="item.path"
      >
        {{ item.meta.breadcrumb }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
 
<script>
import { competitionDetail } from "@/api/match/match.js";
import { getPolicyCrumbsl } from "@/api/policy/policy.js";
// import { local } from "@/utils/storage.js";
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadList: [],
      matchDetail: {},
    };
  },
  methods: {
    async getBreadcrumb() {
      // if (matched[0].path == "/matchDynamic") {
      //   let matchId = this.$route.query?.matchId;
      //   if (matchId) {
      //     await this.getCompetitionDetail(matchId);
      //     let matchDetailRouter = {
      //       path: "/match/matchDetail/" + matchId,
      //       meta: { breadcrumb: this.matchDetail.title },
      //     };
      //     matched = [matchDetailRouter].concat(matched);
      //     console.log(matched, ';;');
      //   }
      // }
      //获取路由
      let matched = this.$route.matched;
      console.log("----------",this.$route.matched);
      // console.log(JSON.parse(local.Get("currentMatched")));
      /**
       * 切换一次路由调用一次面包屑
       * 问题：  路由后参数会变成undefined
       * 解决：  每次进入新的路由将路由中的path赋值给matched中最后一个值的path
       */
      if (matched && matched.length) {
        //如果进入赛事详情页面， 将路由的面包屑改成赛事名称
        if (this.$route.name == "MatchDetail") {
          await this.getCompetitionDetail(this.$route.params.id);
          matched[matched.length - 1].meta.breadcrumb = this.matchDetail.title;
        }else if (this.$route.name == "MatchSub") {
          let tempId = this.$route.params.matchId;
          await this.getCompetitionDetail(tempId);
          matched[matched.length - 2].path = "/match/matchDetail/" + tempId;

          matched[matched.length - 2].meta.breadcrumb = this.matchDetail.title;
        }else if (this.$route.name == "MatchKindSub") {
          let tempId = this.$route.params.matchId;
          await this.getCompetitionDetail(tempId);
          matched[matched.length - 2].path = "/match/matchDetail/" + tempId;

          matched[matched.length - 2].meta.breadcrumb = this.matchDetail.title;
        } else if (this.$route.name == "DynamicDetail") {
          if (this.$route.query && this.$route.query.matchId) {
            let tempId = this.$route.query.matchId;
            await this.getCompetitionDetail(tempId);
            matched[matched.length - 2].path = "/match/matchDetail/" + tempId;
            matched[matched.length - 2].meta.breadcrumb =
              this.matchDetail.title;
          }
        } else if (this.$route.name == "MatchDynamic") {
          matched[matched.length - 1].path = "/matchDynamic";
          matched[matched.length - 1].meta.breadcrumb = "赛事动态";
        } else if (this.$route.name == "PolicyDetail") {
          await this.getPolicyCrumbsl(this.$route.params.id);
          matched[matched.length - 1].path = "/policy/policyDetail/" + this.$route.params.id;
          matched[matched.length - 1].meta.breadcrumb = this.policyDetail.title;
        }else if (this.$route.name == "PolicySub") {
          let tempId = this.$route.params.id;
          await this.getPolicyCrumbsl(tempId);
          matched[matched.length - 2].path = "/policy/policyDetail/" + tempId;
          matched[matched.length - 1].path = "/policy/policyDetail/" + tempId + '/policySub';
        }
      }

      //第一个路由不是首页的话，添加个首页路由
      if (matched[0].path != "/") {
        let home = [{ path: "/", meta: { breadcrumb: "首页" } }];
        this.breadList = home.concat(matched);
        console.log("this.breadList----",this.breadList);
      } else {
        this.breadList = matched;
      }
    },

    //获取赛事详情信息
    getCompetitionDetail(matchId) {
      return new Promise((resolve, reject) => {
        competitionDetail({ id: matchId })
          .then((res) => {
            this.matchDetail = res.data;
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.data);
          });
      });
    },
    getPolicyCrumbsl(policyId) {
      return new Promise((resolve, reject) => {
        getPolicyCrumbsl({ id: policyId })
          .then((res) => {
            this.policyDetail = res.data;
            resolve(res.data);
          })
          .catch((err) => {
            reject(err.data);
          });
      });
    }
  },
  created() {
    //生命周期中调用获取数据的方法
    this.getBreadcrumb();
  },
  watch: {
    $route() {
      //监听$route
      this.getBreadcrumb();
    },
  },
};
</script>

<style></style>