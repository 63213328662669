<template>
  <div class="phone-home-page">
    <img :src="$globalHeader.imgUrl + '/static/miniprogram/home.png'" />
    <p>请使用微信“扫一扫”，进入小程序操作</p>
    <el-button type="primary" size="mini" class="open-mini" @click="handleClick"
      >打开小程序</el-button
    >
  </div>
</template>

<script>
import { getUrlLink } from "@/api/phone/index.js";
export default {
  data() {
    return {
      option: "",
    };
  },
  mounted() {
    this.option = this.$route.query.option;
    console.log(this.$route.query, "this.$route.query.option");
    this.setTDK();

    
  },
  methods: {
    // 设置TDK
    setTDK() {
      document.title = "高校双创";
    },
    handleClick() {
         let cq="";
     if(this.option.indexOf("inviteMember")!=-1&&this.option.indexOf("type=match")!=-1){
      ///inviteMember?inviteId=1782339271483592705&type=match
       let params=this.option.substring(this.option.indexOf("?")+1).split("&")[0].split("=")[1];
         cq="invite_match;"+params
    }
   
      getUrlLink({
        path: "pages/home",
        query: this.$route.query.option,
      }).then((res) => {
        console.log(res);
        window.location.href = res.data+"?cq="+cq
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-home-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 30px 30px 36px;
  width: 100%;

  img {
    width: 200px;
    height: 200px;
  }

  P {
    padding-top: 20px;
  }

  .open-mini {
    background-color: #4aca6d;
    margin-top: 60px;
    padding: 12px;
    width: 300px;
    border: #4aca6d;
  }
}
</style>
