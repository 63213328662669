<template>
  <div class="policy-block">
    <div class="header">
      <div class="title">
        <span class="txt">人才政策推荐</span>
      </div>
      <div class="more hover-effect" @click="toMore">
        查看更多<span class="icon iconfont icon-more"></span>
      </div>
    </div>
    <div class="guid">
      <div class="total">
        <div class="load-animate">
          <img
            class="floor floor1"
            :src="$globalHeader.imgUrl + '/static/icon/v2/p-loading-01.png'"
          />
          <img
            class="floor floor2"
            :src="$globalHeader.imgUrl + '/static/icon/v2/p-loading-02.png'"
          />
          <img
            class="floor floor3"
            :src="$globalHeader.imgUrl + '/static/icon/v2/p-loading-03.png'"
          />
          <div class="floor-round"></div>
        </div>
        <div class="count">
          <div class="txt">
            <span class="num">获取</span>
            <!-- <span class="unit">万</span> -->
          </div>
        </div>
      </div>
      <div class="content">
        <div class="title">为您匹配人才政策补贴</div>
        <div class="desc">
          大量人才创业、就业、培训、落户等，相关的政策扶持、补贴...
        </div>
        <div class="extend" @click="toMore">
          查看更多推荐<span class="icon iconfont icon-more"></span>
        </div>
      </div>
    </div>

    <div class="policy-list">
      <div
        class="policy-item hover-item"
        v-for="(item, index) in rePolicyData"
        :key="index"
        @click="toDetail(item.interpretateId)"
      >
        <div class="p-header">
          <div class="title">{{ item.policyName }}</div>
        </div>
        <div class="label-list">
            <span class="is-light">{{ item.policyType }}</span>
            <span
              v-for="(subitem, index) in item.subTitleResults"
              :key="index"
              >{{ subitem.subTitle }}</span
            >
            <span class="is-light" v-if="item.handleType == 1">线上办理</span>
            <span class="is-light" v-if="item.handleType == 2">线下办理</span>
            <span class="is-light" v-if="item.handleType == 3"
              >线上办理、线下办理</span
            >
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rePolicyData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    //更多政策
    toMore() {
      this.$router.push("/policy");
    },
    //详情
    toDetail(id) {
      this.$router.push({ path: "/policy/policyDetail/" + id });
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-block {
  flex: 1;
  padding: 0 20px;
  margin: 20px 0;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      display: flex;
      align-items: center;
      font-size: 19px;
      font-weight: 600;
      color: #000;
    }
    .more {
      line-height: 1;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #888;
      .icon {
        font-size: 16px;
      }
    }
  }
  .guid {
    margin: 15px 0;
    padding: 15px 10px;
    background: linear-gradient(0deg, #86acff 0%, #a5deff 100%);
    display: flex;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    .total {
      position: relative;
      width: 144px;
      height: 144px;
      color: #fff;

      .count {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .txt {
          display: flex;
          align-items: baseline;
          justify-content: center;
          font-size: 16px;
          line-height: 1;

          .num {
            font-size: 34px;
          }

          .unit {
            margin-left: 4px;
          }
        }
      }
    }

    .content {
      flex: 1;
      margin-left: 10px;
      .title {
        font-weight: bold;
        font-size: 17px;
      }

      .desc {
        line-height: 1.5;
        font-size: 14px;
        color: #4a4a4a;
        margin-top: 0.5em;
      }

      .extend {
        margin-top: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ff9103;
        height: 31px;
        width: 135px;
        border-radius: 16px;
        background: linear-gradient(
          90deg,
          #FFA800 0%,
          #fccc24 8%,
          #fccc24 92%,
          #FFA800 100%
        );

        .icon {
          margin-left: 0.15em;
        }
      }
      .extend:hover {
        opacity: 0.9;
      }
    }
  }
  .load-animate {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    .floor-round {
      position: absolute;
      z-index: 5;
      width: 60%;
      height: 60%;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      margin-top: -30%;
      margin-left: -30%;
      background-color: rgba(255, 255, 255, 0.25);
    }
    .floor {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    .floor1 {
      z-index: 1;
      // animation: rotateR 37s linear infinite;
    }
    .floor2 {
      z-index: 2;
      // animation: rotateL 19s linear infinite;
    }
    .floor3 {
      z-index: 3;
      // animation-name: rotateR, opa;
      // animation-duration: 47s, 7s;
      // animation-timing-function: linear, linear;
      // animation-iteration-count: infinite, infinite;
    }
  }
  // 顺时针旋转
  @keyframes rotateR {
    from {
      transform: rotate(0); /* 从0度开始旋转 */
    }
    to {
      transform: rotate(360deg); /* 一圈360度的旋转结束 */
    }
  }
  // 逆时针旋转
  @keyframes rotateL {
    from {
      transform: rotate(360deg); /* 从360度开始旋转 */
    }
    to {
      transform: rotate(0); /* 一圈0度的旋转结束 */
    }
  }
  // 透明度变化
  @keyframes opa {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.9;
    }
  }

  .policy-list {
    flex: 1;
    line-height: 1.5;
    .policy-item {
      padding: 10px 20px;
      background-color: #fff;
      border-bottom: 1px solid #f7f7f7;
    //   box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
      .p-header {
        .title {
          margin-bottom: 12px;
          font-size: 16px;
          font-weight: bold;
          color: #000;
          line-height: 1.35;
        }
      }
      .label-list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        min-height: 30px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 24px;
          margin-right: 10px;
          margin-bottom: 10px;
          padding: 0 10px;
          border-radius: 22px;
          font-size: 12px;
          color: #666;

          background-color: #eaeaea;
        }
        .is-light {
          color: #f7932e;
          background-color: #fee3b0;
        }
      }
    }
    .policy-item:nth-child(3) {
        border:none
    }
  }
}
</style>