import http from "@/utils/http.js"

//获取驻点/联络站列表
export function getLiaisonStationList(data) {
    return http({
        url: "/api/web/station/getLiaisonStationList",
        method: "GET",
        data: data,
    })
}
//获取驻点/联络站条数
export function getStaionCount(data) {
    return http({
        url: "/api/web/station/getStaionCount",
        method: "GET",
        data: data,
    })
}

//获取驻点/联络站列表
export function getStationList(data) {
    return http({
        url: "/api/web/station/getStationList",
        method: "GET",
        data: data,
    })
}
//获取站点的高校列表
export function getSchoolList(data) {
    return http({
        url: "/api/web/station/getSchoolList",
        method: "GET",
		data:data,
    })
}
//获取站点的详情
export function getStaionDetail(data) {
    return http({
        url: "/api/web/station/getStaionDetail",
        method: "GET",
		data:data,
    })
}
//获取高效服务站列表
export function getServiceStationList(data) {
    return http({
        url: "/api/web/station/getServiceStationList",
        method: "GET",
        data: data,
    })
}
// 根据联络站的地区编码获取政策信息
export function getLiaisonAreaPolicy(data) {
	return http({
		url: "/api/miniprogram/station/getPolicyPage",
		data: data,
		method: 'GET',
	})
}
//获取高效服务站列表
export function getCompetitionPage(data) {
    return http({
        url: "/api/web/station/getCompetitionPage",
        method: "GET",
        data: data,
    })
}
//获取站长申请表单
export function getStationForm(data) {
    return http({
        url: "/api/web/stationCenter/getStationForm",
        method: "GET",
        data: data,
    })
}