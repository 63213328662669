<template>
  <div class="page">
    <div class="page-warp" v-loading.fullscreen.lock="isLoading">
      <!-- 顶部1 -->
      <page-top></page-top>
      <!-- 头部 -->
      <Header @chooseTab="getTab"></Header>
      <!-- 主体 -->
      <main class="main">
        <section class="breadcrumb-plate w">
          <div class="label">我的位置：</div>
          <breadcrumb />
        </section>
        <section class="commonBlock w">
          <div class="c-left">
            <div class="apply-block">
              <div class="cover">
                <img class="img" :src="policyData.cover" alt="" />
              </div>
              <div class="policy-list">
                <div class="title">
                  <span class="txt">{{ policyData.policyName }}</span>
                </div>
                <div class="count">
                  <div>
                    <span class="icon iconfont icon-browse"></span
                    >{{ policyData.viewQuantity || "0" }}
                  </div>
                  <div>想办：{{ policyData.handleQuantity || "0" }}</div>
                </div>
                <div class="extend">
                  <div
                    class="fee"
                    :class="{ 'fee-size ': !policyData.policyRewardResult }"
                  >
                    {{ policyData.policyRewardResult?.rewardAmt || "未设置" }}
                  </div>
                </div>
                <div class="tag">{{ policyData.policyType || "人才政策" }}</div>
              </div>
            </div>
            <PolicySubForm :dataForm="dataForm"/>

          </div>
          <div class="c-right">
            <div class="item">
              <PolicyRecom />
            </div>
            <div class="item">
              <PolicyHotHandle />
            </div>
            <div class="item">
              <PolicyNew />
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from "@/components/common/header.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import pageTop from "@/components/common/pageTop.vue";
import Footer from "@/components/common/footer.vue";
import PolicyRecom from "./components/policy-recom.vue";
import PolicyHotHandle from "./components/policy-hot-handle.vue";
import PolicyNew from "./components/policy-new.vue";
import PolicySubForm from "./components/policy-sub-form.vue";
import { getPolicyDetail } from "@/api/policy/policy.js";

export default {
  components: {
    Header,
    pageTop,
    Footer,
    Breadcrumb,
    PolicyRecom,
    PolicyHotHandle,
    PolicyNew,
    PolicySubForm,
  },
  data() {
    return {
      policyData: {},
      policyId: "1",
      isLoading: true,
      dataForm:{
      }
    };
  },
  created() {
    this.policyId = this.$route.params.id;
    this.dataForm.interpretateId = this.policyId;
    this.fetchData();
  },
  methods: {
    fetchData() {
      //详情
      getPolicyDetail({
        id: this.policyId,
      }).then((res) => {
        if (!res) return;
        this.policyData = res.data;
        this.dataForm.policyName = this.policyData.policyName;
        this.isLoading = false;
      });
    },
    //获取header组件的点击事件
    getTab(data) {
      alert("12");
      if (data && typeof data == "string") {
        window.location.href = "/" + data;
        //  this.$router.push("/" + data);
      }
    },
    saveSub() {

    },
    toResult(conductId) {
      this.$router.replace({
        path: "/policy/policyDetail/policySubResult?conductId=" + conductId,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  background-color: #efefef;
  .main {
    flex: 1;
    padding-bottom: 80px;
    background-color: #efefef;
    .breadcrumb-plate {
      display: flex;
      align-items: center;
      padding: 16px 0;

      .label {
        line-height: 1;
        font-size: 14px;
      }
    }
    .commonBlock {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: #fff;
      .c-left {
        flex: 1;
        .apply-block {
          position: relative;
          display: flex;
          flex-direction: row;
          min-width: 800px;
          padding: 20px;
          margin: 20px;
          overflow: hidden;
          border-radius: 8px;
          box-shadow: -2px 2px 20px 0 #dbefffad;
          .cover {
            margin-right: 20px;
            height: 104px;
            .img {
              width: 134px;
              height: 104px;
              border-radius: 6px;
            }
          }
          .policy-list {
            display: flex;
            flex: 1;
            flex-direction: column;
          }
          .title {
            width: 100%;
            .txt {
              margin-top: 4px;
              height: 26px;
              font-size: 18px;
              color: #000000;
              font-weight: bold;
            }
          }
          .label-list {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            min-height: 40px;
            margin-top: 12px;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 24px;
              margin-right: 10px;
              // margin-bottom: 10px;
              padding: 0 10px;
              border-radius: 22px;
              font-size: 12px;
              color: #666;

              background-color: #eaeaea;
            }
            .is-light {
              color: #f7932e;
              background-color: #fee3b0;
            }
          }
          .count {
            flex: 1;
            display: flex;
            // justify-content: flex-end;
            align-items: center;
            font-size: 12px;
            color: #888;
            div:nth-child(1) {
              margin-right: 15px;
            }
            .icon {
              font-size: 14px;
              margin-right: 0.3em;
            }
          }
          .extend {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .fee {
              padding: 4px 15px;
              margin-top: 4px;
              font-size: 20px;
              background: linear-gradient(
                90deg,
                rgba(255, 246, 238, 1) 0%,
                rgba(255, 246, 238, 0) 100%
              );
              color: #fe3e3e;
              width: 350px;
            }
            .fee-size {
              padding: 8px 20px;
              font-size: 14px;
            }
            .btn-warp {
              display: flex;
              align-items: center;
              margin-left: 20px;
              .btn {
                padding: 8px 20px;
                color: #fff;
                font-size: 14px;
                border-radius: 6px;
                margin-left: 20px;
                background: linear-gradient(to right, #81cfff, #0099cc);
              }
              .btn-primary {
                background: linear-gradient(to right, #81cfff, #0099cc);
              }
              .btn-apply {
                background: linear-gradient(to right, #ffcd34, #ff9200);
              }
            }
          }

          .tag {
            background-color: #ff6969;
            color: #fff;
            width: 18%;
            height: 36px;
            line-height: 36px;
            text-align: center;
            right: -40px;
            top: 10px;
            /* margin-top: 40px; */
            position: absolute;
            transform: rotate(45deg);
          }
        }
        
      }
      .c-right {
        margin: 20px;
        margin-left: 0px;
        flex-shrink: 0;
        width: 320px;
        background-color: #fff;
        .item {
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>