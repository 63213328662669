<template>
  <div>
    <iframe
      src="https://gx.scjfu.com/api/web/government/zwfw"
      width="100%"
      height="600"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>