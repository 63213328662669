<template>
  <div class="match-sub-page page">
    <!-- 顶部 -->
    <page-top></page-top>
    <!-- 头部 -->
    <Header @chooseTab="getTab"></Header>
    <!-- 主体 -->
    <main class="main">
      <section class="breadcrumb-plate w">
        <div class="label">我的位置：</div>
        <breadcrumb />
      </section>

      <section class="content w">
        <div class="c-left">
          <div class="info-plate plate">
            <div class="title">
              <span>{{ matchName }}</span>
            </div>
            <div class="subtitle">
              <div class="subtitle-item">
                <div class="s_i_left">
                  <span>学校名称</span>
                </div>
                <div class="s_i_right">
                  <span>{{ authInfo.school || "无" }}</span>
                </div>
              </div>
              <div class="subtitle-item">
                <div class="s_i_left">
                  <span>学生姓名</span>
                </div>
                <div class="s_i_right">
                  <span>{{ authInfo.name || "无" }}</span>
                </div>
              </div>
              <div class="subtitle-item">
                <div class="s_i_left">
                  <span>所属院系</span>
                </div>
                <div class="s_i_right">
                  <span>{{ authInfo.academy || "无" }}</span>
                </div>
              </div>
              <div class="subtitle-item">
                <div class="s_i_left">
                  <span>所学专业</span>
                </div>
                <div class="s_i_right">
                  <span>{{ authInfo.major || "无" }}</span>
                </div>
              </div>
              <div class="subtitle-item">
                <div class="s_i_left">
                  <span>学号</span>
                </div>
                <div class="s_i_right">
                  <span>{{ authInfo.studentNo || "无" }}</span>
                </div>
              </div>
              <div class="subtitle-item" v-if="projectFileList.length">
                <div class="s_i_left">
                  <span>项目模板</span>
                </div>
                <div class="s_i_right">
                  <div v-for="(item, index) in projectFileList" :key="index">
                    <a class="file-list" :href="item.path">{{
                      item.fileName
                    }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="info-title">请如实填写参赛信息</div>
          <div class="c-left-content">
            <div class="item-wrap_old">
              <div class="item-title">
                <div class="item-name" :class="{ ban: !isUpdate }">
                  选择赛区
                </div>
                <div class="must">*</div>
              </div>
              <div class="item-select">
                <el-select
                  v-model="comArea"
                  placeholder="请选择赛区"
                  style="width: 240px"
                  :disabled="!isUpdate"
                >
                  <el-option
                    v-for="(subitem, subindex) in comAreaList"
                    :key="subindex"
                    :label="subitem"
                    :value="subitem"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="item-wrap_old">
              <div class="item-title">
                <div class="item-name" :class="{ ban: !isUpdate }">
                  选择赛道
                </div>
                <div class="must">*</div>
              </div>
              <div class="item-select">
                <el-select
                  v-model="comTrack"
                  placeholder="请选择赛道"
                  style="width: 240px"
                  :disabled="!isUpdate"
                  @change="changeComTrack"
                >
                  <el-option
                    v-for="(subitem, subindex) in comTrackList"
                    :key="subindex"
                    :label="subitem"
                    :value="subitem"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>

          <div class="info-title">填写申请信息</div>
          <div class="c-left-content">
            <div class="project-choose">
              <div
                class="form-wrap"
                v-for="(item, index) in formData"
                :key="index"
              >
                <!-- input -->
                <div class="item-wrap" v-if="item.type == 'input'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="item-content-left">
                      <div class="input-wrap">
                        <input
                          class="input"
                          :class="{ ban: !item.rule.isUpdate }"
                          :placeholder="item.placeholder"
                          v-model="item.remark"
                          :disabled="!item.rule.isUpdate"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- textarea -->
                <div class="item-wrap" v-if="item.type == 'textarea'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content textarea-content">
                    <div class="item-content-left">
                      <textarea
                        class="textarea"
                        :class="{ ban: !item.rule.isUpdate }"
                        v-model="item.remark"
                        maxlength="-1"
                        :disabled="!item.rule.isUpdate"
                        resize="none"
                        :placeholder="item.placeholder"
                      ></textarea>
                    </div>
                  </div>
                </div>

                <!-- select -->
                <div class="item-wrap" v-if="item.type == 'select'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <el-select
                      v-model="item.remark"
                      :placeholder="item.placeholder"
                      style="width: 240px"
                      :disabled="!item.rule.isUpdate"
                      @change="changeSelect(item)"
                      @focus="clickSelect(item)"
                    >
                      <el-option
                        v-for="subitem in getSelectValue(item.defaultValue)"
                        :key="subitem.value"
                        :label="subitem.label"
                        :value="subitem.value"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>

                <!-- datetimePicker -->
                <div class="item-wrap" v-if="item.type == 'datePicker'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <el-date-picker
                      v-model="item.remark"
                      type="date"
                      placeholder="选择日期"
                      style="width: 240px"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </div>
                </div>

                <!-- areaSelect籍贯 -->
                <div class="item-wrap" v-if="item.type == 'areaSelect'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="area-content">
                      <div class="area-item">
                        <el-select
                          v-model="provinceCode"
                          @change="provinceChange(index)"
                          placeholder="请选择省"
                        >
                          <el-option
                            v-for="item in provinceOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="area-item">
                        <el-select
                          v-model="cityCode"
                          @change="cityChange(index)"
                          placeholder="请选择市"
                        >
                          <el-option
                            v-for="item in cityOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                      <div class="area-item">
                        <el-select
                          v-model="districtCode"
                          @change="districtChange(index)"
                          placeholder="请选择区"
                        >
                          <el-option
                            v-for="item in districtOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </div>
                    </div>

                    <div class="item-content-left" style="margin-top: 20px">
                      <div class="input-wrap">
                        <input
                          class="input"
                          type="span"
                          v-model="areaInput"
                          placeholder="请输入详细地址"
                          style="padding: 8px 0"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- radio -->
                <div class="item-wrap" v-if="item.type == 'radio'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div
                    class="tips"
                    v-if="item.placeholder"
                    style="
                      font-size: 24rpx;
                      color: #999;
                      margin-bottom: 10px;
                      line-height: 1.35;
                    "
                  >
                    {{ item.placeholder }}
                  </div>
                  <div class="item-content">
                    <div class="radio-content">
                      <el-radio
                        v-model="item.remark"
                        :label="subitem.label"
                        @input="changeRadio(item)"
                        v-for="(subitem, subindex) in getRadioList(
                          item.defaultValue
                        )"
                        :key="subindex"
                        style="margin-bottom: 4px"
                        >{{ subitem.value }}</el-radio
                      >
                      <div class="item-content-left" v-if="item.switchYn">
                        <div class="input-wrap">
                          <input
                            v-model="item.value"
                            v-if="item.switchYn"
                            placeholder="请输入"
                            class="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- checkbox -->
                <div class="item-wrap" v-if="item.type == 'checkbox'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="checkbox-content">
                      <el-checkbox-group
                        v-model="item.remark"
                        @change="changeCheckbox(item)"
                      >
                        <el-checkbox
                          v-for="(subitem, subindex) in getSelectValue(
                            item.defaultValue
                          )"
                          :label="subitem.label"
                          :key="subindex"
                          >{{ subitem.value }}</el-checkbox
                        >
                      </el-checkbox-group>
                      <div class="item-content-left" v-if="item.switchYn">
                        <div class="input-wrap">
                          <input
                            v-model="item.value"
                            v-if="item.switchYn"
                            placeholder="请输入"
                            class="input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- dict学校 -->
                <div class="item-wrap" v-if="item.type == 'dict'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <el-autocomplete
                      class="autocomplete"
                      @select="handleSelect"
                      @change="changeSchool"
                      :fetch-suggestions="selectScoolList"
                      placeholder="请输入高校名称"
                      :trigger-on-focus="false"
                      v-model="item.remark"
                    />
                  </div>
                </div>

                <!-- invite -->
                <div class="item-wrap" v-if="item.type == 'invite'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div
                    class="subtitle"
                    :class="{ ban: !item.rule.isUpdate }"
                    v-if="item.rule.minNum"
                  >
                    至少添加{{ item.rule.minNum }}名团队成员
                    <span v-if="item.rule.maxNum && item.rule.maxNum != '0'"
                      >，至多{{ item.rule.maxNum }}名
                    </span>
                    <span v-if="item.rule.placeholder"
                      >， {{ item.rule.placeholder }}</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="member-wrap">
                      <div
                        class="input"
                        :class="{ ban: !item.rule.isUpdate }"
                        @click="toAddMember(item)"
                      >
                        <div
                          class="member-list"
                          v-if="getRemark(item.remark, 'array').length"
                        >
                          <div
                            :class="{ ban: !item.rule.isUpdate }"
                            v-for="(subitem, subindex) in getRemark(
                              item.remark,
                              'array'
                            )"
                            :key="subindex"
                          >
                            {{ subitem.name }}；
                          </div>
                        </div>
                        <div class="memer-list" v-else>
                          <span
                            :class="{ ban: !item.rule.isUpdate }"
                            class="span"
                            >去添加</span
                          >
                        </div>
                      </div>
                      <div class="more">
                        <div
                          class="refresh"
                          style="
                            font-size: 14px;
                            padding: 0 3px;
                            margin-right: 10px;
                          "
                          @click.stop="refreshMember"
                          v-if="!isRefreshMember"
                        >
                          <span
                            class="span"
                            :class="{ ban: !item.rule.isUpdate }"
                            style="color: #0099cc; cursor: pointer"
                            >刷新</span
                          >
                        </div>
                        <div
                          class="refresh"
                          style="
                            font-size: 14px;
                            padding: 0 3px;
                            margin-right: 10px;
                          "
                          v-else
                        >
                          <span
                            class="span"
                            :class="{ ban: !item.rule.isUpdate }"
                            >...</span
                          >
                        </div>
                        <div
                          :class="{ ban: !item.rule.isUpdate }"
                          style="font-size: 16px"
                        >
                          {{ item.remark ? item.remark.length : 0 }}
                        </div>
                        <div class="">
                          <span
                            class="icon iconfont icon-more"
                            :class="{ ban: !item.rule.isUpdate }"
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- json添加导师 -->
                <div
                  class="item-wrap"
                  v-if="item.type == 'json' && item.field == 'teacher_Array'"
                >
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="item-content-left">
                      <div class="add-teacher">
                        <div
                          class="teacher-list"
                          v-if="getRemark(item.remark, 'array').length"
                        >
                          <div
                            class="teacher-item"
                            v-for="(subitem, subindex) in getRemark(
                              item.remark,
                              'array'
                            )"
                            :key="subindex"
                            @click="changeTeacher(item, subindex)"
                          >
                            <div
                              class="wrap"
                              :class="{ ban: !item.rule.isUpdate }"
                            >
                              <span class="name">{{
                                getRemark(subitem.teacher_name, "object").value
                              }}</span>
                            </div>
                            <div
                              class="delete"
                              :class="{ ban: !item.rule.isUpdate }"
                              @click.stop="deleteTeacherList(subindex)"
                            >
                              删除
                            </div>
                          </div>
                        </div>
                        <div class="teacher-list" v-else>
                          <div class="teacher-item">
                            <span class="span" style="color: #808080"
                              >去添加</span
                            >
                          </div>
                        </div>
                        <div class="btn-wrap">
                          <button
                            class="btn"
                            :class="{ banBgc: !item.rule.isUpdate }"
                            @click="jumpToAddTeacher(item)"
                          >
                            添加导师
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- json添加公司 -->
                <div
                  class="item-wrap"
                  v-if="
                    item.type == 'json' &&
                    item.field == 'company_Array' &&
                    isShowCompany
                  "
                >
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="item-content">
                    <div class="item-content-left">
                      <div class="add-teacher">
                        <div
                          class="teacher-list"
                          v-if="getRemark(item.remark, 'array').length"
                        >
                          <div
                            class="teacher-item"
                            v-for="(subitem, subindex) in getRemark(
                              item.remark,
                              'array'
                            )"
                            :key="subindex"
                            @click="changeCompany(item, subindex)"
                          >
                            <div
                              class="wrap"
                              :class="{ ban: !item.rule.isUpdate }"
                            >
                              <span class="name">{{
                                getRemark(subitem.company_name, "object").value
                              }}</span>
                            </div>
                            <div
                              class="delete"
                              :class="{ ban: !item.rule.isUpdate }"
                              @click.stop="deleteCompanyList(subindex)"
                            >
                              删除
                            </div>
                          </div>
                        </div>
                        <div class="teacher-list" v-else>
                          <div class="teacher-item">
                            <span class="span" style="color: #808080"
                              >去添加</span
                            >
                          </div>
                        </div>
                        <div
                          class="btn-wrap"
                          v-if="!getRemark(item.remark, 'array').length"
                        >
                          <button
                            class="btn"
                            :class="{ banBgc: !item.rule.isUpdate }"
                            @click="jumpToAddCompany(item)"
                          >
                            添加公司
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- image -->
                <div class="item-wrap" v-if="item.type == 'image'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="subtitle">
                    <span
                      style="color: #999; font-size: 12px; overflow: hidden"
                      >{{ item.rule.placeholder || "" }}</span
                    >
                  </div>
                  <div class="item-content" @click="changeImgIndex(index)">
                    <el-upload
                      :action="fileUploadUrl"
                      :data="fileUploadParam"
                      list-type="picture-card"
                      :limit="item.rule.maxNum"
                      :before-upload="beforeImgUpload"
                      :on-success="handleImgUploadSuccess"
                      :on-preview="handleImgPreview"
                      :on-remove="handleImgRemove"
                      :on-exceed="handleImgExceed"
                      :file-list="item.value ? item.value : []"
                    >
                      <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img width="100%" :src="dialogImageUrl" alt="" />
                    </el-dialog>
                  </div>
                </div>

                <!-- file -->
                <div class="item-wrap" v-if="item.type == 'file'">
                  <div class="item-title">
                    <span
                      class="item-name"
                      :class="{ ban: !item.rule.isUpdate }"
                      >{{ item.title }}</span
                    >
                    <span
                      style="color: red; margin-right: 5px"
                      v-if="item.isNeed != 0"
                      >*</span
                    >
                  </div>
                  <div class="subtitle">
                    <span
                      style="color: #999; font-size: 12px; overflow: hidden"
                      >{{ item.rule.placeholder || "" }}</span
                    >
                  </div>
                  <div class="item-content" @click="changeFileIndex(index)">
                    <el-upload
                      class="upload-demo"
                      :action="fileUploadUrl"
                      :data="fileUploadParam"
                      :before-upload="beforeFileUpload"
                      :on-preview="handleFilePreview"
                      :on-remove="handleFileRemove"
                      :on-success="handleFileUploadSuccess"
                      :on-error="handleFileUploadError"
                      :before-remove="beforeFileRemove"
                      multiple
                      :limit="1"
                      :on-exceed="handleFileExceed"
                      :file-list="item.value ? item.value : []"
                      :disabled="item.disabled"
                    >
                      <el-button size="small" type="primary"
                        >点击上传</el-button
                      >
                    </el-upload>
                  </div>
                </div>
              </div>
            </div>

            <div class="foot">
              <div class="btn-wrap">
                <div
                  class="btn btn-default"
                  @click="tempSave"
                  v-if="!isTempSave"
                >
                  暂存草稿
                </div>
                <div class="btn btn-default" v-else>暂存中</div>
                <div class="btn btn-primary" @click="submit" v-if="!isSave">
                  提交
                </div>
                <div class="btn btn-primary" v-else>提交中...</div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-right">
          <div class="plate1" @click="toMatchDetail">
            <div class="title">推荐赛事</div>
            <div class="title-border"></div>
            <div class="img-wrap">
              <img class="img" :src="mainRecommendMatch.image" alt="" />
            </div>
            <div class="content">{{ mainRecommendMatch.title }}</div>
          </div>
        </div>
      </section>

      <div class="popup-wrap" v-if="showPopup">
        <div v-if="teacherShow">
          <el-dialog
            title="编辑导师信息"
            :visible.sync="formData[teacherArrayIndex].switchYn"
            :close-on-click-modal="false"
          >
            <match-sub-item
              :formData="popupData"
              :index="currentTeacherIndex"
              :isOpen="formData[teacherArrayIndex].switchYn"
              @getTeacherData="getTeacherData"
            ></match-sub-item>
          </el-dialog>
        </div>
        <div v-else-if="companyShow">
          <el-dialog
            title="编辑公司信息"
            :visible.sync="formData[companyArrayIndex].switchYn"
            :close-on-click-modal="false"
          >
            <match-add-company
              :formData="popupData"
              :index="currentCompanyArrayIndex"
              :isOpen="isFreshComponent"
              @getCompanyData="getCompanyData"
            ></match-add-company>
          </el-dialog>
        </div>
        <div v-else-if="projectMemberShow">
          <el-dialog
            title="邀请成员"
            :visible.sync="formData[projectMemberIndex].switchYn"
            :close-on-click-modal="false"
          >
            <match-add-member
              :matchId="matchId"
              :matchName="matchName"
              :categoryId="categoryId"
              :memberData="memberData"
              :rules="addmemberRules"
            ></match-add-member>
          </el-dialog>
        </div>
      </div>
      <!-- <div v-if="saveVisible">
          <el-dialog
            title="提示"
            :visible.sync="saveVisible"
            width="30%"
            center
            :close-on-click-modal="false">
            <span>是否获取最近的暂存数据</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="cancleShowSave">取 消</el-button>
              <el-button type="primary" @click="showSave">确 定</el-button>
            </span>
          </el-dialog>
        </div> -->
    </main>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Header from "@/components/common/header.vue";
import Footer from "@/components/common/footer.vue";
import Breadcrumb from "@/components/common/breadcrumb";
import matchSubItem from "@/views/match/components/matchSubItem";
import matchAddMember from "@/views/match/components/matchAddMember";
import matchAddCompany from "@/views/match/components/matchAddCompany";

import {
  getProject,
  customMatchApply,
  getMatchProjectMember,
  getMatchSub,
  getRecommend,
  getMatchDetail,
} from "@/api/match/match";
import { getAuthInfo } from "@/api/user/user";
import { getqiniuToken } from "@/api/common/common";
import { getScoolList, getAcademyList, getMajorList } from "@/api/school";
import { getProvinceList, getConfigAreaList } from "@/api/area";
import { regionData } from "element-china-area-data";
export default {
  components: {
    pageTop,
    Header,
    Footer,
    Breadcrumb,
    matchSubItem,
    matchAddMember,
    matchAddCompany,
  },

  data() {
    return {
      applyId: "", //办理id
      matchId: "", //大赛ID
      matchName: "", //大赛名称
      status: 0, //认证状态
      authInfo: {}, //认证信息

      //表单数据
      formData: [], //表单列表
      form: {}, //提交表单数据
      isUpdate: true,

      //赛区
      zones: [],
      comArea: "", //选择的赛区
      comAreaList: [], //赛区显示列表

      //赛道
      categories: [], //赛道列表
      categoryId: "", //赛道ID
      comTrack: "", //当前选择的赛道
      comTrackList: [], //赛道显示列表
      projectFileList: [], //项目模板列表

      ///areaSelect 籍贯
      provinceOptions: [],
      provinceCode: null,
      province: "",
      cityOptions: [],
      cityCode: null,
      city: "",
      districtOptions: [],
      districtCode: null,
      district: "",
      areaInput: "",
      areaList: regionData, //城市列表

      //dict学校
      school: null,
      searchSchoolList: [], //学校列表
      schoolIndex: null, //学校的index
      schoolId: "", //学校id
      //院系
      academyIndex: null, //院系的index
      academyId: "", //院系id
      academyList: [], //院系列表
      //专业
      majorIndex: null, //专业的index
      majorList: [], //专业列表

      //invite 团队成员
      isRefreshMember: false,
      projectMemberIndex: null, //邀请成员的index
      addmemberRules: {},
      memberData: {},

      //json导师
      teacherList: [], //导师列表
      teacherArrayIndex: null, //导师的index
      currentTeacherIndex: null,

      //json公司
      companyList: [], //导师列表
      companyArrayIndex: null, //导师的index
      currentCompanyArrayIndex: null,
      isShowCompany: false,
      isFreshComponent: 0,

      //image
      imgIndex: 0, //图片类型索引
      dialogVisible: false, //是否预览
      dialogImageUrl: "", //预览图片地址

      //file
      fileIndex: 0, //文件类型索引
      fileList: [], //文件列表
      fileUploadUrl: "https://up-z2.qiniup.com", //上传文件地址
      fileUploadParam: {},

      //common
      isUpData: false,
      showPopup: false,
      popupData: [],
      isSave: false,
      isTempSave: false,

      kindInfo: {}, //信息
      getFormKindInfo: [], //配套报名信息
      formKindOtherList: [], //其他配套报名
      mainRecommendMatch: {}, //主要推荐赛事
      otherRecMatchList: [], //其他推荐赛事
      saveVisible: false, //回显弹窗
      checkedShow: false,

      //控制弹窗
      teacherShow: false,
      companyShow: false,
      projectMemberShow: false,
    };
  },
  created() {
    this.baseURL = this.$baseURL;
    this.applyId = this.$route.params.applyId;
    this.matchId = this.$route.params.matchId;
    getProvinceList().then((res) => {
      this.provinceOptions = res.data;
    });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    //获取header组件的点击事件
    getTab(data) {
      if (data && typeof data == "string") {
        this.$router.push("/" + data);
      }
    },
    //获取数据
    fetchData() {
      this.getMatchDetail();
      this.judgeFetchData();
      this.queryRecommend();
      this.fetchAuthData();
    },
    //获取认证信息
    fetchAuthData() {
      getAuthInfo().then((res) => {
        this.authInfo = res.data.authInfo;
      });
    },
    //获取赛事详情
    getMatchDetail() {
      getMatchDetail({ id: this.matchId }).then((res) => {
        this.matchName = res.data.title;
        this.zones = res.data.zones;
        this.categories = res.data.categories;
        this.projectFileList = res.data.fileList;
        this.zones?.forEach((item) => {
          this.comAreaList.push(item.title);
        });
        this.categories?.forEach((item) => {
          this.comTrackList.push(item.title);
        });
      });
    },
    //获取推荐赛事
    queryRecommend() {
      getRecommend({ limit: 5 }).then((res) => {
        if (!res.data) return;
        if (res.data.length >= 1) {
          this.mainRecommendMatch = res.data[0];
          this.otherRecMatchList = res.data.slice(1);
        }
      });
    },
    // showSave(){
    //   this.saveVisible = false
    //   this.checkedShow = true
    //   this.judgeFetchData()
    // },
    // cancleShowSave(){
    //   this.saveVisible = false
    // },
    //表单数据
    judgeFetchData() {
      if (this.applyId) {
        getProject(this.applyId).then((res) => {
          if (!res.data) return;
          this.comAreaList = [];
          this.comTrackList = [];
          //获取当前赛区
          if (this.zones && this.zones.length) {
            this.zones.forEach((item) => {
              this.comAreaList.push(item.title);
              if (item.id == res.data.zoneId) {
                this.comArea = item.title;
              }
            });
          }
          //获取当前赛道
          if (this.categories && this.categories.length) {
            this.categories.forEach((item) => {
              this.comTrackList.push(item.title);
              if (item.id == res.data.categoryId) {
                this.comTrack = item.title;
                this.categoryId = item.id;
              }
            });
          }
          //获取其他表单信息
          this.formData = res.data.formList;
          this.applyId = res.data.applyId;
          this.isUpdate = res.data.isUpdate;
          this.formData.forEach((item, index) => {
            if (item.type == "areaSelect" && item.remark) {
              if (item.remark.indexOf("#") > -1) {
                let area = item.remark.split("#");
                item.remark = area[0];
                this.areaInput = area[1];
              }
              this.getArea(item.remark, index);
            }
            if (item.type == "checkbox") {
              item.value = "";
              if (item.remark && item.remark.indexOf("#") > -1) {
                let result = item.remark.split("#");
                item.remark = result[0];
                item.value = result[1];
              }
              item.remark = item.remark.split(",");
              if (item.remark) {
                this.formData[index].switchYn = !this.formData[index].switchYn;
              }
            }
            if (item.type == "radio") {
              item.value = "";
              if (item.remark && item.remark.indexOf("#") > -1) {
                let result = item.remark.split("#");
                item.remark = result[0];
                item.value = result[1];
              }
              if (item.remark && item.remark.indexOf("其他") != -1) {
                this.formData[index].switchYn = true;
              }
            }
            if (item.field == "school") {
              this.schoolIndex = index;
              getScoolList({ name: item.remark }).then((res) => {
                if (!res.data.list) return;
                this.searchSchoolList = res.data.list;
                this.searchSchoolList.forEach((schoolitem) => {
                  if (item.remark == schoolitem.name) {
                    this.schoolId = schoolitem.id;
                  }
                });
                if (!this.schoolId) {
                  if (this.academyIndex) {
                    this.formData[this.academyIndex].type = "input";
                  }
                  if (this.majorIndex) {
                    this.formData[this.majorIndex].type = "input";
                  }
                }
                if (this.schoolId) {
                  if (!this.academyIndex) return;
                  this.formData[this.academyIndex].type = "select";
                  getAcademyList(this.schoolId).then((res) => {
                    this.academyList = res.data;
                    this.formData[this.academyIndex].defaultValue = [];
                    this.academyList.forEach((item) => {
                      this.formData[this.academyIndex].defaultValue.push(
                        item.name
                      );
                      if (
                        item.name == this.formData[this.academyIndex].remark
                      ) {
                        this.academyId = item.id;
                        return;
                      }
                    });
                    if (this.academyId) {
                      if (!this.majorIndex) return;
                      this.formData[this.majorIndex].type = "select";
                      getMajorList(this.academyId).then((res) => {
                        this.majorList = res.data;
                        this.formData[this.majorIndex].defaultValue = [];
                        res.data.forEach((item) => {
                          this.formData[this.majorIndex].defaultValue.push(
                            item.name
                          );
                        });
                      });
                    }
                  });
                }
              });
            }
            if (item.field == "academy") {
              this.academyIndex = index;
            }
            if (item.field == "major") {
              this.majorIndex = index;
            }
            if (item.field == "project_member") {
              this.projectMemberIndex = index;
              this.addmemberRules = item.rule;
            }
            if (item.field == "teacher_Array") {
              this.teacherArrayIndex = index;
              if (typeof item.remark == "string") {
                let arr = [];
                this.getRemark(item.remark, "array").forEach((item) => {
                  for (let key in item) {
                    item[key] = this.getRemark(item[key], "object");
                  }
                  arr.push(this.getRemark(item, "object"));
                });
                console.log("arr", arr);
                this.formData[index].remark = arr;
              }
            }
            if (item.field == "is_register_company") {
              if (item.remark == "是") {
                this.isShowCompany = true;
              }
            }
            if (item.field == "company_Array") {
              this.companyArrayIndex = index;
              if (typeof item.remark == "string") {
                let arr = [];
                this.getRemark(item.remark, "array").forEach((item) => {
                  for (let key in item) {
                    item[key] = this.getRemark(item[key], "object");
                  }
                  arr.push(this.getRemark(item, "object"));
                });
                this.formData[index].remark = arr;
              }
            }
            if (item.field == "is_register_company") {
              if (item.remark == "是") {
                this.isShowCompany = true;
              }
            }
            if (item.type == "image") {
              item.value = [];
              let fileRemarkList = this.getRemark(item.remark, "array");
              if (!fileRemarkList.length) return;
              fileRemarkList.forEach((item) => {
                this.formData[index].value.push({
                  name: item.originalName,
                  url: item.url,
                });
              });
            }
            if (item.type == "file") {
              item.value = [];
              let fileRemarkList = this.getRemark(item.remark, "array");
              if (!fileRemarkList.length) return;
              fileRemarkList.forEach((item) => {
                if (item.url) {
                  this.formData[index].value.push({
                    name: item.originalName,
                    url: item.url,
                  });
                }
              });
            }
          });
          //回显获取学校
          if (!this.schoolId) {
            if (this.academyIndex) {
              this.formData[this.academyIndex].type = "input";
            }
            if (this.majorIndex) {
              this.formData[this.majorIndex].type = "input";
            }
          }
          //回显获取邀请
          if (this.categoryId) {
            if (this.projectMemberIndex) {
              getMatchProjectMember({
                competitionId: this.matchId,
                categoryId: this.categoryId,
              }).then((res) => {
                if (res.code != 200) return;
                this.memberData = res.data;
                this.formData[this.projectMemberIndex].remark = [];
                if (res.data.projectMembers.length) {
                  res.data.projectMembers.forEach((item) => {
                    this.formData[this.projectMemberIndex].remark.push({
                      name: item.name,
                    });
                  });
                }
              });
            }
          }
        });
      } else {
        getMatchSub({ competitionId: this.matchId }).then((res) => {
          if (!res.data) return;
          this.formData = res.data.formList;
          this.applyId = res.data.applyId;
          this.isUpdate = res.data.isUpdate;
          if (this.applyId) {
            // this.saveVisible = true
            this.$confirm("是否获取最近的暂存数据?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.checkedShow = true;
                this.judgeFetchData();
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消",
                });
              });
            if (this.checkedShow) return;
          }
          this.formData.forEach((item, index) => {
            if (item.type == "checkbox") {
              this.formData[index].remark = [];
            }
            if (item.type == "image") {
              this.formData[index].remark = [];
            }
            if (item.type == "file") {
              this.formData[index].remark = [];
            }
            if (item.field == "school") {
              this.schoolIndex = index;
            }
            if (item.field == "academy") {
              this.academyIndex = index;
            }
            if (item.field == "major") {
              this.majorIndex = index;
            }
            if (item.field == "project_member") {
              this.projectMemberIndex = index;
              this.addmemberRules = item.rule;
            }
            if (item.field == "teacher_Array") {
              this.teacherArrayIndex = index;
              if (typeof item.remark == "string") {
                this.formData[index].remark = this.getRemark(
                  item.remark,
                  "array"
                );
              }
            }
            if (item.field == "company_Array") {
              this.companyArrayIndex = index;

              console.warn(index);
              if (typeof item.remark == "string") {
                this.formData[index].remark = this.getRemark(
                  item.remark,
                  "array"
                );
              }
            }
            if (item.type == "image") {
              item.value = [];
            }
            if (item.type == "file") {
              item.value = [];
            }
          });
        });
      }
    },

    //赛道
    changeComTrack(e) {
      this.categoryId = this.categories[this.comTrackList.indexOf(e)].id;
      if (this.projectMemberIndex) {
        getMatchProjectMember({
          competitionId: this.matchId,
          categoryId: this.categoryId,
        }).then((res) => {
          if (res.code != 200) return;
          this.memberData = res.data;
          this.formData[this.projectMemberIndex].remark = [];
          if (res.data.projectMembers.length) {
            res.data.projectMembers.forEach((item) => {
              this.formData[this.projectMemberIndex].remark.push({
                name: item.name,
              });
            });
          }
        });
      }
    },

    // select
    //获取选择框列表
    getSelectValue(data) {
      if (!data) return "";
      let list = [];
      if (typeof data == "string") {
        if (data.indexOf(",") != -1) {
          let temp = data.split(",");
          temp.forEach((item) => {
            list.push({ value: item, label: item });
          });
          return list;
        } else {
          list = [{ value: data, label: data }];
          return list;
        }
      } else if (data instanceof Array) {
        data.forEach((item) => {
          list.push({ value: item, label: item });
        });
        return list;
      }
    },

    //areaSelect
    //省 选择事件
    provinceChange(index) {
      if (this.provinceCode) {
        this.city = "";
        this.cityCode = "";
        this.cityOptions = [];
        this.district = "";
        this.districtCode = "";
        this.districtOptions = [];
      }
      this.provinceOptions.forEach((e) => {
        if (e.value == this.provinceCode) {
          this.province = e.label;
          this.provinceCode = e.value;
        }
      });
      this.formData[index].remark = "";
      if (this.provinceCode) {
        getConfigAreaList({ areaCode: this.provinceCode }).then((res) => {
          this.cityOptions = res.data;
          if (this.city) {
            this.cityChange(index);
          }
        });
      }
    },
    //市 选择事件
    cityChange(index) {
      if (this.cityCode) {
        this.district = "";
        this.districtCode = "";
        this.districtOptions = [];
      }
      this.cityOptions.forEach((e) => {
        if (e.value == this.cityCode) {
          this.city = e.label;
          this.cityCode = e.value;
        }
      });
      this.formData[index].remark = "";
      if (this.cityCode) {
        getConfigAreaList({ areaCode: this.cityCode }).then((res) => {
          this.districtOptions = res.data;
          if (this.district) {
            this.districtChange(index);
          }
        });
      }
    },
    //区 选择事件
    districtChange(index) {
      this.districtOptions.forEach((e) => {
        if (e.value == this.districtCode) {
          this.district = e.label;
          this.districtCode = e.value;
        }
      });
      this.formData[index].remark =
        this.province + "、" + this.city + "、" + this.district;
    },
    //获取认证地区
    getArea(item) {
      const split = item.split("、");
      this.province = split[0];
      this.city = split[1];
      this.district = split[2];
      this.provinceOptions.forEach((e) => {
        if (e.label == this.province) {
          this.provinceCode = e.value;
          this.getAreaSelect();
        }
      });
    },
    //回显地区
    getAreaSelect() {
      this.provinceOptions.forEach((e) => {
        if (e.value == this.provinceCode) {
          this.province = e.label;
        }
      });
      if (this.provinceCode) {
        getConfigAreaList({ areaCode: this.provinceCode }).then((res) => {
          this.cityOptions = res.data;
          this.cityOptions.forEach((e) => {
            if (e.label == this.city) {
              this.cityCode = e.value;
            }
          });
          if (this.cityCode) {
            getConfigAreaList({ areaCode: this.cityCode }).then((res) => {
              this.districtOptions = res.data;
              this.districtOptions.forEach((e) => {
                if (e.label == this.district) {
                  this.districtCode = e.value;
                }
              });
            });
          }
        });
      }
    },

    //radio
    //获取单选框列表
    getRadioList(data) {
      if (!data) return "";
      let list = [];
      if (typeof data == "string") {
        if (data.indexOf(",") != -1) {
          let temp = data.split(",");
          temp.forEach((item) => {
            list.push({ value: item, label: item });
          });
          return list;
        } else {
          list = [{ value: data, label: data }];
          return list;
        }
      } else if (data instanceof Array) {
        data.forEach((item) => {
          list.push({ value: item, label: item });
        });
        return list;
      }
    },
    //改变radio
    changeRadio(item) {
      if (item.field == "is_register_company") {
        if (item.remark == "是") {
          this.isShowCompany = true;
        } else {
          this.isShowCompany = false;
        }
      }
      if (item.remark == "其他") {
        item.switchYn = true;
      } else {
        item.switchYn = false;
      }
    },
    //改变checkbox
    changeCheckbox(item) {
      if (item.remark.indexOf("其他") != -1) {
        item.switchYn = true;
      } else {
        item.switchYn = false;
      }
    },

    // dict学校
    changeSchool(e) {
      if (!e) {
        this.formData[this.academyIndex].remark = "";
        this.formData[this.academyIndex].type = "select";
        this.formData[this.majorIndex].remark = "";
        this.formData[this.majorIndex].type = "select";
      }
    },
    //学校 触发事件
    handleSelect(item) {
      this.schoolId = item.id;
      this.school = item.value;
      this.academyId = "";
      this.majorId = "";
      if (this.academyIndex) {
        this.formData[this.academyIndex].remark = "";
      }
      if (this.majorIndex) {
        this.formData[this.majorIndex].remark = "";
      }
      if (item.id) {
        if (this.academyIndex) {
          this.formData[this.academyIndex].type = "select";
        }
        if (this.majorIndex) {
          this.formData[this.majorIndex].type = "select";
        }
        getAcademyList(item.id).then((res) => {
          this.academyList = res.data;
          this.formData[this.academyIndex].defaultValue = [];
          res.data.forEach((item) => {
            if (this.academyIndex) {
              this.formData[this.academyIndex].defaultValue.push(item.name);
            }
          });
        });
      } else {
        if (this.academyIndex) {
          this.formData[this.academyIndex].type = "input";
        }
        if (this.majorIndex) {
          this.formData[this.majorIndex].type = "input";
        }
      }
    },
    // 远程查询学校列表
    selectScoolList(queryString, cb) {
      if (queryString != "") {
        this.schoolList = [];
        getScoolList({ name: queryString }).then((res) => {
          res.data.list.forEach((element) => {
            this.schoolList.push({
              value: element.name,
              id: element.id,
            });
          });
          cb(this.schoolList);
        });
      }
    },
    changeSelect(item) {
      if (item.field == "academy") {
        this.majorId = "";
        this.academyList.forEach((e) => {
          // 存remark
          if (e.name == item.remark) {
            this.academyId = e.id;
            this.academy = e.name;
          }
        });
        getMajorList(this.academyId).then((res) => {
          this.formData[this.majorIndex].type = "select";
          this.majorList = res.data;
          this.formData[this.majorIndex].remark = [];

          this.formData[this.majorIndex].defaultValue = [];
          res.data.forEach((item) => {
            this.formData[this.majorIndex].defaultValue.push(item.name);
          });
        });
      }
      if (item.field == "major") {
        this.majorList.forEach((e) => {
          if (e.id == item) {
            this.major = e.name;
            this.majorId = e.id;
          }
        });
      }
    },
    clickSelect(item) {
      if (item.field == "academy") {
        if (this.schoolIndex && !this.schoolId) {
          this.$message.error("请先选择学校");
        }
      }
      if (item.field == "major") {
        if (this.schoolIndex && !this.schoolId) {
          this.$message.error("请先选择学校");
          return;
        }
        if (this.academyIndex && !this.academyId) {
          this.$message.error("请先选择院系");
        }
      }
    },

    //invite 团队成员
    //添加成员
    toAddMember(item) {
      if (!item.rule.isUpdate) {
        return;
      }
      if (!this.categoryId) {
        return this.$message.error("请先选择赛道");
      }
      this.showPopup = true;
      this.formData[this.projectMemberIndex].switchYn = true;

      this.teacherShow = false;
      this.companyShow = false;
      this.projectMemberShow = true;
    },
    //刷新成员
    refreshMember() {
      if (!this.categoryId) {
        return this.$message.error("请先选择赛道");
      }
      getMatchProjectMember({
        competitionId: this.matchId,
        categoryId: this.categoryId,
      }).then((res) => {
        this.memberData = res.data;
        this.formData[this.projectMemberIndex].remark = [];
        if (res.data.projectMembers?.length) {
          res.data.projectMembers.forEach((item) => {
            this.formData[this.projectMemberIndex].remark.push({
              name: item.name,
            });
          });
        }
      });
      this.$message.success("刷新成功");
    },

    //json添加导师
    //导师
    jumpToAddTeacher(item) {
      if (!item.rule.isUpdate) {
        return;
      }
      this.isFreshComponent++;
      this.currentTeacherIndex = null;
      this.showPopup = true;
      item.childList.forEach((subitem) => {
        subitem.remark = null;
      });

      this.popupData = item.childList;
      this.formData[this.teacherArrayIndex].switchYn = true;

      this.teacherShow = true;
      this.companyShow = false;
      this.projectMemberShow = false;
    },
    getTeacherData(data) {
      let arr = this.formData[this.teacherArrayIndex].remark
        ? this.formData[this.teacherArrayIndex].remark
        : [];
      if (data.index != null && data.index != undefined) {
        arr.splice(data.index, 1, data.form);
        this.formData[this.teacherArrayIndex].remark = arr;
      } else {
        arr.push(data.form);
        this.formData[this.teacherArrayIndex].remark = arr;
      }
      this.showPopup = false;
    },
    changeTeacher(item, index) {
      if (!item.rule.isUpdate) {
        return;
      }
      this.isFreshComponent++;
      this.formData[this.teacherArrayIndex].switchYn = true;
      let remark = this.getRemark(item.remark, "array");
      let childList = item.childList;
      childList.forEach((childItem) => {
        if (!remark[index][childItem.field]) {
          childItem.remark = null;
          return;
        }
        if (typeof remark[index][childItem.field].value == "string") {
          childItem.remark = remark[index][childItem.field].value;
        } else {
          childItem.remark = remark[index][childItem.field].value;
        }
      });
      childList.forEach((e) => {
        if (e.type == "image") {
          if (e.remark && e.remark.length > 0 && !e.remark[0].url) {
            e.remark = [];
          }
        }
      });
      this.showPopup = true;
      this.currentTeacherIndex = index;
      this.popupData = childList;

      this.teacherShow = true;
      this.companyShow = false;
      this.projectMemberShow = false;
    },
    deleteTeacherList(index) {
      this.formData[this.teacherArrayIndex].remark.splice(index, 1);
    },

    //公司
    jumpToAddCompany(item) {
      if (!item.rule.isUpdate) {
        return;
      }
      this.isFreshComponent++;
      this.currentCompanyArrayIndex = null;
      this.showPopup = true;
      item.childList.forEach((subitem) => {
        subitem.remark = null;
      });
      this.popupData = item.childList;
      console.warn(
        this.formData[this.companyArrayIndex].switchYn,
        this.showPopup,
        this.popupData
      );
      this.formData[this.companyArrayIndex].switchYn = true;

      this.teacherShow = false;
      this.companyShow = true;
      this.projectMemberShow = false;
    },
    getCompanyData(data) {
      let arr = this.formData[this.companyArrayIndex].remark
        ? this.formData[this.companyArrayIndex].remark
        : [];
      if (data.index != null && data.index != undefined) {
        arr.splice(data.index, 1, data.form);
        this.formData[this.companyArrayIndex].remark = arr;
      } else {
        arr.push(data.form);
        this.formData[this.companyArrayIndex].remark = arr;
      }
      this.showPopup = false;
    },
    changeCompany(item, index) {
      if (!item.rule.isUpdate) {
        return;
      }
      this.isFreshComponent++;
      this.formData[this.companyArrayIndex].switchYn = true;
      let remark = this.getRemark(item.remark, "array");
      let childList = item.childList;
      childList.forEach((childItem) => {
        if (!remark[index][childItem.field]) {
          childItem.remark = null;
          return;
        }
        if (typeof remark[index][childItem.field].value == "string") {
          childItem.remark = remark[index][childItem.field].value;
        } else {
          childItem.remark = remark[index][childItem.field].value;
        }
      });
      this.showPopup = true;
      this.currentCompanyArrayIndex = index;
      this.popupData = childList;

      this.teacherShow = false;
      this.companyShow = true;
      this.projectMemberShow = false;
    },
    deleteCompanyList(index) {
      this.formData[this.companyArrayIndex].remark.splice(index, 1);
    },

    //image
    changeImgIndex(index) {
      this.imgIndex = index;
      getqiniuToken().then((res) => {
        this.fileUploadParam = { key: "", token: res.data };
      });
    },
    beforeImgUpload(file) {
      this.fileUploadParam.key =
        String(new Date().getTime()) +
        Math.floor(Math.random() * 50 + 50) +
        "." +
        file.name;
    },
    handleImgUploadSuccess(file) {
      this.formData[this.imgIndex].remark = this.formData[this.imgIndex].remark
        ? this.formData[this.imgIndex].remark
        : [];
      this.formData[this.imgIndex].remark.push({
        id: file.data.id,
        originalName: file.data.fname,
        url: this.$globalHeader.fileUrl + "/" + file.data.key,
      });
      this.$message.success(`上传成功`);
    },
    handleImgUploadError() {
      this.$message.warning(`上传失败`);
    },
    handleImgPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleImgExceed() {
      this.$message.warning(
        `抱歉，最多只能上传 ${this.formData[this.imgIndex].rule.maxNum} 个文件`
      );
    },
    beforeImgRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleImgRemove(file, fileList) {
      console.log(file, fileList);
      this.formData[this.imgIndex].remark = [];
      fileList.forEach((item) => {
        this.formData[this.imgIndex].remark.push({
          id: item.response.data.id,
          originalName: item.response.data.fname,
          url: this.$globalHeader.fileUrl + "/" + item.response.data.key,
        });
      });
    },

    // url: config.BaseUrl + '/api/file/web/upload'
    //file
    changeFileIndex(index) {
      this.fileIndex = index;
      getqiniuToken().then((res) => {
        this.fileUploadParam = { key: "", token: res.data };
      });
    },
    beforeFileUpload(file) {
      this.fileUploadParam.key =
        String(new Date().getTime()) +
        Math.floor(Math.random() * 50 + 50) +
        "." +
        file.name;
    },
    handleFileUploadSuccess(file) {
      this.formData[this.fileIndex].remark = this.formData[this.fileIndex]
        .remark
        ? this.formData[this.fileIndex].remark
        : [];

      this.formData[this.fileIndex].remark.push({
        id: file.data.id,
        originalName: file.data.fname,
        url: this.$globalHeader.fileUrl + "/" + file.data.key,
      });
      this.$message.success(`上传成功`);
    },
    handleFileUploadError() {
      this.$message.warning(`上传失败`);
    },
    handleFilePreview(file) {
      console.log(file);
    },
    handleFileExceed() {
      this.$message.warning(`抱歉，最多只能上传 1个文件`);
    },
    beforeFileRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleFileRemove(file, fileList) {
      console.log(file, fileList);
      this.formData[this.fileIndex].remark = [];
      fileList.forEach((item) => {
        this.formData[this.fileIndex].remark.push({
          id: item.response.data.id,
          originalName: item.response.data.fname,
          url: this.$globalHeader.fileUrl + "/" + item.response.data.key,
        });
      });
    },

    //common
    toMatchDetail() {
      this.$router.replace({
        path: "/match/matchDetail/" + this.matchId,
      });
    },

    //转换remark类型
    getRemark(data, type) {
      const dataType = typeof data;
      if (type == "array") {
        if (!data) {
          return [];
        }
        if (dataType == "string") {
          let data2 = data.replace(/:s*([0-9]{15,})s*(,?)/g, ': "$1" $2');
          return JSON.parse(data2);
        }
        if (dataType == "object") {
          return data;
        }
      }
      if (type == "string") {
        if (!data) {
          return "";
        }
        if (dataType == "string") {
          return data;
        }
        if (dataType == "object") {
          console.log("参数错误");
        }
      }
      if (type == "object") {
        if (!data) {
          return {};
        }
        if (dataType == "string") {
          return JSON.parse(data);
        }
      }
      return data;
    },
    //暂存
    tempSave() {
      this.isTempSave = true;
      //赛区赛道
      const oldParam = this.getParam();
      if (!oldParam) return (this.isTempSave = false);
      //自定义表单
      let result = "";
      this.formData.forEach((item) => {
        if (item.type == "image") {
          console.log("image", item);
          if (item.remark && item.remark.length == 0) {
            item.remark = null;
          }
        }
        if (item.type == "file") {
          if (item.remark && item.remark.length == 0) {
            item.remark = null;
          }
        }
        if (item.type == "radio") {
          if (item.remark && item.remark.indexOf("其他") != -1) {
            this.form[item.field] = {
              id: item.id,
              value: item.remark + "#" + item.value,
              isUpdate: item.rule.isUpdate,
            };
          } else {
            this.form[item.field] = {
              id: item.id,
              value: item.remark,
              isUpdate: item.rule.isUpdate,
            };
          }
        } else if (item.type == "checkbox") {
          if (item.remark && item.remark.length) {
            item.remark.forEach((item, index) => {
              if (index == 0) {
                result = item;
              } else {
                result += "," + item;
              }
            });
          }
          if (result.indexOf("其他") != -1) {
            if (item.value == null) {
              result += "#" + " ";
            } else {
              result += "#" + item.value;
            }
          }
          this.form[item.field] = {
            id: item.id,
            value: result,
            isUpdate: item.rule.isUpdate,
          };
        } else if (item.type == "areaSelect" && this.areaInput) {
          item.remark = item.remark + "#" + this.areaInput;
          this.form[item.field] = {
            id: item.id,
            value: item.remark,
            isUpdate: item.rule.isUpdate,
          };
        } else {
          this.form[item.field] = {
            id: item.id,
            value: item.remark,
            isUpdate: item.rule.isUpdate,
          };
        }
      });
      let param = {
        applyId: this.applyId,
        ...oldParam,
        form: this.form,
        signupStatus: "2",
      };
      customMatchApply(param).then((res) => {
        if (res.code == 200) {
          this.$message.success("暂存成功");
          this.applyId=res.data
        } else {
          this.$message.error(res.message);
        }
        setTimeout(() => {
          this.isTempSave = false;
        }, 500);
      });
    },
    //提交
    submit() {
      //赛区赛道
      const oldParam = this.getParam();
      if (!oldParam) return;
      //自定义表单
      let isEmpty = true;
      let result = "";
      this.formData.forEach((item) => {
        if (!isEmpty) return;
        if (item.isNeed != 0) {
          if (item.type == "invite") return;
          if (!item.rule.isUpdate) return;
          if (typeof item.remark == "string") {
            if (!item.remark || !item.remark.replace(/\s/g, "").length) {
              this.$message.error(item.title + "未填写");
              isEmpty = false;
              return;
            }
          } else {
            if (!item.remark || !item.remark.length) {
              this.$message.error(item.title + "未填写");
              isEmpty = false;
              return;
            }
          }
        }

        if (item.type == "image") {
          if (item.remark && item.remark.length == 0) {
            item.remark = null;
          }
        }
        if (item.type == "file") {
          if (item.remark && item.remark.length == 0) {
            item.remark = null;
          }
        }

        if (item.type == "radio") {
          if (item.remark && item.remark.indexOf("其他") != -1) {
            this.form[item.field] = {
              id: item.id,
              value: item.remark + "#" + item.value,
              isUpdate: item.rule.isUpdate,
            };
          } else {
            this.form[item.field] = {
              id: item.id,
              value: item.remark,
              isUpdate: item.rule.isUpdate,
            };
          }
        } else if (item.type == "checkbox") {
          result = "";
          if (item.remark && item.remark.length) {
            item.remark.forEach((child) => {
              if (child != "其他") {
                result += (result.length ? "," : "") + child;
              }
            });
          }
          if (item.remark.indexOf("其他") != -1) {
            result += "," + "其他#";
            if (item.value != null) {
              result += item.value;
            }
          }
          this.form[item.field] = {
            id: item.id,
            value: result,
            isUpdate: item.rule.isUpdate,
          };
        } else if (item.type == "areaSelect" && this.areaInput) {
          item.remark = item.remark + "#" + this.areaInput;
          this.form[item.field] = {
            id: item.id,
            value: item.remark,
            isUpdate: item.rule.isUpdate,
          };
        } else {
          this.form[item.field] = {
            id: item.id,
            value: item.remark,
            isUpdate: item.rule.isUpdate,
          };
        }
      });
      if (!isEmpty) return;
      this.saveSub();
    },
    saveSub() {
      this.isSave = true;
      const oldParam = this.getParam();
      let param = {};
      if (this.applyId) {
        param = {
          applyId: this.applyId,
          form: this.form,
          ...oldParam,
          signupStatus: "1",
        };
      } else {
        param = {
          competitionId: this.matchId,
          form: this.form,
          ...oldParam,
          signupStatus: "1",
        };
      }
      customMatchApply(param).then((res) => {
        if (res.code == 200) {
          this.$message.success("提交成功");
          setTimeout(() => {
            this.isSave = false;
          }, 500);
          setTimeout(() => {
            this.$router.replace({
              path: "/match/matchDetail/" + this.matchId + "/matchSubResult",
              query: {
                matchId: this.matchId,
              },
            });
          }, 1000);
        } else {
          setTimeout(() => {
            this.isSave = false;
          }, 500);
          this.$message.error(res.message);
        }
      });
    },
    getParam() {
      if (!this.zones[this.comAreaList.indexOf(this.comArea)]?.id) {
        this.$message.error("赛区未选择");
        return false;
      }
      if (!this.categories[this.comTrackList.indexOf(this.comTrack)]?.id) {
        this.$message.error("赛道未选择");
        return false;
      }
      return {
        zoneId: this.zones[this.comAreaList.indexOf(this.comArea)].id,
        categoryId:
          this.categories[this.comTrackList.indexOf(this.comTrack)].id,
        competitionId: this.matchId,
        isUpdate: this.isUpdate,
      };
    },
    toAuth() {
      // uni.navigateTo({
      //     url: '/pages/authentication/auth'
      // })
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/src/assets/style/matchSub/matchSub.scss";
</style>
