
// import Vue from 'vue'
import axios from "axios";
import config from "../../config.js"
import { local } from "@/utils/storage.js"
import Vue from 'vue'
import $router from '../router'

//axios实例
const request = axios.create({
    baseURL: config.baseURL,
    timeout: 5000,
});

// 1、请求拦截
request.interceptors.request.use(function (config) {
    config.headers['Authorization'] = local.Get('Authorization') || ''       //携带token
    return config;
}, function (err) {
    //请求错误之前可以进行处理
    return Promise.reject(err)
});

// 2、拦截响应
request.interceptors.response.use(function (response) {
    //响应成功
    if (response.status == 200) {
        if (response.data.code == 401) {
            console.log('请先登录');

            //未登录
            Vue.prototype.$confirm("您暂未登录, 是否前往登录?", "登录提醒", {
                confirmButtonText: "去登录",//确认按钮文字更换
                cancelButtonText: "取消",//取消按钮文字更换
                showClose: true,//是否显示右上角关闭按钮
                type: "warning",
            }).then(res => {
                if (res) {
                    $router.push({
                        path: "/login",
                        query: {
                            redirect: $router.currentRoute.path,
                        },
                    });
                }
            })
        }
        if (response.data.code == 1019) {
            console.log('请先认证');
        }
        if (response.data.code == 200) {
            return response.data
        } else {
            console.log(response.data.message);
            return response.data
        }
    }
}, function (err) {
    //响应错误
    return Promise.reject(err)
})

export default request;
