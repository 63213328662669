<template>
  <div class="match-result-page page">
    <!-- 顶部 -->
    <page-top></page-top>

    <main class="main">
      <div class="result-content w">
        <div class="awaiting result-wrap" v-if="applyId">
          <div class="image-wrap">
            <img
              class="image"
              v-if="applyDetail.status == 1"
              :src="$globalHeader.imgUrl + '/static/icon/wait_audit.png'"
              alt="结果图标"
            />
            <img
              class="image"
              v-if="applyDetail.status == 2"
              :src="$globalHeader.imgUrl + '/static/icon/audit_fail.png'"
              alt="结果图标"
            />
            <img
              class="image"
              v-if="applyDetail.status == 3"
              :src="$globalHeader.imgUrl + '/static/icon/audit_success.png'"
              alt="结果图标"
            />
          </div>
          <div class="result">
            {{ applyDetail.content }}
          </div>
          <div class="result-time">
            {{ applyDetail.time }}
          </div>
          <div class="result-over-time">
            {{ applyDetail.reason }}
          </div>
          <div class="back-btn">
            <el-button
              type="primary"
              size="normal"
              shape="circle"
              plain
              @click="toConduct"
              v-if="applyDetail.status == 2"
              >重新提交</el-button
            >
            <div style="margin-bottom: 20px"></div>
            <el-button
              type="primary"
              size="normal"
              shape="circle"
              plain
              @click="backToMine"
              >返回</el-button
            >
          </div>
        </div>
        <div class="awaiting result-wrap" v-else>
          <div class="image-wrap">
            <img
              class="image"
              :src="$globalHeader.imgUrl + '/static/icon/wait_audit.png'"
            />
          </div>
          <div class="result">在线申请已提交，等待审核</div>
          <div class="result-time">
            {{ nowDate }}
          </div>
          <div class="result-over-time">请留意审核结果</div>
          <div class="back-btn">
            <el-button
              type="primary"
              size="normal"
              shape="circle"
              plain
              @click="backToMine"
              >返回</el-button
            >
          </div>
        </div>
      </div>

      <div class="tips-plate" v-if="tipContent">
        <div class="left">
          <text class="text">{{ tipContent }}</text>
        </div>
        <div class="right" v-if="!isSubscribe">
          <div class="btn-wrap" @click="toFollow">
            <text class="btn">去开启</text>
          </div>
        </div>
        <div class="right" v-else>
          <div class="btn-wrap" @click="toOpen">
            <text class="btn">去开启</text>
          </div>
        </div>
      </div>
    </main>

    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import pageTop from "@/components/common/pageTop.vue";
import Footer from "@/components/common/footer.vue";

import * as util from "@/utils/util.js";
import { getAuditResult } from "@/api/match/match.js";
export default {
  components: { pageTop, Footer },
  data() {
    return {
      matchId: "",
      kindId: "",
      applyId: "",
      applyDetail: {},
      nowDate: "",
      //消息通知
      howOutPopup: false,
      tipContent: "",
      isSubscribe: false,
      imageUrl: "",
    };
  },
  created() {
    this.applyId = this.$route.query.applyId;
    this.kindId = this.$route.query.kindId;
    this.matchId = this.$route.query.matchId;
    this.nowDate = util.getDate("yyyy-MM-dd HH:mm");
    this.fetchData();
  },
  methods: {
    //获取数据
    fetchData() {
      if (this.applyId) {
        this.getApplyDetail();
      }
    },
    //获取办理结果
    getApplyDetail() {
      getAuditResult({ applyId: this.applyId }).then((res) => {
        console.log(res);
        this.applyDetail = res.data;
      });
    },
    //信息通知
    toFollow() {
      this.showOutPopup = true;
    },
    close() {
      this.showOutPopup = false;
      this.fetchData();
    },
    //重新办理
    toConduct() {
      this.$router.replace({
        path:
          "/match/matchDetail/" +
          this.matchId +
          "/matchKindSub/" +
          this.matchId +
          "/" +
          this.kindId +
          "/" +
          this.applyId,
        // query: {
        //   matchId: this.matchId,
        //   kindId: this.kindId,
        //   applyId: this.applyId
        // },
      });
    },
    //查看详情
    toDetail() {
      // uni.redirectTo({
      //     url: '/pages/mine/matchList'
      // })
    },
    //返回
    backToMine() {
      this.$router.replace({
        path: "/match/matchDetail/" + this.matchId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.match-result-page {
  display: flex;
  flex-direction: column;
  background-color: #efefef;

  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
    background-color: #efefef;

    .result-content {
      .result-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 40px 40px;

        .image-wrap {
          width: 180px;
          height: 180px;
          margin-top: 20px;
          margin-bottom: 20px;

          .image {
            width: 100%;
            height: 100%;
          }
        }

        .result {
          padding: 8px 0;
          font-size: 20px;
        }

        .result-time {
          padding: 8px 0;
          font-size: 14px;
          color: #999;
        }

        .result-over-time {
          padding: 8px 0;
          font-size: 16px;
          color: #999;
          line-height: 1.35;
        }

        .err {
          color: #ff6633;
        }

        .back-btn {
          width: 20%;
          padding: 20px 0;
          color: #57aaff;

          .el-button {
            width: 100%;
          }
        }
      }
    }

    .tips-plate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 32px;
      background-color: #ffe4ac;

      .left {
        flex: 1;

        .text {
          line-height: 1.35;
          font-size: 26px;
          color: #ff6633;
        }
      }

      .right {
        flex-shrink: 0;
        margin-left: 32px;

        .btn-wrap {
          padding: 12px 24px;
          background-color: #ff6633;
          border-radius: 48px;

          .btn {
            line-height: 1.4;
            font-size: 28px;
            color: #fff;
          }
        }
      }
    }
  }

  /deep/.u-popup__content {
    width: 80%;
    max-width: 80%;
    max-height: 60%;
    border-radius: 12px;
  }

  /deep/.u-safe-bottom {
    padding-bottom: 0 !important;
  }
}
</style>