import http from "@/utils/http.js"


export function saveWebCustomDetail(data) {
    return http({
        url: "/api/web/personal/saveWebCustomDetail", data,
        method: "POST"
    })
}

export function personDetail() {
    return http({
        url: "/api/web/personal/getPersonDetail",
        method: "GET",
    })
}

export function getCustomDetail() {
    return http({
        url: "/api/web/personal/getCustomDetail",
        method: "GET",
    })
}

export function getProgressDetail() {
    return http({
        url: "/api/web/competition/getParticipantOrMaterial",
        method: "GET",
    })
}

export function getBaseInfo() {
    return http({
        url: "/api/web/personal/getBaseInfo",
        method: "GET",
    })
}
//查询课程项目列表
export function getCoursetPage(data) {
    return http({
        url: "/api/web/personal/getCourseProjectPage",
        data:data,
        method: "GET",
    })
}
//课程项目删除和取消
export function deleteCourse(data) {
    return http({
        url: "/api/web/personal/deleteCourseProject?courseApplyId=" + data.courseApplyId + "&flag=" + data.flag,
        method: "DELETE",
        wait: data.wait
    })
}
//查询课程项目详情
export function getCourseDetail(courseApplyId) {
    return http({
        url: "/api/web/personal/getCourseProjectDetail/"+ courseApplyId,
        method: "GET",
    })
}
//分页查询参赛记录列表
export function getCompetitionPage(data) {
    return http({
        url: "/api/web/personal/getCompetitionPage",
        data:data,
        method: "GET",
    })
}
//取消参赛报名
export function cancelApply(applyId) {
    return http({
        url: "/api/web/personal/cancelApply/"+ applyId,
        method: "GET",
    })
}
//删除参赛报名
export function deleteApply(applyId) {
    return http({
        url: "/api/web/personal/deleteApply/"+ applyId,
        method: "GET",
    })
}
//查询参赛报名详情
export function getApplyDetail(applyId) {
    return http({
        url: "/api/web/personal/getParticipantDetail/"+ applyId,
        method: "GET",
    })
}
// 获取参赛记录进展
export function getRecodeProgress(applyId) {
	return http({
		url: "/api/web/personal/getParticipantProgressDetail/" + applyId,
		method: 'GET',
		showLoading: true
	})
}
//分页查询政策办理列表
export function getPolicyPage(data) {
    return http({
        url: "/api/web/personal/getHandleDetailPage",
        data:data,
        method: "GET",
    })
}
//查询政策办理详情
export function getPolicyDetail(data) {
    return http({
        url: "/api/web/personal/getHandleDetail",
        data:data,
        method: "GET",
    })
}
//删除和取消政策申请
export function removeHandle(data) {
    return http({
        url: "/api/web/personal/removeHandle?id=" + data.id + '&flag=' + data.flag,
        data:data,
        method: "DELETE",
    })
}
//分页查询订单列表
export function getOrderPage(data) {
    return http({
        url: "/api/web/personal/getOrderPage",
        data:data,
        method: "GET",
    })
}
//取消订单
export function removeOrder(orderId) {
    return http({
        url: "/api/web/personal/removeOrder/"+ orderId,
        method: "DELETE",
    })
}
//查询订单详情
export function getOrderDetail(data) {
    return http({
        url: "/api/web/personal/getOrderDetail",
        data:data,
        method: "GET",
    })
}
