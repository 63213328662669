<template>
  <div class="match-list">
    <!-- <div class="item first-item">
      <div class="i-center">
        <div class="title-wrap">
          <div class="title">赛事动态</div>
          <div class="more" @click="toMore">
            <span>更多</span>
            <span class="icon iconfont icon-more"></span>
          </div>
        </div>
        <div class="list">
          <a class="item-dy" v-for="(item, index) in matchDynamicList" :key="index" @click="dynamicDetailClick(item.id)">
            <div class="item-dynamic">
              <div class="circle"></div>
              <div class="content">{{ item.title }}</div>
              <div class="time">{{ item.time }}</div>
            </div>
            <div class="item-dec">
              {{ item.dynamicContent }}
            </div>
          </a>
        </div>
      </div>
    </div> -->
    <div
      class="item matchItem"
      v-for="(item, index) in matchList"
      :key="index"
      @click="toMatchDetail(item)"
    >
      <match-plate :item="item"></match-plate>
    </div>
  </div>
</template>

<script>
import MatchPlate from "@/components/plate/matchPlate.vue";

export default {
  props: {
    matchList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    matchDynamicList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: { MatchPlate },
  data() {
    return {};
  },
  methods: {
    //赛事详情
    toMatchDetail(item) {
      this.$router.push({
        path: "/match/matchDetail/" + item.id,
      });
    },
    //更多赛事动态
    toMore() {
      console.log("tiaozhuan");
      this.$router.push("/matchDynamic");
    },
    //跳转动态详情
    dynamicDetailClick(id) {
      this.$router.push({
        path: "/matchDynamic/dynamicDetail/" + id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.match-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .first-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .i-top {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 18px;
      padding: 16px 20px;
      width: 100%;
      height: 280px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

      .i-top-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 16px;
        border-radius: 6px;
        box-sizing: border-box;
        box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);
        cursor: pointer;

        .i-top-item-left {
          flex-shrink: 0;
          margin-right: 16px;

          .icon {
            font-size: 28px;
            color: #fff;
          }
        }

        .i-top-item-right {
          flex: 1;
          color: #fff;

          .subtitle {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .title {
            font-size: 16px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .i-center {
      flex: 1;
      padding: 10px 0;
      height: 196px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 2px 2px rgba($color: #000000, $alpha: 0.05);

      .title-wrap {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 10px;

        .title {
          display: flex;
          align-items: center;
          padding: 4px 20px;
          font-size: 18px;
          position: relative;
        }

        .title::after {
          content: " ";
          position: absolute;
          bottom: 0;
          background-color: #ffdc0b;
          width: 2em;
          height: 3px;
          border-radius: 2px;
          overflow: hidden;
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-shrink: 0;
          padding: 10px 20px;
          cursor: pointer;

          .icon {
            font-size: 12px;
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;

        a:hover {
          border: none !important;
        }

        .item-dy {
          padding: 14px 20px;
          cursor: pointer;
        }

        .item-dy:hover .item-dec,
        .item-dy:hover .item-dynamic .content {
          color: #0068c6 !important;
        }

        .item-dy:hover .item-dynamic .circle {
          background-color: #0068c6;
        }

        .item-dec {
          padding-left: 14px;
          margin-top: 8px;
          font-size: 12px;
          padding-right: 42px;
          color: #999;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }

        .item-dynamic {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .circle {
            margin-top: -1px;
            margin-right: 8px;
            width: 6px;
            height: 6px;
            background-color: #e5e5e5;
            border-radius: 50%;
          }

          .content {
            flex: 1;
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .time {
            min-width: 3.5em;
            flex-shrink: 0;
            margin-left: 10px;
            text-align: right;
            font-size: 12px;
            color: #999;
          }
        }
      }
    }
  }

  .item {
    width: calc(24% - 8px);
    margin-right: 25px;
    margin-bottom: 25px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 4px 4px 12px rgba($color: #000000, $alpha: 0.05);
  }

  .item:nth-child(4n) {
    margin-right: 0;
  }

  // .matchItem:hover {
  //   box-shadow: 4px 4px 12px rgba($color: #000000, $alpha: 0.15);
  // }

  /* Shrink */
  .matchItem {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
  }

  .matchItem:hover,
  .matchItem:focus,
  .matchItem:active {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
}
</style>