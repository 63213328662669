<template>
  <div class="formModule">
    <div class="formModule-header">
      <div class="title">
        <span class="order-num">{{ index }}.</span>
        <span class="txt">{{ formDetail.title }}</span>
      </div>
      <div class="extend">
        <div class="isMust" v-if="formDetail.isNeed == '1'">必填</div>
      </div>
    </div>
    <div class="subtitle" v-if="formDetail.rule.minNum">
      团队 | 至少添加{{ formDetail.rule.minNum }}名团队成员<span
        v-if="formDetail.rule.maxNum && formDetail.rule.maxNum != '0'"
        >，至多{{ formDetail.rule.maxNum }}名</span
      >
      <span v-if="formDetail.rule.placeholder"
        >， {{ formDetail.rule.placeholder }}</span
      >
    </div>
    <!-- <div class="formModule-content" @click="toAddMember()">
      <div class="mod-member">
        <div class="member-list" v-if="formDetail.value">
          {{ formDetail.value }}
        </div>
        <div class="member-empty" v-else>邀请添加成员</div>
      </div>
      <div class="op-inner">
        <div class="btn-link" @click.stop="refreshMember()">刷新</div>
        <div class="btn-button">去添加</div>
      </div>
    </div> -->
    <div class="formModule-content1">
      <div class="mod-member">
        <div
          class="member-list"
          v-if="memberData.projectMembers && memberData.projectMembers.length"
        >
          {{ memberNameDetail }}
        </div>
        <div class="member-empty" v-else>邀请添加成员</div>
      </div>
      <div class="op-inner">
        <div class="btn-txt">
          <span class="num"
            >{{ memberData.projectMembers ? memberData.projectMembers.length : 0 }} 人</span
          >
        </div>
      </div>
    </div>
    <div class="op-outer">
      <div class="btn-button" hover-class="hover-effect" @click="toAddMember">
        去添加
      </div>
      <div class="btn-button" hover-class="hover-effect" @click="refreshMember">
        刷新
      </div>
    </div>
    <!-- <div class="item-content">
      <div class="member-wrap">
        <div
          class="input"
          :class="{ ban: !formDetail.rule.isUpdate }"
          @click="toAddMember()"
        >
          <div class="mod-member">
            <div class="member-list" v-if="formDetail.value">
              {{ formDetail.value }}
            </div>
            <div class="member-empty" v-else>邀请添加成员</div>
          </div>
        </div>
        <div class="more">
          <div class="refresh" v-if="!isRefreshMember">
            <span class="span" :class="{ ban: !formDetail.rule.isUpdate }"
              >刷新</span
            >
          </div>
          <div class="refresh" v-else>
            <span class="span" :class="{ ban: !formDetail.rule.isUpdate }"
              >...</span
            >
          </div>
          <div
            :class="{ ban: !formDetail.rule.isUpdate }"
            style="font-size: 16px"
          >
            {{ formDetail.value ? formDetail.value.length : 0 }}
          </div>
          <div class="">
            <span
              class="icon iconfont icon-more"
              :class="{ ban: !formDetail.rule.isUpdate }"
            ></span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="popup-wrap" v-if="showPopup">
      <el-dialog :visible.sync="isMatchShow" :close-on-click-modal="false" @close="closePopup">
        <match-add-member
          :matchId="matchId"
          :categoryId="categoryId"
          :memberData="memberData"
          :rules="formDetail.rule"
        ></match-add-member>
      </el-dialog>
      <el-dialog :visible.sync="isStudyShow" :close-on-click-modal="false" @close="closePopup">
        <study-add-member
          :studyId="studyId"
          :memberData="memberData"
          :rules="formDetail.rule"
        ></study-add-member>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getMatchProjectMember } from "@/api/match/match";
import { getStudyProjectMember } from "@/api/course/course";
import matchAddMember from "@/views/match/components/matchAddMember";
import studyAddMember from "@/views/course/components/studyAddMember";

export default {
  components: {
    matchAddMember,
    studyAddMember,
  },
  props: {
    formItem: {
      type: Object,
    },
    index: {
      type: Number,
    },
    matchId: {
      type: String,
    },
    cateId: {
      type: String,
    },
    studyId: {
      type: String,
    },
  },
  data() {
    return {
      formDetail: this.formItem,
      categoryId: this.cateId,
      showPopup: false,
      isRefreshMember: false,
      memberData: {},
      isMatchShow: false,
      isStudyShow: false,
      memberNameDetail: ''
    };
  },
  methods: {
    //invite 团队成员
    //添加成员
    toAddMember() {
      this.showPopup = true;
      this.formDetail.switchYn = true;
      if (this.formDetail.switchYn && this.matchId && this.categoryId) {
        this.isMatchShow = true;
      } else if (this.formDetail.switchYn && this.studyId) {
        this.isStudyShow = true;
      }
    },
    //刷新成员
    refreshMember(e) {
      if (this.matchId && this.categoryId) {
        this.matchMember();
      } else if (this.studyId) {
        this.studyMember();
      }
      if(!e) return
      this.$message.success(`刷新成功`);
    },
    matchMember() {
      if (!this.matchId || !this.categoryId) return;
      getMatchProjectMember({
        competitionId: this.matchId,
        categoryId: this.categoryId,
      }).then((res) => {
        if (res.code != 200) return;
        this.memberData = res.data;
        console.log("matchMember", res);
        this.formDetail.value = "";
        if (res.data.projectMembers.length) {
          this.formDetail.value = "";
          res.data.projectMembers.forEach((item) => {
            this.formDetail.value += item.name + "；";
          });
        }
      });
    },
    studyMember() {
      getStudyProjectMember({
        courseId: this.studyId,
      }).then((res) => {
        if (res.code != 200) return;
        this.memberData = res.data;
        console.log("studyMember", res);
        if (res.data.projectMembers.length) {
          this.memberNameDetail = ''
          this.formDetail.value = [];
          res.data.projectMembers.forEach((item) => {
            this.formDetail.value.push(item.customId);
            this.memberNameDetail += item.name + '；'
          });
          console.log("this.formDetail.value---",this.formDetail.value);
        }
      });
    },
    closePopup() {
      this.showPopup = false;
      this.refreshMember(0)
    },
  },
  watch: {
    "formDetail.value": {
      handler() {
        this.$bus.$emit("formDetail", this.formDetail);
      },
    },
    cateId: {
      handler(val) {
        this.categoryId = val;
        this.refreshMember(0);
      },
      deep: true,
      immediate: true,
    },
    studyId: {
      handler() {
        this.refreshMember(0);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.formModule {
  font-size: 14px;
  padding: 15px;
  height: auto;
  background-color: #ffffff;
  .ban {
    color: #c0c0c0 !important;
  }
  .formModule-header {
    display: flex;
    margin-bottom: 10px;

    .title {
      font-size: 15px;
      font-weight: 600;
      .txt::after {
        content: "：";
      }
    }

    .extend {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 10px;
    }
  }

  .isMust {
    color: #ff0000;
    font-size: 11px;
  }

  .subtitle {
    margin-bottom: 12px;
    line-height: 1.35;
    font-size: 12px;
    color: #9b9999;
  }

  .formModule-content {
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    justify-content: space-between;
    height: auto;

    .mod-member {
      flex: 1;
      background-color: #f7f7f7;
      display: flex;

      .member-list {
        flex: 1;
        background-color: transparent;
        border: none;
        padding: 20px;
        font-size: 16px;
      }

      .member-empty {
        flex: 1;
        background-color: transparent;
        border: none;
        padding: 0 15px;
        font-size: 14px;
        color: #ccc;
      }
    }
  }

  .op-inner {
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    line-height: 1;

    .btn-icon,
    .btn-more {
      font-size: 16px;
      display: flex;
      align-items: center;
      padding: 8px 10px;
      color: #9b9999;
    }

    .btn-link {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 8px 10px;
      color: #0099cc;
    }

    .btn-txt {
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 8px 10px;
      color: #9b9999;
    }

    .btn-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #199ed8;
      color: #ffffff;
      font-size: 13px;
      border-radius: 4px;
      padding: 0 10px;
      margin: 8px 10px 8px 0;
      line-height: 2;
      max-height: 36px;
      cursor: pointer;
    }
  }
  .formModule-content1 {
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    justify-content: space-between;
    min-height: 40px;
    height: auto;

    .mod-member {
      flex: 1;
      background-color: #f7f7f7;
      display: flex;

      .member-list {
        flex: 1;
        background-color: transparent;
        border: none;
        padding: 10px;
        font-size: 14px;
      }

      .member-empty {
        flex: 1;
        background-color: transparent;
        border: none;
        padding: 0 10px;
        font-size: 14px;
        color: #9b9999;
      }
    }
  }
}
.op-outer {
  margin: 8px 0;
  display: flex;
  align-items: center;

  .btn-button {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    height: 32px;
    color: #ffffff;
    background-color: #199ed8;
    border-radius: 6px;
    padding: 0 15px;
    margin-right: 5px;
    font-size: 13px;
    cursor: pointer;
  }
}
.item-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .member-wrap {
    flex: 1;
    display: flex;
    padding: 10px;
    background-color: #f2f2f2;
    border-radius: 4px;
    font-size: 15px;
    .input {
      flex: 1;
      display: flex;
      align-items: center;
      height: 22px;
    }
  }
  .more {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    margin-left: 12px;
    .refresh {
      font-size: 14px;
      padding: 0px 3px;
      margin-right: 10px;
      .span {
        color: rgb(0, 153, 204);
        cursor: pointer;
      }
    }
    .icon {
      font-size: 16px;
      color: #666;
    }
  }
}
.popup-wrap {
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 101;
    background: rgba(0, 0, 0, 0.5);
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: 60%;
    padding: 66px 32px 0;
    overflow: auto;
    background-color: #fff;
    border-radius: 6px;
    z-index: 102;

    .close-btn {
      position: absolute;
      top: 8px;
      right: 12px;
      padding: 12px;
      color: #333;
      font-size: 16px;
      cursor: pointer;
    }
  }
}
</style>