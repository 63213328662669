import http from "@/utils/http.js"

//获取banner列表
export function getHomeBannerList() {
    return http({
        url: "/api/web/banner/getBannerList/1",
        method: "GET",
    })
}
//获取分页推荐政策
export function getPolicyPage(data) {
    return http({
        url: "/api/web/policy/getPolicyRecDegreePage",
        method: "GET",
        data: data
    })
}
//服务站
export function getStationList(data) {
	return http({
		url: "/api/web/station/getServiceStationList",
		method: 'GET',
		data: data
	})
}
// 获取全部优惠卷
export function getCouponPage(data) {
	return http({
		url: "/api/web/coupon/getCouponPage",
		method: 'GET',
		data: data,
	})
}
// 获取动态板块数据
export function getDynamicData(data) {
	return http({
		url: "/api/web/dynamic/getDynamicList",
		method: 'GET',
        data: data

	})
}