<template>
  <div class="station-wrap">
    <div class="tab-wrap">
      <div
        class="tab-item"
        :class="{ 'tab-active': current == 1 }"
        @click="changeTab(1)"
      >
        服务站（{{ servicePageCount }}个）
      </div>
      <div
        class="tab-item"
        :class="{ 'tab-active': current == 2 }"
        @click="changeTab(2)"
      >
        联络站（{{ liaisonPageCount }}个）
      </div>
    </div>
    <div class="station-block" v-if="stationList.length && current == 1">
      <commonstation :list="stationList"></commonstation>
      <div class="m-pager">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-size="limit"
          :total="servicePageCount"
          @current-change="changePageClick"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else-if="stationList.length == 0  && current == 1">
         <el-empty description="暂无服务站"></el-empty>
    </div>
    <div class="station-block" v-if="LiasonList.length && current == 2">
      <commonstation :list="LiasonList" :type="2"></commonstation>

      <div class="m-pager">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-size="limit"
          :total="liaisonPageCount"
          @current-change="changePageClick"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else-if="LiasonList.length == 0  && current == 2">
         <el-empty description="暂无联络站"></el-empty>
    </div>
  </div>
</template>

<script>
// import {
//   getStationList
// } from "@/api/home/home.js";
import {
  getServiceStationList,
  getLiaisonStationList,
  getStaionCount,
} from "@/api/station/station.js";
import Commonstation from "@/components/station/commonStation.vue";

export default {
  components: {
    Commonstation
  },
  props: {
    // list: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data() {
    return {
      stationList: [],
      LiasonList: [],
      limit: 10,
      current: 1,
      servicePageCount: 0,
      liaisonPageCount: 0,
    };
  },
  created() {
    this.getStaionCount();
    this.getStationList();
  },
  methods: {
    getStaionCount() {
      getStaionCount().then((res) => {
        this.liaisonPageCount = res.data.liaison_station;
        this.servicePageCount = res.data.service_station;
      });
    },
    getStationList(page = 1) {
      const params = {
        page: page,
        limit: this.limit,
      };
      getServiceStationList(params).then((res) => {
        this.stationList = res.data.stationList;
      });
    },
    getLiasonList(page = 1) {
      const params = {
        page: page,
        limit: this.limit,
      };
      getLiaisonStationList(params).then((res) => {
        this.LiasonList = res.data.stationList;
      });
    },
    changeTab(e) {
      this.current = e;
      if (e == 1) return this.getStationList();
      if (e == 2) return this.getLiasonList();
    },
    //切换页面
    changePageClick(page) {
      this.loading = true;
      if (this.current == 1) return this.getStationList(page);
      if (this.current == 2) return this.getLiasonList(page);
    },
    toDetail(item) {
      this.$router.push({
        path: "/station/stationDetail/" + item.stationId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.station-wrap {
  margin-bottom: 30px;
  .tab-wrap {
    display: flex;
    margin: 30px;
    border-bottom: 1px solid #eaeaea;

    .tab-item {
      font-size: 20px;
      color: #303133;
      padding: 10px 0;
      margin: 0 40px;
      cursor: pointer;
    }

    .tab-active {
      color: #87c4f7;
      border-bottom: 6px solid #87c4f7;
    }
  }
  .m-pager {
    margin: 24px 0;

    .el-pagination {
      display: flex;
      justify-content: center;

      /deep/ .btn-prev {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-prev:hover:enabled {
        color: #409eff;
      }

      /deep/ .number {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .more {
        width: 40px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .btn-next {
        width: 100px;
        height: 40px;
        border: 1px solid #e4e4e4;
        background-color: #fff;
      }

      /deep/ .active {
        color: #fff;
        background-color: #409eff;
      }

      /deep/ .btn-next:hover:enabled {
        color: #409eff;
      }
    }
  }
}
.station-block {
  flex: 1;
  padding: 0 20px;
  margin: 20px 0;
}
</style>